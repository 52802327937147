import { TableCellData, TableCellDataType } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import { AppChartData } from 'Atomic/organisms/Chart/AppChart';


const formatCellValueToNumber = (value: string|number) => {

    if (isNaN(Number(value))) {

        return 0;
    }

    return Number(value);
}

export const slideTableToChartData = (slideTable: Array<Array<TableCellData>> = [[]]) => {

    const rowHeaders: Array<string> = [];

    const columnHeaders: Array<string> = [];

    const cellData: Array<Array<string | number>> = [];

    (slideTable || []).forEach((rows, columnIndex) => {

        rows.forEach((cell, rowIndex) => {

            if (cell.cellType === TableCellDataType.Heading) {

                if (columnIndex === 0) {

                    columnHeaders.push(`${cell.content}`);
                }

                if (rowIndex === 0) {

                    rowHeaders.push(`${cell.content}`);
                }

                return;
            }

            if (!cellData[columnIndex]) {

                cellData[columnIndex] = [];
            }

            cellData[columnIndex].push(cell.content);
        })
    })

    const isMultiDimensionalTable = columnHeaders.length > 1 && rowHeaders.length > 1;

    const chartsData: AppChartData = {
        columns: [],
        data: [],
    }

    /** Table contains both column/row headers */
    if (isMultiDimensionalTable) {

        columnHeaders.forEach(header => {

            chartsData.columns.push(header);
        })

        rowHeaders.forEach((header) => {

            chartsData.data.push([ header ]);
        })

        cellData.forEach((column, columnIndex) => {

            column.forEach((cell) => {

                let cellValue = Number(cell);

                if (isNaN(cellValue)) {
                    /** Can't parse string/text values */
                    cellValue = 0;
                }

                if (!chartsData.data[columnIndex]) {

                    chartsData.data[columnIndex] = [];
                }

                chartsData.data[columnIndex].push(cellValue);
            })
        })

        chartsData.data.shift();

        return chartsData;
    }

    /** Table only contains row headers */
    if (Boolean(rowHeaders.length > 1)) {

        const cells: Array<Array<string|number>> = [];

        let cellRowSize = 0;

        rowHeaders.forEach((header, headerIndex) => {

            const cellRow = [
                header,
                ...(cellData[headerIndex] || [])
                .map((cellValue) => formatCellValueToNumber(cellValue))
            ];

            cellRowSize = Math.max(cellRowSize, cellRow.length);

            cells.push(cellRow);
        });

        chartsData.data = cells;

        chartsData.columns = Array.from({ length: cellRowSize }).map((_, index) => `Entry ${index + 1}`);
    }

    /** Table only contains column headers */
    if (Boolean(columnHeaders.length > 1)) {

        const cells: Array<Array<string | number>> = [];

        columnHeaders.forEach((header, headerIndex) => {

            const cellColumn = [
                header,
                ...(cellData || [])
                .map((row) => formatCellValueToNumber(row[headerIndex]))
            ];

            cells.push(cellColumn.filter(v => v !== undefined));
        });

        chartsData.data = cells;

        chartsData.columns = Array.from({ length: cells[0].length }).map((_, index) => `Entry ${index + 1}`);
    }

    return chartsData;
}