import { AnyAction } from "redux";
import { SendstepsIntegrations } from "Types/appTypes";
import { LayoutState, MobileEditorTabs, SelectSlideTab, SlideOptionTabs, SlideTypeTabs } from "Types/layoutTypes";

const initialLayoutState: LayoutState = {
	selectedMobileEditorTab: MobileEditorTabs.Slides,
	leftDrawerOpen: false,
	rightDrawerOpen: false,
	slideIdToRemove: null,
	advancedPresentationOptionsShown: false,
	powerpointModalOpen: false,
	microsoftTeamsModalOpen: false,
	appSurveyModalOpen: false,
	appVersionBannerShowing: false,
	shownMissingFeature: null,
	showCreateFirstPresentation: false,
	guidedTourRunning: false,
	guidedTourStep: 0,
	licenseRequestFailedOverlayOpen: false,
	licenseMultiOverlayOpen: false,
	licenseEligibleOverlayOpen: false,
	welcomeOverlayOpen: false,
	shownZohoFormUrl: null,
	supportPanelOpen: false,
	planDetailsLoading: false,
	paymentSuccessDialogOpen: false,
	paymentPendingDialogOpen: false,
	paymentFailedDialogOpen: false,
	newsFeedOpen: false,
	showCantDownloadAddinFromMobileDevice: false,
	showDeletePresentationWithoutSlides: false,
	selectedSettingsIntegration: SendstepsIntegrations.WebApp,
	showCantEditInspirationTemplateDialog: false,
	shownMissingFeatures: null,
	selectSlideDialogActiveTab: SelectSlideTab.Questions,
	exampleSlideTypeShown: null,
	selectedSlideOptionTab: SlideOptionTabs.Design,
	selectedSlideTypeTab: SlideTypeTabs.Question,
	showImageUploadDialog: false,
	showAIPresentationReadyDialog: false,
	showUploadDocumentDialog: false,
	isRewriting: false,
	showSlideLogoUploadDialog: false,
	speakerNotesExpanded: true,
}

export const layoutReducer = (state: LayoutState = initialLayoutState, { type, payload }: AnyAction) : LayoutState => {
	switch (type) {
		case 'TOGGLE_SPEAKER_NOTES': {

			return {
				...state,
				speakerNotesExpanded: payload,
			}
		}
		case 'SET_SHOW_SLIDE_LOGO_UPLOAD_DIALOG': {

			return {
				...state,
				showSlideLogoUploadDialog: payload,
			}
		}
		case 'SET_IS_REWRITING': {

			return {
				...state,
				isRewriting: payload,
			}
		}
		case 'SET_SHOW_UPLOAD_DOCUMENT_DIALOG': {
			return {
				...state,
				showUploadDocumentDialog: payload,
			}
		}
		case 'SET_SHOW_AI_PRESENTATION_READY_DIALOG': {

			return {
				...state,
				showAIPresentationReadyDialog: payload,
			}
		}
		case 'SET_SHOW_IMAGE_UPLOAD_DIALOG': {

			return {
				...state,
				showImageUploadDialog: payload,
			}
		}
		case 'SET_SLIDE_OPTION_TAB': {

			return {
				...state,
				selectedSlideOptionTab: payload,
			}
		}
		case 'SET_SLIDE_TYPE_TAB': {

			return {
				...state,
				selectedSlideTypeTab: payload,
			}
		}
		case 'SET_EXAMPLE_SLIDE_LAYOUT_SHOWN': {
			return {
				...state,
				exampleSlideTypeShown: payload,
			}
		}
		case 'SET_SELECT_SLIDE_DIALOG_ACTIVE_TAB': {
			return {
				...state,
				selectSlideDialogActiveTab: payload,
			}
		}
		case 'SET_SHOW_MISSING_PRESENTATION_FEATURES': {
			return {
				...state,
				shownMissingFeatures: payload,
			}
		}
		case 'SET_SHOW_CANT_EDIT_INSPIRATION_TEMPLATE_DIALOG': {
			return {
				...state,
				showCantEditInspirationTemplateDialog: payload,
			}
		}
		case 'SET_SELECTED_SETTINGS_INTEGRATION': {
            return {
                ...state,
                selectedSettingsIntegration: payload,
            }
        }
		case 'SET_SHOW_DELETE_PRESENTATION_WITHOUT_SLIDES': {
			return {
				...state,
				showDeletePresentationWithoutSlides: payload,
			}
		}
		case 'SET_SELECTED_MOBILE_EDITOR_TAB': {
			return {
				...state,
				selectedMobileEditorTab: payload,
			}
		}
		case 'TOGGLE_NEWS_FEED_OPEN': {
			return {
				...state,
				newsFeedOpen: payload,
			}
		}
		case 'TOGGLE_PAYMENT_SUCCESS_DIALOG_OPEN': {
			return {
				...state,
				paymentSuccessDialogOpen: payload,
			}
		}
		case 'TOGGLE_PAYMENT_PENDING_DIALOG_OPEN': {
			return {
				...state,
				paymentPendingDialogOpen: payload,
			}
		}
		case 'TOGGLE_PAYMENT_FAILED_DIALOG_OPEN': {
			return {
				...state,
				paymentFailedDialogOpen: payload,
			}
		}
		case 'TOGGLE_PLAN_DETAILS_LOADING': {
			return {
				...state,
				planDetailsLoading: payload,
			}
		}
		case 'TOGGLE_SUPPORT_PANEL': {

			return {
				...state,
				supportPanelOpen: payload,
			}
		}
		case 'SET_SHOWN_ZOHO_FORM_URL': {
			return {
				...state,
				shownZohoFormUrl: payload,
			}
		}
		case 'SET_LICENSE_REQUEST_FAILED_OVERLAY_OPEN': {
			return {
				...state,
				licenseRequestFailedOverlayOpen: payload
			}
		}
		case 'SET_WELCOME_OVERLAY_OPEN': {
			return {
				...state,
				welcomeOverlayOpen: payload
			}
		}
		case 'SET_LICENSE_ELIGIBLE_OVERLAY_OPEN': {
			return {
				...state,
				licenseEligibleOverlayOpen: payload
			}
		}
		case 'SET_MULTI_LICENSE_OVERLAY_OPEN': {
			return {
				...state,
				licenseMultiOverlayOpen: payload
			}
		}
		case 'SET_GUIDED_TOUR_RUNNING': {
			return {
				...state,
				guidedTourRunning: payload,
			}
		}
		case 'SET_GUIDED_TOUR_STEP': {
			return {
				...state,
				guidedTourStep: payload
			}
		}
		case 'SHOW_CREATE_FIRST_PRESENTATION': {
			return {
				...state,
				showCreateFirstPresentation: payload
			}
		}
		case 'SHOW_MISSING_FEATURE': {
			return {
				...state,
				shownMissingFeature: payload
			}
		}
		case 'TOGGLE_APP_SURVEY_MODAL': {
			return {
				...state,
				appSurveyModalOpen: payload
			}
		}
		case 'TOGGLE_ADVANCED_PRESENTATION_OPTIONS': {
			return {
				...state,
				advancedPresentationOptionsShown: payload,
			}
		}
		case 'TOGGLE_LEFT_DRAWER': {
			return {
				...state,
				leftDrawerOpen: payload,
			}
		}
		case 'TOGGLE_RIGHT_DRAWER': {
			return {
				...state,
				rightDrawerOpen: payload,
			}
		}
		case 'SET_SLIDE_ID_TO_REMOVE': {
			return {
				...state,
				slideIdToRemove: payload,
			}
		}
		case 'SET_SHOW_CANT_DOWNLOAD_ADDIN': {
			return {
				...state,
				showCantDownloadAddinFromMobileDevice: payload,
			}
		}
		default:
			return state;
	}
}