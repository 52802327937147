import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, Drawer } from '@mui/material';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppDialogCloseIcon from 'Atomic/atoms/DialogClose/AppDialogCloseIcon';
import FullSizeContainer from 'Atomic/atoms/FullSizeContainer';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import AppDynamicTable, { TableCellData } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import AppChart, { AppChartType } from 'Atomic/organisms/Chart/AppChart';
import WysiwygEditorContainer from 'Atomic/organisms/WysiwygEditor/variants/WysiwygEditorContainer';
import FullHeightContainer from 'Components/common/FullHeightContainer';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import { WysiwygToolBarType } from 'Components/common/util/wysiwygEditor/WysiwygEditor';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import useSlideActions from 'Hooks/useSlideActions';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { slideTableToChartData } from 'Scripts/tableHelper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType, EditorSlideField } from 'Types/presentationTypes';
import React, { useState } from 'react';
import { classes } from './style.css';

interface GraphSlideEditorProps extends SlideConsumerComponent<ContentSlideType> {
    graphType: AppChartType
}

const GraphSlideEditor = ({
    slide,
    graphType = AppChartType.Line,
}: GraphSlideEditorProps) => {

    const { setSlideSetting, } = useSlideActions();

    const [adjustTableDataModalOpen, setAdjustTableDataModalOpen] = useState<boolean>(false);


    const slideProperties = getSlideProperties(slide);

    const handleOnUpdateCell = (cellData: TableCellData, rowIndex: number, columnIndex: number) => {

        const newTableData = [...(slide.table || [])];

        if (!newTableData[rowIndex]) {

            newTableData[rowIndex] = [];
        }

        newTableData[rowIndex][columnIndex] = { ...cellData };

        setSlideSetting('table', newTableData, slide.id);
    }

    /**
     * @param newTableStructure
     */
    const handleOnUpdateTableStructure = (newTableStructure: Array<Array<TableCellData>>) => {

        setSlideSetting('table', newTableStructure, slide.id);
    }

    const { translatePlaceholder } = useTranslations();

    return (
        <FullHeightContainer data-testid={testIds.SLIDE_TYPE_QUOTE}>
            <LayoutWrapper
                interactive={true}
                layoutType={slide.slideLayout}
                videoURL={slide.videoURL}
                imageURL={slide?.file?.base64 || slide?.filename}
                imageStyle={slide.filenameStyle}
                backgroundOpacity={slide.backgroundOpacity}
                backgroundColor={slide.backgroundColor}
                title={(
                    <FullWidthContainer>
                        <Box p={4}>
                            <WysiwygEditorContainer
                                value={slide.title}
                                getPlaceholderHtml={slideProperties.text.getHtmlTitlePlaceholder}
                                updateValue={newTitle => setSlideSetting(EditorSlideField.Title, newTitle, slide.id)}
                                placeholderText={slideProperties.text.titlePlaceholder}
                                fontSize={slideProperties.slideSettings.titleFontSize}
                                toolbarType={WysiwygToolBarType.Simple}
                            />
                        </Box>
                    </FullWidthContainer>
                )}
                content={(
                    <FullSizeContainer>
                        <div className={classes.chartContainer} >
                            <AppButton
                                as={AppButtonVariant.Outlined}
                                startIcon={<EditIcon />}
                                onClick={() => setAdjustTableDataModalOpen(true)}
                                className={classes.editChartIcon}
                            >
                                {translatePlaceholder("ADJUST_TABLE_DATA")}
                            </AppButton>
                            <AppChart
                                chartType={graphType}
                                chartData={slideTableToChartData(slide.table)}
                                chartElementProps={{
                                    className: classes.chart,
                                }}
                            />
                        </div>
                    </FullSizeContainer>
                )}
            />
            <Drawer
                classes={{
                    paper: classes.tableDrawerPaper
                }}
                hideBackdrop
                anchor="bottom"
                open={adjustTableDataModalOpen}
                onClose={() => setAdjustTableDataModalOpen(false)}
            >
                <AppDialogCloseIcon
                    onClose={() => setAdjustTableDataModalOpen(false)}
                />
                <AppText
                    py={2.5}
                    px={4}
                    height={64}
                    as={AppTextVariant.H5Bold}>
                    {translatePlaceholder("EDIT_TYPE_DATA", {
                        type: translatePlaceholder(slideProperties.text.nameTranslationKey)
                    })}
                </AppText>
                <Divider />
                <Box
                    p={4}
                    alignItems="flex-start"
                    justifyContent="center"
                    display="flex"
                    overflow={{
                        overflowY: 'auto',
                        width: '125%'
                    }}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            transform: `scale(.75)`,
                            transformOrigin: 'left top',
                        }}>
                        <AppDynamicTable
                            data={slide.table as Array<Array<TableCellData>>}
                            onUpdateCell={handleOnUpdateCell}
                            onUpdateTableStructure={handleOnUpdateTableStructure}
                        />
                    </Box>
                </Box>
            </Drawer>
        </FullHeightContainer>
    )
}

export default GraphSlideEditor;