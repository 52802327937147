import * as layoutActions from 'Actions/layoutActions';
import * as overviewActions from 'Actions/overviewActions';
import { setPresentationIdToRemove, setPresentationPageData, setPresentationPageIndex } from 'Actions/overviewActions';
import * as presentationActions from 'Actions/quizActions';
import { setPresentationDataFromAPICall, setPresentationErrors, setPresentationStyleId } from "Actions/quizActions";
import { TablePaginationParams } from 'Components/common/table/AppTable';
import { useAppRouting } from 'Hooks/useAppRouting';
import useOfflineSync from 'Hooks/useOfflineSync';
import { SessionRouteMatches, useSessionRoutes } from 'Hooks/useSessionRoutes';
import useUrl from 'Hooks/useUrl';
import { del, get, post } from 'Scripts/api';
import { PresentationErrors, SlideList, SlideVoteMap } from 'Types/presentationTypes';
import { isEmpty } from 'lodash';
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useLayout } from './useLayout';

const usePresentation = () => {

	const dispatch = useDispatch();
	const history = useHistory();
	const urls = useUrl();

	const {
		openSelectSlide
	} = useLayout();

	const { enqueueSnackbar } = useSnackbar();

	const { getRoute } = useSessionRoutes();

	const { presentationId } = useAppRouting();

	const setPresentationId = (id: number|null) => {

		dispatch(presentationActions.setPresentationId(id));
	}

	/** @Todo generalize all these GET/POST requests */
	const fetchPresentationDetails = async (id: number) => {

		setPresentationLoading(true);

		const data = await get(`presentations/${id}`);

		if (!data.error) {

			const apiSlideAnswers = data.slideAnswers as SlideVoteMap;

			const apiSlides = (data.slides || {}) as SlideList ;

			if (!isEmpty(apiSlideAnswers)) {

				for (const slideId in apiSlideAnswers) {

					apiSlides[slideId].options = apiSlideAnswers[slideId];
				}
			}

			/* Localstorage is checked for unsaved data */
			if (!applyDataFromLocalStorage(data)) {

				dispatch(setPresentationDataFromAPICall(data));

			} else {
				/** Still set the title since it's skipped due to LS check */
				dispatch(presentationActions.setQuizTitle(data.name));
			}

			if (!Boolean((Object.keys(apiSlides) || [])?.length)) {

				openSelectSlide();

				history.push(urls.editPresentation(presentationId));
			}

			setErrors(null);

			dispatch(setPresentationStyleId(data?.presentationStyleId));

		} else {

			console.warn(data.error);

			enqueueSnackbar('Something went wrong trying to retrieve this presentation.', { variant: 'error' });
		}

		setPresentationLoading(false);

		return data;
	}

	const { applyDataFromLocalStorage } = useOfflineSync(presentationId);

	const updatePresentationTitle = async (title: string) => {

		if (presentationId) {

			dispatch(presentationActions.setUpdatePresentationTitleLoading(true));

			const updatePresentation = await post('presentations/set-title', {
				presentationId,
				title,
			});

			dispatch(presentationActions.setUpdatePresentationTitleLoading(false));

			if (updatePresentation.error) {

				enqueueSnackbar(`Unable to update this presentation. ${updatePresentation.error}`, { variant: 'warning' })

				return;
			}
		}
	}

	const setPresentationTitle = (title: string) => {

		dispatch(presentationActions.setQuizTitle(`${title}`));
	}

	const duplicatePresentation = async (presentationId: number) => {

		const updatePresentation = await post('presentations/duplicate', {
			presentationId,
		});

		if (updatePresentation.error) {

			enqueueSnackbar(`Unable to duplicate this presentation. ${updatePresentation.error}`, { variant: 'warning' })

			return;
		}

		enqueueSnackbar(`Presentation duplicated!`, { variant: 'success' })

		return updatePresentation.newPresentationId;
	}

	const deletePresentation = async (id: number) => {

		dispatch(setPresentationIdToRemove(id))
	}

	const actuallyDeletePresentaton = async (presentationIdToRemove: number) => {

		try {

			const response = await del(`presentations/${presentationIdToRemove}`);

			if (response.error) {

				enqueueSnackbar(response.error, { variant: 'warning' });
			}

		} catch (error) {

			console.warn(error)
		}
	}

	const setErrors = (errors: PresentationErrors) => {

		dispatch(setPresentationErrors(errors));
	}

	const setPresentationLoading = (isLoading: boolean) => {

		dispatch(overviewActions.setPresentationLoading(isLoading));
	}

	const fetchPresentationPageData = async (paginationParams: TablePaginationParams) => {

		try {

			setPresentationLoading(true);

			const filterValues = new URLSearchParams(paginationParams);

			const fetchResult = await get(`presentations?${filterValues}`);

			dispatch(setPresentationPageData(fetchResult))

			if (Number(fetchResult.totalResultsCount) === 0) {

				dispatch(layoutActions.setShowWelcomeNewUser(true));
			}

		} catch (error) {

			console.warn(error)

		} finally {

			setPresentationLoading(false);
		}
	}

	const setPageIndex = (pageIndex: number) => {

		dispatch(setPresentationPageIndex(pageIndex));
	}

	const markPresentationAsEdited = async (id: number) => {

		try {

			const response = await post(`presentations/${id}/mark-edited`);

			if (response.error) {

				enqueueSnackbar(response.error, { variant: 'warning' });
			}

		} catch (error) {

			console.warn(error)
		}
	}

	const attemptStartPresentation = async (id: number) => {

		try {

			const canStart = await get(`presentations/${id}/can-start`);

			if (canStart && !canStart.error) {

				history.push(getRoute({
					presentationId: id,
				}, SessionRouteMatches.ExplanationSlide));

			} else if (canStart.error) {

				const missingFeatures = JSON.parse(canStart.error);

				dispatch(layoutActions.setShowMissingPresentationFeatures(missingFeatures));
			}

		} catch (error) {

			console.warn(error)
		}
	}

	const setPresentationLogo = (url: string|null) => {

		dispatch(presentationActions.setPresentationLogo(url));
	}

	const setPresentationUserLogo = (url: string|null) => {

		dispatch(presentationActions.setPresentationUserLogo(url));
	}

	return {
		setPresentationId,
		duplicatePresentation,
		deletePresentation,
		setPresentationTitle,
		updatePresentationTitle,
		fetchPresentationDetails,
		setErrors,
		fetchPresentationPageData,
		setPageIndex,
		setPresentationLoading,
		actuallyDeletePresentaton,
		markPresentationAsEdited,
		attemptStartPresentation,
		setPresentationLogo,
		setPresentationUserLogo,
	}
}

export default usePresentation;