import { UserRoleFriendlyNames } from 'Hooks/useAuthentication';
import { UserBrandingData, UserProps } from "Types/appTypes";


export const getFullUsername = (currentUser: UserProps | null = {} as UserProps) => {

    return `${currentUser?.firstName} ${currentUser?.lastName}`;
}

export const getUserInitials = (currentUser: UserProps | null = {} as UserProps) => {

    return `${`${currentUser?.firstName}`.charAt(0)} ${`${currentUser?.lastName}`.charAt(0)}`
}

export const isBrandOwner = (currentUser: UserProps | null = {} as UserProps, userBrandingData: UserBrandingData | null = {} as UserBrandingData) => {

    return (currentUser?.id === userBrandingData?.ownerId);
}

export const hasRole = (role: string = '', userRoles: Array<string> = []) => {

    return userRoles.includes(role);
}

export const hasOneOfRoles = (roles: Array<string> = [], userRoles: Array<string> = [],) => {

    for (const roleIndex in roles) {

        const role = roles[roleIndex];

        if (hasRole(role, userRoles)) {

            return true;
        }
    }

    return false;
}

export const getUserReadableRole = (roles: Array<string> = ['user']): string => {

    let [highestUserRole] = roles;

    roles.forEach(roleName => {

        const roleKeys = Object.keys(UserRoleFriendlyNames);

        const highestRoleIndex = roleKeys.indexOf(highestUserRole);

        const currentRoleIndex = roleKeys.indexOf(roleName);

        if (currentRoleIndex >= highestRoleIndex) {

            highestUserRole = roleName;
        }
    })

    return UserRoleFriendlyNames[highestUserRole];
}