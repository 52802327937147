import { Box, Button, Chip, Divider } from '@mui/material';
import AppSvgIcon, { AppSvgIconType } from 'Atomic/atoms/Svg/AppSvgIcon';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import UserAvatar from 'Components/beta/common/buttons/avatar/UserAvatar';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { useAuthentication } from 'Hooks/useAuthentication';
import { useLayout } from 'Hooks/useLayout';
import useSlideActions from 'Hooks/useSlideActions';
import { useTranslations } from 'Hooks/useTranslations';
import useUrl from 'Hooks/useUrl';
import { testIds } from 'Scripts/cypressTestIds';
import { AppState, UserProps } from 'Types/appTypes';
import { FeatureState } from 'Types/featureTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { classes } from './style.css';

interface ProfileSummaryProps {
	onClose: () => void;
}

const ProfileSummary = ({
	onClose,
}: ProfileSummaryProps) => {

	const currentUser = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentUser) || {} as UserProps;

	const planName = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).planInfo?.name) as string;

	const {
		logout
	} = useAuthentication();

	const {
		clearPresentation
	} = useSlideActions();

	const {
		toggleGuidedTour,
		setGuidedTourStep,
	} = useLayout();

	const history = useHistory();

	const urls = useUrl();

	const takeTour = () => {

		toggleGuidedTour(true);

		setGuidedTourStep(0);

		clearPresentation();

		history.push(urls.presentationEditor)

		onClose();
	}

	const navigateToMyAccountSettings = () => {

		history.push(urls.licenseAccount);

		onClose();
	}

	const navigateToBilling = () => {

		history.push(urls.licenseBilling);

		onClose();
	}

	const navigateToMyPlan = () => {

		history.push(urls.licenseSubscription);

		onClose();
	}

	const clickedLogout = () => {

		logout();

		onClose();
	}


	const { translatePlaceholder } = useTranslations();

	return (
		<>
			<div className={classes.nameContainer} >
				<div className={classes.avatarBox}>
					<UserAvatar />
				</div>
				<CustomTooltip title={<>
					{`${currentUser.firstName} ${currentUser.lastName}`}
					<br />
					{currentUser.email}
				</>}>
					<div className={classes.nameBox}>
						<AppText as={AppTextVariant.BodySmallBold} sx={{ color: "primary" }}>
							{`${currentUser.firstName} ${currentUser.lastName}`}
						</AppText>
						<AppText as={AppTextVariant.BodyTinyBold} sx={{ opacity: .5 }}>
							{currentUser.email}
						</AppText>
					</div>
				</CustomTooltip>
			</div>
			<Box py={1}>
				<Divider />
			</Box>
			<div className={classes.profileOptions}>
				<Button
					onClick={() => navigateToMyAccountSettings()}
					className={classes.profileButton}
					fullWidth
					data-testid={testIds.USER_MENU_MY_ACCOUNT_SETTINGS}
					startIcon={<AppSvgIcon type={AppSvgIconType.UserProfileIcon} className={classes.menuIcon} />}
					data-eltype="link"
					data-elaction="my_account_settings"
					data-elscreen="unspecified"
					data-elposition="profile_popup"
				>
					<AppText as={AppTextVariant.BodyTinyRegular} className={classes.menuText} >
						{translatePlaceholder("MY_PROFILE")}
					</AppText>
				</Button>
				<Button
					onClick={() => navigateToMyPlan()}
					className={classes.profileButton}
					fullWidth
					data-testid={testIds.USER_MENU_MY_PLAN}
					startIcon={<AppSvgIcon type={AppSvgIconType.UserPlanIcon} className={classes.menuIcon} />}
					data-eltype="link"
					data-elaction="my_plan"
					data-elscreen="unspecified"
					data-elposition="profile_popup"
				>
					<Box
						width="100%"
						display="flex"
						justifyContent="space-between">
						<AppText as={AppTextVariant.BodyTinyRegular} className={classes.menuText} >
							{translatePlaceholder("MY_PLAN")}
						</AppText>
						<Chip classes={{ root: classes.planNameChip, label: classes.planNameChipLabel }} label={<AppText as={AppTextVariant.BodyTinyBold}>
							{planName}
						</AppText>} />
					</Box>
				</Button>
				<Button
					onClick={() => navigateToBilling()}
					className={classes.profileButton}
					fullWidth
					data-testid={testIds.USER_MENU_BILLING}
					startIcon={<AppSvgIcon type={AppSvgIconType.UserPaymentIcon} className={classes.menuIcon} />}
					data-eltype="link"
					data-elaction="billing"
					data-elscreen="unspecified"
					data-elposition="profile_popup"
				>
					<AppText as={AppTextVariant.BodyTinyRegular} className={classes.menuText} >
						{translatePlaceholder("PAYMENT")}
					</AppText>
				</Button>
				<Button
					className={`${classes.profileButton} trackable-profile`}
					fullWidth
					onClick={() => takeTour()}
					startIcon={<AppSvgIcon type={AppSvgIconType.UserTakeTour} className={classes.menuIcon} />}
					data-testid={testIds.USER_MENU_TAKE_TOUR}
					data-eltype="link"
					data-elaction="take_a_tour"
					data-elscreen="unspecified"
					data-elposition="profile_popup"
				>
					<AppText as={AppTextVariant.BodyTinyRegular} className={classes.menuText} >
						{translatePlaceholder("TAKE_A_TOUR")}
					</AppText>
				</Button>
				<Button
					onClick={() => clickedLogout()}
					className={classes.profileButton}
					fullWidth
					startIcon={<AppSvgIcon type={AppSvgIconType.UserLogout} className={classes.menuIcon} />}
					data-testid={testIds.USER_MENU_LOG_OUT}
					data-eltype="link"
					data-elaction="logout"
					data-elscreen="unspecified"
					data-elposition="profile_popup"
				>
					<AppText as={AppTextVariant.BodyTinyRegular} className={classes.menuText} >
						{translatePlaceholder("LOG_OUT")}
					</AppText>
				</Button>
			</div>
		</>
	);
};

export default ProfileSummary;