import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import ClaimPromotionalPresentationContainer from 'Components/promotion/claim_presentations/ClaimPromotionalPresentationContainer';
import { useTranslations } from 'Hooks/useTranslations';
import useUrl from 'Hooks/useUrl';
import React from 'react';
import { classes } from './style.css';


const SignupText = () => {

    const { translatePlaceholder } = useTranslations();
    const urls = useUrl();

    return (
        <Box p={4}>
            <Typography
                variant="h1"
                textAlign="center"
                fontWeight={600}
                pt={3}>
                {translatePlaceholder('WELCOME')}
            </Typography>
            <ClaimPromotionalPresentationContainer />
            <Typography textAlign="center" mt={3}>
            {translatePlaceholder('LOG_INTO_YOUR_ACCOUNT_OR')} <a
                    className={classes.signupLink}
                    data-eltype="link"
                    data-elaction="sign_up_link"
                    data-elscreen="login"
                    href={urls.websiteSignupPage}>
                    {translatePlaceholder('SIGNUP_HERE')}
                </a> 🙌
            </Typography>
        </Box>

    );
};

export default SignupText;