import { AnyAction } from 'redux';
import { PresentationStyleState } from 'Types/presentationTypes';


const initialState: PresentationStyleState = {
    presentationStyles: [],
}

export const presentationStyleReducer = (state: PresentationStyleState = initialState, action: AnyAction) : PresentationStyleState => {
    switch (action.type) {
        case 'UPDATE_CURRENT_PRESENTATION_STYLE': {

            const { id, attribute, value } = action;

            const newStyles = [ ...state.presentationStyles];

            newStyles.forEach((newStyle, index) => {

                if (newStyle.id === id || (!id && newStyle.isDefault)) {

                    newStyles[index] = { ...newStyle, [attribute]: value };
                }
            })

            return {
                ...state,
                presentationStyles: newStyles
            }
        }
        case 'SET_PRESENTATION_STYLES': {
            return {
                ...state,
                presentationStyles: action.styles,
            }
        }
        default:
            return state
    }
}