import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PlayState } from 'Types/playTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const OpenEndedSlideMessage = ({ message }) => {

    const theme = useTheme();

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));


    const sessionData = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).sessionData);

    const joinedParticipants = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).joinedParticipants);

    const getParticipant = () => {

        if (!!Number(sessionData?.anonymousSources)) {

            return null;
        }

        const { participantId = null } = message || {};

        return (joinedParticipants || []).find(participant => Number(participant.participantId) === Number(participantId))
    }

    return (
        <Grid container justifyContent="center" alignItems="flex-start" item >
            <Grow in={true}>
                <div className={classes.openEndedMessage}>
                    <Grid container direction="row" alignItems="center" className={classes.paddingY}>
                        <Grid item xs container direction="column" alignItems="flex-start" className={classes.messageSection}>
                            <Grid item container justifyContent="center" direction="column">
                                <Typography className={classes.openEndedMessageText} variant="h4">
                                    {message?.text || 'n/a'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grow>
        </Grid>
    );
};

export default OpenEndedSlideMessage
