import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { IconButton, InputBase, MenuItem, Select, Typography } from '@mui/material';
import EditInput from 'Atomic/atoms/EditInput/EditInput';
import AppSpinner from 'Atomic/atoms/Spinner/AppSpinner';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { themeVars } from 'Components/theme/vanillaTheme.css';
import { useTranslations } from 'Hooks/useTranslations';
import { TDialogTypeObj } from 'Types/outlineTypes';
import { SlideTypes } from 'Types/slideTypes';
import React, { ChangeEvent } from "react";
import { classes } from "./style.css";

interface ISlideOutline {
    title?: string;
    content?: string;
    type: string;
    index: number;
    isLoading: boolean;
    dialogTypeObj: TDialogTypeObj;
    onChangeValue: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number)=> void;
    addSlideOutline: (index: number)=> void;
    deleteSlideOutline: (dialogType: "ai" | "remove", index: number)=> void;
    completeWithAi: (dialogType: "ai" | "remove", index: number)=> void;
    isSelected: boolean;
    onToggleSlideOutlineSelected: (index: number)=> void;
    setNewType: (type: string, index: number)=> void;
}

const SlideOutline = ({
    title = "",
    content = "",
    type,
    index,
    isLoading,
    dialogTypeObj,
    onChangeValue, 
    addSlideOutline, 
    deleteSlideOutline, 
    completeWithAi,
    isSelected,
    onToggleSlideOutlineSelected,
    setNewType,
}: ISlideOutline)=> {

    const { translatePlaceholder } = useTranslations();

    const slideTypesArr = [
        SlideTypes.MultipleChoice,
        SlideTypes.Quiz,
        SlideTypes.Wordcloud,
        SlideTypes.QA,
        SlideTypes.OpenEnded,
        SlideTypes.Points,
        SlideTypes.Content,
        SlideTypes.Title,
        SlideTypes.Image,
        SlideTypes.Video,
        SlideTypes.Quote,
        SlideTypes.Countdown,
        SlideTypes.TitleAndSubtitle,
    ];

    return(
        <div className={title == "" && content == "" ? classes.slideOutlineAnimation : ""}>
            <div className={classes.container}>
                <div className={classes.dragIconContainer}>
                    <IconButton
                        size='small'
                        className={classes.dragAndDropButton}
                        onClick={()=> {}}
                    >
                        <DragIndicatorOutlinedIcon className={classes.dragIcon}/>
                    </IconButton>
                </div>
                <div 
                    style={{
                        borderColor: isSelected  ? themeVars.color.selectedColor : themeVars.color.defaultBg, 
                        height: isSelected  ? "auto" : "59px"
                    }} 
                    className={classes.slideOutlineBox} 
                >
                    <div className={classes.flexBox}>
                        <div className={classes.flexLeftBox}>
                            <AppText as={AppTextVariant.FifthBg}>{index + 1}</AppText>
                        </div>
                        <div className={classes.flexCenterBox}>
                            <div className={classes.inputContainer}>
                                <EditInput 
                                    className={classes.nameInput} 
                                    name="title" 
                                    value={title}
                                    placeholder={translatePlaceholder("INSERT_TITLE")+"..."}
                                    disabled={isLoading && index == dialogTypeObj.index}
                                    onChange={(e)=> onChangeValue(e, index)}
                                />
                            </div>
                        </div>
                        <div className={classes.flexRightBox}>
                        <Select
                            value={type}
                            input={<InputBase className={classes.dropdown}/>}
                        >
                            {slideTypesArr.map((t, i)=> {
                                return(
                                    <MenuItem
                                        key={t + i}
                                        value={t}
                                        onClick={() => setNewType(t, index)}
                                    >
                                        <Typography>
                                            {translatePlaceholder(t.toUpperCase())}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                                
                        </Select>
                        </div>
                    </div>
                    <div className={classes.inputBoxContainer}>
                        <AppText as={AppTextVariant.FifthBgSmall}>{translatePlaceholder("SLIDE_STORYLINE")}</AppText>
                        <div className={classes.inputContainer}>
                            <EditInput 
                                className={classes.contentInput} 
                                multiline
                                name="content" 
                                value={content}
                                placeholder={translatePlaceholder("INSERT_STORYLINE")+"..."}
                                disabled={isLoading && index == dialogTypeObj.index} 
                                onChange={(e) => onChangeValue(e, index)}
                            />
                        </div>
                    </div>
                    <a className={classes.dragLine} onClick={()=> onToggleSlideOutlineSelected(index)}></a>
                </div>
                <div>
                    {isLoading && index == dialogTypeObj.index ? 
                        <span className={classes.rewriteLoader}><AppSpinner/></span>  :
                            !isLoading  ? <CustomTooltip title={translatePlaceholder("COMPLETE_WITH_AI")} placement={"top"}>
                                <div className={classes.iconContainer}>
                                    <IconButton
                                        size='small'
                                        onClick={()=> completeWithAi("ai", index)}
                                        className={classes.iconButton}
                                    >
                                        <AutoAwesomeOutlinedIcon className={classes.icon} fontSize="small"/>
                                    </IconButton>
                                </div>
                            </CustomTooltip> : <></>}
                    {!isLoading && <CustomTooltip title={translatePlaceholder("DELETE")} placement={"top"}>
                        <div className={classes.iconContainer}>
                            <IconButton
                                size='small'
                                onClick={()=> deleteSlideOutline("remove", index)}
                                className={classes.iconButton}
                            >
                                <DeleteOutlineIcon className={classes.icon}/>
                            </IconButton>
                        </div>
                    </CustomTooltip>}
                    {isLoading && index != dialogTypeObj.index && <div className={classes.emptyContainer}></div>}
                </div>
            </div>
            {!isLoading && <div className={classes.plusIconBox}>
                <CustomTooltip title={translatePlaceholder("ADD")} placement={"top"}>
                    <a onClick={()=> addSlideOutline(index)}>
                        <AddIcon className={classes.plusIcon}/>
                    </a>
                </CustomTooltip>
            </div>}
            {isLoading && <div className={classes.emptyPlusIconBox}></div>}
        </div>
    )
}
export default SlideOutline;