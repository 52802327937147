import Grid from '@mui/material/Grid';
import { setNewMessagesAreIn } from 'Actions/playActions';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import PlaySlideTitlePreview from 'Atomic/molecules/PlaySlideTitlePreview/PlaySlideTitlePreview';
import Html from 'Components/common/util/html/Html';
import SessionLoginInfo from 'Components/play/templates/geometry_template/shared_components/session_login_info/SessionLoginInfo';
import QASlideMessageList from 'Components/slides/shared/components/slide_messages/qa/list/QASlideMessageList';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useSlideNavigation } from 'Hooks/useSlideNavigation';
import { useInterval } from 'Scripts/intervalHook';
import { closeMessageRound, openMessageRound } from 'Scripts/requests';
import { NavigationState } from 'Scripts/slideHelper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { PlaySlideType, PlayState } from 'Types/playTypes';
import { PresentationState } from 'Types/presentationTypes';
import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { classes } from './style.css';

const questionStartDelayMs = 3500;

let QASlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {

    const slideProperties = getPlaySlideProperties(slide);

    const { presentationStyle } = usePresentationStyles();


    const newMessagesAreIn = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).newMessagesAreIn);

    const messages = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).messages);

    const slideNavigationState = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slideNavigationState);

    const dispatch = useDispatch();

    const {
        navigateToNextSlideState,
        isActiveState,
    } = useSlideNavigation();

    useInterval(() => {

        dispatch(setNewMessagesAreIn(false));

    }, newMessagesAreIn ? 2500 : null)

    useInterval(() => {

        navigateToNextSlideState();

    }, isActiveState(NavigationState.BeforeOpen) ? questionStartDelayMs : null)

    useEffect(() => {

        if (isActiveState(NavigationState.BeforeOpen)) {

            closeMessageRound(slide.id);
        }

        if (isActiveState(NavigationState.SlideOpen)) {

            openMessageRound(slide.id);
        }

    }, [slideNavigationState])

    /* Runs on unmounting */
    useEffect(() => {

        return () => {

            closeMessageRound(slide.id);
        }

    }, []);

    return <>
        <Grid
            container
            className={classes.questionContainer}
            sx={{
                color: presentationStyle?.textColor,
            }}
            direction="row"
            justifyContent="center">
            <Grid
                container
                item
                xs={12}
                className={`${classes.detailsContainer} ${isActiveState(NavigationState.BeforeOpen) && classes.upSize}`}>
                <LayoutWrapper
                    layoutType={slide.slideLayout}
                    imageURL={slide?.file?.base64 || slide?.filename}
                    imageStyle={slide.filenameStyle}
                    backgroundOpacity={slide.backgroundOpacity}
                    backgroundColor={slide.backgroundColor}
                    videoURL={slide.videoURL}
                    title={
                        <PlaySlideTitlePreview
                            showPreview={isActiveState(NavigationState.BeforeOpen)}
                            title={
                                <DelayedComponent
                                    animationDuration={250}
                                    animationType={AnimationTypes.Grow}
                                    showAfter={500} >
                                    <Html
                                        content={slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                        fontSize={slideProperties.slideSettings.titleFontSize}
                                    />
                                </DelayedComponent>
                            }
                        />
                    }
                    content={isActiveState(NavigationState.SlideOpen) && (
                        <QASlideMessageList
                            slide={slide}
                            messages={messages[slide.id]}
                        />
                    )}
                />
            </Grid>
        </Grid>
        <SessionLoginInfo />
    </ >;
};

export default QASlideSession
