import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BaseTypes } from 'Components/types/helpers/Slide';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { useAppRouting } from 'Hooks/useAppRouting';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useTranslations } from 'Hooks/useTranslations';
import React, { useCallback } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PlayState } from 'Types/playTypes';

const SessionSMSInfo = ({ showPrefix = true }) => {

	const { presentationStyle } = usePresentationStyles();

	const sessionData = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).sessionData);

	const playSlides = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).playSlides);

	const { currentSlideKey } = useAppRouting()

	const { translate } = useTranslations();

	const isVoteQuestion = useCallback(() => {

		const { Vote } = BaseTypes

		const slideType = getPlaySlideProperties(playSlides[currentSlideKey])

		return slideType.baseType === Vote;

	}, [currentSlideKey])

	const theme = useTheme();

	return (
		<>
			{!!Number(sessionData?.textMessagingSelected) && (
				<Box display="flex">
					{showPrefix && (
						<Typography
							variant="h5"
							mr={4}
							style={{
								minWidth: 128
							}}
							sx={{
								color: presentationStyle?.textColor,
								fontWeight: 600,
								fontSize: theme.spacing(4),
							}}>
							{translate('SMS')}
						</Typography>
					)}
					<Typography
						variant="h5"
						sx={{
							color: presentationStyle?.textColor,
							fontWeight: 600,
							fontSize: theme.spacing(4),
						}}
					>
						<span>
							{isVoteQuestion() && translate('SEND_YOUR_VOTE', [
								sessionData.phoneNumberDisplayText,
								<strong>{sessionData.textMessagingKeyword}</strong>,
								<strong>{sessionData.textMessagingKeyword}</strong>,
							])}
							{!isVoteQuestion() && translate('SEND_YOUR_MESSAGE', [
								sessionData.phoneNumberDisplayText,
								<strong> {sessionData.textMessagingKeyword}</strong>,
							])}
						</span>
					</Typography>
				</Box>
			)}
		</>
	);
};

export default SessionSMSInfo;