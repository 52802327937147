
export const setMusic = (musicType: string) => ({
	type: 'SET_MUSIC_TYPE',
	musicType
})

export const setVolume = (volume: number = 1) => ({
	type: 'SET_VOLUME',
	volume
})

export const setPlayMusic = (shouldPlay: boolean) => ({
	type: 'SET_PLAY_MUSIC',
	shouldPlay
})

export const setFadeMusicOut = (isFading: boolean) => ({
	type: 'SET_FADE_MUSIC_OUT',
	isFading
})