import { Box } from '@mui/material';
import FullSizeContainer from 'Atomic/atoms/FullSizeContainer';
import AppChart, { AppChartType } from 'Atomic/organisms/Chart/AppChart';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import Html from 'Components/common/util/html/Html';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import { slideTableToChartData } from 'Scripts/tableHelper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';


interface GraphSlideSessionProps extends SlideConsumerComponent<ContentSlideType> {
    graphType: AppChartType
}

const GraphSlideSession = ({
    slide,
    graphType,
}: GraphSlideSessionProps) => {

    const slideProperties = getSlideProperties(slide);


    return (
        <LayoutWrapper
            layoutType={slide.slideLayout}
            videoURL={slide.videoURL}
            imageURL={slide?.file?.base64 || slide?.filename}
            imageStyle={slide.filenameStyle}
            backgroundOpacity={slide.backgroundOpacity}
            backgroundColor={slide.backgroundColor}
            title={(
                <FullWidthContainer>
                    <Box p={6}>
                        <Html
                            content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                            fontSize={slideProperties.slideSettings.titleFontSize}
                        />
                    </Box>
                </FullWidthContainer>
            )}
            content={(
                <FullSizeContainer>
                    <div
                        className={classes.chartContainer}>
                        <AppChart
                            chartType={graphType}
                            chartData={slideTableToChartData(slide.table)}
                            chartElementProps={{
                                className: classes.chart,
                            }}
                        />
                    </div>
                </FullSizeContainer>
            )}
        />
    );
};

export default GraphSlideSession;