import { Divider } from '@mui/material';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import SlideSettingButton from 'Components/beta/common/buttons/settings/slide/SlideSettingButton';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import React, { useMemo } from 'react';
import { classes } from './style.css';

const SlideResultTypeToggle = () => {

	const {
		currentSlide,
		currentSlideKey,
	} = useSlides();

	const {
		setSlideSetting,
	} = useSlideActions();

	const { translatePlaceholder } = useTranslations();

	const setShowPercentages = (showPercentages: boolean) => {

		setSlideSetting('showPercentages', String(Number(showPercentages)), currentSlideKey);
	}

	const percentagesEnabled = useMemo(() => {

		return Boolean(Number(currentSlide.showPercentages));

	}, [currentSlideKey, currentSlide.showPercentages])

	return (
		<>
			<AppText
				as={AppTextVariant.AppTextBaseStyle}
				pt={2}
			>
				{translatePlaceholder("SHOW_RESULTS_AS")}
			</AppText>
			<div
				className={classes.resultTypeContainer}
			>
				<div className={classes.resultTypeButtonContainer}>
					<SlideSettingButton
						data-testid={testIds.SLIDE_OPTION_PERCENTAGE_RESULTS}
						isActive={Boolean(percentagesEnabled)}
						value={"%"}
						onClick={() => setShowPercentages(true)}
						{...renderingGAAttr(`${DataElAction.ShowResultsAs}_%`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Button)}
					/>
				</div>
				<div className={classes.resultTypeButtonContainer}>
					<SlideSettingButton
						data-testid={testIds.SLIDE_OPTION_NUMERIC_RESULTS}
						isActive={!Boolean(percentagesEnabled)}
						value={translatePlaceholder("NUMBERS")}
						onClick={() => setShowPercentages(false)}
						{...renderingGAAttr(`${DataElAction.ShowResultsAs}_#`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Button)}
					/>
				</div>
			</div>
			<Divider />
		</>
	);
};

export default SlideResultTypeToggle