import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import { DerivedFeatures, FeatureProps, Features, useFeatures } from 'Hooks/useFeatures';
import { useLayout } from 'Hooks/useLayout';
import { TrackingAttributes } from 'Scripts/measurementsGAHelper';
import React, { ReactNode } from 'react';
import { classes } from './style.css';

interface FeatureWrapperProps extends TrackingAttributes {
    children: ReactNode | ((blockFeature: boolean) => ReactNode);
    enabled?: boolean;
    feature: Features|DerivedFeatures|null;
    wrapperProps?: BoxProps;
}

const FeatureWrapper = ({
    children,
    enabled = true,
    feature,
    ...props
}: FeatureWrapperProps) => {

    const { isFeatureAvailable, getFeatureProps } = useFeatures();

    const { setShowMissingFeature } = useLayout();

    const featureIsAvailable = isFeatureAvailable(feature as Features);

    const featureProps = getFeatureProps(feature as Features) as FeatureProps;

    const blockFeature = (enabled && !featureIsAvailable);

    const handleMouseEvents = (e: any) => {

        if (blockFeature) {

            e.preventDefault();
            e.stopPropagation();

            setShowMissingFeature(feature);
        }
    }


    return (
        // <CustomTooltip
        //     /** Disable listeners if the feature is available */
        //     disableTouchListener={!blockFeature || undefined}
        //     disableHoverListener={!blockFeature || undefined}
        //     disableFocusListener={!blockFeature || undefined}
        //     title={(
        //     <div>
        //         <AppText pb={1} color="white" fontSize={12}>
        //             This is a premium feature.
        //         </AppText>
        //         <AppText pb={2} color="white" fontSize={12}>
        //             Upgrade to use!
        //         </AppText>
        //         <UpgradeButton
        //             small
        //             {...props}
        //             data-elaction={`tooltip_upgrade_missing_feature_${`${featureProps.friendlyName}`.toLowerCase().split(' ').join('_')}`}
        //         />
        //     </div>
        // )}>
            <Box
                className={classes.featureComponentWrapper}
                onTouchStart={handleMouseEvents}
                onMouseDown={handleMouseEvents}
                onClick={handleMouseEvents}
                {...props?.wrapperProps}>
                {typeof children === "function" ? children(blockFeature) : children}
            </Box>
        // </CustomTooltip>
    );
};

export default FeatureWrapper;