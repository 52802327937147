import { UserRoles } from "Hooks/useAuthentication";

export enum ScreenRatio {
	Ratio16By9 = '16 / 9',
	Ratio9By16 = '9 / 16',
	Ratio4By3 = '4 / 3',
}

export enum ScreenSizes {
	Width1080P = 1920,
	Height1080P = 1080,
	Width720P = 1280,
	Height720P = 720,
	Width480P = 480,
	Height480P = 640,
	Width360P = 640,
	Height360P = 360,
}

export enum EventTypes {
	Download = 'download',
	Message = 'message',
}

export enum UserInfoFieldProperties {
    FIRST_NAME  = 'firstName',
    LAST_NAME   = 'lastName',
    EMAIL       = 'email',
    DEPARTMENT  = 'departmentName',
}

export enum AccountInfoFieldProperties {
	POSTAL_CODE = 'postalCode',
	ADDRESS = 'address',
	CITY = 'city',
	ORGANISATION = 'university',
	COUNTRY = 'country',
	VAT_ID = 'vatId',
	TIMEZONE = 'timezone',
}

export interface PaginationProps {
	pageData?: Array<any>;
	pageIndex: number;
	pageSize: number;
	searchQuery?: string;
	sortBy: string;
	sortDesc: boolean | number;
}

export interface UserProps {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	filename: string;
	useNewWebDesign: boolean;
	roles: Array<string>;
}

export interface UserBrandingData {
	id: number;
	websiteAddress: string;
	dashboardUrl: string;
	downloadAddinUrl: string;
	accountId: number;
	email: string;
	name: string;
	ownerId: number;
	supportEmail: string;
	userOpinionsContact: string;
	logo: string;
}

export interface UserInfoProps {
    firstName?: string;
    lastName?: string;
    email?: string;
    departmentName?: string;
}

export interface UserInfoErrorProps {
    firstName: Array<string> | null;
    lastName: Array<string> | null;
    email: Array<string> | null;
    departmentName: Array<string> | null;
}

export interface UserSessionInfo {
	isAnonymous: boolean;
	responseCode: string;
	responseUrl: string;
	survey: boolean;
}

export interface ColorSet {
	lightest: string;
	light: string;
	normal: string;
	dark: string;
	darker: string;
}

export interface AppState {
	colorType: ColorSet;
	settingsOpen: boolean;
	menuOpen: boolean;
	loginOpen: boolean;
	loginLoading: boolean;
	saveOpen: boolean;
	currentUser: UserProps | null;
	currentUserRoles: Array<UserRoles>;
	userBrandingData: UserBrandingData | null;
	userSessionInfo: UserSessionInfo | null;
	currentSessionId: number | null;
	headerHeight: number;
	toolbarHeight: number;
	ratingOpen: boolean;
	authLoading: boolean;
	liveWebsocket: WebSocket | null;
	loginCallback: null | ((a?: any) => void);
	selectSlideOpened: boolean;
	saveCompleted: boolean;
	generalSettingsOpen: boolean;
	unsavedChangesOpen: boolean;
	moderatorSharingToken: string | null;
	isOnline: boolean;
	userBrandingLoading: boolean;
	hash: string | null;
	presentationIdToShare: null | number;
	feedbackDialogOpen: boolean;
}

export enum SendstepsIntegrations {
    WebApp = "Sendsteps Web App Options",
    PowerPoint = "PowerPoint Addin Options",
    MSTeams = "MS Teams App Options",
}

export interface SendstepsIntegrationList {
    [integrationKey: string]: {
        name: string;
		icon: any;
		testId: number;
    }
}

export enum UTM {
	Source = "utm_source",
	Campaign = "utm_campaign",
	Medium = "utm_medium",
	ExternalAuthKey = "external_auth_key",
}

export interface ExternalAuthParams {
	externalAuthKey?: string;
}

export interface IdentityProviderParams {
	email: string;
	extraParams?: any;
}

export interface LoginParams extends ExternalAuthParams {
	client_id: string;
	email: string;
	otp?: string;
}

export interface SlideConsumerComponent<Type> {
	slide: Type;
}