import { Divider, Typography } from '@mui/material';
import FeatureWrapper from 'Atomic/atoms/FeatureWrapper/FeatureWrapper';
import OnOffSwitch from 'Components/beta/common/buttons/switch/OnOffSwitch';
import SummaryOption from 'Components/beta/common/summary/option/SummaryOption';
import { Features, useFeatures } from 'Hooks/useFeatures';
import usePlaySettings from 'Hooks/usePlaySettings';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import React, { useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { OverviewState } from 'Types/overviewTypes';

const TAB_EDIT_ANSWERS_PROPERTY = 'edit_answers';

const EditAnswersToggle = () => {

	const {
		userSessionInfo,
	} = useSession();

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

	const {
		updateTabSettings,
		updateTabSettingsLoading,
	} = usePlaySettings();

	const {
		isFeatureAvailable
	} = useFeatures()

	const { translatePlaceholder } = useTranslations();

	const canEditAnswers = useMemo(() => {

		return Boolean(Number(userSessionInfo?.tabSettings?.edit_answers));

	}, [userSessionInfo?.tabSettings?.edit_answers, updateTabSettingsLoading])

	const hasEditAnswersFeature = isFeatureAvailable(Features.EDIT_VOTE_ANSWERS);

	const toggleSetting = () => {

		updateTabSettings(TAB_EDIT_ANSWERS_PROPERTY, Number(!canEditAnswers))
	}

	return (
		<>
			<Divider style={{paddingTop: "20px"}}/>
			<SummaryOption
				loading={!userSessionInfo || presentationLoading}
				leftItem={
					<Typography pt={1} fontSize={"14px"} fontWeight={"600"} color="#000" variant={"h5"}>
						{!hasEditAnswersFeature && "💎"} {translatePlaceholder("PARTICIPANTS_CAN_EDIT_ANSWERS")}
					</Typography>
				}
				rightItem={(
					<FeatureWrapper
						data-elscreen={DataElScreen.Settings}
						feature={Features.EDIT_VOTE_ANSWERS}>
						<OnOffSwitch
							disabled={updateTabSettingsLoading}
							checked={canEditAnswers}
							onChange={() => toggleSetting()}
							data-testid={testIds.PARTICIPANTS_CAN_EDIT_ANSWERS_TOGGLE}
						/>
					</FeatureWrapper>
				)}
				divider={false}
				trackingAttr={renderingGAAttr(`${DataElAction.ParticipantEdit}_${canEditAnswers}${!hasEditAnswersFeature ? '_limited' : ''}`, DataElPosition.MainOptSection, DataElScreen.Settings, DataElType.Toggle)}
			/>
		</>
	);
};

export default EditAnswersToggle;