import React from 'react';
import LimitToSlideOption from '../../limit_to_slide/LimitToSlideOption';
import ModeratorPanelOption from '../../moderator_panel/ModeratorPanelOption';

const AdditionalUpvotingSettings = () => {
	return (
		<>
			<LimitToSlideOption />
			<ModeratorPanelOption />
		</>
	);
};

export default AdditionalUpvotingSettings;