import AppLottiePlayer from 'Atomic/atoms/Lottie/AppLottiePlayer';
import spinnerAnimation from 'Atomic/atoms/Lottie/assets/spinner_animation.json';
import React from 'react';


interface AppSpinnerProps {
    height?: number;
    width?: number;
}

const AppSpinner = ({
    height = 40,
    width = 40,
}: AppSpinnerProps) => (
    <AppLottiePlayer
        src={spinnerAnimation}
        autoplay
        loop
        elementProps={{
            style: {
                height,
                width,
            }
        }}
    />
);

export default AppSpinner;