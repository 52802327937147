import EditAnswersToggle from 'Components/beta/settings/presentation_summary/options/general/edit_answers/EditAnswersToggle';
import ParticipantEmojiToggle from 'Components/beta/settings/presentation_summary/options/general/emoji/ParticipantEmojiToggle';
import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import PlayMusicToggle from 'Components/beta/settings/presentation_summary/options/general/music/PlayMusicToggle';
import React from 'react';
import TimerLimitSetting from '../../../../settings/items/time_limit/TimerLimitSetting';
import AnswerModeToggle from '../../../items/answer_mode/AnswerModeToggle';
import SlidePointsSetting from '../../../items/points_toggle/SlidePointsSetting';
import SlideResultTypeToggle from '../../../items/result_type/SlideResultTypeToggle';

const QuizSettings = () => {
	return (
		<>
			<SlidePointsSetting />
			<AnswerModeToggle />
			<TimerLimitSetting />
			<SlideResultTypeToggle />
			<LoginCodeEditor />
			<EditAnswersToggle/>
			<PlayMusicToggle/>
			<ParticipantEmojiToggle/>
		</>
	);
};


export default QuizSettings;