import { TranslationItems } from "Types/languageTypes";

export const setLanguage = (language: string = 'en') => ({
	type: 'SET_LANGUAGE',
	language
})

export const setTranslations = (translations: TranslationItems) => ({
	type: 'SET_TRANSLATIONS',
	translations
})

export const setUpdateLanguageLoading = (isLoading: boolean) => ({
	type: 'SET_LANGUAGE_LOADING',
	isLoading,
})