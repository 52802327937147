import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Editor } from "@tinymce/tinymce-react";
import { TestIdAttributes } from "Scripts/measurementsGAHelper";
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useSelector } from "react-redux";
import { RawEditorOptions, Editor as TinyMCEEditor } from 'tinymce';
import { classes } from './style.css';

export enum WysiwygToolBarType {
    Simple = 'Simple',
    Extended = 'Extended',
    SuperExtended = 'SuperExtended',
}

export enum WysiwygToolBarContainer {
    Editor = 'editor-toolbar-container',
    SpeakerNotes = 'speakernotes-toolbar-container',
}

export enum WysiwygEditorToolbarComponentType {
    Button,
    MenuButton,
}

interface WysiwygEditorOnActionCallback {
    setEnabled: (enable: boolean) => void;
}

export interface WysiwygEditorToolbarItem {
    key: any | string,
    type: WysiwygEditorToolbarComponentType;
    text: string;
    subText?: string;
    icon?: string;
    separator?: boolean;
    onClick?: (editor: TinyMCEEditor) => Promise<void>;
    items?: Array<NestedWysiwygEditorToolbarItem>;
}

/**
 * Nested items don't require key, type or separator props but
 * still share most properties from WysiwygEditorToolbarItem
 */
interface NestedWysiwygEditorToolbarItem extends Omit<WysiwygEditorToolbarItem, "separator"> { }

export interface WysiwygEditorIconProps {
    /** Icon name (for referencing) */
    iconKey: string;
    /** some <svg></svg> text or other html */
    iconHtml: string;
}

export interface WysiwygEditorProps extends TestIdAttributes {
    updateValue: (newValue: string, rawText: string) => void;
    handleOnBlur: (e: any, editor: TinyMCEEditor) => void;
    handleOnKeyDown?: (e: any, editor: TinyMCEEditor) => void;
    handleOnKeyUp?: (e: any, editor: TinyMCEEditor) => void;
    handlePostProcess?: (e: any, editor: TinyMCEEditor) => void;
    handleGetContent?: (e: any, editor: TinyMCEEditor) => void;
    handleOnImmediateChange: (text: string, editor: TinyMCEEditor) => void;
    handleInit?: (e: any, editor: TinyMCEEditor) => void;
    getPlaceholderHtml: (placeholderText: string) => string;
    placeholderText: string;
    value: string;
    ref?: any;
    placeholder?: string;
    editorColor: string;
    disabled?: boolean;
    loading?: boolean;
    toolbarType: WysiwygToolBarType;
    toolbarContainer?: WysiwygToolBarContainer,
    additionalIcons?: Array<WysiwygEditorIconProps>;
    additionalButtons?: Array<WysiwygEditorToolbarItem>;
}

const defaultSettings: RawEditorOptions = {
    menubar: false,
    inline: true,
    toolbar: false,
    force_br_newlines: false,
    link_default_target:"_blank",
    font_size_formats: '8pt 12pt 16pt 24pt 32pt 40pt 48pt 56pt 64pt 72pt 80pt',
    plugins: [
        'lists',
        'autolink',
    ],
    directionality: 'auto',
    valid_elements: 'p[style],strong,em,span[style],a[href],ul,ol,li',
    valid_styles: {
        '*': 'font-size,font-family,color,padding-left,background-color,text-decoration,text-align,font-weight'
    },
    powerpaste_word_import: 'clean',
    powerpaste_html_import: 'clean',
}

// We need to add mathjax later (formula latex support)
const toolbarConfig = {
    [WysiwygToolBarType.Simple]: 'bold italic underline | alignleft aligncenter alignright alignfull',
    [WysiwygToolBarType.Extended]: 'bold italic underline | alignleft aligncenter alignright alignfull | bullist outdent indent',
    [WysiwygToolBarType.SuperExtended]: 'bold italic underline strikethrough | fontfamily fontsize forecolor | indent outdent lineheight | bullist | aligncenter alignjustify alignleft alignnone alignright | cut copy paste pastetext remove removeformat | subscript superscript | blockquote | hr | charmap | link openlink unlink',
}

interface WysiwygToolbarMenuButtonSpec {
    key: string;
    text: string;
    icon?: string;
    type: string;
    onAction?: (props: WysiwygEditorOnActionCallback) => Promise<void>;
    getSubmenuItems?: () => Array<WysiwygToolbarMenuButtonSpec>;
}

const WysiwygEditor = forwardRef(({
    updateValue,
    handleOnBlur,
    handleOnKeyDown,
    handleOnKeyUp,
    handleGetContent,
    handlePostProcess,
    handleOnImmediateChange,
    handleInit,
    getPlaceholderHtml,
    placeholderText,
    value,
    placeholder,
    editorColor,
    disabled,
    loading,
    toolbarType = WysiwygToolBarType.Simple,
    toolbarContainer = WysiwygToolBarContainer.Editor,
    additionalIcons = [],
    additionalButtons = [],
    ...props
}: WysiwygEditorProps, ref: React.ForwardedRef<Editor>) => {

    const currentUser = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentUser);

    const showFullEditor = true; // process.env.ENABLE_FULL_EDITOR;

    const mobileResolution = useMediaQuery(theme => theme.breakpoints.down('md'));

    // @todo, once on production and everything seemds ok, we can merge this code to the default settings
    if (showFullEditor)
    {
        // Override some default to show a toolbar with more functionality
        toolbarType = WysiwygToolBarType.SuperExtended

        defaultSettings.mathjax = {
            lib: 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js', //required path to mathjax
            //symbols: {start: '\\(', end: '\\)'}, //optional: mathjax symbols
            className: "math-tex", //optional: mathjax element class
            //configUrl: '/your-path-to-plugin/@dimakorotkov/tinymce-mathjax/config.js' //optional: mathjax config js
          }

        defaultSettings.plugins = [
            'quickbars',
            'lists',
            'autolink',
            'mathjax',
            'a11ychecker','advlist','autolink','checklist',
            'lists','link','charmap','preview','anchor','searchreplace','visualblocks',
            'powerpaste','fullscreen','formatpainter',
        ];

        defaultSettings.inline = true;
        defaultSettings.toolbar_mode = 'wrap';
        defaultSettings.contextmenu = '';
        defaultSettings.extended_valid_elements = '*[.*]';
        defaultSettings.advlist_bullet_styles = 'default';
        defaultSettings.advlist_number_styles = 'default';

        defaultSettings.mobile = {
            toolbar_mode: 'floating',
        };

        // We cannot use this, as each shape is an editor.. so we will get a toolbar for every shape :(
        // defaultSettings.toolbar_persist = true;

        // Unset all the valid elements and styles, we should set this based on the chosen toolbar options
        defaultSettings.valid_elements = undefined;
        defaultSettings.valid_styles = undefined;
    }

    const [placeholderValue, setPlaceholderValue] = useState<string>(value || getPlaceholderHtml(value));

    useEffect(() => {

        setPlaceholderValue(value || getPlaceholderHtml(value));

    }, [value])

    /**
     * Returns a 'flat' (non-nested) representation of the additional buttons
     */
    const getFlatAdditionalButtons = useCallback(() => {

        const flattened: Array<WysiwygEditorToolbarItem | NestedWysiwygEditorToolbarItem> = [];

        const flatten = (item: WysiwygEditorToolbarItem | NestedWysiwygEditorToolbarItem) => {

            if (item.items) {

                item.items.forEach(subItem => {

                    flattened.push(flatten(subItem));
                })
            }

            return item;
        }

        additionalButtons.forEach(button => {

            flattened.push(flatten(button));
        })

        return flattened;

    }, [additionalButtons]);

    /**
     * Function that customizes some toolbar buttons (because tinymce doesn't support this natively)
     *
     */
    const applyToolbarCustomization = useCallback(() => {

        const flattenedItems = getFlatAdditionalButtons();

        flattenedItems.forEach(item => {
            /** There is no other reliable query selector for these items, so fetch it by tag attributes */
            const element = document.querySelector(`[title="${item.text}"]`);

            if ((Boolean(element))) {

                /** Handle 'subText' values by adding a new box to the text container */
                if ((Boolean(item.subText))) {

                    if (!element?.classList.contains('tox-subtitled-item')) {

                        (element as HTMLElement).classList.add('tox-subtitled-item');

                        const subtitleTextNode = document.createTextNode(item.subText as string);

                        const subTitleNode = document.createElement('div');

                        subTitleNode.classList.add('tox-subtitle-item');

                        subTitleNode.appendChild(subtitleTextNode);

                        element?.appendChild(subTitleNode);
                    }
                }

                /** Add a 'chevron' pointed to the right */
                if (item.type === WysiwygEditorToolbarComponentType.MenuButton) {

                    const menuTextNode = document.querySelector(`[title="${item.text}"] .tox-collection__item-label`);

                    const menu = document.querySelector(`[title="${item.text}"]`);

                    if (!menuTextNode?.classList.contains('tox-menu-text')) {

                        menuTextNode?.classList.add('tox-menu-text');
                    }
                }
            }
        })

    }, [ getFlatAdditionalButtons ]);

    const editorRef = useRef<TinyMCEEditor>(null);

    const onSetup = (editor: TinyMCEEditor) => {

        editorRef.current = editor;

        /**
         * Jira SN-3707
         *
         * https://github.com/tinymce/tinymce/issues/3671
         */
        editor.on('execCommand', (e) => {

            const selection = editor.selection.getSelectedBlocks();

            const relevantCommands = /^Insert(Uno|O)rderedList|Justify.+$/;

            if (relevantCommands.test(e.command)) {

                selection
                    .filter(element => element.nodeName === 'LI')
                    .forEach((listItem: HTMLElement) => {
                        listItem.style.textAlign = '';
                        listItem.removeAttribute('data-mce-style');
                    });
            }
        });

        editor.on('selectionchange', () => {

            const editorContent = editor.selection
                .getContent({ format: 'text' })
                .replace(/[\s]/g, '');

            if (Boolean(editorContent)) {

                editor.show();
            }
        })

        if (Boolean((additionalIcons.length))) {

            additionalIcons.forEach(icon => {

                const { iconKey, iconHtml } = icon;
                /** Add icons to the registry */
                editor.ui.registry.addIcon(iconKey, iconHtml);
            })
        }

        if (Boolean((additionalButtons.length))) {

            additionalButtons.forEach(buttonProps => {

                switch (buttonProps.type) {

                    case WysiwygEditorToolbarComponentType.MenuButton: {
                        /** Add menu buttons to the registry */
                        editor.ui.registry.addMenuButton(buttonProps.key, {
                            text: buttonProps.text,
                            icon: buttonProps.icon,
                            fetch: (successCallback) => {

                                const menuItems = getStructuredToolbarItems(buttonProps.items || [], editor);

                                successCallback(menuItems as any);
                                /** push to end of event loop so the elements already exist */
                                requestAnimationFrame(() => applyToolbarCustomization());
                            }
                        });
                        break;
                    }
                    case WysiwygEditorToolbarComponentType.Button:
                    default: {
                        /** Add buttons to the registry */
                        editor.ui.registry.addButton(buttonProps.key, {
                            text: buttonProps.text,
                            icon: buttonProps.icon,
                            onAction: async ({ setEnabled }) => {

                                if (buttonProps.onClick) {

                                    setEnabled(false);

                                    editor.ui.hide();

                                    await buttonProps.onClick(editor);

                                    editor.ui.show();

                                    setEnabled(true);
                                }
                            }
                        });
                        break;
                    }
                }
            })
        }
    }

    const toolbarSettings: RawEditorOptions = useMemo(() => {
        /**
         * E.g.:
         * "extraButton_1 | extraButton2 extraButton3 | extraButton 4"
         */
        const extraToolbarKeys = additionalButtons.map(button => (
            `${button.separator ? `| ` : ``}${button.key} `
        )).join('');

        // Temp, only show the formula editor bbutton for sendsteps users
        const tmpToolbarKeys = currentUser?.email.endsWith('@sendsteps.com') ? ' mathjax ' : '';

        defaultSettings.toolbar_mode = mobileResolution ? 'wrap' : 'quickbars';
        
        return {
            ...defaultSettings,
            toolbar: false,
            quickbars_image_toolbar: false,
            quickbars_insert_toolbar: false,
            quickbars_selection_toolbar: `${toolbarConfig[toolbarType] || ``} ${tmpToolbarKeys}${extraToolbarKeys}`,
            placeholder,
            setup: onSetup,
        };

    }, [
        toolbarType,
        placeholder,
        additionalButtons.length
    ]);

    /**
     * Recursive function which formats the additionalButtons props to a nested array
     * of WysiwygToolbarMenuButtonSpec items
     */
    const getStructuredToolbarItems = (items: Array<WysiwygEditorToolbarItem | NestedWysiwygEditorToolbarItem>, editor: TinyMCEEditor): Array<WysiwygToolbarMenuButtonSpec> => {

        const toolbarItemSpecs: Array<any> = [];

        items.forEach((item: WysiwygEditorToolbarItem) => {

            const { key, text, icon, onClick } = item;

            const toolbarItem: WysiwygToolbarMenuButtonSpec = {
                key,
                text,
                icon,
                type: "menuitem",
            }

            if (onClick) {

                toolbarItem.onAction = async ({ setEnabled }) => {

                    setEnabled(false);

                    editor.ui.hide();

                    await (onClick as Function)(editor);

                    setEnabled(true);

                    editor.ui.show();
                }
            }

            if (Boolean(item.items?.length)) {

                toolbarItem.getSubmenuItems = () => {

                    return getStructuredToolbarItems(item.items as Array<NestedWysiwygEditorToolbarItem>, editor);
                };
            }

            toolbarItemSpecs.push(toolbarItem);
        })

        return toolbarItemSpecs;
    }

    const onFocus = (e, editor: TinyMCEEditor) => {

        const rawEditorContent = editor.getContent({ format: 'text' }).trim();

        if (rawEditorContent === getPlaceholderHtml(placeholderText)) {

            setPlaceholderValue('');
        }
    }

    /**
    * When user blurs the field, we check if there is any text in the field.
    * If there is no text, it's repopulated with the placeholder text.
    */
    const onBlur = (e: FocusEvent, editor: TinyMCEEditor) => {

        const rawEditorContent = editor.getContent({ format: 'text' }).trim();

        requestAnimationFrame(() => {

            handleOnBlur && handleOnBlur(e, editor);
        })

        if (value === editor.getContent()) {
            /** Don't update the state if nothing changed */
            return;
        }

        if (!Boolean(rawEditorContent)) {

            setPlaceholderValue(getPlaceholderHtml(placeholderText));

        } else {

            setPlaceholderValue(editor.getContent());
        }
    }

    /**
     * Sets the placeholder value
     * (This is used to sync the placeholder value with the actual value)
     */
    const onChange = (newText: string, _: TinyMCEEditor) => {

        setPlaceholderValue(newText);
    }

    const [_, startTransition] = useTransition();

    const stringFormatting =(input: string) => {
        const formattedString = input?.replace(/&nbsp;/g, '')?.replace(/\n/g, '')?.replace(/ data-mce-style="color: #000000;/g, '')?.replace(/rgb\(0, 0, 0\);"/g, '#000000;');
        return formattedString;      
    }

    const removeHtmlTags = (str: string)  => {
        return str?.replace(/<\/?[^>]+>/gi, '')?.replace(/<\/?[^>]+>/g, '');
    }

    const isHtmlElement = (str: string) => {
        const pattern = /<\/?[a-z][\s\S]*>/i;
        return pattern.test(str);
    }

    useEffect(() => {
        const placeholderValueFormatted = stringFormatting(placeholderValue);
        const valueFormatted = stringFormatting(value);

        const isParam1HtmlElement = isHtmlElement(placeholderValue);
        const isParam2HtmlElement = isHtmlElement(value);

        if ((isParam1HtmlElement || isParam2HtmlElement) && !(isParam1HtmlElement && isParam2HtmlElement)) {

            let htmlStripped, plainText;
            if (isParam1HtmlElement) {
                htmlStripped = removeHtmlTags(placeholderValueFormatted);
                plainText = valueFormatted;
            } else {
                htmlStripped = removeHtmlTags(valueFormatted);
                plainText = placeholderValueFormatted;
            }
            
            if (htmlStripped === plainText) {
                return;
            }
        }

        if(placeholderValueFormatted === valueFormatted) {
            return;
        }

        if (!Boolean(editorRef.current)) {

            return;
        }

        const rawEditorContent = editorRef.current.getContent({ format: 'text' }).trim();

        if (Boolean(editorRef.current)) {

            /** Don't update if the content is equal to the placeholder text */
            if (rawEditorContent === getPlaceholderHtml(placeholderText)) {

                return;
            }
        }

        startTransition(() => {
            updateValue(placeholderValue, rawEditorContent);
        })

    }, [placeholderValue]);

    return (
        <Box className={classes.textContainer} sx={{
            cursor: (disabled || loading) ? 'not-allowed' : 'text',
            opacity: (disabled || loading) ? 0.5 : 1,
        }}>
            <Typography
                component="div"
                fontSize="inherit"
                className={classes.text}
                sx={{
                    color: editorColor,
                }}
                {...props}>
                <Editor
                    tinymceScriptSrc={process.env.CDN_URL + 'editor/tinymce7/tinymce.min.js'}
                    onEditorChange={onChange}
                    onInit={handleInit}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onKeyDown={handleOnKeyDown}
                    onKeyUp={handleOnKeyUp}
                    onGetContent={handleGetContent}
                    value={placeholderValue}
                    ref={ref}
                    init={{
                        ...toolbarSettings,
                        content_style: `.tox-tinymce .tox-toolbar-overlord`
                    }}
                    disabled={disabled || loading}
                />
            </Typography>
        </Box>

    )
})

export default WysiwygEditor;