import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box } from '@mui/material';
import QuoteSvgIcon from 'Assets/images/icons/text/quote_icon.svg';
import WysiwygEditorContainer from 'Atomic/organisms/WysiwygEditor/variants/WysiwygEditorContainer';
import FullHeightContainer from 'Components/common/FullHeightContainer';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import { WysiwygToolBarType } from 'Components/common/util/wysiwygEditor/WysiwygEditor';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import useSlideActions from 'Hooks/useSlideActions';
import { testIds } from 'Scripts/cypressTestIds';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType, EditorSlideField, StringReturnFunction } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';


const QuoteSlideEditor = ({ slide }: SlideConsumerComponent<ContentSlideType>) => {

    const { presentationStyle } = usePresentationStyles();


    const { setSlideSetting } = useSlideActions();

    const slideProperties = getSlideProperties(slide);

    return (
        <FullHeightContainer data-testid={testIds.SLIDE_TYPE_QUOTE}>
            <LayoutWrapper
                interactive={true}
                layoutType={slide.slideLayout}
                videoURL={slide.videoURL}
                imageURL={slide?.file?.base64 || slide?.filename}
                imageStyle={slide.filenameStyle}
                backgroundOpacity={slide.backgroundOpacity}
                backgroundColor={slide.backgroundColor}
                content={
                    <Box
                        height="100%"
                        px={7}>
                        <div className={classes.quoteContent} >
                            <QuoteSvgIcon className={classes.quoteTag} style={{
                                fill: presentationStyle?.textColor,
                            }}/>
                            <FullWidthContainer>
                                <WysiwygEditorContainer
                                    value={slide.title}
                                    getPlaceholderHtml={slideProperties.text.getHtmlTitlePlaceholder}
                                    updateValue={newTitle => setSlideSetting(EditorSlideField.Title, newTitle, slide.id)}
                                    placeholderText={slideProperties.text.titlePlaceholder}
                                    fontSize={slideProperties.slideSettings.titleFontSize}
                                    toolbarType={WysiwygToolBarType.Simple}
                                />
                            </FullWidthContainer>
                            <AccountCircleIcon className={classes.quoteAuthorIcon} sx={{
                                color: presentationStyle?.textColor,
                            }}/>
                            <FullWidthContainer>
                                <WysiwygEditorContainer
                                    value={slide.content}
                                    getPlaceholderHtml={slideProperties.text.getHtmlContentPlaceholder as StringReturnFunction}
                                    updateValue={(newContent) => setSlideSetting(EditorSlideField.Content, newContent, slide.id)}
                                    placeholderText={slideProperties.text.contentPlaceholder as string}
                                    fontSize={slideProperties.slideSettings?.contentFontSize as number}
                                    toolbarType={WysiwygToolBarType.Extended}
                                />
                            </FullWidthContainer>
                        </div>
                    </Box>
                }
            />
        </FullHeightContainer>
    );
};

export default QuoteSlideEditor;