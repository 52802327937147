import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import { Box, Typography } from '@mui/material';
import MediaComponent from 'Atomic/atoms/MediaComponent';
import FileUploadOption from 'Components/beta/editor/content/details/form/file/FileUploadOption';
import FullHeightContainer from 'Components/common/FullHeightContainer';
import { useHover } from 'Hooks/useHover';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { SlideConsumerComponent } from 'Types/appTypes';
import { SlideType } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';

const VideoSlideEditor = ({
    slide,
}: SlideConsumerComponent<SlideType>) => {

    const slideVideo = slide.videoURL as string;

    const { presentationStyle } = usePresentationStyles();

    const { translatePlaceholder } = useTranslations();

    const { hoverRef, isHovering } = useHover();

    return (
        <FullHeightContainer data-testid={testIds.SLIDE_TYPE_VIDEO}>
            <FileUploadOption
                show={Boolean(slideVideo) && isHovering}
                center
                uploadPlaceholder={(
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column">
                        <VideoLibraryOutlinedIcon
                            className={classes.addVideoIcon}
                            sx={{
                                color: presentationStyle?.textColor,
                            }}
                        />
                        <Box mb={3} />
                        <Typography variant="h2"
                            className={classes.addVideoText}
                            sx={{
                                color: presentationStyle?.textColor,
                            }}>
                            {translatePlaceholder("CLICK_TO_ADD_VIDEO")}
                        </Typography>
                    </Box>
                )}
            />
            <Box
                ref={hoverRef}
                className={classes.mediaAreaContainer}>
            </Box>
            {Boolean(slideVideo) && (
                <MediaComponent
                    interactive={true}
                    videoURL={slideVideo}
                />
            )}
        </FullHeightContainer>
    )
}

export default VideoSlideEditor;