import { useState } from 'react';

type LoadingKey = string | number;

export const useLoading = (baseStates: Array<LoadingKey>) => {

    const initialLoadStates = baseStates.reduce((stateMap, state) => {

        stateMap[state] = false;

        return stateMap;

    }, {} as Record<string, boolean>);

    const [loadStates, setLoadStates] = useState<Record<LoadingKey, boolean>>(initialLoadStates);

    const startLoading = (key: LoadingKey) => {

        setLoadStates((currentLoadStates) => ({
            ...currentLoadStates,
            [key]: true
        }));
    }

    const stopLoading = (key: LoadingKey) => {

        setLoadStates((currentLoadStates) => ({
            ...currentLoadStates,
            [key]: false
        }));
    }

    const isLoading = (loadState: LoadingKey) => {

        return loadStates[loadState] === true;
    };

    return {
        startLoading,
        stopLoading,
        isLoading,
    };
}
