// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root {
    --mode: light;
    --primary-main: #21003c;
    --secondary-main: #8B6EC0;
    --success-main: #2DAD83;
    --warning-main: #F6B200;

    --breakpoint-xs: 0px;
    --breakpoint-sm: 600px;
    --breakpoint-md: 900px;
    --breakpoint-lg: 1200px;
    --breakpoint-xl: 1536px;

    --button-text-transform: none;
    --tooltip-font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/app/Variables.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,uBAAuB;IACvB,uBAAuB;;IAEvB,oBAAoB;IACpB,sBAAsB;IACtB,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;;IAEvB,6BAA6B;IAC7B,yBAAyB;AAC7B","sourcesContent":["\n:root {\n    --mode: light;\n    --primary-main: #21003c;\n    --secondary-main: #8B6EC0;\n    --success-main: #2DAD83;\n    --warning-main: #F6B200;\n\n    --breakpoint-xs: 0px;\n    --breakpoint-sm: 600px;\n    --breakpoint-md: 900px;\n    --breakpoint-lg: 1200px;\n    --breakpoint-xl: 1536px;\n\n    --button-text-transform: none;\n    --tooltip-font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
