import { GoogleCharts } from 'google-charts';

export const handleBorderRadius = (chart: any, containerElement: HTMLElement, radius: number = 4) => {

    function setBorderRadius() {

        Array.prototype.forEach.call(containerElement.getElementsByTagName('rect'), function (rect: HTMLElement) {

            if (Number(rect.getAttribute('x')) > 0) {

                rect.setAttribute('rx', String(radius));
                rect.setAttribute('ry', String(radius));
            }
        });
    }

    var observer = new MutationObserver(setBorderRadius);

    GoogleCharts.api.visualization.events.addListener(chart, 'ready', function () {

        setBorderRadius();

        observer.observe(containerElement, {
            childList: true,
            subtree: true
        });
    })
}