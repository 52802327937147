import * as presentationActions from 'Actions/quizActions';
import { addOption, clearSlides, deleteOption, removeSlideItem, reorderSlides, setQuestionText, updateSlideProperty } from "Actions/quizActions";
import { TableCellData } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import { BaseTypes } from 'Components/types/helpers/Slide';
import useUrl from 'Hooks/useUrl';
import { del, get, post } from 'Scripts/api';
import { config } from 'Scripts/config';
import { ApiErrorCodes } from 'Scripts/globals';
import { LayoutTypes } from 'Types/layoutTypes';
import { Slide, SlideType, VoteType } from 'Types/presentationTypes';
import { SlideShape, SlideTypes } from 'Types/slideTypes';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { getSlidePropertiesByType } from "../components/types/helpers/slideTypeHelper";
import { useLayout } from './useLayout';

const useSlideActions = () => {

    const {
        closeSelectSlide,
        setShowCantEditInspirationTemplateDialog,
    } = useLayout();

    const urls = useUrl();

    const { closeSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const history = useHistory();

    const setSlideOption = useCallback((index: number, optionValue: string, isCorrect: boolean, slideId: number) => {

        dispatch(addOption(index, optionValue, isCorrect, slideId));
    }, []);

    const updateSlideOption = useCallback((index: number, answer: string, correctAnswer: number | boolean, slideId: number) => {

        dispatch(addOption(index, answer, correctAnswer, slideId));
    }, []);

    const removeOption = useCallback((optionIndex: number, slideId: number) => {

        dispatch(deleteOption(optionIndex, slideId))
    }, []);

    const setSlideTitle = useCallback((slideId: number, title: string) => {

        const { maxContentLength } = config;

        const trimmedTitle = title.substring(0, maxContentLength).replace(/\n/g, " ").trim();

        dispatch(setQuestionText(trimmedTitle, slideId));
    }, []);

    const reOrder = useCallback((sourceIndex: number, destinationIndex: number) => {

        dispatch(reorderSlides(sourceIndex, destinationIndex))
    }, []);

    const deleteSlide = useCallback(async (slideKey: number) => {

        const response = await del(`slides/delete-slide/${slideKey}`);

        if (response.error && response.error === ApiErrorCodes.EditTemplateMissingFeatures) {

            setShowCantEditInspirationTemplateDialog(true);

            return false;
        }

        dispatch(removeSlideItem(slideKey));
    }, []);

    const setSlideSetting = useCallback((setting: string, value: string | number | boolean | null | Object, slideId: number) => {

        dispatch(updateSlideProperty(slideId, setting, value));
    }, []);

    const addNewSlides = useCallback((slideList: Array<Slide>) => {

        dispatch(presentationActions.addNewSlides(slideList));
    }, []);

    const createSlide = useCallback(async (presentationId: number, slideType: SlideTypes, index: number) => {

        closeSnackbar();

        const addedSlides = await post('slides/create-slides', {
            presentationId,
            type: slideType,
            slideIndex: index,
        });

        if (addedSlides.error && addedSlides.error === ApiErrorCodes.EditTemplateMissingFeatures) {

            setShowCantEditInspirationTemplateDialog(true);

            closeSelectSlide();

            return false;
        }

        const slidesToAddToStore: Array<Slide> = [];

        (addedSlides as Array<Slide>).forEach((newSlide, index) => {

            const slideProperties = getSlidePropertiesByType(newSlide.type);

            const emptySlide = slideProperties.emptySlide(
                newSlide.slideIndex,
                newSlide.id,
                presentationId,
            );

            slidesToAddToStore.push({
                ...emptySlide,
                ...newSlide
            });
        })

        closeSelectSlide();

        /**
         * We only want to focus some relevant slides
         * so if a single slide is returned from the api, focus that.
         *
         * If multiple slides are returned from the api (quiz),
         * don't focus the extra added slides (podium, countdown, leaderboard)
         */
        const getSlideToFocus = (slides: Array<Slide>) => {

            if(slides.length === 1) {

                const [ firstSlide ] = slides;

                return firstSlide;
            }

            const focusableSlides = slides.filter((slide) => ![
                SlideTypes.Leaderboard,
                SlideTypes.Podium,
                SlideTypes.Countdown,
            ].includes(slide.type));

            const [firstSlide] = focusableSlides;

            return firstSlide;
        }

        const slideToFocus = getSlideToFocus(addedSlides);

        history.push(urls.editSlide(presentationId, slideToFocus.id));

        return slidesToAddToStore;

    }, []);

    const duplicateSlide = useCallback(async (presentationId: number, slideKeyToDuplicate: number, slideIndex: number, type: SlideTypes) => {

        const duplicatedSlides = await post('slides/create-slides', {
            presentationId,
            type,
            slideIndex,
        });

        if (duplicatedSlides.error) {

            if(duplicatedSlides.error === ApiErrorCodes.EditTemplateMissingFeatures) {

                setShowCantEditInspirationTemplateDialog(true);
            }

            return false;
        }

        const [newSlide] = duplicatedSlides;

        dispatch(presentationActions.duplicateSlide(
            slideKeyToDuplicate,
            duplicatedSlides,
        ))

        history.push(urls.editSlide(presentationId, newSlide.id));
    }, []);

    const clearPresentation = useCallback(() => {

        dispatch(clearSlides())
    }, []);

    const clearQuestionFile = useCallback((slideKey: number) => {

        dispatch(presentationActions.clearQuestionFile(slideKey))
    }, []);

    /**
     * When a user pressed 'Tab' while having the title field focused, we check if
     * the question is of type 'Vote' (meaning it can have options).
     *
     * If it is and there aren't already any options, create a new first option so it can be auto-focused
     */
    const handlePressedTabOnVoteSlideTitle = useCallback((e: KeyboardEvent, slide: VoteType) => {

        if (e.key === 'Tab') {

            const slideProperties = getSlidePropertiesByType(slide.type);

            if (!Boolean(slide?.options?.length) && slideProperties.baseType === BaseTypes.Vote) {

                e.preventDefault();

                e.stopPropagation();

                setSlideOption(0, '', false, slide.id);
            }
        }
    }, []);

    const setPreviewSlideLayout = useCallback((layoutType: LayoutTypes | null | undefined) => {

        dispatch(presentationActions.setPreviewSlideLayout(layoutType))
    }, []);

    const updateSlideShape = useCallback((shape: SlideShape, shapeIndex: number) => {

        dispatch(presentationActions.updateSlideShape(shape, shapeIndex));
    }, []);

    const fetchSlideShapePresets = useCallback(async () => {

        const data = await get('slides/shape-presets');

        if (Boolean(data.presets)) {

            dispatch(presentationActions.setSlideShapePresets(data.presets));
        }

        if (Boolean(data.error)) {

            console.warn({
                error: data.error,
            })
        }
    }, []);

    const updateSlide = useCallback((slide: SlideType) => {

        dispatch(presentationActions.updateSlide(slide));
    }, []);

    const setSlideTable = useCallback((table: Array<Array<TableCellData>>, slideId: number) => {

        dispatch(presentationActions.setSlideTable(table, slideId))
    }, []);

    return {
        createSlide,
        setSlideTable,
        addNewSlides,
        duplicateSlide,
        deleteSlide,
        setSlideOption,
        updateSlideOption,
        updateSlide,
        removeOption,
        setSlideTitle,
        setSlideSetting,
        clearQuestionFile,
        reOrder,
        clearPresentation,
        handlePressedTabOnVoteSlideTitle,
        setPreviewSlideLayout,
        updateSlideShape,
        fetchSlideShapePresets,
    }
}

export default useSlideActions;