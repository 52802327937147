import { AppButtonVariant } from "Atomic/atoms/Button/AppButton";
import { AppTextVariant } from "Atomic/atoms/Text/AppText";
import BackToOverviewButton from "Components/beta/common/buttons/back/BackToOverviewButton";
import ProfileIcon from "Components/beta/content/content_header/profileicon/ProfileIcon";
import ThreeColumnsLayout from "Components/layouts/threeColumnsLayout/ThreeColumnsLayout";
import { themeVars } from "Components/theme/vanillaTheme.css";
import React from "react";
import { classes } from "./style.css";

const Header =()=> {

    return(
        <ThreeColumnsLayout
            bgColor={themeVars.color.tertiaryBg} 
            leftContent={<BackToOverviewButton as={AppButtonVariant.Primary}/>}
            rightContent={<div className={classes.profileIcon}><ProfileIcon as={AppTextVariant.PrimaryBgSmall}/></div>}
        >
        </ThreeColumnsLayout>
    );
}
export default Header;