import * as appTranslationActions from 'Actions/appTranslationActions';
import { get } from 'Scripts/api';
import { getFromCookies } from 'Scripts/cookieHelper';
import { CookieKeys } from 'Scripts/globals';
import { capitalize } from 'Scripts/stringFormatter';
import { AppTranslationState } from 'Types/appTranslationTypes';
import { LanguageState } from 'Types/languageTypes';
import React, { ReactElement, useCallback } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

const DEFAULT_LANGUAGE_KEY = "en";

const useTranslations = () => {

	const translations = useSelector((state: RootStateOrAny) => (state.languageReducer as LanguageState).translations)
	const appTranslations = useSelector((state: RootStateOrAny) => (state.appTranslationReducer as AppTranslationState).translations)

	const dispatch = useDispatch();

	const fetchAppTranslations = async () => {

		const language = getFromCookies(CookieKeys.Language) || DEFAULT_LANGUAGE_KEY;

		try {

			dispatch(appTranslationActions.setAppUpdateLanguageLoading(true));

			const webappTranslations = await get(`translations/tags/${language}/webapp,features`);

			dispatch(appTranslationActions.setAppTranslations({
				...webappTranslations
			}))

			const availableLanguages = await get(`translations/available-languages`);

			dispatch(appTranslationActions.setAppAvailableLanguages(
				availableLanguages
			))

		} catch (error) {

			console.warn(error)

		} finally {

			dispatch(appTranslationActions.setAppUpdateLanguageLoading(false));
		}
	}
	const translate = useCallback((translationKey: string, variables: Array<string> = []) : ReactElement[] | string => {

		let translation = translations?.[translationKey] || translationKey;

		if (translationKey === translation || !variables.length) {

			return capitalize(translation);
		}

		/* Split by %s and %d */
		const splitParts = translation.split(/%\w/g);

		const totals: string[] = [];

		splitParts.forEach((item: string, index: number) => {

			totals.push(item)

			if(variables[index]) {
				totals.push(variables[index])
			}
		})

		return totals.map((item: string, index: number) => {

			if(typeof item === 'string') {
				return (
					<span
						key={index}
						dangerouslySetInnerHTML={{ __html: item }}
	                />
				)
			}

			return (
				<span key={index}>{item}</span>
			)
		})
	}, [translations]);

	const translateWithVars = useCallback((translationKey: string, variables = {}): ReactElement[] | string => {

		let translation = translations?.[translationKey] || translationKey

		if(translationKey === translation) {

			return translation;
		}

		let translationItems: string[] = [];

		Object.keys(variables).forEach((variableKey, variableIndex) => {

			let splitPieces = translation.split(`{${variableKey}}`)

			translation = translation.replace(splitPieces[0], '')
			translation = translation.replace(`{${variableKey}}`, '')

			const translationPieces = [
				splitPieces[0],
				variables[variableKey],
			];

			if (variableIndex + 1 === Object.keys(variables).length) {
				translationPieces.push(splitPieces[1])
			}

			translationItems = [
				...translationItems,
				...translationPieces
			]
		})

		if (!translationItems.length) {

			return translation;
		}

		return translationItems.map((piece, index) => {
			return (
				<span key={index}>{piece}</span>
			)
		});

	}, [ translations]);

	/**
	 * Placeholder function for translations
	 */
	const translatePlaceholder = (translationFileKey: string, vars: Record<string, String|number> = {}, strictCheck: boolean = false ) => {
		
		if (strictCheck && !appTranslations[translationFileKey]) return;

		let translationValue =  appTranslations?.[translationFileKey] || translationFileKey;

		for(const variableKey in vars) {

			const variableValue = `${vars[variableKey]}`;

			translationValue = `${translationValue}`.replace(`{${variableKey}}`, variableValue);
		}

		// Replace \n to a space for now, so it does not look weird.. we cannot use <br/> as react doesn't allow html
		return translationValue.replace(/\\n/g, ' ');
	}

	return {
		translate,
		translateWithVars,
		translatePlaceholder,
		fetchAppTranslations,
	}
}

export { useTranslations };

