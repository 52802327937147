import { InputBase, Radio } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useIdentification } from 'Hooks/useIdentification';
import { useTranslations } from 'Hooks/useTranslations';
import React from 'react';
import { testIds } from 'Scripts/cypressTestIds';
import { QuestionTypes } from 'Types/identificationQuestionTypes';

interface EmptyIdentificationAnswerProps {
    questionType: QuestionTypes;
    questionIndex: number;
    questionId: number | null;
}

const EmptyIdentificationAnswer = ({ questionType, questionIndex, questionId }: EmptyIdentificationAnswerProps) => {

    const {
        addIdentificationQuestionAnswer,
    } = useIdentification();

    const handleSelect = () => {

        addIdentificationQuestionAnswer(questionIndex, questionId);
    }

    const { translatePlaceholder } = useTranslations();

    return (
        <div
                onClick={(e) => handleSelect(e)}
        >
            <InputBase
                startAdornment={<>
                    {questionType === QuestionTypes.Radio && <Checkbox disabled />}
                    {questionType === QuestionTypes.MultipleChoice && <Radio disabled />}
                </>}
                data-eltype="button"
                data-elaction="identification_question_option_add"
                data-elscreen="settings"
                fullWidth
                placeholder={translatePlaceholder("ENTER_YOUR_ANSWER_OPTION")}
                data-testid={`${testIds.IDENTIFICATION_QUESTION_PREFIX}_${testIds.QUESTION_OPTION_INPUT_EMPTY}`}
            />
        </div>
    );
};

export default EmptyIdentificationAnswer;