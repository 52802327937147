import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import React from 'react';
import { classes } from './style.css';

const PreviewQuoteAuthorSlideShape = () => {

    const { presentationStyle } = usePresentationStyles();

    return (
        <div>
            <AccountCircleIcon
                className={classes.quoteAuthorIcon}
                sx={{
                    color: presentationStyle?.textColor,
                }}
            />
        </div>
    );
};

export default PreviewQuoteAuthorSlideShape;