import React from 'react';
import ModeratorPanelOption from '../../moderator_panel/ModeratorPanelOption';

const AdditionalWordcloudSettings = () => {
	return (
		<>
			<ModeratorPanelOption />
		</>
	);
};

export default AdditionalWordcloudSettings;