import { setModeratorSharingToken } from "Actions/appActions";
import { useSession } from "Hooks/useSession";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { get } from "Scripts/api";
import { AppState } from "Types/appTypes";


const useMessageFilter = () => {

    const {
        currentSessionId
    } = useSession();

    const moderatorSharingToken = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).moderatorSharingToken);

    const dispatch = useDispatch();

    const [ sharePanelLoading, setSharePanelLoading ] = useState<boolean>(false);

    const fetchModeratorSharingToken = async () => {

        setSharePanelLoading(true);

        try {

            const response = await get(`sessions/${currentSessionId}/moderator`);

            if (Boolean(response.moderatorSharingToken)) {

                dispatch(setModeratorSharingToken(response.moderatorSharingToken))

            } else {
                /* If the user doesn't have a token, generate one */
                generateModeratorPanelToken();
            }

        } catch (error) {

            console.warn('Unable to fetch moderator sharing token', error);

        }

        setSharePanelLoading(false);
    }

    const generateModeratorPanelToken = async () => {

        setSharePanelLoading(true);

        try {

            const filterData = await get(`users/share-filter`);

            if(!filterData.error) {

                const { moderatorSharingToken } = filterData;

                dispatch(setModeratorSharingToken(moderatorSharingToken));
            }

        } catch (error) {

            console.warn('Unable to enable sharing message filter', error);

        }

        setSharePanelLoading(false);
    }

    return {
        sharePanelLoading,
        moderatorSharingToken,
        fetchModeratorSharingToken,
        generateModeratorPanelToken,
    }
}

export default useMessageFilter;