import * as presentationActions from 'Actions/quizActions';
import { LayoutTypes } from "Types/layoutTypes";
import { PresentationState, SlideShapePresets } from 'Types/presentationTypes';
import { SlideShapeType, SlideTypes } from 'Types/slideTypes';
import { useCallback } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";


export const useSlideShapes = () => {

    const dispatch = useDispatch();

    const slideShapePresets = useSelector((state: RootStateOrAny) => (state.quizReducer) as PresentationState).slideShapePresets;

    const convertSlide = (slideId: number, newLayoutType: LayoutTypes, newSlideType: SlideTypes) => {

        dispatch(presentationActions.convertSlide(slideId, newLayoutType, newSlideType))
    }

    const getPreset = (slideLayout: LayoutTypes, type: SlideTypes) => {

        slideLayout = (slideLayout || 'default');

        return (slideShapePresets as SlideShapePresets)?.[slideLayout]?.[type] ?? [];
    }

    const getShapePreset = useCallback((slideLayout: LayoutTypes, type: SlideTypes, shapeType: SlideShapeType) => {

        const presets = getPreset(slideLayout, type);

        const shapePreset = presets.find((shape) => shape.type === shapeType);

        return shapePreset;
    },[
        slideShapePresets,
    ]);

    return {
        convertSlide,
        getPreset,
        getShapePreset,
    }
}