import DataConnectorComponent from 'Components/beta/data_connector/DataConnectorComponent';
import ParticipantEmojiToggle from 'Components/beta/settings/presentation_summary/options/general/emoji/ParticipantEmojiToggle';
import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import React from 'react';

const TableSlideSettings = () => {

    return (
        <>
            <LoginCodeEditor />
            {process.env.ENABLE_DATA_CONNECTOR && (
                <DataConnectorComponent />
            )}
            <ParticipantEmojiToggle/>
        </>
    );
};

export default TableSlideSettings;

