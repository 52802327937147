import { Box, ClickAwayListener, Popper, Table, TableBody, TableRow } from '@mui/material';
import { generateString } from 'Scripts/keyHelper';
import { chunk } from 'Scripts/listHelper';
import React, { CSSProperties, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import AppDynamicTableCell from './cell/AppDynamicTableCell';
import AppDynamicTableMenu from './menu/AppDynamicTableMenu';
import { classes } from './style.css';

export enum TableCellPositionAttributes {
    DataCellColumn = 'data-cell-column',
    DataCellRow = 'data-cell-row',
}

export enum TableCellAttributeNames {
    Delete = 'data-highlight-delete',
    Select = 'data-highlight-select',
    RowBefore = 'data-highlight-row-before',
    RowAfter = 'data-highlight-row-after',
    ColumnBefore = 'data-highlight-column-before',
    ColumnAfter = 'data-highlight-column-after',
}

export enum TableControlsPosition {
    Side,
    Top,
}

export interface TableCellPosition {
    rowIndex: number;
    columnIndex: number;
}

export enum TableCellType {
    Text = 'text',
    Image = 'image',
    Data = 'data',
}

export enum TableCellDataType {
    Default = '',
    Heading = 'heading',
}

export interface TableCellData {
    id: string | number;
    content: string | number;
    type: TableCellType;
    cellType: TableCellDataType;
    jss: string;
}

interface AppDynamicTableProps {
    data: Array<Array<TableCellData>>;
    onUpdateCell: (newCellData: TableCellData, rowIndex: number, columnIndex: number) => void;
    onUpdateTableStructure: (newTable: Array<Array<TableCellData>>) => void;
    maxRows?: number;
    maxColumns?: number;
    cellStyles?: CSSProperties;
}

export enum AppDyanmicTableBoundaries {
    MaxRows = 10,
    MaxColumns = 10,
}

export interface AppDynamicTableContextData {
    onToggleColumnHeadings: () => void;
    onToggleRowHeadings: () => void;
    onClearTable: () => void;
    onAddRow: (rowIndex: number) => void;
    onAddColumn: (columnIndex: number) => void;
    onDeleteColumn: (columnIndex: number) => void;
    onDeleteRow: (rowIndex: number) => void;
    onEditCell: (rowIndex: number, columnIndex: number, newValue: string) => void;
    onHighlightTable: (showHighlight: boolean, highlightType: TableCellAttributeNames) => void;
    onHighlightCell: (showHighlight: boolean, highlightType: TableCellAttributeNames, rowIndex: number, columnIndex: number) => void;
    onHighlightColumn: (showHighlight: boolean, highlightType: TableCellAttributeNames, columnIndex: number) => void;
    onHighlightRow: (showHighlight: boolean, highlightType: TableCellAttributeNames, rowIndex: number) => void;
    tableRef: React.RefObject<HTMLTableElement>;
    rowCount: number;
    columnCount: number;
    canDeleteRow: boolean;
    canDeleteColumn: boolean;
    canAddColumn: boolean;
    canAddRow: boolean;
    rowHasHeader: boolean;
    columnHasHeader: boolean;
    showTableControls: boolean;
    cellStyles: CSSProperties;
}

export const AppDynamicTableContext = React.createContext<AppDynamicTableContextData>({} as AppDynamicTableContextData);

export const getPlaceholderTableData = () => {

    return Array.from({ length: 2 }).map((_, rowIndex) => {

        return Array.from({ length: 2 }).map((_, columnIndex) => getEmptyTableCell())
    });
}

export const getRandomPlaceholderChartData = () : Array<Array<TableCellData>> => {

    const randomDataSets = [
        [
            ['Year', 'Q1', 'Q2', 'Q3', 'Q4'],
            ['2020', 500, 600, 700, 750],
            ['2021', 550, 650, 700, 800],
            ['2022', 600, 700, 800, 850],
        ],
        [
            ['Month', 'Rent', 'Groceries', 'Transportation', 'Entertainment', 'Health', 'Savings', 'Other'],
            ['January', 1200, 300, 100, 50, 75, 200, 100],
            ['February', 1200, 300, 100, 75, 100, 200, 50],
            ['March', 1200, 250, 100, 100, 75, 200, 100],
            ['April', 1200, 275, 100, 75, 100, 300, 75],
            ['May', 1200, 300, 150, 50, 100, 200, 100],
        ]
    ];

    const randomDataSet = randomDataSets[Math.floor(Math.random() * randomDataSets.length)];

    const randomDataSetTableData: Array<Array<TableCellData>> = [[]];

    randomDataSet.forEach((columnn, columnIndex) => {

        columnn.forEach((cell, rowIndex) => {

            if (!randomDataSetTableData[columnIndex]) {

                randomDataSetTableData[columnIndex] = [];
            }

            randomDataSetTableData[columnIndex][rowIndex] = {
                ...getEmptyTableCell(),
                content: cell,
                cellType: columnIndex === 0 || rowIndex === 0 ? TableCellDataType.Heading : TableCellDataType.Default,
            }
        })
    });

    return randomDataSetTableData
}

const getEmptyTableCell = () => ({
    id: generateString(16),
    content: '',
    type: TableCellType.Text,
    cellType: TableCellDataType.Default,
    jss: JSON.stringify({}),
})

const AppDynamicTable = ({
    data = [],
    onUpdateCell,
    onUpdateTableStructure,
    maxRows = AppDyanmicTableBoundaries.MaxRows,
    maxColumns = AppDyanmicTableBoundaries.MaxColumns,
    cellStyles = {},
}: AppDynamicTableProps) => {

    const [tableData, setTableData] = useState<Array<Array<TableCellData>>>([]);

    const tableRef = useRef<HTMLTableElement>(null);

    const [showTableControls, setShowTableControls] = useState<boolean>(false);

    useEffect(() => {

        if (Boolean(data.length)) {

            setTableData(data);
        }

    }, [data])

    const columnHasHeader = useMemo(() => {

        if (Boolean(tableData?.[1]?.[0])) {

            return tableData?.[1]?.[0].cellType === TableCellDataType.Heading;
        }

        return tableData?.[0]?.[0].cellType === TableCellDataType.Heading;

    }, [
        tableData?.[1]?.[0]?.cellType,
        tableData?.[0]?.[0]?.cellType
    ]);

    const rowHasHeader = useMemo(() => {

        if (Boolean(tableData?.[0]?.[1])) {

            return tableData?.[0]?.[1].cellType === TableCellDataType.Heading
        }

        return tableData?.[0]?.[0].cellType === TableCellDataType.Heading;

    }, [
        tableData?.[0]?.[1]?.cellType,
        tableData?.[0]?.[0]?.cellType
    ]);

    const onAddRow = (rowIndex: number) => {

        const newData = [...tableData];

        const [row] = newData;

        newData.splice(rowIndex, 0, Array.from(row).map((_, index) => {

            const newCell = getEmptyTableCell();

            if (columnHasHeader && index === 0) {

                newCell.cellType = TableCellDataType.Heading;
            }

            return newCell;

        }));

        setTableData(newData);

        onUpdateTableStructure(newData);
    }

    const onAddColumn = (columnIndex: number) => {

        const newData = [...tableData];

        newData.forEach((row, rowIndex) => {

            const newCell = getEmptyTableCell();

            if (rowHasHeader && rowIndex === 0) {

                newCell.cellType = TableCellDataType.Heading;
            }

            newData[rowIndex].splice(columnIndex, 0, newCell);
        })

        setTableData(newData);

        onUpdateTableStructure(newData);
    }

    const onDeleteRow = (rowIndex: number) => {

        const updatedData = [
            ...tableData.slice(0, rowIndex),
            ...tableData.slice(rowIndex + 1)
        ];

        setTableData(updatedData);

        onUpdateTableStructure(updatedData);
    }

    const onDeleteColumn = (columnIndex: number) => {

        const updatedData = tableData.map(row => [
            ...row.slice(0, columnIndex),
            ...row.slice(columnIndex + 1)
        ]);

        setTableData(updatedData);

        onUpdateTableStructure(updatedData);
    }

    const onEditCell = (rowIndex: number, columnIndex: number, newValue: string) => {

        if (newValue !== tableData?.[rowIndex]?.[columnIndex]?.content) {

            setTableData(currentTableData => {

                currentTableData[rowIndex][columnIndex].content = newValue;

                onUpdateCell(currentTableData[rowIndex][columnIndex], rowIndex, columnIndex);

                return currentTableData;
            });
        }
    }

    const onHighlightColumn = (showHighlight: boolean, higlightType: TableCellAttributeNames, columnIndex: number) => {

        clearHighlights(higlightType === TableCellAttributeNames.Select);

        if (!showHighlight || !showTableControls) {

            return;
        }

        const tdElements = (tableRef.current as HTMLTableElement).querySelectorAll('td');

        const chunkedElements = chunk(Array.from(tdElements), tableData[0].length);

        chunkedElements.forEach((row) => {

            row.forEach((cell, colIdx) => {

                if (colIdx === columnIndex) {

                    cell.setAttribute(higlightType, String(true));
                }
            })
        })
    }

    const clearHighlights = (includeSelected: boolean = false) => {

        const tdElements = (tableRef.current as HTMLTableElement).querySelectorAll('td');

        Array.from(tdElements).forEach((cell) => {

            if (includeSelected) {

                cell.removeAttribute(TableCellAttributeNames.Select);
            }

            cell.removeAttribute(TableCellAttributeNames.ColumnAfter);
            cell.removeAttribute(TableCellAttributeNames.Delete);
            cell.removeAttribute(TableCellAttributeNames.ColumnBefore);
            cell.removeAttribute(TableCellAttributeNames.RowBefore);
            cell.removeAttribute(TableCellAttributeNames.RowAfter);
        })
    }

    const onHighlightRow = (showHighlight: boolean, highlightType: TableCellAttributeNames, rowIndex: number) => {

        clearHighlights(highlightType === TableCellAttributeNames.Select);

        if (!showHighlight || !showTableControls) {

            return;
        }

        const tdElements = (tableRef.current as HTMLTableElement).querySelectorAll('td');

        const chunkedElements = chunk(Array.from(tdElements), tableData[0].length);

        chunkedElements.forEach((row, rowIdx) => {

            row.forEach((cell) => {

                if (rowIndex === rowIdx) {

                    cell.setAttribute(highlightType, String(true));
                }
            })
        })
    }

    const onHighlightCell = (showHighlight: boolean, highlightType: TableCellAttributeNames, columnIndex: number, rowIndex: number) => {

        clearHighlights(highlightType === TableCellAttributeNames.Select);

        if (!showHighlight || !showTableControls) {

            return;
        }

        const tdElements = (tableRef.current as HTMLTableElement).querySelectorAll('td');

        const chunkedElements = chunk(Array.from(tdElements), tableData[0].length);

        chunkedElements.forEach((row, rowIdx) => {

            row.forEach((cell, columnIdx) => {

                if (rowIndex === rowIdx && columnIndex === columnIdx) {

                    cell.setAttribute(highlightType, String(true));
                }
            })
        })
    }

    const onHighlightTable = (showHighlight: boolean, highlightType: TableCellAttributeNames) => {

        clearHighlights(highlightType === TableCellAttributeNames.Select);

        if (!showHighlight || !showTableControls) {

            return;
        }

        const tdElements = (tableRef.current as HTMLTableElement).querySelectorAll('td');

        Array.from(tdElements).forEach(cell => {

            cell.setAttribute(highlightType, String(true));
        })
    }

    const enableTableControls = () => {

        if (!showTableControls) {

            setShowTableControls(true);
        }
    }

    const disableTableControls = () => {

        if (showTableControls) {

            setShowTableControls(false);
        }
    }

    useEffect(() => {

        if (!Boolean(showTableControls)) {

            clearHighlights(true);
        }

    }, [showTableControls]);


    const enableColumnHeader = () => {

        const newTableData = [...tableData];

        newTableData.forEach(row => {

            const [cell] = row;

            if (Boolean(cell)) {

                cell.cellType = TableCellDataType.Heading;
            }
        })

        setTableData(newTableData);

        onUpdateTableStructure(newTableData);
    }

    const enableRowHeader = () => {

        const newTableData = [...tableData];

        newTableData[0].forEach(cell => {

            cell.cellType = TableCellDataType.Heading;
        })

        setTableData(newTableData);

        onUpdateTableStructure(newTableData);
    }

    const disableRowHeader = () => {

        const newTableData = [...tableData];

        const rowSize = newTableData.length;

        newTableData[0].forEach((cell, index) => {

            if (!(index === 0 && columnHasHeader) || rowSize === 1) {

                cell.cellType = TableCellDataType.Default;
            }
        })

        setTableData(newTableData);

        onUpdateTableStructure(newTableData);
    }

    const disableColumnHeader = () => {

        const newTableData = [...tableData];

        const columnSize = newTableData[0].length;

        newTableData.forEach((row, index) => {

            if (!(index === 0 && rowHasHeader) || columnSize === 1) {

                const [cell] = row;

                if (Boolean(cell)) {

                    cell.cellType = TableCellDataType.Default;
                }
            }
        })

        setTableData(newTableData);

        onUpdateTableStructure(newTableData);
    }

    const onClearTable = () => {

        const newTableData = [...tableData];

        newTableData.forEach((row, rowIndex) => {

            row.forEach((cell, columnIndex) => {

                newTableData[rowIndex][columnIndex] = {
                    ...getEmptyTableCell(),
                }
            })
        })

        setTableData(newTableData);

        onUpdateTableStructure(newTableData);
    }

    const onToggleRowHeadings = () => {

        if (rowHasHeader) {

            disableRowHeader();

        } else {

            enableRowHeader();
        }
    }

    const onToggleColumnHeadings = () => {

        if (columnHasHeader) {

            disableColumnHeader();

        } else {

            enableColumnHeader();
        }
    }

    const rowCount = (tableData.length);

    const columnCount = (tableData?.[0] || []).length;

    return (
        <AppDynamicTableContext.Provider value={{
            onToggleColumnHeadings,
            onToggleRowHeadings,
            onClearTable,
            onAddRow,
            onAddColumn,
            onDeleteColumn,
            onDeleteRow,
            onEditCell,
            onHighlightTable,
            onHighlightCell,
            onHighlightColumn,
            onHighlightRow,
            tableRef,
            rowCount,
            columnCount,
            canDeleteRow: rowCount > 1,
            canDeleteColumn: columnCount > 1,
            canAddColumn: columnCount < maxColumns,
            canAddRow: rowCount < maxRows,
            rowHasHeader,
            columnHasHeader,
            showTableControls,
            cellStyles,
        }}>
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={() => disableTableControls()}
            >
                <Box
                    position="relative" >
                    <Box
                        onMouseDown={() => enableTableControls()}
                        display="flex"
                        ref={tableRef}>
                        <Table className={classes.table}>
                            <TableBody className={classes.tableBody}>
                                {tableData.map((row, rowIndex) => (
                                    <TableRow key={rowIndex} className={classes.tableRow}>
                                        {row.map((cell, columnIndex) => (
                                            <Fragment key={cell.id}>
                                                <AppDynamicTableCell
                                                    cell={cell}
                                                    rowIndex={rowIndex}
                                                    columnIndex={columnIndex}
                                                />
                                            </Fragment>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                    <Popper
                        style={{ zIndex: 1300 }}
                        anchorEl={tableRef.current}
                        open={showTableControls}>
                        <AppDynamicTableMenu />
                    </Popper>
                </Box>
            </ClickAwayListener>
        </AppDynamicTableContext.Provider>
    )
}

export default AppDynamicTable;