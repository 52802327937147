
export const shuffleContents = (list: any[]) : any[] => {
	for (var i = list.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = list[i];
		list[i] = list[j];
		list[j] = temp;
	}

	return list
}

export const sortByProperty = (list: any[], property: string, ascending?: boolean = true) => {
	return list.sort((a, b) => {
		const first = ascending ? a : b
		const second = ascending ? b : a
		const evaluation = first[property] - second[property]
		if (isNaN(evaluation)) {
			if (first[property] < second[property]) {
				return -1;
			}
			if (first[property] > second[property]) {
				return 1
			}
			return 0;
		}
		return evaluation
	})
}

export const sortObjectKeysByProperty = (obj: Record<string, any>, property: string, ascending: boolean = true) => {
	return Object.keys(obj).sort((a, b) => {
		const first = ascending ? a : b
		const second = ascending ? b : a
		return obj[first][property] - obj[second][property]
	})
}

export const chunk = (array: Array<any>, chunkSize: number) => {

	const result = [];

	for (let i = 0; i < array.length; i += chunkSize) {

		const chunk = array.slice(i, i + chunkSize);
		result.push(chunk);
	}

	return result;
}

export const quizSortProperties = {
	QUESTION: 'questions', // Sort by question count
	ALPHABETICAL: 'quizTitle', // Sort title alphabetically
	DATE: 'id', // Newer quizzes have a higher ID
	POINTS: 'totalPoints',
	TYPES: 'questionTypes'
}

export const quizResultSortProperties = {
	START_TIME: 'startTime',
	PARTICIPANTS: 'participants',
	ALPHABETICAL: 'quizTitle',
	QUESTION: 'questions',
	RESPONSES: 'totalVotes',
}

export const sortDirections = {
	ASC: 'ASC',
	DESC: 'DESC',
}