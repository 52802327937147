import Button from '@mui/material/Button';
import Caption from 'Components/beta/common/caption/Caption';
import { useTranslations } from 'Hooks/useTranslations';
import React from 'react';
import { classes } from './style.css';

const WeightedVotingInfo = () => {

	const { translatePlaceholder } = useTranslations();

	const clickedContactUs = () => {

		window.location.href = 'mailto:sales@sendsteps.com';
	}

	return (
		<div>
			<div>
				<Caption title={translatePlaceholder("WEIGHTED_VOTING_TITLE")} />
				<br/>
				<Caption title={`• ${translatePlaceholder("WEIGHTED_VOTING_LOGIN_CODES")}`} />
				<Caption title={`• ${translatePlaceholder("WEIGHTED_VOTING_PARTICIPANTS")}`} />
				<Caption title={`• ${translatePlaceholder("WEIGHTED_VOTING_RESULTS")}`} />
			</div>
			<Button
				className={classes.contactUsButton}
				onClick={() => clickedContactUs()}

				data-eltype="button"
				data-elaction="contact_us"
				data-elscreen="settings"
				data-elposition="unspecified"
			>
				{translatePlaceholder("BTN_CONTACT_US")}
			</Button>
		</div>

	);
};

export default WeightedVotingInfo;