import * as newsFeedActions from 'Actions/newsFeedActions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { get } from 'Scripts/api';
import { NewsFeedState } from 'Types/newsFeedTypes';

export const useNewsFeed = () => {

    const dispatch = useDispatch();

    const pageSize = useSelector((state: RootStateOrAny) => (state.newsFeedReducer as NewsFeedState).newsFeedPageData.pageSize);

    const pageIndex = useSelector((state: RootStateOrAny) => (state.newsFeedReducer as NewsFeedState).newsFeedPageData.pageIndex);

    const setNewsFeedPageIndex = (pageIndex: number) => {

        dispatch(newsFeedActions.setNewsFeedPageIndex(pageIndex));
    }

    const setNewsFeedPageSize = (pageSize: number) => {

        dispatch(newsFeedActions.setNewsFeedPageSize(pageSize));
    }

    const fetchNewsFeed = async () => {

        try {

            dispatch(newsFeedActions.setNewsFeedPageLoading(true));

            const params = new URLSearchParams({
                pageSize,
                pageIndex,
            })

            const response = await get(`news-feed?${params}`);

            if(!response.error) {

                dispatch(newsFeedActions.setNewsFeedTableData(response, (pageIndex > 0)));
            }

        } catch (error) {

            console.warn(error)

        } finally {

            dispatch(newsFeedActions.setNewsFeedPageLoading(false));
        }
    }

    return {
        setNewsFeedPageIndex,
        setNewsFeedPageSize,
        fetchNewsFeed,
    }
}