import { addQuestion, addQuestionAnswer, removeQuestion, removeQuestionAnswer, setQuestionDeleting, setQuestions, updateQuestion, updateQuestionAnswer } from "Actions/identificationActions"
import { useSnackbar } from "notistack"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { del, get, post } from "Scripts/api"
import { IdentificationState } from "Types/identificationQuestionTypes"

export const useIdentification = () => {

    const questions = useSelector((state: RootStateOrAny) => (state.identificationReducer as IdentificationState).questions);

    const questionDeleting = useSelector((state: RootStateOrAny) => (state.identificationReducer as IdentificationState).questionDeleting);

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    /**
     * Fetches the identification questions and dispatches them to the reducer
     */
    const fetchIdentificationQuestions = async () => {

        try {

            const result = await get('identification/questions');

            if(result.questions) {

                dispatch(setQuestions(result.questions))
            }

            if(result.error) {

                enqueueSnackbar(result.error, { variant: 'error' });
            }

        } catch (error) {

            console.warn(error)
        }
    }

    /**
     * Adds a new identification question to the reducer
     */
    const addIdentificationQuestion = () => {

        dispatch(addQuestion())
    }

    /**
     *
     * @param questionIndex The index of the question to update
     * @param property The property to update
     * @param value The value of the property to update
     */
    const updateIdentificationQuestion = async (questionIndex: number, property: string, value: any) => {

        const questionToUpdate = {
            data: {
                ...(questions[questionIndex]?.data || {}),
                [property]: value,
            },
            answers: [
                ...(questions[questionIndex]?.answers || []),
            ]
        };

        const updateResponse = await post('identification/question', {
            question: JSON.stringify(questionToUpdate.data),
        });

        if(updateResponse.id) {

            questionToUpdate.data.id = updateResponse.id
        }

        if(updateResponse.answers) {

            questionToUpdate.answers = updateResponse.answers
        }

        dispatch(updateQuestion(questionIndex, questionToUpdate));
    }

    const setIsQuestionDeleting = (isDeleting: boolean) => {

        dispatch(setQuestionDeleting(isDeleting))
    }

    /**
     * Removes an identification question.
     * If the question has an id which matches the DB, the deletion is posted to the API.
     *
     * @param questionId The Id of the question to remove (if unset, just remove locally)
     * @param questionIndex The array index of the question (Since it might not have an ID)
     * @return boolean
     */
    const removeIdentificationQuestion = async (questionIndex: number, questionId: number | null) => {

        try {

            setIsQuestionDeleting(true);

            const response = await del(`identification/question/${questionId}`);

            if (response.error && Boolean(questionId)) {

                enqueueSnackbar(response.error, { variant: 'error' });

                setIsQuestionDeleting(false);

            } else {

                /* Delay to animate the state correctly first to prevent jumping */
                const timeout = setTimeout(() => {

                    clearTimeout(timeout);

                    dispatch(removeQuestion(questionIndex))

                    setIsQuestionDeleting(false);

                }, 500);

                return true;
            }

            setIsQuestionDeleting(false);

        } catch (error) {

            console.warn(error)

            setIsQuestionDeleting(false);
        }

        return false;
    }

    /**
     * Adds an answer to an identification question (locally)
     *
     * @param questionId The Id of the question to add the answer to
     * @param questionIndex The array index of the question (for easier access)
     */
    const addIdentificationQuestionAnswer = async (questionIndex: number, questionId: number) => {

        dispatch(addQuestionAnswer(questionIndex, questionId));
    }

    /**
     * Updates an identification question answer with a new text
     *
     * @param questionIndex The index of the question
     * @param answerIndex The index of the answer to update
     * @param answerValue The (text) value of the answer
     */
    const updateIdentificationQuestionAnswer = async (questionIndex: number, answerIndex: number, answerValue: string) => {

        try {

            const question = questions[questionIndex];

            const response = await post('identification/question-option', {
                participantinfofieldsId: question?.data?.id,
                allowedValues: answerValue,
            });

            if(response.id) {

                dispatch(updateQuestionAnswer(questionIndex, answerIndex, answerValue, response.id))
            }

        } catch (error) {

            console.warn(error)
        }
    }

    /**
     * Removes an answer from a question. If the ID of the answer is known, we post the deletion to the API.
     * (Empty answers arent communicated to the API untill filled, so we only remove them locally)
     *
     * @param questionIndex The index of the question which we're removing the answer from
     * @param answerIndex The index of the answer to remove
     * @return boolean;
     */
    const removeIdentificationQuestionAnswer = async (questionIndex: number, answerIndex: number) => {

        try {

            const question = questions[questionIndex];

            const answer = question?.answers[answerIndex];

            setIsQuestionDeleting(true);

            const response = await del(`identification/question-option/${answer?.id}`);

            if (response.error && Boolean(answer?.id)) {

                enqueueSnackbar(response.error, { variant: 'error' });

                setIsQuestionDeleting(false);

            } else {

                setTimeout(() => {

                    dispatch(removeQuestionAnswer(questionIndex, answerIndex))

                    setIsQuestionDeleting(false);

                }, 500);

                return true;
            }

        } catch (error) {

            console.warn(error)

            setIsQuestionDeleting(false);
        }

        return false;
    }

    return {
        questions,
        questionDeleting,
        setIsQuestionDeleting,

        fetchIdentificationQuestions,

        addIdentificationQuestion,
        updateIdentificationQuestion,
        removeIdentificationQuestion,

        addIdentificationQuestionAnswer,
        updateIdentificationQuestionAnswer,
        removeIdentificationQuestionAnswer,
    }
}