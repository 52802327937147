import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Html from 'Components/common/util/html/Html';
import DarkTransitionAnimation from 'Components/play/templates/geometry_template/animations/components/dark/DarkTransitionAnimation';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useInterval } from 'Scripts/intervalHook';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import React, { useEffect, useState } from 'react';
import { classes } from './style.css';


const defaultTimerValue = 5;

const QuizCountdownSlideSessionPreview = ({ slide }: SlideConsumerComponent<ContentSlideType>) => {

    const slideProperties = getSlideProperties(slide);

    /* Countdown before the quiz actually starts */
    const [countdown, setCountdown] = useState((slide.dynamicTimerLimit || defaultTimerValue) as number);

    /* Determines wether the page transition should be played */
    const [playTransition, setPlayTransition] = useState(false);

    const { presentationStyle } = usePresentationStyles();


    /*
        Every second, assuming the countdown is higher than 1,
        decrement the countdown untill it reaches 0
    */
    useInterval(() => {

        setCountdown(countdown - 1);

    }, (!!countdown) ? 1000 : null)

    /*
        After the page transition has played,
        wait for 2.5s and navigate to the next slide
    */
    useInterval(() => {

        setCountdown((slide.dynamicTimerLimit || defaultTimerValue) as number);

        setPlayTransition(false)

    }, playTransition ? 1500 : null)

    /*
     Whenever countdown changes, check if its still higher than 0.
     If not, navigate to next slide
    */
    useEffect(() => {

        setPlayTransition(!countdown);

    }, [countdown])

    return (
        <div className={classes.wrapper}>
            <DarkTransitionAnimation show={playTransition} />
            <Fade in={true}>
                <Grid
                    height="100%"
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    <Grid item>
                        <Typography variant="h1" className={classes.countdownCounter} sx={{
                            color: presentationStyle?.textColor,
                        }}>
                            {countdown}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.startingSoon}>
                        <Html
                            content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                            fontSize={slideProperties.slideSettings.titleFontSize}
                        />
                    </Grid>
                </Grid>
            </Fade>
        </div >
    );
};

export default QuizCountdownSlideSessionPreview