export const TOKEN_NAME: string = 'SendstepsQuizToken';
export const REFRESH_TOKEN_NAME: string = 'SendstepsWebPresentationsRefreshToken';
export const TOKEN_EXPIRES_NAME = 'AuthTokenExpires';
export const LAST_SELECTED_PRESENTATION_PAGE: string = 'LastSelectedPresentationPage';
export const EXPIRED_TOKEN_VALUE: string = 'expired token';
export const WEB_PRESENTATIONS_CLIENT_ID: string = 'web_presentations';
export const GENERAL_ERROR_MESSAGE = 'It seems our services are experiencing high capacity. Please try again later.';

export enum ErrorTexts {
    PresentationHasNoSlides = `This presentation doesn't contain any slides`,
    PresentationContainsErrors = `This presentation contains missing values.`,
}

export enum ElementIDs {
    EditorContent = "EditorContent",
}

export enum ApiErrorCodes {
    LicenseReachedMaxUsers = 'reached_maximum_users',
    LicenseReachedMaxResults = 'reached_maximum_results',
    EditTemplateMissingFeatures = 'edit_template_missing_features',
    AiPresentationLimitReached = 'AI_PRESENTATION_LIMIT_REACHED',
    AiSlideLimitReached = 'AI_SLIDE_LIMIT_REACHED',
    NoFeatureAllAiLanguages = 'ALL_AI_LANGUAGES',
}

export enum LocalStorageKeys {
    LastSeenNewsFeedItemDate = 'lastSeenNewsFeedItemDate',
    ClaimPresentationKey = 'claimPresentationKey',
    UserOpinionAskNewDesignFeedbackKey = 'userOpinionAskNewDesignFeedback',
    PlanName = 'planName',
    UnsavedPresentationData = 'UnsavedPresentationData',
    LimitPopup = 'limit-popup',
    RedirectUrl = 'redirectUrl',
}

export enum CookieKeys {
    AuthToken = 'AuthToken',
    AIGuidedTourCompleted = 'AIGuidedTourCompleted',
    AIFeedbackScreenHidden = 'AIFeedbackScreenHidden',
    AiPrompt = 'aiPrompt',
    HideAiWebinarRibbon = 'hideAiWebinarRibbon',
    HideOneOnOneMeetingRibbon = 'hideOneOnOneMeetingRibbon',
    Language = 'lang',
    HasSeenAiPresentationReadyDialog = 'hasSeenAiPresentationReadyDialog',
}

export const ADDIN_CLIENT_ID: string = 'addin';

export const WEBSITE_PROMO_GET_PARAM_KEY: string = 'promo';

export const DATE_FORMAT_SHORT = 'MMMM Do YYYY';
export const DATE_FORMAT_LONG_WITH_TIME = 'MMMM Do YYYY [at] HH:mm';
export const DATE_FORMAT_TODAY_WITH_TIME = '[Today at] HH:mm';
