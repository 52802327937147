import * as authActions from 'Actions/authActions';
import useWebSocket from 'Hooks/useWebSocket';
import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { AppState } from "Types/appTypes";
import { AuthState } from 'Types/authTypes';
import { SocketEvents } from 'Types/socketTypes';

export const useExternalAuthentication = () => {

    const dispatch = useDispatch();

    const socket = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).liveWebsocket) as WebSocket;

    useWebSocket({});

    const externalAuthenticationKey = useSelector((state: RootStateOrAny) => (state.authReducer as AuthState).externalAuthenticationKey);

    const joinAuthenticationRoom = () => {

        socket.emit(SocketEvents.AuthSubscribe, {
            key: externalAuthenticationKey,
        });
    }

    const bindConfirmEvent = () => {

        socket.on(SocketEvents.AuthConfirmed, (data: any) => {

            if (data.key === externalAuthenticationKey) {

                dispatch(authActions.setExternalAuthenticationConfirmed(true));
            }
        })
    }

    useEffect(() => {

        if (Boolean(socket) && Boolean(externalAuthenticationKey)) {

            joinAuthenticationRoom();

            bindConfirmEvent();
        }

    }, [socket, externalAuthenticationKey])
}