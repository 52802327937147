import { OutlineActionTypes } from "Reducers/outlineReducer";

export const setOutlineObj = (outline: []) => ({
    type: OutlineActionTypes.SetOutline,
    payload: outline,
});

export const setIsLoading = (isLoading: boolean) => ({
    type: OutlineActionTypes.SetIsLoading,
    payload: isLoading,
});

OutlineActionTypes.SetIsLoading