import { Divider, Typography } from '@mui/material';
import FeatureWrapper from 'Atomic/atoms/FeatureWrapper/FeatureWrapper';
import OnOffSwitch from 'Components/beta/common/buttons/switch/OnOffSwitch';
import Caption from 'Components/beta/common/caption/Caption';
import SummaryOption from 'Components/beta/common/summary/option/SummaryOption';
import { Features, useFeatures } from 'Hooks/useFeatures';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { OverviewState } from 'Types/overviewTypes';
import React, { useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import CountrySelect from '../country_select/CountrySelect';

const RespondViaSMSToggle = () => {

	const {
		userSessionInfo,
		updateTexting,
		updateTextingLoading,
	} = useSession();

	const { translatePlaceholder } = useTranslations();

	const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

	const { isFeatureAvailable } = useFeatures();

	const textMessagingEnabled = useMemo(() => {

		return Boolean(Number(userSessionInfo?.smsEnabled));

	}, [userSessionInfo?.smsEnabled, updateTextingLoading])

	const hasTxtSmsFeature = isFeatureAvailable(Features.VOTE_SMS);

	const toggleSetting = () => {

		updateTexting(!textMessagingEnabled)
	}

	return (
		<div>
			<Typography variant="h2" my={3}>
				{translatePlaceholder("SMS_TEXT")}
			</Typography>
			<Divider />
			<SummaryOption
				loading={!userSessionInfo || presentationLoading}
				leftItem={<div>
					<Typography variant="body2">
						{!hasTxtSmsFeature && "💎"} {translatePlaceholder("RESPOND_VIA_SMS_TEXT")}
					</Typography>
					<Caption title={translatePlaceholder("PARTICIPANTS_CAN_RESPOND_VIA_SMS_TEXT")} />
				</div>}
				rightItem={(
					<FeatureWrapper
						data-elscreen={DataElScreen.Settings}
						feature={Features.VOTE_SMS}>
						<OnOffSwitch
							disabled={updateTextingLoading}
							checked={textMessagingEnabled}
							onChange={() => toggleSetting()}
							data-testid={testIds.PARTICIPANT_TXT_RESPONSES_TOGGLE}
						/>
					</FeatureWrapper>
				)}
				trackingAttr={renderingGAAttr(`${DataElAction.SMSResponse}_${textMessagingEnabled}${!hasTxtSmsFeature ? '_limited' : ''}`, DataElPosition.HideAdvancedSection, DataElScreen.Settings, DataElType.Toggle)}
			/>
			<CountrySelect />
		</div>

	);
};

export default RespondViaSMSToggle;