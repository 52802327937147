import { Button, Typography } from '@mui/material';
import RandomWidthSkeleton from 'Atomic/atoms/RandomWidthSkeleton';
import EditableInput from 'Components/beta/common/input/editable/EditableInput';
import { DerivedFeatures } from 'Hooks/useFeatures';
import { useLayout } from 'Hooks/useLayout';
import usePresentation from 'Hooks/usePresentation';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { getTextWithUnlimitedValue, prettyifyQuizTitle } from 'Scripts/stringFormatter';
import { isValidTitleLength } from 'Scripts/validator';
import { FeatureState } from 'Types/featureTypes';
import { OverviewState } from 'Types/overviewTypes';
import { PresentationState } from 'Types/presentationTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const PresentationTitleContainer = () => {

	const presentationTitle = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).quizTitle);

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

	const planInfo = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).planInfo);

	const updatePresentationTitleLoading = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).updatePresentationTitleLoading);

	const {
		setPresentationTitle,
		updatePresentationTitle,
	} = usePresentation();

	const {
		setShowMissingFeature
	} = useLayout()

	const { translatePlaceholder } = useTranslations();

	const saveTitle = (newValue: string) => {

		if (isValidTitleLength(newValue)) {

			setPresentationTitle(newValue);

			updatePresentationTitle(newValue);
		}
	}

	const showParticipantUpgrade = () => {

		setShowMissingFeature(DerivedFeatures.UNLIMITED_PARTICIPANTS)
	}

	const audienceSizeLoading = (!Boolean(planInfo?.audienceSize) || presentationLoading);

	const hasUnlimitedAudienceSize = (planInfo?.audienceSize > 999) || (planInfo?.audienceSize === -1)

	return (
		<div>
			<Typography variant="h1" className={classes.presentationTitle}>
				{presentationLoading && <RandomWidthSkeleton />}
				{!presentationLoading && <EditableInput
					value={prettyifyQuizTitle(presentationTitle)}
					inputProps={{
						className: classes.presentationTitleInput,
						'data-testid': testIds.SETTINGS_PRESENTATION_TITLE,
					}}
					isLoading={updatePresentationTitleLoading}
					onSave={(newValue: string) => saveTitle(newValue)}
				/>}
			</Typography>
			<Typography variant="h5">
				{!audienceSizeLoading && <>
					{translatePlaceholder("YOUR_MAX_IS")}
					<Button
						className={classes.participantButton}
						onClick={() => showParticipantUpgrade()}
						data-eltype="link"
						data-testid={testIds.MAX_PARTICIPANT_COUNT}
						data-elaction="participants_upgrade"
						data-elscreen="settings_summary_before_starting_sendsteps"
						data-elposition="unspecified"
					>
						<Typography variant="h5">
							<u>
								{!hasUnlimitedAudienceSize ? "💎" : ""} {getTextWithUnlimitedValue(planInfo?.audienceSize || 0)} {translatePlaceholder("PARTICIPANTS")}
							</u>
						</Typography>
					</Button>
				</>}
				{audienceSizeLoading && <RandomWidthSkeleton />}
			</Typography>
		</div>
	);
};

export default PresentationTitleContainer;