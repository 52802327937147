import { BoxProps, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { getFontStyleName } from 'Hooks/usePresentationStyles';
import { PresentationStyleType } from 'Types/presentationTypes';
import React from 'react';


interface PresentationStyleContextComponentProps extends Omit<BoxProps, "style"> {
    style: PresentationStyleType;
    children: React.ReactNode;
}

export const PresentationStyleContext = React.createContext<PresentationStyleType|null>(null);

export const PresentationStyleContextWrapper = ({ style, children, ...props }: PresentationStyleContextComponentProps) => {

    const theme = useTheme();

    const customFont = getFontStyleName(style) || (theme.typography.body1.fontFamily as string);

    return (
        <Box {...props} sx={{
            '& *': {
                fontFamily: `${customFont}`,
            },
            height: 'inherit',
        }}>
            <PresentationStyleContext.Provider value={style}>
                {children}
            </PresentationStyleContext.Provider>
        </Box>
    )
}