import { useIdentification } from 'Hooks/useIdentification';
import React from 'react';
import { testIds } from 'Scripts/cypressTestIds';
import { QuestionAnswerProperties, QuestionTypes } from 'Types/identificationQuestionTypes';
import QuestionAnswer from '../../../shared/answer/QuestionAnswer';

interface IdentificationAnswerProps {
    questionIndex: number;
    questionType: QuestionTypes;
    answerIndex: number;
    answer: QuestionAnswerProperties;
}

const IdentificationAnswer = ({
    questionIndex,
    questionType,
    answerIndex,
    answer,
}: IdentificationAnswerProps) => {

    const {
        questions,
        questionDeleting,
        updateIdentificationQuestionAnswer,
        removeIdentificationQuestionAnswer,
    } = useIdentification();

    const answersLength = (questions?.[questionIndex]?.answers || []).length;

    return (
        <QuestionAnswer
            questionIndex={questionIndex}
            answerIndex={answerIndex}
            value={answer.allowedValues}
            type={questionType}
            totalAnswersCount={answersLength}
            update={updateIdentificationQuestionAnswer}
            remove={removeIdentificationQuestionAnswer}
            testIdPrefix={testIds.IDENTIFICATION_QUESTION_PREFIX}
            dataPrefix="identification"
            disabled={questionDeleting}
    />
    );
};

export default IdentificationAnswer;