import { IdentificationQuestionProperties } from "Types/identificationQuestionTypes";

export const setQuestions = (questions: Array<IdentificationQuestionProperties>) => ({
    type: 'SET_QUESTIONS',
    questions
});

export const updateQuestion = (questionIndex: number, questionProps: {}) => ({
    type: 'UPDATE_QUESTION',
    questionIndex,
    questionProps,
})

export const removeQuestionAnswer = (questionIndex: number, answerIndex: number) => ({
    type: 'REMOVE_QUESTION_ANSWER',
    questionIndex,
    answerIndex
})

export const addQuestion = () => ({
    type: 'ADD_QUESTION',
})

export const setQuestionDeleting = (isDeleting: boolean) => ({
    type: 'SET_QUESTION_DELETING',
    isDeleting,
})

export const removeQuestion = (questionIndex: number) => ({
    type: 'REMOVE_QUESTION',
    questionIndex,
})

export const addQuestionAnswer = (questionIndex: number, questionId: number) => ({
    type: 'ADD_QUESTION_ANSWER',
    questionIndex,
    questionId,
})

export const updateQuestionAnswer = (questionIndex: number, answerIndex: number, answerValue: any, answerId: number) => ({
    type: 'UPDATE_QUESTION_ANSWER',
    questionIndex,
    answerIndex,
    answerValue,
    answerId,
})