import { Typography } from '@mui/material';
import OnOffSwitch from 'Components/beta/common/buttons/switch/OnOffSwitch';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import React from 'react';
import { classes } from './style.css';

const AdditionalSlideSettings = () => {

	const {
		currentSlide,
		currentSlideKey
	} = useSlides();

	const { translatePlaceholder }  = useTranslations();

	const {
		setSlideSetting,
	} = useSlideActions();

	const toggleLimitToSlide = () => {

		setSlideSetting('limitToSlide', Number(!Boolean(currentSlide.limitToSlide)), currentSlideKey);
	}

	return (
		<div className={classes.limitToSlideContainer}>
			<div>
				<OnOffSwitch
					checked={!Boolean(currentSlide.limitToSlide)}
					onChange={() => toggleLimitToSlide()}
					{...renderingGAAttr(`${DataElAction.AllowQuestionsBeforehand}_${!Boolean(currentSlide?.limitToSlide)}`, DataElPosition.BellowSlide, DataElScreen.Editor, DataElType.Toggle)}
				/>
			</div>
			<div className={classes.limitToSlideTextContainer}>
				<Typography variant="h3">
					{translatePlaceholder("LIMIT_TO_SLIDE_TEXT")}
				</Typography>
				<div className={classes.moreInfoContainer}>
					<Typography variant="subtitle2" >
						{translatePlaceholder("LIMIT_TO_SLIDE_EXPLANATION")}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default AdditionalSlideSettings;