import { getFromCookies } from "Scripts/cookieHelper";
import { WEBSITE_PROMO_GET_PARAM_KEY } from 'Scripts/globals';
import { useTranslations } from "./useTranslations";


/**
 * @TODO Bryan Refactor this unit so we can use enums (see useSessionRoutes)
 */
const editPresentation = (presentationId: number) => {
    return '/editor/' + presentationId;
}

const startPresentation = (presentationId: number) => {
    return `/play/${presentationId}/intro`;
}

const presentationSummary = (presentationId: number)  => {
    return '/summary/' + presentationId;
}

const editSlide = (presentationId: number, slideId?: number | null ) => {
    return '/editor/' + presentationId + (Boolean(slideId) ? '/create/' + slideId : '');
}

const startPresentationSummary = (presentationId: number) => {
    return `/editor/${presentationId}/start`;
}

const websitePricingPage = (translatePlaceholder: (translationFileKey: string, vars?: Record<string, number | String>) => string) => {
    return new URL(translatePlaceholder('PRICINGPAGE_URL'), process.env.WEBSITE_URL).toString();
}

const websiteSignupPage = (translatePlaceholder: (translationFileKey: string, vars?: Record<string, number | String>) => string) => {
    return new URL(translatePlaceholder('SIGNUP_URL'), process.env.WEBSITE_URL).toString();
}

const websiteLegalPage = () => {
    return new URL('/en/legal/', process.env.WEBSITE_URL).toString();
}

const websiteCheckoutPage = (plan: string) => {

    const urlParams = new URLSearchParams();

    urlParams.set('plan', plan);

    const discountCodeValue = getFromCookies(WEBSITE_PROMO_GET_PARAM_KEY);

    if(Boolean(discountCodeValue)) {

        urlParams.set(WEBSITE_PROMO_GET_PARAM_KEY, discountCodeValue);
    }

    return new URL(`/en/pricing/checkout/?${urlParams}`, process.env.WEBSITE_URL).toString();
}

const websiteContactPage = (plan: string) => {
    return new URL(`/en/contact/plan/?plan=${plan}`, process.env.WEBSITE_URL).toString();
}

const presentationResults = (presentationId: number) => {

    return `/presentations/${presentationId}`;
}

const sessionResults = (sessionRunId: number) => {

    return `/sessions/${sessionRunId}`;
}

const invoiceDetails = (invoiceId: number) => {

    return `/license/billing/${invoiceId}/receipt`;
}

const previewPresentation = (presentationId: number) => {

    return `/preview/${presentationId}/intro`;
}

const useUrl = () => {
    const { translatePlaceholder } = useTranslations();
    return {
        fourOFour: '/404',
        authSuccess: '/auth/success',
        presentationOverview: '/',
        settings: '/settings',
        startSession: '/play',
        presentationEditor: '/editor',
        welcomePage: '/welcome',
        licenseEligiblePage: '/license-eligible',
        requestedAccess: '/requested-access',
        multipleLicenseEligiblePage: '/multi-license',
        licenseRequestFailedPage: '/request-access-failed',
        playPodiumSlide: '/play/end/podium',
        pricingPage: '/pricing',
        team: '/team',
        claim: '/claim',
        sessions: '/sessions',
        teamUserInfo: '/team/users',
        teamManageUsers: '/team/manage',
        teamJoinRequests: '/team/join-requests',
        presentations: '/presentations',
        license: '/license',
        licenseSubscription: '/license/subscription',
        licenseBilling: '/license/billing',
        licenseAccount: '/license/account',
        licenseCancel: '/license/cancel',
        results: '/results',
        survey: '/survey',
        paymentSuccess: '/payment/success',
        paymentPending: '/payment/pending',
        paymentFailed: '/payment/failed',
        upgrade: '/upgrade',
        licenseUpgrade: '/licenseupgrade',
        downloadAddin: '/download/add-in',
        inspirationTemplates: '/inspiration-templates',
        aiCreate: '/ai/create',
        aiCreateFromDocument: '/ai/create-from-document',
        analyticsDashboard: '/dashboard/analytics',
        recurringPresentations: '/recurring-presentations',
        recurringPresentationsOverview: '/recurring-presentations/overview',
        createRecurringPresentations: '/recurring-presentations/create',
        dataConnectionsOverview: '/recurring-presentations/data-connections',
        dataConnectionAccounts: '/recurring-presentations/data-connection-accounts',
        themeBuilder: '/theme-builder',
        presentationStepper: '/ai/create/presentation',
        quizStepper: '/ai/create/quiz',
        outline: '/ai/create/outline',
        startPresentationSummary,
        editPresentation,
        startPresentation,
        presentationSummary,
        editSlide,
        websiteCheckoutPage,
        websiteContactPage,
        websitePricingPage: websitePricingPage(translatePlaceholder),
        websiteSignupPage: websiteSignupPage(translatePlaceholder),
        websiteLegalPage,
        presentationResults,
        invoiceDetails,
        sessionResults,
        previewPresentation,
    } as const

}

export default useUrl;