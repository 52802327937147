import { Box, CircularProgress, Grid } from '@mui/material';
import { BoxProps } from '@mui/system';
import AppSvgIcon, { AppSvgIconType } from 'Atomic/atoms/Svg/AppSvgIcon';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import React from 'react';
import { classes } from './style.css';


export enum AppSlideSelectionWrapperSide {
    Left,
    Right
}

interface Props extends BoxProps {
    children: React.ReactNode;
    index: number | null;
    selected?: boolean;
    loading?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    side?: AppSlideSelectionWrapperSide;
}

const AppSlideSelectionWrapper = ({
    index = null,
    selected,
    children,
    loading,
    disabled,
    onClick,
    side = AppSlideSelectionWrapperSide.Left,
    ...props
}: Props) => {


    const handleClicked = (e: any) => {

        if(loading || disabled) {

            return;
        }

        onClick && onClick();
    }

    return (
        <Grid
            item
            pb={2}>
            <Box
                {...props}
                onClick={handleClicked}
                className={classes.selectionItem}
                sx={{
                    opacity: disabled ? .7 : 1,
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    backgroundColor: (selected && !loading) ? '#E5F5F0' : 'transparent',
                    border: `2px solid ${(selected && !loading) ? '#2DAD83' : 'transparent'}`,
                    color: selected ? '#2DAD83' : 'inherit',
                }}>
                {side === AppSlideSelectionWrapperSide.Right && (
                    <div>
                        {children}
                    </div>
                )}
                <Box className={classes.selectionIndicator} pt={.5}>
                    <AppText
                        noWrap={false}
                        as={AppTextVariant.CaptionBold}>
                        {loading && (
                            <CircularProgress
                                size={12}
                                thickness={4}
                            />
                        )}
                        {!loading && (
                            <>
                                {!selected && (
                                    <>
                                        {(index !== null && index) || ``}
                                    </>
                                )}
                                {selected && <AppSvgIcon type={AppSvgIconType.CheckSmall} />}
                            </>
                        )}
                    </AppText>
                </Box>
                {side === AppSlideSelectionWrapperSide.Left && (
                    <div>
                        {children}
                    </div>
                )}
            </Box>
        </Grid>
    )
}

export default AppSlideSelectionWrapper;