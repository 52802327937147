import { Grid } from '@mui/material';
import FileUploadOption from 'Components/beta/editor/content/details/form/file/FileUploadOption';
import { useHover } from 'Hooks/useHover';
import { testIds } from "Scripts/cypressTestIds";
import React from 'react';
import { LayoutWrapperProps } from '../../LayoutWrapper';
import { classes } from './style.css';

const LayoutImageOnBackground = ({
    title,
    content,
    imageURL,
    imageStyle,
    interactive,
    backgroundOpacity,
}: LayoutWrapperProps) => {


    const { hoverRef, isHovering } = useHover();

    return (
        <>
            <Grid
                ref={hoverRef}
                data-testid={testIds.LAYOUT_IMAGE_ON_BACKGROUND}
                className={`${classes.imageProperties} ${classes.image}`}
                sx={{
                    backgroundSize: imageStyle,
                    opacity: backgroundOpacity,
                    backgroundImage: `url(${imageURL})`,
                }}>
            </Grid>
            <Grid
                className={classes.layoutBox}
                container
                item
                flexDirection="column"
                width="100%"
                xs>
                <Grid
                    item
                    width="100%">
                    {title}
                </Grid>
                <Grid
                    xs
                    item
                    display="flex"
                    flexDirection="column"
                    overflow="auto"
                    width="100%">
                    {content}
                </Grid>
                <Grid
                    className={classes.imageSelectBox}
                    item>
                    {interactive && (
                        <FileUploadOption
                            show={Boolean(imageURL) && isHovering}
                            center
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default LayoutImageOnBackground;