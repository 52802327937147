import { Box } from '@mui/material';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import Html from 'Components/common/util/html/Html';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { PlaySlideType } from 'Types/playTypes';
import React from 'react';
import { classes } from './style.css';


const TitleAndSubtitleSlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {


    const slideProperties = getPlaySlideProperties(slide);

    return (
        <>
            <LayoutWrapper
                layoutType={slide.slideLayout}
                videoURL={slide.videoURL}
                imageURL={slide?.file?.base64 || slide?.filename}
                imageStyle={slide.filenameStyle}
                backgroundOpacity={slide.backgroundOpacity}
                backgroundColor={slide.backgroundColor}
                content={
                    <Box height="100%" px={12}>
                        <div className={classes.container} >
                            <DelayedComponent
                                style={{ width: '100%' }}
                                showAfter={250}
                                animationDuration={250}
                                animationType={AnimationTypes.Grow}>
                                <Html
                                    content={(slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)) as string}
                                    fontSize={slideProperties.slideSettings.titleFontSize}
                                />
                            </DelayedComponent>
                            <Box pb={4} />
                            <DelayedComponent
                                style={{ width: '100%' }}
                                showAfter={500}
                                animationDuration={250}
                                animationType={AnimationTypes.Grow}>
                                <Html
                                    content={(slide.contentHtml || slide.content || slideProperties.text?.getHtmlContentPlaceholder?.(slideProperties.text.contentPlaceholder)) as string}
                                    fontSize={slideProperties.slideSettings?.contentFontSize as number}
                                />
                            </DelayedComponent>
                        </div>
                    </Box>
                }
            />
        </>
    );
};

export default TitleAndSubtitleSlideSession;