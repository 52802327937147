import { ScreenSizes } from 'Types/appTypes';
import { SlideInputFontSize, SlideShape, SlideTypes, fixedSlideShapes, fullSizeSlideShapes, staticSlideShapes } from 'Types/slideTypes';
import { CSSProperties } from 'react';

/**
 * Checks if there are image/videos shapes positioned on top/bottom of the container.
 * Full size images/videos are ignored since they are placed on the background.
 *
 * Depending on the height/y position of the image, a relevant y/height of the remaining area
 * is returned.
 */

enum ShapeSizeConstants {
    MinTopSpacing = 56,
    ShapeSpacing = 32,
}

const minShapeHeight = SlideInputFontSize.Small;

// Give me the y position and the height of the container we can use for the shapes
export const getVerticalContainerDimensions = (shapes: SlideShape[]) : { height: number, y: number } => {

    let containerHeight = ScreenSizes.Height1080P;
    let containerYStart = 0;

    /**
     * Determine container height
     */
    shapes.forEach(shape => {

        // Fixed shapes will never be re-positioned, they are always on the same x,y position
        if(fixedSlideShapes.includes(shape.type)) {

            return;
        }

        // We are ONLY interested in image / video shapes
        if (!staticSlideShapes.includes(shape.type)) {

            return;
        }

        // We have an images or video shape
        if (shape.width === ScreenSizes.Width1080P) {
            /** full sized shapes */
            if (shape.height === ScreenSizes.Height1080P) {

                return;
            }

            // We have a image/video with the full width and not the full height

            /** Shape on top */
            if (shape.y === 0) {

                containerYStart = shape.height;
                containerHeight = ScreenSizes.Height1080P - shape.height;

                return;
            }

            /** Shape on bottom */
            if ((shape.y + shape.height) === ScreenSizes.Height1080P) {

                containerHeight = ScreenSizes.Height1080P - shape.height;
            }
        }
    })

    return {
        height: containerHeight,
        y: containerYStart,
    }
}

/**
 * Align shapes vertically from the center of the container.
 *
 * First the container height is determined (based on shapes that are full width and either aligned to top or bottom of the container)
 * Then center the shapes in the remaining space
 */
const calculateVerticalCenterAlignedShapeDimensions = (shapes: SlideShape[]) => {

    const shapesToPosition = shapes.filter(shape => (
        !staticSlideShapes.includes(shape.type) &&
        !fixedSlideShapes.includes(shape.type)
    ));

    const staticShapes = shapes.filter(shape => staticSlideShapes.includes(shape.type));

    const fixedShapes = shapes.filter(shape => fixedSlideShapes.includes(shape.type));

    const sortedByY = [...shapesToPosition].sort((shapeA, shapeB) => shapeA.y - shapeB.y);

    const totalShapesHeight = sortedByY.reduce((totalHeight, shape) => {

        return totalHeight + shape.height;

    }, 0);

    const { height, y, } = getVerticalContainerDimensions(shapes)

    let yOffset = Number(y) + (Number(height) - totalShapesHeight) / 2;

    /* Set positions for each shape while capping y to not exceed the container height */
    sortedByY.forEach((shape, index) => {

        shape.y = (index * ShapeSizeConstants.ShapeSpacing) + Math.max(0, Math.min(yOffset, Number(height) - shape.height)) >> 0;

        yOffset += shape.height;
    })

    return [
        ...fixedShapes,
        ...staticShapes,
        ...sortedByY,
    ];
}

/**
 * Align shapes vertically from the top of the container.
 *
 * First the container height is determined (based on shapes that are full width and either aligned to top or bottom of the container)
 * Then order the shapes in the remaining space from top to bottom
 */
const calculateVerticalTopAlignedShapeDimensions = (shapes: SlideShape[]) => {

    const shapesToPosition = shapes.filter(shape => (
        !staticSlideShapes.includes(shape.type) &&
        !fixedSlideShapes.includes(shape.type)
    ));

    const staticShapes = shapes.filter(shape => staticSlideShapes.includes(shape.type));

    const fixedShapes = shapes.filter(shape => fixedSlideShapes.includes(shape.type));

    const sortedByY = [...shapesToPosition].sort((shapeA, shapeB) => shapeA.y - shapeB.y);

    let { height, y, } = getVerticalContainerDimensions(shapes);

    /** Some spacing so the top shape isn't squished to the top of the container */
    let yOffset = Number(y) + ShapeSizeConstants.MinTopSpacing;

    /* Set positions for each shape while capping y to not exceed the container height */
    sortedByY.forEach((shape, index) => {

        if(fullSizeSlideShapes.includes(shape.type)) {

            shape.height = Math.max(minShapeHeight, Number(height - (ShapeSizeConstants.MinTopSpacing * 3)));
        }

        height -= shape.height;

        shape.y = yOffset + (ShapeSizeConstants.ShapeSpacing * index);

        yOffset += shape.height;
    })

    return [
        ...fixedShapes,
        ...staticShapes,
        ...sortedByY,
    ];
}

export enum ShapeVerticalAlignments {
    Top = 'top',
    Center = 'center',
}

/**
 * Currently some slides have their content vertically aligned to the top.
 * In those cases we have to apply different alignment strategies for its shapes,
 * so those slide types are defined in this object
 *
 * The fallback alignment for all the other slide types is centered
 */
const nonCenteredShapeTypeVerticalAlignments = {
    [SlideTypes.Table]:             ShapeVerticalAlignments.Top,
    [SlideTypes.Content]:           ShapeVerticalAlignments.Top,
    [SlideTypes.BarGraph]:          ShapeVerticalAlignments.Top,
    [SlideTypes.LineGraph]:         ShapeVerticalAlignments.Top,
    [SlideTypes.Quiz]:              ShapeVerticalAlignments.Top,
    [SlideTypes.MultipleChoice]:    ShapeVerticalAlignments.Top,
    [SlideTypes.Points]:            ShapeVerticalAlignments.Top,
    [SlideTypes.Wordcloud]:         ShapeVerticalAlignments.Top,
    [SlideTypes.OpenEnded]:         ShapeVerticalAlignments.Top,
    [SlideTypes.QA]:                ShapeVerticalAlignments.Top,
} as Record<SlideTypes, ShapeVerticalAlignments>;

export const calculateShapeHorizontalPositions = (slideType: SlideTypes, shapes: SlideShape[]) => {

    const shapeVerticalAlignment = (
        nonCenteredShapeTypeVerticalAlignments[slideType] ||
        ShapeVerticalAlignments.Center
    ) as ShapeVerticalAlignments;

    const alignmentHandles = {
        [ShapeVerticalAlignments.Top]: calculateVerticalTopAlignedShapeDimensions,
        [ShapeVerticalAlignments.Center]: calculateVerticalCenterAlignedShapeDimensions,
    };

    return alignmentHandles[shapeVerticalAlignment](shapes);
}

/**
 * Returns a CSSProperties object from a shapes.jss entry
 *
 * @return {CSSProperties}
 */
export const getParsedJSS = (jss: string | Object) => {

    let parsedJSS: CSSProperties = {};

    try {

        if (typeof jss === "object") {

            parsedJSS = jss;
        }

        if (typeof jss === "string") {

            parsedJSS = JSON.parse(jss);
        }

    } catch (error) {

        console.warn(error);

        parsedJSS = {};
    }

    return parsedJSS;
}

