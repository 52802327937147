import { UserBrandingData, UserInfoProps, UserProps, UserSessionInfo } from "Types/appTypes";

export const setIsOnline = (isOnline: boolean) => ({
    type: 'SET_IS_ONLINE',
    isOnline,
})

export const toggleTheme = () => ({
    type: 'TOGGLE_THEME'
})

export const signOut = () => ({
    type: 'SIGN_OUT'
})

export const toggleMenu = () => ({
    type: 'TOGGLE_MENU'
})

export const toggleSelectSlideOpened = (isOpened: boolean) => ({
    type: 'TOGGLE_SELECT_SLIDE_OPENED',
    isOpened
})

export const setGeneralSettingsOpen = (open: boolean) => ({
    type: 'SET_GENERAL_SETTINGS_OPEN',
    open
})

export const toggleRating = (show: boolean) => ({
    type: 'TOGGLE_RATING',
    show,
})

export const toggleLoginScreen = (isOpen: boolean) => ({
    type: 'TOGGLE_LOGIN_SCREEN',
    isOpen,
})

export const toggleLogin = () => ({
    type: 'TOGGLE_LOGIN'
})

export const toggleSave = () => ({
    type: 'TOGGLE_SAVE'
})

export const saveUserData = (data: UserProps) => ({
    type: 'SAVE_USER_DATA',
    data
})

export const updateUser = (updatedUser: UserInfoProps) => ({
    type: 'UPDATE_USER',
    updatedUser,
})

export const updateUserSetting = (key: string, value: any) => ({
    type: 'UPDATE_USER_SETTING',
    key,
    value,
})

export const setModeratorSharingToken = (token: string|null) => ({
    type: 'SET_MODERATOR_SHARING_TOKEN',
    token
})

export const setHeaderHeight = (height: number) => ({
    type: 'SET_HEADER_HEIGHT',
    height
})

export const setToolbarHeight = (height: number) => ({
    type: 'SET_TOOLBAR_HEIGHT',
    height
})

export const setAuthLoading = (isLoading: boolean) => ({
    type: 'SET_AUTH_LOADING',
    isLoading
})

export const setSocketConnection = (socket: WebSocket) => ({
    type: 'SET_SOCKET_CONNECTION',
    socket
})

export const clearAppState = () => ({
    type: 'CLEAR_APP_STATE',
})

export const setLoginCallback = (callback: null | (() => void)) => ({
    type: 'SET_LOGIN_CALLBACK',
    callback
})

export const setLoginLoading = (isLoading: boolean) => ({
    type: 'SET_LOGIN_LOADING',
    isLoading,
})

export const setUnsavedChangesOpen = (isOpen: boolean) => ({
    type: 'SET_UNSAVED_CHANGES_OPEN',
    isOpen
})

export const setUserBrandingLoading = (isLoading: boolean) => ({
    type: 'SET_BRANDING_LOADING',
    isLoading,
})

export const setUserBrandingData = (data: UserBrandingData) => ({
    type: 'SET_USER_BRANDING_DATA',
    data
})

export const setUserSessionInfo = (info: UserSessionInfo) => ({
    type: 'SET_USER_SESSION_INFO',
    info
})

export const storeHashFragment = (hash: string) => ({
    type: 'SET_HASH_FRAGMENT',
    hash
})

export const setPresentationIdToShare = (id: number | null) => ({
    type: 'SET_PRESENTATION_ID_TO_SHARE',
    id,
})

export const setFeedbackDialogOpen = (open: boolean) => ({
    type: 'SET_FEEDBACK_DIALOG_OPEN',
    open,
})