import { AnyAction } from 'redux';
import { NewsFeedState } from 'Types/newsFeedTypes';

export const NEWS_FEED_PAGE_SIZE = 4;

export const initialNewsFeedState: NewsFeedState = {
    newsFeedPageData: {
        pageSize: NEWS_FEED_PAGE_SIZE,
        pageIndex: 0,
        totalResultsCount: 0,
        filteredResultsCount: 0,
        pageResultsCount: 0,
        pageData: [],
        pageLoading: false,
        sortProperty: {
            id: 'createdAt',
            desc: true,
        }
    }
}

export const newsFeedReducer = (state : NewsFeedState = initialNewsFeedState, action: AnyAction) => {
    switch (action.type) {
        case 'SET_NEWS_FEED_PAGE_LOADING': {
            return {
                ...state,
                newsFeedPageData: {
                    ...state.newsFeedPageData,
                    pageLoading: action.isLoading,
                }
            }
        }
        case 'SET_NEWS_FEED_PAGE_SIZE': {
            return {
                ...state,
                newsFeedPageData: {
                    ...state.newsFeedPageData,
                    pageSize: action.pageSize,
                }
            }
        }
        case 'SET_NEWS_FEED_PAGE_INDEX': {
            return {
                ...state,
                newsFeedPageData: {
                    ...state.newsFeedPageData,
                    pageIndex: action.pageIndex,
                }
            }
        }
        case 'SET_NEWS_FEED_TABLE_DATA': {

            const newNewsFeedPageData = {
                ...state.newsFeedPageData,
                ...action.data,
            }

            if(action.shouldAppend) {

                newNewsFeedPageData.pageData = [
                    ...state.newsFeedPageData.pageData,
                    ...action.data.pageData,
                ];
            }

            return {
                ...state,
                newsFeedPageData: newNewsFeedPageData
            }
        }
        default:
            return state
    }
}
