import { setUserSessionInfo } from 'Actions/appActions';
import { post, put } from 'Scripts/api';
import { AppState } from 'Types/appTypes';
import { Participant, PlayState } from '__types/playTypes';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useAppRouting } from './useAppRouting';

export const useSession = () => {

	const {
		currentSlideKey
	} = useAppRouting();

	const dispatch = useDispatch();

	const [updateTextingLoading, setUpdateTextingLoading] = useState<boolean>(false);

	const [updateParticipantEmojisLoading, setUpdateParticipantEmojisLoading ] = useState<boolean>(false);

	const [ updateAnonymousLoading, setUpdateAnonymousLoading ] = useState<boolean>(false);

	const [ toggleSurveyLoading, setToggleSurveyLoading ] = useState<boolean>(false);

	const userSessionInfo = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).userSessionInfo);

	const currentSessionId = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentSessionId);

	const joinedParticipants = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).joinedParticipants);

	const votes = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).votes);

	const playSlides = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).playSlides);

	const [initialParticipantsIds, setInitialParticipantIds] = useState(new Set<number>([]));
	/**
	 * If users have their devices locked, or the screen is turned off,
	 * the socket connection will be interrupted and the joinedParticipants.length will decrease.
	 *
	 * We don't want to decrease the displayed participant count during a session, since this might
	 * confuse presenters and will appear as if users are 'leaving'.
	 *
	 * So, we only increment the displayed participant count during a slide instead of decrementing it.
	 * Whenever the component that is using this hook is rerendered, the initial participant count will be fetched again
	 */
	const [displayedParticipantIds, setDisplayedParticipantIds] = useState(new Set<number>([]));

	// contains quiz slides

	useEffect(() => {

		const initialIds = new Set<number>([
			...joinedParticipants.map((participant: Participant) => participant.participantId),
		])

		setInitialParticipantIds(initialIds);

	}, [currentSlideKey])

	useEffect(() => {

		const participantIdsToDisplay = new Set<number>([
			...Array.from(initialParticipantsIds),
			...Array.from(displayedParticipantIds),
			...joinedParticipants.map((participant: Participant) => participant.participantId),
		]);

		setDisplayedParticipantIds(participantIdsToDisplay);

	}, [joinedParticipants])

	const displayedParticipantCount = useMemo(() => {

		return displayedParticipantIds.size;

	}, [displayedParticipantIds])

	/**
	 * Calculates if all votes are in based on displayedParticipantCount
	 * and slide config (nrOfVotes, nrOfVotesArePerAnswer)
	 */
	const allVotesAreIn = useMemo(() => {

		const currentSlide = playSlides[currentSlideKey];

		const currentSlideVotes = votes[currentSlideKey];

		if (!isEmpty(currentSlideVotes) && displayedParticipantCount > 0) {

			let totalAllowedVotes = currentSlide.nrOfVotes * displayedParticipantCount;

			if (!!currentSlide.nrOfVotesArePerAnswer) {

				totalAllowedVotes *= Object.keys(currentSlide.options).length
			}

			const totalResponses = Object.keys(currentSlideVotes).length

			if (totalAllowedVotes === totalResponses) {

				return true;
			}
		}

		return false;

	}, [initialParticipantsIds.size, Object.keys(votes[currentSlideKey] || {}).length, currentSlideKey])

	const updateTexting = async (isEnabled: boolean) => {

		setUpdateTextingLoading(true);

		const response = await updateSession('textMessagingSelected', Number(isEnabled));

		setUpdateTextingLoading(false);

		if(!response.error) {

			dispatch(setUserSessionInfo({
				...userSessionInfo,
				smsEnabled: isEnabled
			}))
		}
	}

	const updateSession = async (sessionKey: string, sessionValue: boolean|string|number) => {

		return post('sessions/update', {
			[sessionKey]: sessionValue
		});
	}

	const updateAnonymousSources = async (isAnonymous: boolean) => {

		setUpdateAnonymousLoading(true);

		const response = await put('sessions/anonymous-sources', {
			anonymousSources: isAnonymous
		});

		if(response.error) {

			console.warn(response.error);

			return;
		}

		setUpdateAnonymousLoading(false);

		dispatch(setUserSessionInfo({
			...userSessionInfo,
			isAnonymous,
		}))
	}

	const toggleSurvey = async (isEnabled: boolean) => {

		setToggleSurveyLoading(true);

		const response = await post('sessions/toggle-survey', {
			status: Number(isEnabled)
		});

		if(response.error) {

			console.warn(response.error);

			return;
		}

		setToggleSurveyLoading(false);

		dispatch(setUserSessionInfo({
			...userSessionInfo,
			survey: isEnabled,
		}))
	}

	const toggleAutoApprove = async (isEnabled: boolean) => {

		dispatch(setUserSessionInfo({
			...userSessionInfo,
			autoApprove: isEnabled,
		}))

		await put(`sessions/${currentSessionId}/auto_approve`, {
            autoApprove: Number(isEnabled)
		});
	}

	const updateParticipantEmojis = async (isEnabled: boolean) => {

		try {

			setUpdateParticipantEmojisLoading(true);

			await post(`sessions/toggle-emoji`, {
				allowEmojis: Number(isEnabled)
			});

			dispatch(setUserSessionInfo({
				...userSessionInfo,
				allowParticipantEmojis: isEnabled,
			}))

		} catch (error) {

			console.warn(error)

		} finally {

			setUpdateParticipantEmojisLoading(false);
		}
	}

	return {
		allVotesAreIn,
		displayedParticipantCount,
		userSessionInfo,
		updateTexting,
		updateTextingLoading,
		updateAnonymousSources,
		updateAnonymousLoading,
		toggleSurvey,
		toggleSurveyLoading,
		toggleAutoApprove,
		currentSessionId,
		updateParticipantEmojis,
		updateParticipantEmojisLoading
	}
}