import Clear from '@mui/icons-material/Clear';
import { Box, Grid, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DiamondImage from 'Atomic/atoms/DiamondImage';
import KeepWorkingOnPresentationButton from 'Atomic/molecules/KeepWorkingOnPresentationButton/KeepWorkingOnPresentationButton';
import UpgradeButton from 'Components/beta/common/buttons/upgrade/UpgradeButton';
import { useAppRouting } from 'Hooks/useAppRouting';
import { useFeatures } from 'Hooks/useFeatures';
import { useLayout } from 'Hooks/useLayout';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { LocalStorageKeys } from 'Scripts/globals';
import { addToLocalStorage, getFromLocalStorage } from 'Scripts/localStorage';
import { getSortedSlideKeysBySlideIndex } from 'Scripts/slideHelper';
import { LayoutState } from 'Types/layoutTypes';
import { PresentationState } from 'Types/presentationTypes';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';


const MissingMultipleFeaturesDialog = () => {

    const shownMissingFeatures = useSelector((state: RootStateOrAny) => (state.layoutReducer as LayoutState).shownMissingFeatures);

    const { presentationId } = useAppRouting();

	const planInfo = useSelector((state: RootStateOrAny) => state.featureReducer.planInfo)

	const availableFeatures = useSelector((state: RootStateOrAny) => state.featureReducer.availableFeatures)

	const currentPresentationIsTemplate = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).currentPresentationIsTemplate)

	const slides = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slides);

	const slideKeys: number[] = getSortedSlideKeysBySlideIndex(slides);

    const { setShowMissingPresentationFeatures } = useLayout();

    const { getFeatureFriendlyName, getMissingFeatures } = useFeatures();

    const { translatePlaceholder } = useTranslations();

    const closeDialog = () => {

        setShowMissingPresentationFeatures(null)
    }

	useEffect(() => {

		const limitPopupLocalStorage = Number(getFromLocalStorage(LocalStorageKeys.LimitPopup)) === presentationId;

		if (!currentPresentationIsTemplate && !limitPopupLocalStorage && Boolean(Object.keys(slides).length) && Boolean(planInfo)) {

			const missingPresentationFeatures = getMissingFeatures(slides);

			if (Boolean(missingPresentationFeatures.length) && Boolean(availableFeatures)) {

				setShowMissingPresentationFeatures(missingPresentationFeatures);

				addToLocalStorage(LocalStorageKeys.LimitPopup, presentationId);
			}
		}

	}, [
		slideKeys.length,
		planInfo,
		availableFeatures,
		currentPresentationIsTemplate
	]);

    const showDialog = useMemo(() => {

        return !isEmpty(shownMissingFeatures);

    }, [
        shownMissingFeatures?.length
    ])

    const isInEditor = (window.location.pathname.includes('editor'));

    return (
        <Dialog
            data-testid={testIds.MISSING_MULTIPLE_FEATURE_DIALOG}
            onClose={() => closeDialog()}
            open={showDialog}
            className={classes.rootDialog}
            PaperProps={{
                className: classes.dialog
            }}>
            <DialogContent>
                <Box p={4}>
                    <IconButton
                        data-testid={testIds.MISSING_MULTIPLE_FEATURES_DIALOG_CLOSE}
                        onClick={() => closeDialog()}
                        className={classes.closeIcon}>
                        <Clear />
                    </IconButton>
                    <DiamondImage />
                    <Typography variant="h2" fontWeight={600} mt={2}>
                        {translatePlaceholder("PRESENTATION_CANNOT_START")}
                    </Typography>
                    <Typography my={3} variant="h3">
                        {translatePlaceholder("PRESENTATION_REQUIRES_MISSING_FEATURES")}
                    </Typography>
                    <Typography mb={3} variant="h3">
                        {translatePlaceholder("FEATURES_TO_START_PRESENTATION")}
                    </Typography>
                    {!isEmpty(shownMissingFeatures) && (shownMissingFeatures as Array<string>).map((feature: string) => (
                        <Typography key={feature} variant="h3" fontWeight={600} mb={2}>
                            💎 {getFeatureFriendlyName(feature)}
                        </Typography>
                    ))}
                    <Box mb={3} />
                    <Grid p={2} container spacing={2} display="flex" justifyContent="space-between">
                        {isInEditor && (
                            <KeepWorkingOnPresentationButton />
                        )}
                        <UpgradeButton
                            trackScreen="missing_features_dialog"
                        />
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default MissingMultipleFeaturesDialog;