import { SlideShapeType } from "Types/slideTypes";

export const getTransition = (type: SlideShapeType) => {
    if(window.location.pathname.includes("editor")) return "none";
    
    switch (type) {
        case SlideShapeType.MpcOptions:
        case SlideShapeType.Title:
        case SlideShapeType.Content: {
            return `1500ms ease-in-out`
        }
        case SlideShapeType.Podium:
        case SlideShapeType.Leaderboard:
        case SlideShapeType.Video:
        case SlideShapeType.Image: {
            return `2000ms ease-in-out`
        }
        default: {
            return "unset";
        }
    }
}