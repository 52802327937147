import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useTranslations } from 'Hooks/useTranslations';
import React from 'react';
import ScorePanel from '../../../play/templates/geometry_template/slides/leaderboard_slide/panels/ScorePanel';
import { classes } from './style.css';

const PreviewLeaderboardSlideShape = () => {

    const { presentationStyle } = usePresentationStyles();

    const { translatePlaceholder } = useTranslations();

    const placeholderScores = [
        {
            points: 1500,
            name: "Participant A",
            lastReceivedPoints: 500,
        },
        {
            points: 1400,
            name: "Participant B",
            lastReceivedPoints: 400,
        },
        {
            points: 850,
            name: "Participant C",
            lastReceivedPoints: 350,
        },
        {
            points: 650,
            name: "Participant D",
            lastReceivedPoints: 0,
        },
        {
            points: 500,
            name: "Participant E",
            lastReceivedPoints: 0,
        },
    ]

    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="stretch"
                justifyContent="space-evenly"
                className={classes.leaderboardContainer}
                sx={{
                    backgroundColor: presentationStyle?.normalColor,
                }}>
                <Grid
                    item
                    xs={6}
                    container
                    direction="row"
                    alignItems="stretch"
                    justifyContent="space-evenly">
                    <ScorePanel scores={placeholderScores} animate={false} />
                </Grid>
                <Grid
                    item
                    xs={6}
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="space-evenly" >
                    <Grid item>
                        <Typography className={classes.perkText} noWrap sx={{
                            color: presentationStyle?.textColor,
                        }}>
                            {translatePlaceholder('HIGHEST_STREAK')} 🔥
                        </Typography>
                        <Typography className={classes.nameText} noWrap sx={{
                            color: presentationStyle?.textColor,
                        }}>
                            {placeholderScores[0].name} 2x
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PreviewLeaderboardSlideShape;