import { CircularProgress, useTheme } from '@mui/material';
import React from 'react';
import { classes } from './style.css';

interface ButtonLoaderProps {
	dark?: boolean;
	inline?: boolean;
	flex?: boolean;
}

const ButtonLoader = ({ dark = false, inline = false, flex = false, }: ButtonLoaderProps) => {


	const theme = useTheme();

	return (
		<CircularProgress className={classes.loader} sx={{
			display: flex ? 'flex' : 'inherit',
			marginLeft: inline ? 0 : theme.spacing(2),
			marginRight: inline ? theme.spacing(1) : theme.spacing(2),
			color: dark ? theme.palette.common.black : theme.palette.common.white,
		}} />
	);
};

export default ButtonLoader;