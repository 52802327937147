import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/system';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import React, { useEffect } from 'react';

interface HtmlProps extends TypographyProps {
    content: string;
    fontSize?: number;
    className?: string;
}

const Html = React.forwardRef<HTMLSpanElement, HtmlProps>(({ content, fontSize, className, ...props }, ref) => {

    const {
        presentationStyle,
    } = usePresentationStyles();

    // We need to tell MatchJax to check the DOM again for Latex content
    useEffect(()=>{
        if(typeof window?.MathJax !== "undefined"){
          window.MathJax.typeset()
        }
      },[]);

	return (
        <Typography
            ref={ref}
            dir="auto"
            color={presentationStyle?.textColor}
            className={`html-txt-contents ${className || ``}`}
            dangerouslySetInnerHTML={{ __html: content }}
            {...props}
            sx={{
                fontSize: `${fontSize}pt`,
                wordBreak: 'break-word',
                '& p': {
                    minHeight: `${fontSize}pt`
                }
            }}
        />
	);
});

export default Html;
