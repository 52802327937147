import * as quizActions from 'Actions/quizActions';
import { TableCellData } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import { LocalStorageKeys } from 'Scripts/globals';
import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage } from "Scripts/localStorage";
import { AppState } from 'Types/appTypes';
import { SlideFile, SlideList, SlideType } from "Types/presentationTypes";
import { SlideAnswer } from 'Types/sharedSlideTypes';
import { SlideShape } from 'Types/slideTypes';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslations } from './useTranslations';

interface SlideAPIData {
	slides: Record<number, SlideType>;
	slideShapes: Record<number, SlideShape[]>;
	slideAnswers: Record<number, SlideAnswer[]>;
	slideTables: Record<number, Array<Array<TableCellData>>>;
}

const useOfflineSync = (presentationId: number) => {

	const isOnline = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).isOnline);

	const { enqueueSnackbar } = useSnackbar();

	const { translatePlaceholder } = useTranslations();

	const dispatch = useDispatch();

	const formatDataForLocalStorage = (data: SlideList) => {

		const dataToFormat = {
			slideList: data,
		}

		return btoa(unescape(encodeURIComponent(JSON.stringify(dataToFormat))));
	}

	const parseDataFromLocalStorage = (data: string): SlideList | false => {

		try {

			const { slideList } = JSON.parse(decodeURIComponent(escape(atob(data))));

			return slideList;

		} catch (error) {

			console.warn('Unable to parse localstorage data. Removing');

			removeFromLocalStorage(`${LocalStorageKeys.UnsavedPresentationData}:${presentationId}`);
		}

		return false;
	}

	const saveUnsavedDataToLocalstorage = (unSavedData: SlideList) => {

		const formattedDataForLocalstorage = formatDataForLocalStorage(unSavedData);

		addToLocalStorage(`${LocalStorageKeys.UnsavedPresentationData}:${presentationId}`, formattedDataForLocalstorage);
	}

	/**
	 * Attempts to apply data from local storage.
	 *
	 * - First check if there is a record in localstorage for the current presentationId
	 * - Parse the data and iterate over each slide passed from the API
	 * - If the parsed data contains a reference to a slideId, update the api slide with
	 * 	 the data from the localstorage slide (shapes, table, options and other props)
	 * - Update the current slides with the stored data
	 * - Re-sync the slides by adding the slideIds to unsyncedSlideids
	 */
	const applyDataFromLocalStorage = (dataFromApi: SlideAPIData) => {

		const { slides, slideShapes, slideAnswers, slideTables } = dataFromApi;

		const encodedLocalStorageSlideData = getFromLocalStorage(`${LocalStorageKeys.UnsavedPresentationData}:${presentationId}`);

		if(encodedLocalStorageSlideData) {

			try {

				const storedSlideData = parseDataFromLocalStorage(encodedLocalStorageSlideData as string);

				if(!storedSlideData) {

					return false;
				}

				const updatedSlides = {
					...slides,
				}

				for (const slideId in updatedSlides) {

					updatedSlides[slideId].shapes = (slideShapes[slideId] || []);

					updatedSlides[slideId].options = (slideAnswers?.[slideId] || []);

					updatedSlides[slideId].table = (slideTables?.[slideId] || []);

					const storedSlide = storedSlideData[slideId];

					if (!Boolean(storedSlide)) {

						continue;
					}

					const { shapes, options, table, ...otherProps } = storedSlide;

					updatedSlides[slideId] = {
						...updatedSlides[slideId],
						...otherProps,
						/** Clear the file (if present) as base64 data is too large to store in localstorage */
						file: {} as SlideFile,
					}

					if (!isEmpty(options)) {
						/** Update the options to whatever is stored */
						updatedSlides[slideId].options = options;
					}

					if (!isEmpty(table)) {
						/** Update the table to whatever is stored */
						updatedSlides[slideId].table = table;
					}

					if (!isEmpty(shapes)) {
						/** Update the shapes to whatever is stored */
						updatedSlides[slideId].shapes = shapes;
					}
				}

				const [ slide ] = Object.values(updatedSlides);

				dispatch(quizActions.setSlides(updatedSlides, true));

				dispatch(quizActions.setPresentationId(slide.presentationId));

				enqueueSnackbar(translatePlaceholder("UNSAVED_CHANGED_RESTORED", {
					slideCount: Object.keys(storedSlideData).length
				}), { variant: 'success' });

				return true;

			} catch (error) {

				console.warn(error);

			} finally {

				removeFromLocalStorage(`${LocalStorageKeys.UnsavedPresentationData}:${presentationId}`);
			}
		}

		return false;
	}

	const removeUnsavedLocalstorageData = (id: number) => {

		removeFromLocalStorage(`${LocalStorageKeys.UnsavedPresentationData}:${id}`);
	}

	const clearUnsyncedSlideIds = (slideIds?: Array<number>) => {

		dispatch(quizActions.clearUnsyncedSlideIds(slideIds));
	}

	return {
		isOnline,
		applyDataFromLocalStorage,
		saveUnsavedDataToLocalstorage,
		removeUnsavedLocalstorageData,
		clearUnsyncedSlideIds,
	}
}

export default useOfflineSync;