import { Box, InputBase, InputBaseProps } from '@mui/material';
import ButtonLoader from 'Components/common/util/button_loader/ButtonLoader';
import { DataElType, TestIdAttributes, TrackingAttributes } from 'Scripts/measurementsGAHelper';
import React from 'react';
import { classes } from './style.css';


interface AppInputProps extends TrackingAttributes, TestIdAttributes, InputBaseProps {
    loading?: boolean;
}

const AppInput = React.forwardRef<HTMLInputElement, AppInputProps>(({
    loading,
    ...props
}: AppInputProps, ref) => {


    const {
        "data-testid": dataTestId,
        "data-elaction": dataElAction,
        "data-elposition": dataElPosition,
        "data-elscreen": dataElScreen,
        "data-eltype": dataElType,
        inputProps,
        ...otherProps
    } = props;

    return (
        <InputBase
            fullWidth
            data-eltype={DataElType.Input}
            classes={{
                root: classes.inputRoot,
                disabled: classes.inputDisabled,
                error: classes.inputError,
            }}
            inputProps={{
                ref,
                className: classes.input,
                "data-testid": dataTestId,
                "data-elaction": dataElAction,
                "data-elposition": dataElPosition,
                "data-elscreen": dataElScreen,
                "data-eltype": dataElType,
                ...inputProps,
            }}
            {...otherProps}
            startAdornment={(
                <>
                    {(loading || props.startAdornment) && (
                        <Box px={1} className={classes.startAdornmentContainer}>
                            {loading && (
                                <ButtonLoader inline dark />
                            )}
                            {!loading && props.startAdornment}
                        </Box>
                    )}
                </>
            )}
        />
    );
});

export default AppInput;