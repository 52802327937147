import React from 'react';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import VideoSlideSession from '../../session/view/VideoSlideSession';

const VideoSlideSessionPreview = ({
    slide,
}: SlideConsumerComponent<ContentSlideType>) => {

    return (
        <VideoSlideSession slide={slide} />
    )
}

export default VideoSlideSessionPreview;