import { SignupStepperData } from "Types/layoutTypes";
import { PurchaseablePlanDetails, SupportDetails, UserFlowState } from "Types/userFlowTypes";

export const setAvailablePlans = (plans: Array<PurchaseablePlanDetails>) => ({
	type: 'SET_AVAILABLE_PLANS',
	plans,
})

export const setSupportDetails = (details: SupportDetails) => ({
	type: 'SET_SUPPORT_DETAILS',
	details,
})

export const setSignupStepperWelcomeShown = (shown: boolean) => ({
	type: 'SET_SIGNUP_STEPPER_WELCOME_SHOWN',
	shown,
})

export const initUserFlow = (data : UserFlowState) => ({
	type: 'INIT_USER_FLOW',
	data
})

export const updateUserFlowSetting = (key: string, value: any) => ({
	type: 'UPDATE_USER_FLOW_DATA',
	key,
	value,
})

export const setPurchaseCompletedThankYou = (show: boolean) => ({
	type: 'SET_PURCHASE_COMPLETED_TY',
	show
})


export const setSignupStepperData = (data: SignupStepperData) => ({
	type: 'SET_SIGNUP_STEPPER_DATA',
	data,
})

export const setSignupStepperStep = (stepNr: number) => ({
	type: 'SET_SIGNUP_STEPPER_STEP',
	stepNr,
})

export const setShowSignupStepper = (isOpen: boolean) => ({
	type: 'SET_SHOW_SIGNUP_STEPPER',
	isOpen,
})

export const setIgnoreLicensePopups = (ignore: boolean) => ({
    type: 'SET_IGNORE_LICENSE_POPUPS',
    ignore,
})

export const setToggleResultsAfterSession = (enabled: boolean) => ({
	type: 'SET_TOGGLE_RESULTS_AFTER_SESSION',
	enabled,
})

export const setToggleResultsAfterSessionLoading = (isLoading: boolean) => ({
	type: 'SET_TOGGLE_RESULTS_AFTER_SESSION_LOADING',
	isLoading,
})

export const setUserFlowLoading = (isLoading: boolean) => ({
	type: 'SET_USER_FLOW_LOADING',
	isLoading,
})

export const setSupportDetailsLoading = (isLoading: boolean) => ({
	type: 'SET_SUPPORT_DETAILS_LOADING',
	isLoading,
})

export const setUserSegmentUpdateLoading = (isLoading: boolean) => ({
	type: 'SET_USER_SEGMENT_UPDATE_LOADING',
	isLoading,
})
export const setRequestAccessLoading = (isLoading: boolean) => ({
	type: 'SET_REQUEST_ACCESS_LOADING',
	isLoading,
})