import WifiOffIcon from '@mui/icons-material/WifiOff';
import AppText from 'Atomic/atoms/Text/AppText';
import HeaderNotificationWrapper from 'Components/beta/common/notifications/header_notification/HeaderNotificationWrapper';
import { useTranslations } from 'Hooks/useTranslations';
import { AppState } from 'Types/appTypes';
import React, { ReactNode } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

interface ConnectionStatusWrapperProps {
    readonly children: ReactNode | ReactNode[]
}

const ConnectionStatusWrapper = ({
    children
}: ConnectionStatusWrapperProps) => {

    const isOnline = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).isOnline);

    const { translatePlaceholder } = useTranslations();

    return (
        <HeaderNotificationWrapper
            show={!isOnline}
            content={<div className={classes.offlineContent}>
                <WifiOffIcon />
                <AppText p={2} color="inherit">
                    {translatePlaceholder("YOU_SEEM_OFFLINE")}
                </AppText>
            </div>} >
            {children}
        </HeaderNotificationWrapper>
    );
};


export default ConnectionStatusWrapper;