import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import React from 'react';
import { Step } from 'react-joyride';
import { useTranslations } from './useTranslations';
export interface StepAction {
    action: string;
    controlled: boolean;
    index: number;
    lifecycle: string;
    size: number;
    status: string;
    step: Step,
    type: string;
}

/**
 * Element ID's for the guided tours
 */
export enum IntroGuidedTourStepID {
    AddSlideMobile = `GuidedTour_AddSlideMobile`,
    AddSlide = `GuidedTour_AddSlide`,
    Autosave = `GuidedTour_Autosave`,
    SlideContent = `GuidedTour_SlideContent`,
    SlideEditorContent = 'GuidedTour_SlideEditorContent',
    PreviewSlide = `GuidedTour_PreviewSlide`,
    PreviewPlaySlide = `GuidedTour_PreviewPlaySlide`,
    SlideMedia = `GuidedTour_SlideMedia`,
    SlideOptions = `GuidedTour_SlideOptions`,
    SlideOptionsMobile = `GuidedTour_SlideOptionsMobile`,
    StartSession = `GuidedTour_StartSession`,
    StartSessionMobile = `GuidedTour_StartSessionMobile`,
    SpeakerNotes = `GuidedTour_SpeakerNotes`,
}

export enum AIGuidedTourSteps {
    WordcloudInstructions,
    ImageInstructions,
    QuizInstructions,
    PreviewInstructions,
    // ShareInstructions,
    SpeakerNotesInstructions,
}


interface AIStepProps extends Step {
    index: AIGuidedTourSteps;
}

export const useGuidedTour = () => {

    const theme = useTheme();

    const { translatePlaceholder } = useTranslations();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Unused
    const aiGuidedTourSteps: Array<AIStepProps> = [
        {
            disableBeacon: true,
            isFixed: true,
            placement: 'bottom',
            index: AIGuidedTourSteps.WordcloudInstructions,
            target: `#${IntroGuidedTourStepID.SlideEditorContent}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        Wordcloud question
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        Sendsteps.ai adds a wordcloud to your presentation, so you can collect ideas from the audience.
                    </AppText>
                </>
            ),
        },
        {
            disableBeacon: true,
            isFixed: true,
            placement: 'left',
            index: AIGuidedTourSteps.ImageInstructions,
            target: `#${IntroGuidedTourStepID.SlideMedia}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        Images
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        Customise the images on your presentation by hovering on them and clicking this icon.
                    </AppText>
                </>
            )
        },
        {
            disableBeacon: true,
            isFixed: true,
            placement: 'bottom',
            index: AIGuidedTourSteps.QuizInstructions,
            target: `#${IntroGuidedTourStepID.SlideEditorContent}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        Quiz question
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        Use this quiz question to play a game with your audience, the winners will show in a podium at the end.
                    </AppText>
                </>
            )
        }, {
            disableBeacon: true,
            isFixed: true,
            placement: 'left-start',
            index: AIGuidedTourSteps.PreviewInstructions,
            target: `#${IntroGuidedTourStepID.PreviewPlaySlide}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        Play/Preview
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        Click here to test your presentation or play it to your audience.
                    </AppText>
                </>
            )
        }, {
            disableBeacon: true,
            isFixed: true,
            placement: 'right',
            index: AIGuidedTourSteps.SpeakerNotesInstructions,
            target: `#${IntroGuidedTourStepID.SpeakerNotes}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        Speaker notes
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        Here you will have supporting info related to your presentation. You can also type notes of your own.
                    </AppText>
                </>
            )
        }
    ]

    const guidedTourSteps: Array<Step> = [
        {
            disableBeacon: true,
            isFixed: true,
            placement: isSmallScreen ? 'bottom' : 'right',
            target: `#${isSmallScreen ? IntroGuidedTourStepID.AddSlideMobile : IntroGuidedTourStepID.AddSlide}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        {translatePlaceholder("ADDING_SLIDES")}
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        {translatePlaceholder("ADDDING_SLIDES_EXPLANATION")}
                    </AppText>
                </>
            )
        },
        {
            disableBeacon: true,
            isFixed: true,
            target: `#${IntroGuidedTourStepID.Autosave}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        {translatePlaceholder("SAVING")}
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        {translatePlaceholder("SAVING_CHANGES_EXPLANATION")}
                    </AppText>
                </>
            )
        },
        {
            disableBeacon: true,
            isFixed: true,
            target: `#${IntroGuidedTourStepID.SlideContent}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        {translatePlaceholder("EDITING_SLIDES")}
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        {translatePlaceholder("EDIT_AREA_EXPLANATION")}
                    </AppText>
                </>
            )
        },
        {
            disableBeacon: true,
            isFixed: true,
            target: `#${isSmallScreen ? IntroGuidedTourStepID.StartSessionMobile : IntroGuidedTourStepID.StartSession}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        {translatePlaceholder("PREVIEWING")}
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        {translatePlaceholder("PREVIEWING_EXPLANATION")}
                    </AppText>
                </>
            )
        },
        {
            disableBeacon: true,
            isFixed: true,
            placement: isSmallScreen ? 'bottom' : 'left',
            target: `#${isSmallScreen ? IntroGuidedTourStepID.SlideOptionsMobile : IntroGuidedTourStepID.SlideOptions}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        {translatePlaceholder("CUSTOMIZING")}
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        {translatePlaceholder("CUSTOMIZING_EXPLANATION")}
                    </AppText>
                </>
            )
        },
        {
            disableBeacon: true,
            isFixed: true,
            target: `#${isSmallScreen ? IntroGuidedTourStepID.StartSessionMobile : IntroGuidedTourStepID.StartSession}`,
            content: (
                <>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.H5Bold}
                        pb={2}
                    >
                        {translatePlaceholder("STARTING_SESSION")}
                    </AppText>
                    <AppText
                        style={{ color: theme.palette.common.white }}
                        as={AppTextVariant.BodyRegular}
                        noWrap={false}>
                        {translatePlaceholder("STARTING_SESSION_EXPLANATION")}
                    </AppText>
                </>
            )
        }
    ];

    return {
        guidedTourSteps,
        aiGuidedTourSteps,
    }
}