import { Divider, Typography } from '@mui/material';
import FeatureWrapper from 'Atomic/atoms/FeatureWrapper/FeatureWrapper';
import OnOffSwitch from 'Components/beta/common/buttons/switch/OnOffSwitch';
import Caption from 'Components/beta/common/caption/Caption';
import SummaryOption from 'Components/beta/common/summary/option/SummaryOption';
import { Features, useFeatures } from 'Hooks/useFeatures';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { OverviewState } from 'Types/overviewTypes';
import React, { useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import HighLight from '../../HighLight';
import ParticipantIdentificationQuestions from './questions/ParticipantIdentificationQuestions';

const ParticipantIdentificationToggle = () => {

	const {
		userSessionInfo,
        updateAnonymousSources,
        updateAnonymousLoading
	} = useSession();

	const { translatePlaceholder } = useTranslations();

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

	const { isFeatureAvailable } = useFeatures()

	const isAnonymous = useMemo(() => {

		return Boolean(Number(userSessionInfo?.isAnonymous));

	}, [userSessionInfo?.isAnonymous])

	const hasAudienceIdentificationFeature = isFeatureAvailable(Features.AUDIENCE_IDENTIFICATION);

	const toggleSetting = () => {

		updateAnonymousSources(!isAnonymous)
	}

	return (
		<HighLight anchor="#identification">
			<Typography variant="h2" my={3}>
				{translatePlaceholder("COLLECT_PARTICIPANT_INFORMATION")}
			</Typography>
			<Divider />
			<SummaryOption
				loading={!userSessionInfo || presentationLoading}
				leftItem={<div>
					<Typography variant="body2">
						{!hasAudienceIdentificationFeature && "💎"} {translatePlaceholder("IDENTIFY_PARTICIPANTS")}
					</Typography>
					<Caption title={translatePlaceholder("IDENTITY_PARTICIPANTS_EXPLANATION")} />
				</div>}
				rightItem={(
					<FeatureWrapper
						data-elscreen={DataElScreen.Settings}
						feature={Features.AUDIENCE_IDENTIFICATION}>
						<OnOffSwitch
							disabled={updateAnonymousLoading}
							checked={!isAnonymous}
							onChange={() => toggleSetting()}
							data-testid={testIds.PARTICIPANT_IDENTIFICATION_TOGGLE}
						/>
					</FeatureWrapper>
				)}
				divider={false}
				trackingAttr={renderingGAAttr(`${DataElAction.IdentifyParticipant}_${!isAnonymous}${!hasAudienceIdentificationFeature ? '_limited' : ''}`, DataElPosition.HideAdvancedSection, DataElScreen.Settings, DataElType.Toggle)}
			/>
			<div>
				<ParticipantIdentificationQuestions />
			</div>
		</HighLight>
	);
};

export default ParticipantIdentificationToggle;