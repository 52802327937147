import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import PlaySlideTitlePreview from 'Atomic/molecules/PlaySlideTitlePreview/PlaySlideTitlePreview';
import Html from 'Components/common/util/html/Html';
import SessionLoginInfo from 'Components/play/templates/geometry_template/shared_components/session_login_info/SessionLoginInfo';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import WordCloudComponent from 'Components/wordcloud/WordCloudComponent';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useInterval } from 'Scripts/intervalHook';
import { NavigationState } from 'Scripts/slideHelper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { MessageRoundType } from 'Types/presentationTypes';
import React, { useState } from 'react';
import { classes } from './style.css';

const questionStartDelayMs = 2500;

const WordCloudSlideSessionPreview = ({ slide }: SlideConsumerComponent<MessageRoundType>) => {

    const [slideNavigationState, setSlideNavigationState] = useState<NavigationState>(NavigationState.BeforeOpen);

    const slideProperties = getSlideProperties(slide);

    const isActiveState = (state: NavigationState) => {

        return slideNavigationState === state;
    }

    const { presentationStyle } = usePresentationStyles();


    useInterval(() => {

        setSlideNavigationState(NavigationState.SlideOpen);

    }, isActiveState(NavigationState.BeforeOpen) ? questionStartDelayMs : null)

    return <>
        <Grid
            container
            className={classes.questionContainer}
            sx={{
                color: presentationStyle?.textColor
            }}
            direction="row"
            justifyContent="center">
            <Grid
                container
                item
                xs={12}
                className={`${classes.detailsContainer} ${isActiveState(NavigationState.BeforeOpen) && classes.upSize}`}
            >
                <LayoutWrapper
                    layoutType={slide.slideLayout}
                    imageURL={slide?.file?.base64 || slide?.filename}
                    imageStyle={slide.filenameStyle}
                    backgroundOpacity={slide.backgroundOpacity}
                    backgroundColor={slide.backgroundColor}
                    videoURL={slide.videoURL}
                    interactive={false}
                    title={
                        <PlaySlideTitlePreview
                            showPreview={isActiveState(NavigationState.BeforeOpen)}
                            title={
                                <DelayedComponent
                                    animationDuration={250}
                                    animationType={AnimationTypes.Grow}
                                    showAfter={500} >
                                    <Box p={4}>
                                        <Html
                                            content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                            fontSize={slideProperties.slideSettings.titleFontSize}
                                        />
                                    </Box>
                                </DelayedComponent>
                            }
                        />
                    }
                    content={
                        <Grid item className={classes.wordcloudGrid}>
                            {isActiveState(NavigationState.SlideOpen) && <Fade in={true} style={{ height: '100%', width: '100%' }}>
                                <div>
                                    <WordCloudComponent demoMode={true} />
                                </div>
                            </Fade>}
                        </Grid>
                    }
                />
            </Grid>
        </Grid>
        <SessionLoginInfo />
    </>;
};

export default WordCloudSlideSessionPreview