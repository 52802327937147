import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, InputBase, MenuItem, Select, Typography } from '@mui/material';
import { setFlatInputObjValues } from 'Actions/generatePresentationActions';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import ContainerWithBorder from "Atomic/atoms/ContainerWithBorder/ContainerWithBorder";
import EditInput from 'Atomic/atoms/EditInput/EditInput';
import AppText, { AppTextVariant } from "Atomic/atoms/Text/AppText";
import TwoCtaDialog from 'Atomic/atoms/TwoCtaDialog/TwoCtaDialog';
import { useAiLanguages } from 'Hooks/useAiLanguages';
import useOutline from 'Hooks/useOutline';
import { useTranslations } from 'Hooks/useTranslations';
import { AIAmountOfTextOnSlides, AIToneOfVoice, AIWriteFor } from 'Types/aiTypes';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classes } from "./style.css";

const Summary = ()=> {
    const { translatePlaceholder } = useTranslations();
    const {
        isLoading,
    } = useOutline();
    const {
        generateOutline,
    } = useOutline();
    const {
        aiLanguages,
    } = useAiLanguages();
    const dispatch = useDispatch();

    const inputObjValues = useSelector((state: RootStateOrAny) => state.generatePresentationReducer.flatInputObjValues);
    const flatInputObjValues = inputObjValues?.flatInputObjValues || {};
    
    const [summaryObj, setSummaryObj] = useState({...flatInputObjValues});
    const [enableInput, setEnableInput] = useState<string|null>(null);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const updateValue = (name: string, value: string|boolean|number)=> {
        const updatedSummaryObj = {...summaryObj};
        updatedSummaryObj[name] = value;
        setSummaryObj(updatedSummaryObj);
    };

    const regenerateOutline = ()=> {
        generateOutline({flatInputObjValues: {...summaryObj}});
        dispatch(setFlatInputObjValues({...summaryObj}));
        setEnableInput(null);
        setDialogIsOpen(false);
    };

    const handleOnClick = (type: string)=> {
        setEnableInput(type);
        const element = document.getElementsByName(type);
        if (element.length > 0) {
            element[0].focus();
            element[0].select();
        }
    }

    return (
        <>
            <ContainerWithBorder>
                <AppText mb={"10px"} as={AppTextVariant.BodySmallLight}>{translatePlaceholder("PRESENTATION_PARAMETERS")}<InfoOutlinedIcon className={classes.infoIcon}/></AppText>
                <Grid container rowGap={1.5} marginBottom={2}>
                    <Grid item sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("TITLE")}:</AppText></Grid>
                    <Grid item sm={6}>
                        <EditInput name="presentationTitle" value={summaryObj?.presentationTitle} disabled={enableInput !== "presentationTitle"} onChange={(e)=> updateValue("presentationTitle", e.target.value)} onClick={()=> handleOnClick("presentationTitle")} className={classes.summaryInput}/>
                    </Grid>
                    <Grid item sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("SUBJECT")}:</AppText></Grid>
                    <Grid item sm={6}>
                        <EditInput name="keywords" value={summaryObj?.sourceType == "keywords" ? summaryObj?.keywords : ""} disabled={enableInput !== "keywords"} onChange={(e)=> updateValue("keywords", e.target.value)} onClick={()=> handleOnClick("keywords")} className={classes.summaryInput}/>
                    </Grid>
                    <Grid item sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("LANGUAGE")}:</AppText></Grid>
                    <Grid item sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.language}
                            input={<InputBase className={classes.dropdown}/>}
                            className={!enableInput ? classes.dropdownDisabled : classes.dropdownEnabled}
                        >
                           {(aiLanguages||[]).map((l)=> {
                                return(
                                    <MenuItem
                                        key={l?.isoCode}
                                        value={l?.name}
                                        onClick={()=> {updateValue("language", l?.name);setEnableInput("language")}}
                                    >
                                        <Typography>
                                            {l.name}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                    <Grid item sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("NUMBER_OF_SLIDES")}:</AppText></Grid>
                    <Grid item sm={6} className={classes.dropdownBox}>
                        <Select
                                value={summaryObj?.numberOfSlides}
                                input={<InputBase className={classes.dropdown}/>}
                                className={!enableInput ? classes.dropdownDisabled : classes.dropdownEnabled}
                            >
                                {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map((n)=> {
                                    return(
                                        <MenuItem
                                            key={n}
                                            value={n}
                                            onClick={()=> {updateValue("numberOfSlides", n);setEnableInput("numberOfSlides")}}
                                        >
                                            <Typography>
                                                {n}
                                            </Typography>
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </Grid>
                    <Grid item sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("AMOUNT_OF_TEXT_ON_SLIDES")}:</AppText></Grid>
                    <Grid item sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.amountOfText}
                            input={<InputBase className={classes.dropdown}/>}
                            className={!enableInput ? classes.dropdownDisabled : classes.dropdownEnabled}
                        >
                            {Object.keys(AIAmountOfTextOnSlides).map((amountValue)=> {
                                return(
                                    <MenuItem
                                        key={amountValue}
                                        value={amountValue}
                                        onClick={()=> {updateValue("amountOfText", amountValue);setEnableInput("amountOfText")}}
                                    >
                                        <Typography>
                                            {translatePlaceholder("STEPPER_" + amountValue.toUpperCase())}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                    <Grid item sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("INCLUDE_SPEAKER_NOTES")}:</AppText></Grid>
                    <Grid item sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.speakerNotes}
                            input={<InputBase className={classes.dropdown}/>}
                            className={!enableInput ? classes.dropdownDisabled : classes.dropdownEnabled}
                        >
                            <MenuItem
                                key={"speakerNotesTrue"}
                                value={true}
                                onClick={()=> {updateValue("speakerNotes", true);setEnableInput("speakerNotes")}}
                            >
                                <Typography>
                                    {translatePlaceholder("TRUE")}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                key={"speakerNotesFalse"}
                                value={false}
                                onClick={()=> {updateValue("speakerNotes", false);setEnableInput("speakerNotes")}}
                            >
                                <Typography>
                                    {translatePlaceholder("FALSE")}
                                 </Typography>
                            </MenuItem>
                        </Select>
                    </Grid>
                    <Grid item sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("ADD_INTERACTIVE_QUESTIONS")}:</AppText></Grid>
                    <Grid item sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.interactiveQuestions}
                            input={<InputBase className={classes.dropdown}/>}
                            className={!enableInput ? classes.dropdownDisabled : classes.dropdownEnabled}
                        >
                            <MenuItem
                                key={"interactiveQuestionsTrue"}
                                value={true}
                                onClick={()=> {updateValue("interactiveQuestions", true);setEnableInput("interactiveQuestions")}}
                            >
                                <Typography>
                                    {translatePlaceholder("TRUE")}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                key={"interactiveQuestionsFalse"}
                                value={false}
                                onClick={()=> {updateValue("interactiveQuestions", false);setEnableInput("interactiveQuestions")}}
                            >
                                <Typography>
                                    {translatePlaceholder("FALSE")}
                                 </Typography>
                            </MenuItem>
                        </Select>
                    </Grid>
                    <Grid item sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("WRITE_FOR")}:</AppText></Grid>
                    <Grid item sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.writeFor}
                            input={<InputBase className={classes.dropdown}/>}
                            className={!enableInput ? classes.dropdownDisabled : classes.dropdownEnabled}
                        >
                            {Object.keys(AIWriteFor).map((writeFor)=> {
                                return(
                                    <MenuItem
                                        key={writeFor}
                                        value={writeFor}
                                        onClick={()=> {updateValue("writeFor", writeFor);setEnableInput("writeFor")}}
                                    >
                                        <Typography>
                                            {translatePlaceholder("STEPPER_" + writeFor.toUpperCase())}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                    <Grid item sm={6}><AppText className={classes.appText} as={AppTextVariant.TertiaryBgSmall}>{translatePlaceholder("TONE_OF_VOICE")}:</AppText></Grid>
                    <Grid item sm={6} className={classes.dropdownBox}>
                        <Select
                            value={summaryObj?.toneOfVoice}
                            input={<InputBase className={classes.dropdown}/>}
                            className={!enableInput ? classes.dropdownDisabled : classes.dropdownEnabled}
                        >
                            {Object.keys(AIToneOfVoice).map((toneOfVoice)=> {
                                return(
                                    <MenuItem
                                        key={toneOfVoice}
                                        value={toneOfVoice}
                                        onClick={()=> {updateValue("toneOfVoice", toneOfVoice);setEnableInput("toneOfVoice")}}
                                    >
                                        <Typography>
                                            {translatePlaceholder("STEPPER_" + toneOfVoice.toUpperCase())}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                </Grid>
                <div className={classes.regenerateOutlineBox}>
                    <AppButton
                        onClick={()=> setDialogIsOpen(true)}
                        disabled={!enableInput || isLoading}
                        as={AppButtonVariant.AiMagic}>
                            {translatePlaceholder("REGENERATE_OUTLINE")}
                    </AppButton>
                </div>
            </ContainerWithBorder>
            <TwoCtaDialog 
                isOpen={dialogIsOpen}
                close={()=> setDialogIsOpen(false)}
                title={translatePlaceholder("DO_YOU_WANT_TO_REGENERATE_THE_OUTLINE") as string} 
                leftCtaIcon={<></>} 
                leftCtaLabel={translatePlaceholder("YES") as string} 
                leftCtaFc={regenerateOutline}
                leftCtaVariant={AppButtonVariant.Success}
            />
        </>
            
    );
}
export default Summary;
