export interface IdentificationState {
    questions: Array<IdentificationQuestionAttributes>;
    questionDeleting: boolean;
}

export interface IdentificationQuestionAttributes {
    data?: IdentificationQuestionProperties;
    answers?: Array<QuestionAnswerProperties>
}

export enum QuestionTypes {
    MultipleChoice = 'checkbox',
    Radio = 'radio',
    Text = 'textbox',
}

export interface QuestionAnswerProperties {
    id?: number;
    allowedValues: string;
    participantinfofieldsId: number;
}

export interface IdentificationQuestionProperties {
    deleted: number| boolean;
    id?: number;
    isIdentifier: number | boolean;
    isRequired: number | boolean;
    quizIdentifier: number | boolean;
    title: string;
    type: QuestionTypes;
}
