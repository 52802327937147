import React from 'react';
import { classes } from './style.css';


const SendstepsLogo = () => {

    return (
        <div className={classes.centerLogo} />
    );
};

export default SendstepsLogo;