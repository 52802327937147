import React from 'react';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import TitleAndSubtitleSlideEditorPreview from '../../editor/preview/TitleAndSubtitleSlideEditorPreview';


const TitleAndSubtitleSlideSessionPreview = ({ slide }: SlideConsumerComponent<ContentSlideType>) => {

    return (
        <TitleAndSubtitleSlideEditorPreview slide={slide} />
    );
};

export default TitleAndSubtitleSlideSessionPreview;