import Skeleton from '@mui/material/Skeleton';
import React, { useMemo } from 'react';
import { getRandomPercentage } from 'Scripts/mathHelper';

const RandomWidthSkeleton = (props: any) => {

	const randomWidth = useMemo(() => {

		return `${getRandomPercentage(props.minWidth || 25, 100) >> 0}%`;

	}, [])

	return (
		<Skeleton width={randomWidth} {...props} />
	);
};

export default RandomWidthSkeleton;