import { alpha } from '@mui/material';
import { config } from "./config";

export type HexColor = string; /* e.g: #FFFFFF */
export type RGBColor = { r: number, g: number, b: number }
export type LottieFormattedColor = [ red: number, green: number, blue: number, alpha: number ]

export const getRandomColor = () : HexColor => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color
}

export const lottieFormattedColor = (color: HexColor) : LottieFormattedColor => {

    const rgbColor: RGBColor = hexToRgb(color)

    const { r, g, b } = rgbColor

    return [
        r ? r / 255 : 0,
        g ? g / 255 : 0,
        b ? b / 255 : 0,
        1
    ]
}

export const hexToRgb = (hex: HexColor) : RGBColor => {

    const regexpMatch : (RegExpExecArray | null) = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        const [ a = "255", r = "255", g = "255", b = "255" ] = regexpMatch || [];

        return {
            r: parseInt(r, 16),
            g: parseInt(g, 16),
            b: parseInt(b, 16)
        };
}


export const quizOptionColorSet = (): HexColor[] => ([
    '#0c7a5b',
    '#005cb9',
    '#f38b00',
    '#e12f2f'
])

export const getTransitionColorValue = (toHexColor: HexColor, fromHexColor: HexColor, transitionPercentage: number) : HexColor => {

    const mix = (startValue: number, endValue: number, percentage: number) : number => {

        return (startValue + (percentage * (endValue - startValue))) >> 0
    }

    const startRGBColor = fromHexColor.replace('#', '')

    const endRGBColor = toHexColor.replace('#', '')

    const [startR, startG, startB] : RegExpMatchArray = startRGBColor.match(/.{1,2}/g) || [];

    const [endR, endG, endB] : RegExpMatchArray = endRGBColor.match(/.{1,2}/g) || [];

    let finalR = mix(parseInt(startR, 16), parseInt(endR, 16), transitionPercentage).toString(16)

    let finalG = mix(parseInt(startG, 16), parseInt(endG, 16), transitionPercentage).toString(16)

    let finalB = mix(parseInt(startB, 16), parseInt(endB, 16), transitionPercentage).toString(16)

    while (finalR.length < 2) {
        finalR = `0${finalR}`
    }

    while (finalG.length < 2) {
        finalG = `0${finalG}`
    }

    while (finalR.length < 2) {
        finalB = `0${finalB}`
    }

    const finalHex = `#${finalR}${finalG}${finalB}`

    return finalHex
}

export const getConfigBasedColorGradientArray = () : HexColor[] => {
    return [
        ...Object.keys(config.blueColors).reverse(),
        ...Object.keys(config.blueColors).splice(1, Object.keys(config.blueColors).length),
        ...Object.keys(config.blueColors).reverse().splice(1, Object.keys(config.blueColors).length),
        ...Object.keys(config.blueColors).splice(1, Object.keys(config.blueColors).length),
    ];
}

export const getMUISafeAlphaColor = (color: string, value: number) => {

        if (!color || color === 'transparent') {

            return 'transparent';
        }

    return alpha(color, value);
}


export const colorToRgba = (color: string) => {

    const canvas = document.createElement('canvas');

    canvas.height = 1;
    canvas.width = 1;

    const context = canvas.getContext('2d') as CanvasRenderingContext2D;

    context.fillStyle = color;

    context.fillRect(0, 0, 1, 1);

    const { data } = context.getImageData(0, 0, 1, 1);

    const [r, g, b, a] = data;

    return {
        r,
        g,
        b,
        a
    };
}

/**
 *
 * @param color Any valid css color
 * @returns hex color
 */
export const colorToHex = (color: string) => {

    const rgbaValues = colorToRgba(color);

    const toHex = (c: number) => {

        const hex = c.toString(16);

        return hex.length === 1 ? "0" + hex : hex;
    };

    const hexColor = `#${toHex(rgbaValues.r)}${toHex(rgbaValues.g)}${toHex(rgbaValues.b)}`;

    if (rgbaValues.a < 255) {

        return `${hexColor}${toHex(rgbaValues.a)}`;
    }

    return hexColor;
}