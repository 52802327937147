import * as teamActions from 'Actions/teamActions';
import { setAddLicenseUserDialogOpen, setLicenseAutoAcceptDialogOpen, setLicenseManageUserSettings, setLicenseSortProperty, setLicenseUserIdToDelete, setLicenseUserIdToDowngrade, setLicenseUserIdToEdit, setLicenseUsersPageData, setLicenseUsersPageIndex, setLicenseUsersSearchValue, setLicenseUserTableDataLoading } from "Actions/teamActions";
import { SortObject, TablePaginationData } from "Components/common/table/AppTable";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { get, post } from "Scripts/api";
import { PaginationProps } from "Types/appTypes";
import { ManageUsersSettingsData } from "Types/teamTypes";

export const useManageUsers = () => {

	const dispatch = useDispatch();

	const { enqueueSnackbar } = useSnackbar();

	const fetchAllLicenseUsersData = async () => {

		try {

			const allResultsProps: PaginationProps = {
				pageIndex: 0,
				pageSize: Number.MAX_SAFE_INTEGER,
				sortBy: 'date',
				searchQuery: '',
				sortDesc: 1,
			}

			const filterValues = new URLSearchParams(allResultsProps);

			const allData = await get(`license/manage-users-summary?${filterValues}`);

			return allData;

		} catch (error) {

			console.warn(error);

			return [];
		}
	}

	const fetchLicenseUsersPageData = async (tableData: TablePaginationData) => {

		try {

			dispatch(setLicenseUserTableDataLoading(true));

			const paginationData: PaginationProps = {
				...tableData,
				sortBy: tableData.sortProperty.id,
				sortDesc: Number(tableData.sortProperty.desc),
			}

			const filterValues = new URLSearchParams(paginationData);

			const data = await get(`license/manage-users-summary?${filterValues}`);

			if (!data.error) {

				dispatch(setLicenseUsersPageData(data));
			}

		} catch (error) {

			console.warn(error);

		} finally {

			dispatch(setLicenseUserTableDataLoading(false));
		}
	}

	const toggleDeleteUser = (userId: number | null) => {

		dispatch(setLicenseUserIdToDelete(userId));
	}

	const deleteSelectedUser = async (userId: number) => {

		try {

			dispatch(teamActions.setLicensePageUserChangeLoading(true));

			const deleteResponse = await post(`license/delete-user/${userId}`);

			if (deleteResponse.error) {

				enqueueSnackbar(deleteResponse.error, { variant: 'error' });
			}

			if (deleteResponse.message) {

				enqueueSnackbar(deleteResponse.message, { variant: 'info' });
			}

			dispatch(setLicenseUserIdToDelete(null));

		} catch (error) {

			console.warn(error)

		} finally {

			dispatch(teamActions.setLicensePageUserChangeLoading(false));
		}
	}

	const fetchManageUsersSettings = async () => {

		try {

			const userSettings = await get('license/manage-users-settings');

			dispatch(setLicenseManageUserSettings(userSettings))

		} catch (error) {

			console.warn(error)
		}
	}

	const toggleAllowJoinLicense = async (isAllowed: boolean, settings: ManageUsersSettingsData) => {

		try {

			const result = await post('license/toggle-users-join-license', {
				canJoin: Number(isAllowed)
			});

			if (result.message) {

				enqueueSnackbar(result.message, { variant: 'info' })

				dispatch(setLicenseManageUserSettings({
					...settings,
					licenseAllowsJoinRequests: isAllowed
				}))
			}

		} catch (error) {

			console.warn(error)
		}
	}

	const toggleDowngradeuser = (userId: number | null) => {

		dispatch(setLicenseUserIdToDowngrade(userId));
	}

	const toggleLicenseAutoAcceptDialogOpen = (isOpen: boolean) => {

		dispatch(setLicenseAutoAcceptDialogOpen(isOpen));
	}

	const downgradeSelectedUser = async (userId: number) => {

		try {

			dispatch(teamActions.setLicensePageUserChangeLoading(true));

			const downgradeResponse = await post(`license/downgrade-user/${userId}`);

			if (downgradeResponse.error) {

				enqueueSnackbar(downgradeResponse.error, { variant: 'error' });
			}

			if (downgradeResponse.message) {

				enqueueSnackbar(downgradeResponse.message, { variant: 'info' });
			}

			dispatch(setLicenseUserIdToDowngrade(null));

		} catch (error) {

			console.warn(error)

		} finally {

			dispatch(teamActions.setLicensePageUserChangeLoading(false));
		}
	}

	/**
	 * Errors from the api are returned with a user-* prefix
	 * so we strip them out here and replace them with the non user- value
	 */
	const formatLicenseUserFormErrors = (errors: {}) => {

		return Object.keys(errors).reduce((errorMap, errorKey) => {

			const error = errors[errorKey];

			if (errorKey.includes('user-')) {

				errorKey = errorKey.replace('user-', '');
			}

			errorMap[errorKey.toLocaleLowerCase()] = error;

			return errorMap;

		}, {})
	}

	const toggleEditUser = (userId: number | null) => {

		dispatch(setLicenseUserIdToEdit(userId));
	}

	const toggleAddUser = (isOpen: boolean) => {

		dispatch(setAddLicenseUserDialogOpen(isOpen));
	}

	const setAccountLicenseName = async (name: string, settings: ManageUsersSettingsData) => {

		try {

			dispatch(teamActions.setLicenseNameUpdateLoading(true));

			const updateResult = await post('license/license-name', {
				licenseName: name
			});

			if (updateResult.message) {

				enqueueSnackbar(updateResult.message, { variant: 'info' });

				dispatch(setLicenseManageUserSettings({
					...settings,
					licenseName: name
				}));
			}

		} catch (error) {

			enqueueSnackbar(error, { variant: 'error' })

		} finally {

			dispatch(teamActions.setLicenseNameUpdateLoading(false));
		}
	}

	const setLicenseSort = (property: SortObject) => {

		dispatch(setLicenseSortProperty(property))
	}

	const setLicenseTablePage = (pageNumber: number) => {

		dispatch(setLicenseUsersPageIndex(pageNumber));
	}

	const setLicenseSearchValue = (value: string) => {

		dispatch(setLicenseUsersSearchValue(value));
	}

	return {
		toggleDeleteUser,
		toggleDowngradeuser,
		toggleEditUser,
		toggleAddUser,
		toggleLicenseAutoAcceptDialogOpen,
		toggleAllowJoinLicense,
		deleteSelectedUser,
		downgradeSelectedUser,
		fetchLicenseUsersPageData,
		fetchAllLicenseUsersData,
		fetchManageUsersSettings,
		setAccountLicenseName,
		setLicenseSearchValue,
		setLicenseSort,
		setLicenseTablePage,
		formatLicenseUserFormErrors,
	}
}