import * as appActions from 'Actions/appActions';
import * as playActions from 'Actions/playActions';
import { setSlideNavigationState } from "Actions/quizActions";
import { getSlidePropertiesByType } from 'Components/types/helpers/slideTypeHelper';
import { bindKeyboardEvent, unbindKeyboardEvent } from "Scripts/eventBinding";
import { deactivateFullScreen, isFullScreen } from "Scripts/fullScreenHelper";
import { nextNavigationKeys, previousNavigationKeys } from "Scripts/keyboardHelper";
import { NavigationState, containsQuizSlides, getPlaySlideType, getSortedSlideKeysBySlideIndex } from 'Scripts/slideHelper';
import { PlayState } from "Types/playTypes";
import { PresentationState } from "Types/presentationTypes";
import { SlideTypes } from 'Types/slideTypes';
import { useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { getPlaySlideProperties } from "../components/types/helpers/slideTypeHelper";
import { SessionRouteMatches, SessionRoutePrefix, useSessionRoutes } from "../hooks/useSessionRoutes";
import useUrl from "./useUrl";

export const useSlideNavigation = () => {

	const autoMovement = true;

	const {
		getRoute,
		currentSlideKey,
		presentationId,
	} = useSessionRoutes();

	const urls = useUrl();

	const history = useHistory();

	const slideNavigationState = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slideNavigationState);

	const playSlides = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).playSlides);

	const dispatch = useDispatch();

	  /**
	 * Don't show the leaderboard/podium navigation states if the presentation contains podium/leaderboard slides
     *
	 * NOTE: this can be removed once the back-end is released (SAI-918)
	 */
	const hasLeaderboardOrPodiumSlide = useMemo(() => {

		for (const slideKey in playSlides) {

			const slide = playSlides[slideKey];

            const slideType = getPlaySlideType(slide);

			if([
				SlideTypes.Podium,
				SlideTypes.Leaderboard
            ].includes(slideType)) {

				return true;
			}
		}

		return false;

	}, [playSlides])

	/**
	 * @returns The previous slide key (by property slideIndex)
	 */
	const previousSlideKey = useMemo(() => {

		const sortedBySlideIndex = getSortedSlideKeysBySlideIndex(playSlides);

		const currentSlideIndex = sortedBySlideIndex.indexOf(currentSlideKey as number);

		return sortedBySlideIndex[currentSlideIndex - 1];

	}, [currentSlideKey])

	/**
	 * @returns The next slide key (by property slideIndex)
	 */
	const nextSlideKey = useMemo(() => {

		const sortedBySlideIndex = getSortedSlideKeysBySlideIndex(playSlides);

		const currentSlideIndex = sortedBySlideIndex.indexOf(currentSlideKey as number);

		return sortedBySlideIndex[currentSlideIndex + 1];

	}, [currentSlideKey])

	const hasQuizSlides = useMemo(() => {

		return containsQuizSlides(playSlides);

	}, [currentSlideKey, playSlides])

	/**
	 * @returns Whether the current slide is the last slide
	 */
	const isLastSlide = useMemo(() => {

		const sortedSlideKeys = getSortedSlideKeysBySlideIndex(playSlides)

		return currentSlideKey === sortedSlideKeys[sortedSlideKeys.length - 1];

	}, [currentSlideKey, playSlides])

	/**
	 * @returns Whether the current slide is the first slide
	 */
	const isFirstSlide = useMemo(() => {

		const sortedSlideKeys = getSortedSlideKeysBySlideIndex(playSlides)

		return currentSlideKey === sortedSlideKeys[0]

	}, [currentSlideKey, playSlides])

	const slideProperties = useMemo(() => {

		return getSlidePropertiesByType(playSlides[currentSlideKey as number]?.type || getPlaySlideType(playSlides[currentSlideKey as number]));

	}, [currentSlideKey])

	const isOnPodiumSlide = window.location.pathname.includes('end/podium');

	/**
	 * If there's a valid slide key, allow navigation to the next slide
	 */
	const canNavigateNext = (
		isOnPodiumSlide || (
			!(["end"].includes(window.location.pathname)) &&
			Boolean(currentSlideKey)
		)
	);

	/**
	 * If there's a valid slide key or user is on the podium slide,
	 * allow navigation to the previous slide
	 */
	const canNavigatePrevious = (
		isOnPodiumSlide || (
			!(["end"].includes(window.location.pathname)) &&
			Boolean(currentSlideKey)
		)
	);

	const navigateToSlideState = (state: NavigationState) => {

		dispatch(setSlideNavigationState(state));
	}

	/**
	 * Navigates back to the editor from current slide/presentationId
	 */
	const navigateBackToEditorAfterSession = () => {

		let currentSlideId = null;

		if (Boolean(currentSlideKey)) {

			currentSlideId = (playSlides[currentSlideKey as number]?.slideId || null)
		}

		dispatch(playActions.setPlayslides({}));

		dispatch(setSlideNavigationState(NavigationState.BeforeOpen));

		history.push(urls.editSlide(presentationId as number, currentSlideId));
	}

	/**
	 * Navigates to the previous slide state if not already on the first slide state of the first slide.
	 * Will navigate to the last slide state of the previous slide state otherwise.
	 */
	const navigateToPreviousSlideState = () => {

		let previousSlideState = slideNavigationState;

		const { navigationStates } = slideProperties;

		/*
			If on the podium slide,
			navigate to the last navigation state of the last slide
		*/
		if (isOnPodiumSlide) {

			const sortedSlideKeys = getSortedSlideKeysBySlideIndex(playSlides)

			const lastKey = sortedSlideKeys[sortedSlideKeys.length - 1];

			const previousSlideProperties = getPlaySlideProperties(playSlides[lastKey]);

			const previousNavigationStates = previousSlideProperties.navigationStates;

			const [firstNavigationState] = previousNavigationStates;

			dispatch(setSlideNavigationState(firstNavigationState));

			history.push(getRoute({
				presentationId: presentationId as number,
				currentSlideKey: lastKey,
			}, SessionRouteMatches.ActiveSlide));

			return;
		}

		const currentSlideStateIndex = Math.max(0, navigationStates.indexOf(slideNavigationState));
		/* If the current slide state is the first state of this slide */
		if (currentSlideStateIndex === 0) {

			if (!isFirstSlide) {

				const previousSlideProperties = getPlaySlideProperties(playSlides[previousSlideKey]);

				const previousNavigationStates = previousSlideProperties.navigationStates;
				/* Set the previous state to the last state of the previous slide */
				previousSlideState = previousNavigationStates[previousNavigationStates.length - 1];
				/* Navigate to the previous slide */
				history.push(getRoute({
					presentationId: presentationId as number,
					currentSlideKey: currentSlideKey as number,
					nextSlideKey: previousSlideKey,
				}, SessionRouteMatches.SlideRedirect));

			} else {
				/* It's the first slide and the first slide state. Navigate to the explanation slide */
				history.push(getRoute({
					presentationId: presentationId as number,
				}, SessionRouteMatches.ExplanationSlide));
			}

		/* Else, go back a state */
		} else {

			previousSlideState = navigationStates[currentSlideStateIndex - 1];
		}

		dispatch(setSlideNavigationState(previousSlideState));
	}

	/**
	 * Navigates to the next slide state if not already on the last slide state of the last slide.
	 * Will navigate to the first slide state of the next slide state otherwise.
	 */
	const navigateToNextSlideState = () => {

		let nextSlideState = slideNavigationState;

		const { navigationStates } = slideProperties;

		const currentSlideStateIndex = navigationStates.indexOf(slideNavigationState);
		/* If the current slide state is the last state of this slide */
		if (currentSlideStateIndex === (navigationStates.length - 1) || isOnPodiumSlide) {

			if (!isLastSlide && !isOnPodiumSlide) {

				const nextSlideProperties = getSlidePropertiesByType(playSlides[nextSlideKey]?.type || getPlaySlideType(playSlides[nextSlideKey]));

				const nextNavigationStates = nextSlideProperties.navigationStates;
				/* Set the next state to the first state of the next slide */
				nextSlideState = nextNavigationStates[0];
				/* Navigate to the next slide */
				history.push(getRoute({
					presentationId: presentationId as number,
					currentSlideKey: currentSlideKey as number,
					nextSlideKey: nextSlideKey,
				}, SessionRouteMatches.SlideRedirect));

			} else if (hasQuizSlides && !isOnPodiumSlide && !hasLeaderboardOrPodiumSlide) {
				/* It's the last slide and the last slide state. Don't navigate unless there's a quiz question involved */
				history.push(getRoute({
					presentationId: presentationId as number,
				}, SessionRouteMatches.PodiumSlide));

			} else {

				const fullScreenEnabled = isFullScreen();

				if (fullScreenEnabled) {

					deactivateFullScreen();

				} else {

					if (!window.location.pathname.includes(SessionRoutePrefix.Preview)) {

						dispatch(appActions.toggleRating(true));
					}

					navigateBackToEditorAfterSession();

					nextSlideState = NavigationState.BeforeOpen;
				}
			}

		} else {
			/* Else, go forward a state */
			nextSlideState = navigationStates[currentSlideStateIndex + 1];
		}

		dispatch(setSlideNavigationState(nextSlideState));
	}

	/**
	 * Checks available navigation keys and acts accordingly (forward/back)
	 */
	const handleKeys = (e: KeyboardEvent) => {

		const { navigationStates } = slideProperties;

		const lastSlideNavigationState = navigationStates[navigationStates.length - 1];

		const isOnLastNavigationState = slideNavigationState === lastSlideNavigationState

		const isAtEndOfPresentation = ((isLastSlide && isOnLastNavigationState) || isOnPodiumSlide);

		if (nextNavigationKeys.includes(e.key) && (isAtEndOfPresentation || canNavigateNext)) {

			e.preventDefault();

			navigateToNextSlideState();
		}

		if (canNavigatePrevious && previousNavigationKeys.includes(e.key)) {

			e.preventDefault();

			navigateToPreviousSlideState()
		}
	}

	/**
	 * This useeffect handler is constantly being called to rebind the keys
	 * If this isn't done every render, navigation won't be accurate anymore
	 */
	useEffect(() => {

		unbindKeyboardEvent(handleKeys)

		bindKeyboardEvent(handleKeys)

		return () => {

			unbindKeyboardEvent(handleKeys)
		}
	})

	const isActiveState = (state: NavigationState) => {

		return (slideNavigationState === state);
	}

	return {
		isActiveState,
		navigateToSlideState,
		navigateToNextSlideState,
		navigateToPreviousSlideState,
		navigateBackToEditorAfterSession,
		isLastSlide,
		isFirstSlide,
		canNavigatePrevious,
		canNavigateNext,
		autoMovement,
	}
}