import '@dotlottie/player-component';
import lottie, { AnimationConfigWithPath, AnimationItem } from 'lottie-web';
import React, { HTMLProps, useEffect, useRef } from 'react';

export enum LottiePlayMode {
    Normal = 'normal',
    Bounce = 'bounce',
}

export enum LottieRenderer {
    SVG = 'svg',
    Canvas = 'canvas',
    HTML = 'html',
}

interface AppLottiePlayerProps extends Omit<AnimationConfigWithPath, "container"> {
    /** URL to .lottie file or stringified JSON animation data */
    src: string;
    /** Autoplay animation on load */
    autoplay?: boolean;
    /** Background color */
    background?: string;
    /** Show controls */
    controls?: boolean;
    /** Number of times to loop animation */
    count?: number;
    /** Direction of animation */
    direction?: number;
    /** Whether to loop */
    loop?: number | boolean;
    /** Play mode */
    mode?: LottiePlayMode;
    /** Animation speed */
    speed?: number;
    /** Add a pause between loops */
    intermission?: number;
    /** Whether the animation is paused */
    paused?: boolean;
    /** Whether an animation frame is entered */
    onAnimationFrameEntered?: (e: AnimationEvent) => void;
    /** Fired when animation loop completes */
    onAnimationLoopComplete?: (e: AnimationEvent) => void;
    /** Fired when animation completes */
    onAnimationComplete?: (e: AnimationEvent) => void;
    /** Fired when animation segment starts */
    onAnimationSegmentStart?: (e: AnimationEvent) => void;
    /** Fired when animation is destroyed */
    onAnimationDestroy?: (e: AnimationEvent) => void;
    /** Fired when animation config is ready */
    onAnimationConfigReady?: (e: AnimationEvent) => void;
    /** Fired when animation data is ready */
    onAnimationDataReady?: (e: AnimationEvent) => void;
    /** Fired when animation DOM is loaded */
    onAnimationDOMLoaded?: (e: AnimationEvent) => void;
    /** Fired when animation encounters an error */
    onAnimationError?: (e: AnimationEvent) => void;
    /** Fired when animation data fails to load */
    onAnimationDataFailed?: (e: AnimationEvent) => void;
    /** Fired when animation images are loaded */
    onAnimationLoadedImages?: (e: AnimationEvent) => void;
    /** Additional props to pass to the video element */
    elementProps?: HTMLProps<HTMLVideoElement>;
}

enum LottieEvents {
    EnterFrame = 'enterFrame',
    LoopComplete = 'loopComplete',
    Complete = 'complete',
    SegmentStart = 'segmentStart',
    Destroy = 'destroy',
    ConfigReady = 'config_ready',
    DataReady = 'data_ready',
    DOMLoaded = 'DOMLoaded',
    Error = 'error',
    DataFailed = 'data_failed',
    LoadedImages = 'loaded_images',
}

const AppLottiePlayer = ({
    src,
    elementProps,
    onAnimationFrameEntered,
    onAnimationLoopComplete,
    onAnimationComplete,
    onAnimationSegmentStart,
    onAnimationDestroy,
    onAnimationConfigReady,
    onAnimationDataReady,
    onAnimationDOMLoaded,
    onAnimationError,
    onAnimationDataFailed,
    onAnimationLoadedImages,
    ...props
}: AppLottiePlayerProps) => {

    const domRef = useRef<HTMLDivElement>(null);

    const handleFrameEntered = (e: AnimationEvent) => {

        onAnimationFrameEntered?.(e);
    }

    const handleLoopComplete = (e: AnimationEvent) => {

        onAnimationLoopComplete?.(e);
    }

    const handleComplete = (e: AnimationEvent) => {

        onAnimationComplete?.(e);
    }

    const handleSegmentStart = (e: AnimationEvent) => {

        onAnimationSegmentStart?.(e);
    }

    const handleDestroy = (e: AnimationEvent) => {

        onAnimationDestroy?.(e);
    }

    const handleConfigReady = (e: AnimationEvent) => {

        onAnimationConfigReady?.(e);
    }

    const handleDataReady = (e: AnimationEvent) => {

        onAnimationDataReady?.(e);
    }

    const handleDOMLoaded = (e: AnimationEvent) => {

        onAnimationDOMLoaded?.(e);
    }

    const handleError = (e: AnimationEvent) => {

        onAnimationError?.(e);
    }

    const handleDataFailed = (e: AnimationEvent) => {

        onAnimationDataFailed?.(e);
    }

    const bindLottieEvents = (animation: AnimationItem) => {

        animation.addEventListener(LottieEvents.EnterFrame, handleFrameEntered);

        animation.addEventListener(LottieEvents.LoopComplete, handleLoopComplete);

        animation.addEventListener(LottieEvents.Complete, handleComplete);

        animation.addEventListener(LottieEvents.SegmentStart, handleSegmentStart);

        animation.addEventListener(LottieEvents.Destroy, handleDestroy);

        animation.addEventListener(LottieEvents.ConfigReady, handleConfigReady);

        animation.addEventListener(LottieEvents.DataReady, handleDataReady);

        animation.addEventListener(LottieEvents.DOMLoaded, handleDOMLoaded);

        animation.addEventListener(LottieEvents.Error, handleError);

        animation.addEventListener(LottieEvents.DataFailed, handleDataFailed);
    }

    const unbindLottieEvents = (animation: AnimationItem) => {

        animation.removeEventListener(LottieEvents.EnterFrame, handleFrameEntered);

        animation.removeEventListener(LottieEvents.LoopComplete, handleLoopComplete);

        animation.removeEventListener(LottieEvents.Complete, handleComplete);

        animation.removeEventListener(LottieEvents.SegmentStart, handleSegmentStart);

        animation.removeEventListener(LottieEvents.Destroy, handleDestroy);

        animation.removeEventListener(LottieEvents.ConfigReady, handleConfigReady);

        animation.removeEventListener(LottieEvents.DataReady, handleDataReady);

        animation.removeEventListener(LottieEvents.DOMLoaded, handleDOMLoaded);

        animation.removeEventListener(LottieEvents.Error, handleError);

        animation.removeEventListener(LottieEvents.DataFailed, handleDataFailed);

        animation.stop();

        animation.destroy();
    }

    useEffect(() => {

        const animation: AnimationItem = lottie.loadAnimation({
            ...props,
            animationData: JSON.parse(JSON.stringify(src)),
            renderer: LottieRenderer.SVG,
            container: domRef.current as any,
        })

        lottie.setQuality(1);

        bindLottieEvents(animation)

        return () => {

            if (animation) {

                unbindLottieEvents(animation);
            }
        }
    }, [])

    return (
        <div
            ref={domRef}
            {...elementProps}
        />
    )
}



export default AppLottiePlayer;