import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Hidden, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useAppRouting } from 'Hooks/useAppRouting';
import { useTranslations } from 'Hooks/useTranslations';
import useUrl from 'Hooks/useUrl';
import { testIds } from 'Scripts/cypressTestIds';
import { OverviewState } from 'Types/overviewTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { classes } from './style.css';
interface BackToPresentationButtonProps {
    trackAction?: string;
    trackScreen?: string;
    trackPosition?: string;
}

const BackToPresentationButton = ( { trackAction, trackScreen, trackPosition } : BackToPresentationButtonProps ) => {

	const history = useHistory();

	const urls = useUrl();

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

	const { presentationId } = useAppRouting();

	const toEditPresentation = () => {

		history.push(urls.editPresentation(presentationId));
	}

	const { translatePlaceholder } = useTranslations();

	return (
		<div className={classes.backContainer}>
			<Hidden mdDown >
				{presentationLoading && <Skeleton className={classes.buttonLoader} />}
				{!presentationLoading &&
					<span
						data-eltype="button"
						data-elaction={trackAction}
						data-elposition={trackPosition}
						data-elscreen={trackScreen}
						data-testid={testIds.BACK_TO_EDITOR}
					>
						<Button
							startIcon={<ArrowBackIcon className={classes.arrowBack} />}
							onClick={() => toEditPresentation()}
						>
							<Typography className={classes.backText}>
								{translatePlaceholder("BACK_TO_PRESENTATION")}
							</Typography>
						</Button>
					</span>
				}
			</Hidden>
		</div>
    );
};

BackToPresentationButton.defaultProps = {
	trackAction: 'back_to_editor',
	trackScreen: 'unspecified',
	trackPosition: 'unspecified'
};

export default BackToPresentationButton;