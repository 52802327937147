import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { alpha } from '@mui/system';
import React, { Suspense, useEffect } from 'react';

export const Loadable = (Component: any) => (props: any) => {

	return (
		<Suspense fallback={<ScreenLoadingPlaceholder />}>
			<Component {...props} />
		</Suspense>
	);
}

let ScreenLoadingPlaceholder = () => {

	const [opacity, setOpacity] = React.useState(0.01);

	useEffect(() => {

		setOpacity(.5);

		return () => {

			setOpacity(0.01);
		}

	}, [])

	return (
		<Grid
			sx={{
				height: '100%',
				width: '100%',
				textAlign: 'center',
				position: 'absolute',
				zIndex: 1337,
				opacity,
				backgroundColor: alpha("#ffffff", .3),
				transition: '250ms all',
			}}
			container
			justifyContent="center"
			alignItems="center"
			direction="row">
			<CircularProgress
				color="secondary"
				thickness={6}
				size={24}
			/>
		</Grid>
	);
};



export default ScreenLoadingPlaceholder;