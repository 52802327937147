import EditAnswersToggle from 'Components/beta/settings/presentation_summary/options/general/edit_answers/EditAnswersToggle';
import ParticipantEmojiToggle from 'Components/beta/settings/presentation_summary/options/general/emoji/ParticipantEmojiToggle';
import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import PlayMusicToggle from 'Components/beta/settings/presentation_summary/options/general/music/PlayMusicToggle';
import React from 'react';
import MultipleAnswerSetting from '../../../items/multiple_answers/MultipleAnswerSetting';
import SlideResultTypeToggle from '../../../items/result_type/SlideResultTypeToggle';
import TimerLimitSetting from '../../../items/time_limit/TimerLimitSetting';

const MultiplechoiceSettings = () => {

	return (
		<>
			<SlideResultTypeToggle />
			<MultipleAnswerSetting />
			<TimerLimitSetting />
			<LoginCodeEditor />
			<EditAnswersToggle/>
			<PlayMusicToggle/>
			<ParticipantEmojiToggle/>
		</>
	);
};

export default MultiplechoiceSettings;