import { AnyAction } from "redux"
import { AudioState } from "Types/audioTypes"

export const musicTypes = {
	WAITING_ROOM: 'WAITING_ROOM',
	TIMER_20_SECONDS: 'TIMER_20_SECONDS',
	TIMER_ANY: 'TIMER_ANY'
}

export const initialAppState: AudioState = {
	playMusic: false,
	musicType: musicTypes.WAITING_ROOM,
	volume: 1,
	fadeMusicOut: false,
}

export const audioReducer = (state: AudioState = initialAppState, action: AnyAction) => {
	switch (action.type) {
		case 'SET_FADE_MUSIC_OUT': {
			return {
				...state,
				fadeMusicOut: action.isFading
			}
		}
		case 'SET_PLAY_MUSIC': {
			return {
				...state,
				playMusic: action.shouldPlay
			}
		}
		case 'SET_VOLUME': {

			if (action.volume <= 0) {
				state.musicType = null
			}
			return {
				...state,
				volume: action.volume
			}
		}
		case 'SET_MUSIC_TYPE': {
			return {
				...state,
				musicType: action.musicType
			}
		}
		default:
			return state
	}
}
