import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box } from '@mui/material';
import QuoteSvgIcon from 'Assets/images/icons/text/quote_icon.svg';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import Html from 'Components/common/util/html/Html';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';


const QuoteSlideEditorPreview = ({ slide }: SlideConsumerComponent<ContentSlideType>) => {

    const { presentationStyle } = usePresentationStyles();


    const slideProperties = getSlideProperties(slide);

    return (
        <LayoutWrapper
            layoutType={slide.slideLayout}
            videoURL={slide.videoURL}
            imageURL={slide?.file?.base64 || slide?.filename}
            imageStyle={slide.filenameStyle}
            backgroundOpacity={slide.backgroundOpacity}
            backgroundColor={slide.backgroundColor}
            content={
                <Box
                    height="100%"
                    px={10}>
                    <div className={classes.quoteContent} >
                        <QuoteSvgIcon className={classes.quoteTag} style={{
                            fill: presentationStyle?.textColor,
                        }} />
                        <FullWidthContainer>
                            <Html
                                content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                fontSize={slideProperties.slideSettings.titleFontSize}
                            />
                        </FullWidthContainer>
                        <AccountCircleIcon className={classes.quoteAuthorIcon} sx={{
                            color: presentationStyle?.textColor,
                        }} />
                        <FullWidthContainer>
                            <Html
                                content={(slide.content || slideProperties.text.contentPlaceholder) as string}
                                fontSize={slideProperties.slideSettings?.contentFontSize as number}
                            />
                        </FullWidthContainer>
                    </div>
                </Box>
            }
        />
    );
};

export default QuoteSlideEditorPreview;