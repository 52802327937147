import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box } from '@mui/material';
import QuoteSvgIcon from 'Assets/images/icons/text/quote_icon.svg';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import Html from 'Components/common/util/html/Html';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { SlideConsumerComponent } from 'Types/appTypes';
import { PlaySlideType } from 'Types/playTypes';
import React from 'react';
import { classes } from './style.css';


const QuoteSlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {

    const { presentationStyle } = usePresentationStyles();


    const slideProperties = getPlaySlideProperties(slide);

    return (
        <>
            <LayoutWrapper
                layoutType={slide.slideLayout}
                videoURL={slide.videoURL}
                imageURL={slide?.file?.base64 || slide?.filename}
                imageStyle={slide.filenameStyle}
                backgroundOpacity={slide.backgroundOpacity}
                backgroundColor={slide.backgroundColor}
                content={
                    <Box
                        height="100%"
                        px={10}>
                        <div className={classes.quoteContent} >
                            <DelayedComponent
                                animationType={AnimationTypes.Grow}
                                animationDuration={250}
                                showAfter={250}
                                style={{
                                    width: '100%',
                                }}>
                                <div>
                                    <QuoteSvgIcon className={classes.quoteTag} style={{
                                        fill: presentationStyle?.textColor,
                                    }} />
                                    <FullWidthContainer>
                                        <Html
                                            content={slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                            fontSize={slideProperties.slideSettings.titleFontSize}
                                        />
                                    </FullWidthContainer>
                                </div>
                            </DelayedComponent>
                            <DelayedComponent
                                animationType={AnimationTypes.Grow}
                                animationDuration={250}
                                showAfter={500}
                                style={{
                                    width: '100%',
                                }}>
                                <div>
                                    <AccountCircleIcon className={classes.quoteAuthorIcon} sx={{
                                        color: presentationStyle?.textColor,
                                    }}/>
                                    <FullWidthContainer>
                                        <Html
                                            content={(slide.contentHtml || slide.content || slideProperties.text.getHtmlContentPlaceholder?.(slideProperties.text.contentPlaceholder)) as string}
                                            fontSize={slideProperties.slideSettings?.contentFontSize as number}
                                        />
                                    </FullWidthContainer>
                                </div>
                            </DelayedComponent>
                        </div>
                    </Box>
                }
            />
        </>
    );
};

export default QuoteSlideSession;