import ModeratorPanelToggle from 'Components/beta/settings/presentation_summary/options/advanced/moderator_panel/ModeratorPanelToggle';
import ParticipantEmojiToggle from 'Components/beta/settings/presentation_summary/options/general/emoji/ParticipantEmojiToggle';
import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import React from 'react';
import MessagesOnScreenSetting from '../../../items/messages_on_screen/MessagesOnScreenSetting';
import ParticipantResponsesSetting from '../../../items/participant_responses/ParticipantResponsesSetting';
import UpvoteToggle from '../../../items/upvoting_toggle/UpvoteToggle';


const OpenEndedSettings = () => {
	return (
		<>
			<ParticipantResponsesSetting />
			<MessagesOnScreenSetting />
			<UpvoteToggle />
			<LoginCodeEditor />
			<ParticipantEmojiToggle/>
			<ModeratorPanelToggle />
		</>
	);
};

export default OpenEndedSettings;