import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import { Box, Grid, Typography } from '@mui/material';
import FileUploadOption from 'Components/beta/editor/content/details/form/file/FileUploadOption';
import FullHeightContainer from 'Components/common/FullHeightContainer';
import { useHover } from 'Hooks/useHover';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';

const ImageSlideEditor = ({
    slide,
}: SlideConsumerComponent<ContentSlideType>) => {

    const { presentationStyle } = usePresentationStyles();

    const { translatePlaceholder } = useTranslations();

    const { hoverRef, isHovering } = useHover();

    return (
        <FullHeightContainer data-testid={testIds.SLIDE_TYPE_IMAGE}>
            <FileUploadOption
                show={Boolean(slide.filename) && isHovering}
                center
                uploadPlaceholder={(
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column">
                        <PhotoSizeSelectActualOutlinedIcon className={classes.addImageIcon} sx={{
                            color: presentationStyle?.textColor,
                        }}/>
                        <Box mb={3} />
                        <Typography variant="h2" className={classes.addImageText} sx={{
                            color: presentationStyle?.textColor,
                        }}>
                            {translatePlaceholder("CLICK_TO_ADD_IMAGE")}
                        </Typography>
                    </Box>
                )}
            />
            <Grid
                ref={hoverRef}
                className={classes.image}
                sx={{
                    backgroundSize: slide.filenameStyle,
                    backgroundImage: `url(${slide.filename})`,
                }}>
            </Grid>
        </FullHeightContainer>
    )
}

export default ImageSlideEditor;