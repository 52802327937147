import { Box } from '@mui/material';
import WysiwygEditorContainer from 'Atomic/organisms/WysiwygEditor/variants/WysiwygEditorContainer';
import FullHeightContainer from 'Components/common/FullHeightContainer';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import { WysiwygToolBarType } from 'Components/common/util/wysiwygEditor/WysiwygEditor';
import SlideOptionsListEditor from 'Components/slides/shared/components/editor/options/list/SlideOptionsListEditor';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import useSlideActions from 'Hooks/useSlideActions';
import { testIds } from 'Scripts/cypressTestIds';
import { SlideConsumerComponent } from 'Types/appTypes';
import { EditorSlideField, VoteType } from 'Types/presentationTypes';
import React from 'react';

const PointsSlideEditor = ({
    slide
}: SlideConsumerComponent<VoteType>) => {

    const { setSlideSetting, handlePressedTabOnVoteSlideTitle } = useSlideActions();

    const slideProperties = getSlideProperties(slide);

    return (
        <FullHeightContainer data-testid={testIds.QUESTION_TYPE_POINTS}>
            <LayoutWrapper
                interactive={true}
                imageStyle={slide.filenameStyle}
                backgroundOpacity={slide.backgroundOpacity}
                backgroundColor={slide.backgroundColor}
                layoutType={slide.slideLayout}
                title={<FullWidthContainer>
                    <Box p={4}>
                        <WysiwygEditorContainer
                            value={slide.title}
                            getPlaceholderHtml={slideProperties.text.getHtmlTitlePlaceholder}
                            updateValue={newTitle => setSlideSetting(EditorSlideField.Title, newTitle, slide.id)}
                            placeholderText={slideProperties.text.titlePlaceholder}
                            handleKeyDown={(e) => handlePressedTabOnVoteSlideTitle(e, slide)}
                            fontSize={slideProperties.slideSettings.titleFontSize}
                            toolbarType={WysiwygToolBarType.Extended}
                        />
                    </Box>
                </FullWidthContainer>}
                content={<SlideOptionsListEditor slide={slide as VoteType} />}
                imageURL={slide?.file?.base64 || slide?.filename}
                videoURL={slide?.videoURL}
            />
        </FullHeightContainer>
    );
};

export default PointsSlideEditor;