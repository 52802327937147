import { Box } from '@mui/system';
import { Editor } from '@tinymce/tinymce-react';
import WysiwygEditor, { WysiwygEditorIconProps, WysiwygEditorToolbarItem, WysiwygToolBarType } from 'Components/common/util/wysiwygEditor/WysiwygEditor';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { TestIdAttributes } from 'Scripts/measurementsGAHelper';
import React, { forwardRef, useState } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { classes } from './style.css';

export interface WysiwygFieldProps extends TestIdAttributes {
    /** The actual value */
    value: string;
    /** Function to update the value (rawValue is text without html text) */
    updateValue: (newValue: string, rawValue: string) => void;
    /** The placeholder text in case the field is empty */
    placeholderText: string;
    /** The placeholder html (Style wrapper such as fontWeight, textAlign etc) */
    getPlaceholderHtml: (placeholderText: string) => string;
    /** Toolbar type */
    toolbarType?: WysiwygToolBarType;
    /** Fixed font size for the field */
    fontSize: number;
    /** Font color override */
    fontColor?: string;
    /** Whether field is disabled */
    disabled?: boolean;
    /** Whether field is loading */
    loading?: boolean;
    /** On key down press */
    handleKeyDown?: (e: any, editor: TinyMCEEditor) => void;
    /** On key up press */
    handleKeyUp?: (e: any, editor: TinyMCEEditor) => void;
    /** On focus */
    handleFocus?: (e: any, editor: TinyMCEEditor) => void;
    /** On blur */
    handleBlur?: (e: any, editor: TinyMCEEditor) => void;
    /** On init */
    handleInit?: (editor: TinyMCEEditor) => void;
    /** On post-processing */
    handlePostProcess?: (e: any, editor: TinyMCEEditor) => void;
    /** On get content */
    handleGetContent?: (e: any, editor: TinyMCEEditor) => void;
    /** Extra icons */
    additionalIcons?: Array<WysiwygEditorIconProps>;
    /** Extra toolbar buttons */
    additionalButtons?: Array<WysiwygEditorToolbarItem>;
}

const AppWysiwygField = forwardRef(({
    value,
    updateValue,
    placeholderText,
    getPlaceholderHtml,
    toolbarType = WysiwygToolBarType.Simple,
    handleKeyDown,
    handleKeyUp,
    handleFocus,
    handleBlur,
    handleInit,
    handlePostProcess,
    handleGetContent,
    fontSize,
    fontColor,
    disabled,
    loading,
    additionalIcons,
    additionalButtons,
    ...props
}: WysiwygFieldProps, ref: React.ForwardedRef<Editor>) => {

    const editorRef = React.useRef<TinyMCEEditor | null>(null);

    const {
        presentationStyle,
    } = usePresentationStyles();

    fontColor = fontColor || presentationStyle?.textColor as string;

    const [initialized, setInitialized] = useState<boolean>(true);

    /**
     * Calls the updateValue function instead of setting placeholder text
     */
    const onImmediateChange = (newText: string, editor: TinyMCEEditor) => {

        const rawText = editor.getContent({ format: 'text' });

        updateValue?.(newText, rawText);
    }

    /**
     * Set the editor reference
     */
    const onInit = (_: any, editor: TinyMCEEditor) => {

        setInitialized(true);

        editorRef.current = editor;

        handleInit && handleInit(editor);

    }
   
    return (
        <Box
            className={classes.fieldWrapper}
            sx={{
                fontSize: `${fontSize}pt`,
                pointerEvents: initialized ? 'auto' : 'none',
                '& *[data-mce-placeholder]:not(.mce-visualblocks)::before': {
                    color: `${fontColor} !important`,
                }
            }}>
            <WysiwygEditor
                ref={ref}
                updateValue={updateValue}
                placeholderText={placeholderText}
                handleInit={onInit}
                getPlaceholderHtml={getPlaceholderHtml}
                handleOnKeyDown={handleKeyDown}
                handleOnKeyUp={handleKeyUp}
                handleOnImmediateChange={onImmediateChange}
                handlePostProcess={handlePostProcess}
                handleGetContent={handleGetContent}
                value={value}
                loading={loading}
                disabled={disabled}
                editorColor={fontColor}
                toolbarType={toolbarType}
                additionalIcons={additionalIcons}
                additionalButtons={additionalButtons}
                {...props}
            />
        </Box>
    )
})

export default AppWysiwygField;