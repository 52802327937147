import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import { Box, CardActionArea, Divider } from '@mui/material';
import { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppDropdownButton from 'Atomic/atoms/DropdownButton/AppDropdownButton';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import usePresentation from 'Hooks/usePresentation';
import { SessionRouteMatches, SessionRoutePrefix, useSessionRoutes } from 'Hooks/useSessionRoutes';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen } from 'Scripts/measurementsGAHelper';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { classes } from './style.css';

export interface PlayPresentationButtonProps {
    trackScreen?: string | null;
    trackPosition?: string | null;
    disabled?: boolean;
    presentationId: number;
    tooltip?: string | false;
}

const PlayPresentationButton = ({
    trackScreen,
    trackPosition,
    disabled,
    tooltip,
    presentationId,
}: PlayPresentationButtonProps) => {

    const history = useHistory();

    const { getRoute } = useSessionRoutes();

    const { attemptStartPresentation } = usePresentation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const playSession = async () => {

        setIsLoading(true);

        await attemptStartPresentation(presentationId);

        setIsLoading(false);
    }

    const previewPresentation = () => {

        history.push(getRoute({
            prefix: SessionRoutePrefix.Preview,
            presentationId,
        }, SessionRouteMatches.ExplanationSlide))
    }

    const { translatePlaceholder } = useTranslations();

    return (
        <CustomTooltip title={tooltip || ""}>
            <Box minWidth={72}>
                <AppDropdownButton
                    fullWidth
                    as={AppButtonVariant.Success}
                    disabled={disabled}
                    responsive
                    loading={isLoading}
                    startIcon={<PlayCircleFilledRoundedIcon
                        className={classes.startIcon}
                    />}
                    data-testid={testIds.PLAY_PRESENTATION_BUTTON}
                    dropdownComponent={(
                        <div>
                            <CardActionArea
                                disabled={disabled}
                                onClick={() => playSession()}
                                data-testid={testIds.PLAY_PRESENTATION_DROPDOWN_BUTTON}
                                data-elaction={DataElAction.StartSendsteps}
                                data-elscreen={trackScreen as DataElScreen}
                                data-elposition={trackPosition as DataElPosition}
                            >
                                <Box p={1}>
                                    <AppText as={AppTextVariant.BodyRegular} pb={1}>
                                        {translatePlaceholder("PLAY_PRESENTATION")}
                                    </AppText>
                                    <AppText as={AppTextVariant.CaptionLight}>
                                        {translatePlaceholder("PLAY_PRESENTATION_TO_AUDIENCE")}
                                    </AppText>
                                </Box>
                            </CardActionArea>
                            <Box py={1} px={1}>
                                <Divider />
                            </Box>
                            <CardActionArea
                                disabled={disabled}
                                data-testid={testIds.PREVIEW_PRESENTATION_DROPDOWN_BUTTON}
                                onClick={() => previewPresentation()}
                                data-elaction={DataElAction.ShowPreview}
                                data-elscreen={trackScreen as DataElScreen}
                                data-elposition={trackPosition as DataElPosition}
                            >
                                <Box p={1}>
                                    <AppText as={AppTextVariant.BodyRegular} pb={1}>
                                        {translatePlaceholder("PREVIEW_PRESENTATION")}
                                    </AppText>
                                    <AppText as={AppTextVariant.CaptionLight}>
                                        {translatePlaceholder("TEST_AND_TRY_PRESENTATION")}
                                    </AppText>
                                </Box>
                            </CardActionArea>
                        </div>
                    )}
                >
                    <Box mx={-.5} mr={-1} style={{ textTransform: 'uppercase'}}>
                        {translatePlaceholder("PLAY")}
                    </Box>
                </AppDropdownButton>
            </Box>
        </CustomTooltip>
    );
};

export default PlayPresentationButton;