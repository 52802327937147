import ParticipantEmojiToggle from 'Components/beta/settings/presentation_summary/options/general/emoji/ParticipantEmojiToggle';
import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import React from 'react';

const QuoteSlideSettings = () => {

    return <>
            <LoginCodeEditor />
            <ParticipantEmojiToggle/>
        </>
};
export default QuoteSlideSettings;

