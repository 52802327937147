import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Grid, IconButton, Skeleton } from '@mui/material';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppInput from 'Atomic/atoms/Input/AppInput';
import AppSlideTemplate from 'Atomic/atoms/SlideTemplate/AppSlideTemplate';
import AppSvgIcon, { AppSvgIconType } from 'Atomic/atoms/Svg/AppSvgIcon';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import AppSlideSelectionWrapper from 'Atomic/molecules/SlideSelectionWrapper/AppSlideSelectionWrapper';
import AppSwipeComponent from 'Atomic/molecules/SwipeComponent/AppSwipeComponent';
import { SnackbarVariant } from 'Components/app/snackbar/SnackbarWrapper';
import ErrorText from 'Components/beta/common/text/error/ErrorText';
import { useAppRouting } from 'Hooks/useAppRouting';
import { Features } from 'Hooks/useFeatures';
import { useLayout } from 'Hooks/useLayout';
import usePresentation from 'Hooks/usePresentation';
import { useTranslations } from 'Hooks/useTranslations';
import useUrl from 'Hooks/useUrl';
import { get, getErrorMessageFromApiResponse, post } from 'Scripts/api';
import { testIds } from 'Scripts/cypressTestIds';
import { ApiErrorCodes } from 'Scripts/globals';
import { DataElAction, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';
import { useWindowSize } from 'Scripts/screenSizeHook';
import { getSortedSlideKeysBySlideIndex } from 'Scripts/slideHelper';
import { AIState, AiContentCreatorStep } from 'Types/aiTypes';
import { ScreenSizes } from 'Types/appTypes';
import { PresentationState, SlideGenerationTemplate, SlideType } from 'Types/presentationTypes';
import { SlideTypes } from 'Types/slideTypes';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { classes } from './style.css';

const amountOfTemplatesPerPage = 2;
const minKeywordsTextLength = 3;
const templateWidth = ScreenSizes.Width1080P / 5;
const templateHeight = ScreenSizes.Height1080P / 5;

export const maxAiContentCreatorKeywordsTextLength = 200;

const AiContentCreatorContent = () => {

	const slides = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slides);
    /** For syncing purposes */
    const aiContentCreatorKeywords = useSelector((state: RootStateOrAny) => (state.aiReducer as AIState).aiContentCreatorKeywords);
    /** For syncing purposes */
    const aiContentCreatorStep = useSelector((state: RootStateOrAny) => (state.aiReducer as AIState).aiContentCreatorStep);

    const [keywordsText, setKeywordsText] = useState<string>('');

    const [selectedSlideType, setSelectedSlideType] = useState<SlideTypes | null>(null);

    const [templatePageIndex, setTemplatePageIndex] = useState<number>(0);

    const [keywordsError, setKeywordsError] = useState<string | null>(null);

    const [contentGenerating, setContentGenerating] = useState<boolean>(false);

    const [claimTemplatesLoading, setClaimTemplatesLoading] = useState<boolean>(false);

    const [step, setStep] = useState<AiContentCreatorStep>(AiContentCreatorStep.AddKeywords);

    const [templates, setTemplates] = useState<Array<SlideGenerationTemplate>>([]);

    const [selectedTemplateIndices, setSelectedTemplateIndices] = useState<Array<number>>([]);


    const { presentationId } = useAppRouting();

    const history = useHistory();

    const urls = useUrl();

    const { fetchPresentationDetails } = usePresentation();

    const {
        setShowMissingFeature,
        closeSelectSlide,
    } = useLayout();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleKeywordsUpdate = (text: string) => {

        const newText = text.slice(0, maxAiContentCreatorKeywordsTextLength);

        setKeywordsText(newText);

        setKeywordsError(null);
    }

    const getAiSlideTemplate = async (topic: string) => {

        const params = new URLSearchParams({
            topic,
            type: selectedSlideType as SlideTypes,
        });

        return await get(`generate/ai-slide?${params}`);
    }

    const goToSelectTypeStep = () => {

        if (!keywordsText) {

            checkError(keywordsText);

            return;
        }

        setStep(AiContentCreatorStep.SelectType);
    }

    const goToSelectSlidesStep = async () => {

        try {

            setContentGenerating(true);

            const response = await getAiSlideTemplate(keywordsText);

            if (response.error) {

                const errorMessage = getErrorMessageFromApiResponse(response.error);

                setContentGenerating(false);

                setStep(AiContentCreatorStep.AddKeywords);

                enqueueSnackbar(errorMessage, { variant: SnackbarVariant.Error });

                return;
            }

            setStep(AiContentCreatorStep.AddSlide);

            setTemplates([response.template]);

        } catch (error) {

            console.log({
                error
            })
        }
    }

    const checkError = (value: string) => {

        if (value.trim().length < minKeywordsTextLength) {

            setKeywordsError(translatePlaceholder("PLEASE_ENTER_AT_LEAST_X_CHARACTERS", {
                amount: minKeywordsTextLength
            }));

        } else {

            setKeywordsError(null);
        }
    }

    const fetchNextTemplate = async () => {

        const response = await getAiSlideTemplate(keywordsText);

        if (response.template) {

            setTemplates((currentTemplates) => [
                ...currentTemplates,
                response.template,
            ])
        }
    }

    const goBackToSlideTypeSelectionStep = () => {

        setStep(AiContentCreatorStep.SelectType);

        setTemplates([]);

        setSelectedTemplateIndices([]);

        setTemplatePageIndex(0);

        closeSnackbar();
    }

    const goBackToAddKeywordsStep = () => {

        setStep(AiContentCreatorStep.AddKeywords);

        setTemplates([]);

        setSelectedTemplateIndices([]);

        setTemplatePageIndex(0);

        closeSnackbar();
    }

    const addSelectedTemplatesToPresentation = async () => {

        const selectedTemplates: Array<SlideGenerationTemplate> = selectedTemplateIndices.map(index => templates[index]);

        try {

            setClaimTemplatesLoading(true);

            const generateResponse = await post('generate/ai-slides-from-templates', {
                presentationId,
                templates: selectedTemplates,
            });

            if (generateResponse.error) {

                if (generateResponse.error === ApiErrorCodes.AiSlideLimitReached)
                {
                    setClaimTemplatesLoading(false);

                    setShowMissingFeature(Features.UNLIMITED_AI_SLIDES);

                    return;
                }

                const errorMessage = getErrorMessageFromApiResponse(generateResponse.error);

                enqueueSnackbar(errorMessage, {
                    variant: SnackbarVariant.Error
                });

                setClaimTemplatesLoading(false);

                return;
            }

            await fetchPresentationDetails(presentationId);

            if (generateResponse.slideIds) {

                const [firstAddedSlideId] = generateResponse.slideIds;

                history.push(urls.editSlide(presentationId, firstAddedSlideId))
            }

            closeSnackbar();

            enqueueSnackbar(translatePlaceholder("X_SLIDES_ADDED_TO_PRESENTATION", {
                amount: selectedTemplates.length
            }), {
                variant: SnackbarVariant.Success
            });

            setClaimTemplatesLoading(false);

            closeSelectSlide();

        } catch (error) {

            console.warn({ error })

            setClaimTemplatesLoading(false);
        }
    }

    const decrementTemplatePageIndex = () => {

        setTemplatePageIndex(currentIndex => Math.max(currentIndex - 1));
    }

    const incrementTemplatePageIndex = () => {

        setTemplatePageIndex(currentIndex => currentIndex + 1);
    }

    const currentPageTemplates = templates.slice(
        (templatePageIndex * amountOfTemplatesPerPage),
        (templatePageIndex * amountOfTemplatesPerPage) + amountOfTemplatesPerPage
    );

    useEffect(() => {

        if ((
            /** If we're on the right step */
            step === AiContentCreatorStep.AddSlide &&
            /** If we haven't filled the page with templates  */
            currentPageTemplates.length < amountOfTemplatesPerPage &&
            /** If we're on the first page with the initial template loaded or on a page index higher than 0 */
            (currentPageTemplates.length > 0 || templatePageIndex > 0) &&
            /** If we're not claiming the selected templates */
            !claimTemplatesLoading
        )) {

            setContentGenerating(true);

            fetchNextTemplate();

        } else {

            setContentGenerating(false);
        }

    }, [step, currentPageTemplates.length])

    useEffect(() => {

        if (contentGenerating) {

            enqueueSnackbar('Generating content...', {
                variant: SnackbarVariant.WarningLoading,
                persist: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                },
            });

            return;
        }

        closeSnackbar();

    }, [contentGenerating])

    useEffect(() => {

        setKeywordsText(aiContentCreatorKeywords || keywordsText);

    }, [aiContentCreatorKeywords]);

    useEffect(() => {

        setStep(aiContentCreatorStep || step);

    }, [aiContentCreatorStep]);

    const getSlideValueByProperty = (type: SlideTypes, property: string) => {

        const slideKeys: number[] = getSortedSlideKeysBySlideIndex(slides);

        for (const slideKey of slideKeys) {

            const slide = slides[slideKey];

            if (slide.type === type) {

                return slide[property as keyof SlideType];
            }
        }

        return null;
    }

    useEffect(() => {

        const introSlideTitle = getSlideValueByProperty(SlideTypes.TitleAndSubtitle, "title");

        const titleSlideTitle = getSlideValueByProperty(SlideTypes.Title, "title");

        const newKeywordsText = (introSlideTitle || titleSlideTitle);

        if (Boolean(newKeywordsText) && !aiContentCreatorKeywords) {

            const dummyElement = document.createElement('div');

            dummyElement.innerHTML = newKeywordsText;

            setKeywordsText(dummyElement.textContent || dummyElement.innerText || '');
        }

    }, [])

    const addOrRemoveFromSelectedTemplates = (indexValue: number) => {

        const currentItemIndex = selectedTemplateIndices.indexOf(indexValue);

        if (currentItemIndex === -1) {

            setSelectedTemplateIndices(currentIndices => ([
                ...currentIndices,
                indexValue,
            ]));

            return;
        }

        const newArray = [...selectedTemplateIndices].filter(value => value !== indexValue);

        setSelectedTemplateIndices(newArray);
    }

    const containerRef = useRef<HTMLElement>(null);

    const containerWidth = useMemo(() => {

        return containerRef?.current?.clientWidth || "100%";

    }, [containerRef?.current?.clientWidth])

    useWindowSize();

    const { translatePlaceholder } = useTranslations();

    return (
        <Box
            py={5}
            px={4.5}>
            <Box
                height="100%"
                width="100%"
                ref={containerRef}
                flexDirection="column"
                display="flex">
                <AppText
                    noWrap={false}
                    as={AppTextVariant.H6Bold}
                    pb={1}>
                    {translatePlaceholder("WELCOME")}
                </AppText>

                <AppText
                    noWrap={false}
                    as={AppTextVariant.BodyRegular}
                    pb={6}>
                    {translatePlaceholder("AI_CONTENT_CREATOR_EXPLANATION")}
                </AppText>
                <Box overflow="hidden">
                    <AppSwipeComponent
                        width={containerWidth}
                        selectedIndex={Object.keys(AiContentCreatorStep).indexOf(step)}
                        items={[
                            <div>
                                <AppText
                                    noWrap={false}
                                    as={AppTextVariant.H6Bold}>
                                    {translatePlaceholder("PLEASE_ENTER_TOPIC_KEYWORDS")}
                                </AppText>
                                <AppText
                                    pb={.5}
                                    display="flex"
                                    justifyContent="flex-end"
                                    as={AppTextVariant.BodyLight}
                                >
                                    {keywordsText.length}/{maxAiContentCreatorKeywordsTextLength}
                                </AppText>

                                <AppInput
                                    error={Boolean(keywordsError)}
                                    value={keywordsText}
                                    disabled={contentGenerating}
                                    data-testid={testIds.AI_CONTENT_CREATOR_KEYWORDS_INPUT}
                                    data-elaction={DataElAction.AiContentCreatorInput}
                                    data-elscreen={DataElScreen.AiContentCreator}
                                    onBlur={e => checkError(e.target.value)}
                                    onChange={e => handleKeywordsUpdate(e.target.value)}
                                    placeholder={translatePlaceholder("ENTER_SOME_KEYWORDS")}
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                />
                                {Boolean(keywordsError) && (
                                    <ErrorText error={keywordsError as string} />
                                )}
                                <AppText
                                    pt={1}
                                    noWrap={false}
                                    as={AppTextVariant.CaptionLight}>
                                    {translatePlaceholder("AI_CONTENT_CREATOR_DISCLAIMER")}
                                </AppText>
                                <Box
                                    pt={1}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <AppButton
                                        data-elaction={DataElAction.AiContentCreatorToSelectTypeStep}
                                        data-elscreen={DataElScreen.AiContentCreator}
                                        data-testid={testIds.AI_CONTENT_CREATOR_TO_SELECT_TYPE_STEP}
                                        onClick={() => goToSelectTypeStep()}
                                        disabled={(
                                            !keywordsText ||
                                            Boolean(keywordsText.trim().length < minKeywordsTextLength)
                                        )}
                                        as={AppButtonVariant.Primary}>
                                        {translatePlaceholder("NEXT")}
                                    </AppButton>
                                </Box>
                            </div>,
                            <div>
                                <AppText
                                    noWrap={false}
                                    pb={5.5}
                                    as={AppTextVariant.H6Bold}>
                                    {translatePlaceholder("SELECT_TYPE_OF_CONTENT")}
                                </AppText>
                                <Box display="flex" overflow="auto">
                                    <AppSlideSelectionWrapper
                                        mr={2}
                                        onClick={() => setSelectedSlideType(SlideTypes.Content)}
                                        selected={selectedSlideType === SlideTypes.Content}
                                        disabled={contentGenerating}
                                        data-elscreen={DataElScreen.AiContentCreator}
                                        data-eltype={DataElType.Button}
                                        data-elaction={`${DataElAction.AiContentCreatorSelectType}_${SlideTypes.Content}`}
                                        data-testid={`${testIds.AI_CONTENT_CREATOR_SELECT_TYPE}_${SlideTypes.Content}`}
                                    >
                                        <div
                                            className={classes.slideTypeSelection}
                                            style={{ backgroundColor: '#6ED675' }}>
                                            <AppSvgIcon type={AppSvgIconType.SlideExampleTitleAndText} />
                                            <AppText
                                                pt={1}
                                                as={AppTextVariant.BodyRegular}>
                                                {translatePlaceholder("TITLE_AND_TEXT")}
                                            </AppText>
                                        </div>
                                    </AppSlideSelectionWrapper>
                                    <AppSlideSelectionWrapper
                                        mr={2}
                                        onClick={() => setSelectedSlideType(SlideTypes.Wordcloud)}
                                        selected={selectedSlideType === SlideTypes.Wordcloud}
                                        disabled={contentGenerating}
                                        data-elscreen={DataElScreen.AiContentCreator}
                                        data-eltype={DataElType.Button}
                                        data-elaction={`${DataElAction.AiContentCreatorSelectType}_${SlideTypes.Wordcloud}`}
                                        data-testid={`${testIds.AI_CONTENT_CREATOR_SELECT_TYPE}_${SlideTypes.Wordcloud}`}
                                    >
                                        <div
                                            className={classes.slideTypeSelection}
                                            style={{ backgroundColor: '#D44E45' }}>
                                            <AppSvgIcon type={AppSvgIconType.SlideExampleWordcloud} />
                                            <AppText
                                                pt={1}
                                                style={{ color: '#fff' }}
                                                as={AppTextVariant.BodyRegular}>
                                                {translatePlaceholder("WORDCLOUD")}
                                            </AppText>
                                        </div>
                                    </AppSlideSelectionWrapper>
                                    <AppSlideSelectionWrapper
                                        mr={2}
                                        onClick={() => setSelectedSlideType(SlideTypes.Quiz)}
                                        selected={selectedSlideType === SlideTypes.Quiz}
                                        disabled={contentGenerating}
                                        data-elscreen={DataElScreen.AiContentCreator}
                                        data-eltype={DataElType.Button}
                                        data-elaction={`${DataElAction.AiContentCreatorSelectType}_${SlideTypes.Quiz}`}
                                        data-testid={`${testIds.AI_CONTENT_CREATOR_SELECT_TYPE}_${SlideTypes.Quiz}`}
                                    >
                                        <div
                                            className={classes.slideTypeSelection}
                                            style={{ backgroundColor: '#4AAAE6' }}>
                                            <AppSvgIcon type={AppSvgIconType.SlideExampleQuiz} />
                                            <AppText
                                                pt={1}
                                                style={{ color: '#fff' }}
                                                as={AppTextVariant.BodyRegular}>
                                                {translatePlaceholder("QUIZ")}
                                            </AppText>
                                        </div>
                                    </AppSlideSelectionWrapper>
                                </Box>
                                <Box display="flex" justifyContent="space-between" pt={2}>
                                    <AppButton
                                        className={classes.actionButton}
                                        as={AppButtonVariant.Outlined}
                                        data-elaction={DataElAction.AiContentCreatorBackToKeywordsStep}
                                        data-elscreen={DataElScreen.AiContentCreator}
                                        data-eltype={DataElType.Button}
                                        data-testid={testIds.AI_CONTENT_CREATOR_BACK_TO_KEYWORDS_STEP}
                                        disabled={contentGenerating}
                                        onClick={() => goBackToAddKeywordsStep()}
                                    >
                                        {translatePlaceholder("BACK")}
                                    </AppButton>
                                    <Box py={1} />
                                    <AppButton as={AppButtonVariant.Primary}
                                        onClick={() => goToSelectSlidesStep()}
                                        disabled={!Boolean(selectedSlideType) || contentGenerating}
                                        loading={contentGenerating}
                                        data-elaction={DataElAction.AiContentCreatorToSelectSlidesStep}
                                        data-elscreen={DataElScreen.AiContentCreator}
                                        data-eltype={DataElType.Button}
                                        data-testid={testIds.AI_CONTENT_CREATOR_TO_SELECT_SLIDES_STEP}
                                    >
                                        {translatePlaceholder("NEXT")}
                                    </AppButton>
                                </Box>
                            </div>,
                            <div>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    flexDirection={{
                                        xs: "column",
                                        sm: "row"
                                    }}
                                    pb={4}>
                                    <AppText
                                        noWrap={false}
                                        pl={1}
                                        as={AppTextVariant.H6Bold}>
                                        {translatePlaceholder("SELECT_SLIDES_OR_QUESTIONS")}
                                    </AppText>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center">
                                    <Box pb={2}>
                                        <IconButton
                                            data-elaction={DataElAction.AiContentCreatorPreviousTemplatePage}
                                            data-elscreen={DataElScreen.AiContentCreator}
                                            data-eltype={DataElType.Button}
                                            data-testid={testIds.AI_CONTENT_CREATOR_PREVIOUS_PAGE_BUTTON}
                                            color="primary"
                                            className={classes.navigationIcon}
                                            disabled={templatePageIndex === 0 || claimTemplatesLoading}
                                            onClick={() => decrementTemplatePageIndex()}
                                        >
                                            <ChevronLeft />
                                        </IconButton>
                                    </Box>
                                    <Box overflow="hidden">
                                        <AppSwipeComponent
                                            width="100%"
                                            selectedIndex={templatePageIndex}
                                            items={Array.from({ length: Math.ceil(templates.length / amountOfTemplatesPerPage) + 1 }).map((_, itemIndex) => {

                                                const indexOffset = (itemIndex * amountOfTemplatesPerPage);

                                                const itemPageTemplates = templates.slice(
                                                    indexOffset,
                                                    indexOffset + amountOfTemplatesPerPage
                                                );

                                                return (
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        display="flex"
                                                        justifyContent="center">
                                                        {itemPageTemplates.map((template, index) => (
                                                            <AppSlideSelectionWrapper
                                                                onClick={() => addOrRemoveFromSelectedTemplates(index + indexOffset)}
                                                                selected={selectedTemplateIndices.includes(index + indexOffset)}
                                                                disabled={claimTemplatesLoading}
                                                                data-eltype={DataElType.Button}
                                                                data-elscreen={DataElScreen.AiContentCreator}
                                                                data-elaction={`${DataElAction.AiContentCreatorSelectSlide}_${index + indexOffset}`}
                                                                data-testid={`${testIds.AI_CONTENT_CREATOR_SLIDE_TEMPLATE}_${index + indexOffset}`}
                                                                key={index + indexOffset}
                                                                index={index + indexOffset + 1}>
                                                                <AppSlideTemplate
                                                                    template={template}
                                                                    height={templateHeight}
                                                                    width={templateWidth}
                                                                />
                                                            </AppSlideSelectionWrapper>
                                                        ))}
                                                        {Array.from({ length: amountOfTemplatesPerPage - itemPageTemplates.length }).map((_, index) => (
                                                            <AppSlideSelectionWrapper
                                                                loading={!claimTemplatesLoading}
                                                                key={index + indexOffset}
                                                                index={index + indexOffset + 1}>
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    height={templateHeight}
                                                                    width={templateWidth}
                                                                />
                                                            </AppSlideSelectionWrapper>
                                                        ))}
                                                    </Grid>
                                                )
                                            })}
                                        />
                                    </Box>
                                    <Box pb={2}>
                                        <IconButton
                                            data-elaction={DataElAction.AiContentCreatorNextTemplatePage}
                                            data-elscreen={DataElScreen.AiContentCreator}
                                            data-eltype={DataElType.Button}
                                            data-testid={testIds.AI_CONTENT_CREATOR_NEXT_PAGE_BUTTON}
                                            color="primary"
                                            className={classes.navigationIcon}
                                            disabled={currentPageTemplates.length < amountOfTemplatesPerPage || claimTemplatesLoading}
                                            onClick={() => incrementTemplatePageIndex()}
                                        >
                                            <ChevronRight />
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Box
                                    px={1}
                                    pt={2}
                                    pb={.5}
                                    display="flex"
                                    flexDirection={{
                                        xs: "column",
                                        sm: "row",
                                    }}
                                    alignItems="center"
                                    justifyContent="space-between">
                                    <AppButton
                                        className={classes.actionButton}
                                        as={AppButtonVariant.Outlined}
                                        data-elaction={DataElAction.AiContentCreatorBackToSelectTypeStep}
                                        data-elscreen={DataElScreen.AiContentCreator}
                                        data-eltype={DataElType.Button}
                                        data-testid={testIds.AI_CONTENT_CREATOR_BACK_TO_SELECT_TYPE_STEP}
                                        disabled={claimTemplatesLoading}
                                        onClick={() => goBackToSlideTypeSelectionStep()}
                                    >
                                        {translatePlaceholder("BACK")}
                                    </AppButton>
                                    <Box py={1} />
                                    <AppButton
                                        className={classes.actionButton}
                                        data-elaction={DataElAction.AiContentCreatorAddToPresentation}
                                        data-elscreen={DataElScreen.AiContentCreator}
                                        data-testid={testIds.AI_CONTENT_CREATOR_ADD_TO_PRESENTATION_BUTTON}
                                        loading={claimTemplatesLoading}
                                        onClick={() => addSelectedTemplatesToPresentation()}
                                        disabled={!selectedTemplateIndices.length || claimTemplatesLoading}
                                        as={AppButtonVariant.Primary}
                                    >
                                        {translatePlaceholder("ADD_TO_MY_PRESENTATION")}
                                    </AppButton>
                                </Box>
                            </div>
                        ]}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default AiContentCreatorContent;