import { AnyAction } from "redux";
import { FeatureState } from "Types/featureTypes";

export const initialState: FeatureState = {
    availableFeatures: null,
    planInfo: null,
    planFeaturesLoading: false,
    subscriptionDataLoading: false,
}

export function featureReducer(state : FeatureState = initialState, action : AnyAction)
{
    switch (action.type)
    {
        case 'SET_SUBSCRIPTION_DATA_LOADING': {

            return {
                ...state,
                subscriptionDataLoading: action.isLoading,
            }
        }
        case 'SET_PLAN_FEATURES_LOADING': {
            return {
                ...state,
                planFeaturesLoading: action.isLoading,
            }
        }
        case 'SET_AVAILABLE_FEATURES': {
            return {
                ...state,
                availableFeatures: [
                    ...action.availableFeatures
                ]
            }
        }
        case 'SET_PLAN_INFO': {
            return {
                ...state,
                planInfo: action.planInfo
            }
        }
        default:
            return state;
    }
}