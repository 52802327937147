import { Box } from '@mui/material';
import { useWindowSize } from 'Scripts/screenSizeHook';
import React, { useEffect, useRef, useState } from 'react';
import { classes } from './style.css';
interface Props {
    items: Array<React.ReactNode>;
    selectedIndex: number;
    width?: number | string;
}

const multiplyWidth = (width: number | string, factor: number) => {

    if (typeof width === 'string' && width.includes('%')) {

        const [widthValue] = width.split('%');

        return `${Number(widthValue) * factor}%`;
    }

    return (width as number) * factor;
}

const AppSwipeComponent = ({
    items,
    selectedIndex,
    width,
}: Props) => {

    const swipeContainerRef = useRef<HTMLElement>(null);

    const [ containerWidth, setContainerWidth ] = useState<number>(0);

    const windowSizeWidth = useWindowSize().width;

    const itemOffsetPercentage = (selectedIndex * 100);


    const setTransitionEffect = (transition: string) => {

        if (swipeContainerRef.current) {

            (swipeContainerRef.current as HTMLElement).style.transition = transition;
        }
    }

    useEffect(() => {

        setContainerWidth(width || windowSizeWidth);

    }, [
        swipeContainerRef.current,
        width,
        windowSizeWidth,
    ])

    useEffect(() => {

        setTransitionEffect('');

        requestAnimationFrame(() => {

            setTransitionEffect('transform 0.25s ease-in-out');

            window.dispatchEvent(new Event('resize'));
        });

    }, [items.length])

    return (
        <Box
            ref={swipeContainerRef}
            className={classes.swipeWrapper}
            sx={{
                transform: `translateX(-${itemOffsetPercentage / items.length}%)`,
                width: multiplyWidth((containerWidth || window.innerWidth), items.length),
            }}>
            {items.map((item, index) => (
                <Box key={index} sx={{
                    width: containerWidth || window.innerWidth
                }}>
                    {item}
                </Box>
            ))}
        </Box>
    )
}

export default AppSwipeComponent