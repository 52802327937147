import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { classes } from './style.css';

interface SlideSettingButtonProps {
	value: number|string;
	isActive: boolean;
	onClick: () => void;
}

const SlideSettingButton = ({ value, isActive, onClick, ...props } : SlideSettingButtonProps) => {

	const handleClick = () => {

		typeof onClick === 'function' && onClick();
	}

	return (
		<Button
			className={`${classes.responseValueButton} ${isActive && classes.activeButton}`}
			onClick={() => handleClick()}
			{...props}>
			<Typography variant="h6">
				{value}
			</Typography>
		</Button>
	);
};

export default SlideSettingButton