import { refreshPassthrough, request, sendshakeCall } from "./xhr";

export type APIRequestBody = {};

export type APIResponseData = {
    error?: string;
    message?: string;
    [otherProps: string]: any;
};
export type SendshakeData = {
    request: string;
    [key: string]: any;
};

export interface ApiError {
    [key: string]: Array<string>;
}

export const getErrorMessageFromApiResponse = (message: string): string => {

    let errorMessage = message;

    try {
        /** Error messages are either passed as JSON.stringified array of key => [errors] or a simple string */
        const parsedJsonMessage = JSON.parse(message) as ApiError;

        const [ firstErrorArray ] = Object.values(parsedJsonMessage);

        const [ firstApiErrorMessage ] = firstErrorArray;

        errorMessage = firstApiErrorMessage;

    } catch (error) {

        /**
         * Don't do anything, landing here this means the JSON.parse() failed
         * and the response is a simple string instead of a stringified JSON object
         */

    } finally {

        return errorMessage;
    }
}

export const post = async (route: string, body?: APIRequestBody, overwriteBaseUrl?: boolean) : Promise<any> => {
    return await refreshPassthrough({
        method: 'POST',
        route,
        body,
        overwriteBaseUrl,
    }, request);
}

export const get = async (route: string, body?: APIRequestBody, overwriteBaseUrl?: boolean) : Promise<any> => {
    return await refreshPassthrough({
        method: 'GET',
        route,
        body,
        overwriteBaseUrl,
    }, request);
}

export const put = async (route: string, body?: APIRequestBody, overwriteBaseUrl?: boolean): Promise<any> => {
    return await refreshPassthrough({
        method: 'PUT',
        route,
        body,
        overwriteBaseUrl,
    }, request);
}

export const del = async (route: string, body?: APIRequestBody, overwriteBaseUrl?: boolean): Promise<any> => {
    return await refreshPassthrough({
        method: 'DELETE',
        route,
        body,
        overwriteBaseUrl,
    }, request);
}

export const patch = async (route: string, body?: APIRequestBody, overwriteBaseUrl?: boolean): Promise<any> => {
    return await refreshPassthrough({
        method: 'PATCH',
        route,
        body,
        overwriteBaseUrl,
    }, request);
}

export const sendshakeRequest = async (data : SendshakeData) => {

    return await refreshPassthrough(data, sendshakeCall);
}