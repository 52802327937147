
const possibleChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const generateUniqueKey = () : number => {
	return new Date().valueOf();
}

export const generateString = (length: number) : string =>  {
	let text = '';
	new Array(length).fill(null).map(() => {
		text += possibleChars[(Math.random() * possibleChars.length) >> 0];
	});
	return text;
}