import { TablePaginationData } from "Components/common/table/AppTable"

export const setNewsFeedPageLoading = (isLoading: boolean) => ({
    type: 'SET_NEWS_FEED_PAGE_LOADING',
    isLoading,
})

export const setNewsFeedPageIndex = (pageIndex: number) => ({
    type: 'SET_NEWS_FEED_PAGE_INDEX',
    pageIndex,
})

export const setNewsFeedTableData = (data: TablePaginationData, shouldAppend: boolean) => ({
    type: 'SET_NEWS_FEED_TABLE_DATA',
    data,
    shouldAppend,
})

export const setNewsFeedPageSize = (pageSize: number) => ({
    type: 'SET_NEWS_FEED_PAGE_SIZE',
    pageSize,
})