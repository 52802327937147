import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMediaQuery } from '@mui/material';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import usePresentation from 'Hooks/usePresentation';
import { useTranslations } from 'Hooks/useTranslations';
import useUrl from 'Hooks/useUrl';
import { testIds } from 'Scripts/cypressTestIds';
import { OverviewState } from 'Types/overviewTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface BackToOverviewButtonProps {
    as?: AppButtonVariant,
    trackAction?: string;
    trackScreen?: string;
    trackPosition?: string;
}

const BackToOverviewButton = ( {as = AppButtonVariant.Text, trackAction, trackScreen, trackPosition } : BackToOverviewButtonProps ) => {

    const history = useHistory();

    const urls = useUrl();

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

    const {
        setPageIndex,
    } = usePresentation();

    const toOverview = () => {

        setPageIndex(0);

        history.push(urls.presentationOverview);
    }

    const { translatePlaceholder } = useTranslations();

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("lg"));

    return (
        <AppButton
            as={as}
            responsive
            loading={presentationLoading}
            startIcon={<ArrowBackIcon />}
            onClick={() => toOverview()}
            data-testid={testIds.BUTTON_BACK_TO_OVERVIEW}
            data-elaction={trackAction}
            data-elposition={trackPosition}
            data-elscreen={trackScreen}
        >
            {isSmallScreen && translatePlaceholder("MY_LIBRARY")}
            {!isSmallScreen && translatePlaceholder("BACK_TO_MY_LIBRARY")}
        </AppButton>
    );
};

BackToOverviewButton.defaultProps = {
    trackAction: 'back_to_overview',
    trackScreen: 'unspecified',
    trackPosition: 'unspecified'
};

export default BackToOverviewButton;