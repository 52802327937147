import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import PlaySlideTitlePreview from 'Atomic/molecules/PlaySlideTitlePreview/PlaySlideTitlePreview';
import Html from 'Components/common/util/html/Html';
import SessionLoginInfo from 'Components/play/templates/geometry_template/shared_components/session_login_info/SessionLoginInfo';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import WordCloudComponent from 'Components/wordcloud/WordCloudComponent';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useSlideNavigation } from 'Hooks/useSlideNavigation';
import { useInterval } from 'Scripts/intervalHook';
import { closeMessageRound, openMessageRound } from 'Scripts/requests';
import { NavigationState } from 'Scripts/slideHelper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { PlaySlideType } from 'Types/playTypes';
import { PresentationState } from 'Types/presentationTypes';
import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const questionStartDelayMs = 2500;

let WordCloudSlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {

    const { presentationStyle } = usePresentationStyles();


    const slideProperties = getPlaySlideProperties(slide);

    const slideNavigationState = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slideNavigationState);

    const {
        navigateToNextSlideState,
        isActiveState,
    } = useSlideNavigation()

    useInterval(() => {

        navigateToNextSlideState();

    }, isActiveState(NavigationState.BeforeOpen) ? questionStartDelayMs : null)

    useEffect(() => {

        if (isActiveState(NavigationState.BeforeOpen)) {

            closeMessageRound(slide.id);
        }

        if (isActiveState(NavigationState.SlideOpen)) {

            openMessageRound(slide.id);
        }

    }, [slideNavigationState])

    /* Runs on unmounting */
    useEffect(() => () => {

        closeMessageRound(slide.id)

    }, []);

    return (
        <>
            <Grid
                container
                className={classes.questionContainer}
                direction="row"
                justifyContent="center"
                sx={{
                    color: presentationStyle?.textColor
                }}>
                <Grid
                    container
                    item
                    xs={12}
                    className={`${classes.detailsContainer} ${isActiveState(NavigationState.BeforeOpen) && classes.upSize}`}
                >
                    <LayoutWrapper
                        layoutType={slide.slideLayout}
                        imageURL={slide?.file?.base64 || slide?.filename}
                        imageStyle={slide.filenameStyle}
                        backgroundOpacity={slide.backgroundOpacity}
                        backgroundColor={slide.backgroundColor}
                        videoURL={slide.videoURL}
                        title={
                            <PlaySlideTitlePreview
                                showPreview={isActiveState(NavigationState.BeforeOpen)}
                                title={
                                    <DelayedComponent
                                        animationDuration={250}
                                        animationType={AnimationTypes.Grow}
                                        showAfter={500} >
                                        <Html
                                            content={slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                            fontSize={slideProperties.slideSettings.titleFontSize}
                                        />
                                    </DelayedComponent>
                                }
                            />
                        }
                        content={
                            <Grid item className={classes.wordcloudGrid}>
                                {isActiveState(NavigationState.SlideOpen) && <Fade in={true} style={{ height: '100%' }}>
                                    <div>
                                        <WordCloudComponent messageRoundId={slide.id} />
                                    </div>
                                </Fade>}
                            </Grid>
                        }
                    />
                </Grid>
            </Grid>
            <SessionLoginInfo />
        </>
    );
};

export default WordCloudSlideSession