import { Typography } from '@mui/material';
import LoginCodeField from 'Atomic/molecules/LoginCodeField/LoginCodeField';
import Caption from 'Components/beta/common/caption/Caption';
import SummaryOption from 'Components/beta/common/summary/option/SummaryOption';
import { Features, useFeatures } from 'Hooks/useFeatures';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElScreen } from 'Scripts/measurementsGAHelper';
import { OverviewState } from 'Types/overviewTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const LoginCodeUserSettings = () => {

    const {
        userSessionInfo
    } = useSession();

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

    const { isFeatureAvailable } = useFeatures()

    const { translatePlaceholder } = useTranslations();

    const hasEditLoginCodeFeature = isFeatureAvailable(Features.EDIT_RESPONSE_CODE);

    return (
        <SummaryOption
            loading={!userSessionInfo || presentationLoading}
            leftItem={<div>
                <Typography variant="body2" className={classes.label}>
                    {!hasEditLoginCodeFeature && "💎"} {translatePlaceholder("LOGIN_CODE")}
                </Typography>
                <Caption title={translatePlaceholder("LOGIN_CODE_EXPLANATION")} />
            </div>}
            rightItem={<div>
                <LoginCodeField
                    data-elaction={`${DataElAction.LoginCodeEdit}${!hasEditLoginCodeFeature ? '_limited' : ''}`}
                    data-elscreen={DataElScreen.Settings}
                    data-elposition={DataElPosition.MainOptSection}
                />
            </div>}
        />
    );
};

export default LoginCodeUserSettings;