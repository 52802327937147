import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import { useAppRouting } from 'Hooks/useAppRouting';
import { useLayout } from 'Hooks/useLayout';
import usePresentation from 'Hooks/usePresentation';
import { useTranslations } from 'Hooks/useTranslations';
import React, { useState } from 'react';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';

const KeepWorkingOnPresentationButton = () => {

    const { translatePlaceholder } = useTranslations();

    const [ buttonLoading, setButtonLoading ] = useState<boolean>(false);

    const {
        setShowMissingPresentationFeatures
    } = useLayout();

	const { presentationId } = useAppRouting();

    const {
        markPresentationAsEdited,
    } = usePresentation();

    const onClose = async () => {

        setButtonLoading(true);

        await markPresentationAsEdited(presentationId);

        setShowMissingPresentationFeatures([]);

        setButtonLoading(false);
    }

    return (
        <AppButton
            as={AppButtonVariant.Outlined}
            loading={buttonLoading}
            disabled={buttonLoading}
            data-elaction={DataElAction.KeepWorkingOnSendsteps}
            data-eltype={DataElType.Button}
            data-elscreen={DataElScreen.Editor}
            data-elposition={DataElPosition.FeaturePopup}
            data-testid={testIds.KEEP_WORKING_BUTTON}
            onClick={() => onClose()}>
            {translatePlaceholder('KEEP_WORKING_ON_MY_PRESENTATION')}
        </AppButton>
    );
};

export default KeepWorkingOnPresentationButton;