import { config } from "./config";

export function shortenString(text: string, maxLength: number) {
	return text.length > maxLength ? text.slice(0, maxLength) + '...' : text
}

export function numberWithCommas(x: number|string) : string {
	try {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	} catch (error) {
		console.warn(error)
		return `${x}`
	}
}

export function trimString(text: string, maxLength: number = config.maxStringLength) : string {
	return text?.substring(0, maxLength)?.trim();
}

// Don't ask me. This is how data is handled on sendshake serverside
export const encodeString = (text: string) : string => {
	return btoa(encodeURIComponent(text).replace(/%([0-9A-F]{2})/g, (match, subString: string) => {
		return String.fromCharCode(Number(`0x${subString}`));
	}));
}

export const capitalize = (string: string) : string => {
	return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
}

export const getFileNameFromPath = (path: string) : string|undefined => {
	return path.split("\\").pop();
}

export const prettyifyQuizTitle = (title: string) : string => {
	try {
		let parts = title.split("\\");

		const [ firstPart ] = parts;

		if(firstPart === title) {

			parts = title.split("/");
		}

		const prettyTitle = parts[parts.length - 1].replace(".pptx", "");

		return prettyTitle || title;

	} catch (error) {
		console.warn("Unable to prettify quiztitle: ", title)
		return title
	}
}

export const getTextWithUnlimitedValue = (value: string|number, unlimitedValue: number = -1) : string => {

	if(value === unlimitedValue) {

		return "Unlimited";
	}

	return `${numberWithCommas(value)}`;
}

/**
 * This is used to generate a token for the message filter
 */
export const generateNewToken = () : string => {
	var token = "";
	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var tokenLength = 30;

	for( var i=0; i < tokenLength; i++ )
	{
		token += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return token;
}

/*
	The currency values retrieved from the API are displayed as cents.
	We need to divide them by 100 to give a proper currency format
*/
export const orderAmountToCurrency = (orderAmount: number | string) => {

	return (Number(orderAmount) / 100).toFixed(2);
}

enum MediaProvider {
	YouTube = 'youtube',
	YouTubeShort = 'youtu.be',
}

/**
 * Formats a youtube url to an embed link
 *
 * @param url a youtube url
 */
export const getFormattedEmbedUrl = (url: string) => {

	const urlObject = new URL(url);

	const searchParams = new URLSearchParams(urlObject.search);

	const host = urlObject.host;

	if (host.includes(MediaProvider.YouTube) || host.includes(MediaProvider.YouTubeShort)) {

		let videoID = searchParams.get('v');

		if(!videoID) {
			/* If theres no videoID in the url, it must be a short link */
			videoID = urlObject.pathname.replace('/', '');
		}

		/** Nocookie url, same as youtube generates their embed URLs */
		let embedURL = `https://www.youtube-nocookie.com/embed/${videoID}?rel=0`;

		const startTime = searchParams.get('t');

		if(startTime) {

			embedURL += `&start=${startTime}`;
		}

		return embedURL;
	}

	return url;
}

/**
 * E.g.: "soft_kittens" => "Soft Kittens"
 */
export const toTitleCase = (text: string) => {

	return text.split('_')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}