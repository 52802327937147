import * as featureActions from 'Actions/featureActions';
import { setAvailableFeatures, setPlanInfo } from "Actions/featureActions";
import { setUserSubcriptionData } from "Actions/teamActions";
import { get, post } from "Scripts/api";
import { LocalStorageKeys } from 'Scripts/globals';
import { addToLocalStorage } from 'Scripts/localStorage';
import { useDispatch } from "react-redux";

const usePlan = () => {

	const dispatch = useDispatch();

	const fetchPlanDetails = async () => {

		try {

			dispatch(featureActions.setPlanFeaturesLoading(true));

			const { plan_details, available_features } = await post('users/plan-info');

			addToLocalStorage(LocalStorageKeys.PlanName, plan_details.name)

			dispatch(setPlanInfo(plan_details))

			dispatch(setAvailableFeatures(available_features || []));

		} catch (error) {

			console.warn(error)

		} finally {

			dispatch(featureActions.setPlanFeaturesLoading(false));
		}
	}

	const fetchUserSubscriptionData = async () => {

		try {

			dispatch(featureActions.setSubscriptionDataLoading(true));

			const summaryData = await get('license/subscription-summary');

			dispatch(setUserSubcriptionData(
				summaryData.primaryPlan,
				summaryData.otherPlans,
			));

		} catch (error) {

			console.warn(error);

		} finally {

			dispatch(featureActions.setSubscriptionDataLoading(false));
		}
	}

	return {
		fetchPlanDetails,
		fetchUserSubscriptionData,
	}
}

export default usePlan;