import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Divider } from '@mui/material';
import RandomWidthSkeleton from 'Atomic/atoms/RandomWidthSkeleton';
import { useLayout } from 'Hooks/useLayout';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { LayoutState } from 'Types/layoutTypes';

interface AdvancedSettingsToggleProps {
    isOpen: boolean;
}

const AdvancedSettingsToggle = ({ isOpen }: AdvancedSettingsToggleProps) => {

	const advancedPresentationOptionsShown = useSelector((state: RootStateOrAny) => (state.layoutReducer as LayoutState).advancedPresentationOptionsShown);

	const {
		toggleAdvancedOptions,
	} = useLayout();

	const isCollapsed = isOpen || advancedPresentationOptionsShown;

	const {
		userSessionInfo
	} = useSession();

	const { translatePlaceholder } = useTranslations();

	return (
		<>
			<Box py={3}>
				{!userSessionInfo && <RandomWidthSkeleton />}
				{userSessionInfo && <>
					<Button
						data-testid={testIds.ADVANCED_OPTIONS_TOGGLE}
						onClick={() => toggleAdvancedOptions(!isCollapsed)}
						startIcon={isCollapsed ? <RemoveIcon /> : <AddIcon />}
						sx={{fontWeight: 600}}
						{ ...renderingGAAttr(`${DataElAction.AdvancedOpt}_${!isCollapsed ? 'collapsed' : 'extended'}`, DataElPosition.MainOptSection, DataElScreen.Settings, DataElType.Link)}
					>
						{!isCollapsed ? <u>{translatePlaceholder("SHOW_ADVANCED_OPTIONS")}</u> : <u>{translatePlaceholder("HIDE_ADVANCED_OPTIONS")}</u>}
					</Button>
				</>}
			</Box>
			<Divider />
		</>
	);
};

export default AdvancedSettingsToggle;