
import { getPlaySlideType } from "Scripts/slideHelper";
import { PlaySlideList, PlaySlideType } from "Types/playTypes";
import { Slide, SlideType } from "Types/presentationTypes";
import { SlideTypes } from 'Types/slideTypes';
import { isEmpty } from "lodash";
import {
    BarGraphSlide,
    CountdownContentSlide,
    ImageContentSlide,
    LeaderboardSlide,
    LineGraphSlide,
    MPCVote,
    OpenEndedQuestion,
    PodiumSlide,
    PointsQuestion,
    QuizVote,
    QuoteContentSlide,
    TableSlide,
    TitleAndSubtitleSlide,
    TitleAndTextSlide,
    TitleContentSlide,
    UpvotingQuestion,
    VideoContentSlide,
    WordCloudQuestion
} from "./Slides";

export const getPlaySlideProperties = (slide: PlaySlideType) => {

    const slideType = getPlaySlideType(slide);

    return getSlidePropertiesByType(slideType);
}

export const getSlideProperties = (slide: SlideType) => {

	return getSlidePropertiesByType(slide?.type);
}

export const getSlidePropertiesByType = (type: string) => {

    switch(type) {
        case SlideTypes.MultipleChoice: {
            return MPCVote();
        }
        case SlideTypes.Quiz: {
            return QuizVote();
        }
        case SlideTypes.OpenEnded: {
            return OpenEndedQuestion();
        }
        case SlideTypes.Wordcloud: {
            return WordCloudQuestion();
        }
        case SlideTypes.QA: {
            return UpvotingQuestion();
        }
        case SlideTypes.Points: {
            return PointsQuestion();
        }
        case SlideTypes.Countdown: {
            return CountdownContentSlide();
        }
        case SlideTypes.Title: {
            return TitleContentSlide();
        }
        case SlideTypes.Image: {
            return ImageContentSlide();
        }
        case SlideTypes.Video: {
            return VideoContentSlide();
        }
        case SlideTypes.Quote: {
            return QuoteContentSlide();
        }
        case SlideTypes.Countdown: {
            return CountdownContentSlide();
        }
        case SlideTypes.TitleAndSubtitle: {
            return TitleAndSubtitleSlide();
        }
        case SlideTypes.Table: {
            return TableSlide();
        }
        case SlideTypes.LineGraph: {
            return LineGraphSlide();
        }
        case SlideTypes.BarGraph: {
            return BarGraphSlide();
        }
        case SlideTypes.Podium: {
            return PodiumSlide();
        }
        case SlideTypes.Leaderboard: {
            return LeaderboardSlide();
        }
        // case SPACE_INVADERS: {
        //     return SpaceInvadersQuestion();
        // }
		default: {
            return TitleAndTextSlide();
        }
    }
}

export const extractSlidesByType = (slides: PlaySlideList, baseType: string) => {

    const typedSlides = {}

    Object.keys(slides).forEach(slideKey => {

        const slideProperties = getPlaySlideProperties(slides[slideKey])

        if (slideProperties.baseType === baseType) {

            typedSlides[slideKey] = slides[slideKey]
        }
    })

    return !isEmpty(typedSlides) ? typedSlides : null;
}

export const isSlideTimeLimited = (slide: Slide) =>
{
    return [
        SlideTypes.Quiz,
        SlideTypes.MultipleChoice
    ].includes(slide.type);
}