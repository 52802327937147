import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import { Box } from '@mui/system';
import { setMusic, setVolume } from 'Actions/audioActions';
import AppText from 'Atomic/atoms/Text/AppText';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import DynamicColoredProgressBar from 'Atomic/molecules/DynamicColoredProgressBar';
import PlaySlideTitlePreview from 'Atomic/molecules/PlaySlideTitlePreview/PlaySlideTitlePreview';
import Html from 'Components/common/util/html/Html';
import SessionLoginInfo from 'Components/play/templates/geometry_template/shared_components/session_login_info/SessionLoginInfo';
import SessionVoteOptions from 'Components/session/vote_options/SessionVoteOptions';
import { getSlidePropertiesByType } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useSession } from 'Hooks/useSession';
import { useSlideNavigation } from 'Hooks/useSlideNavigation';
import { useTranslations } from 'Hooks/useTranslations';
import { musicTypes } from 'Reducers/audioReducer';
import { useInterval } from 'Scripts/intervalHook';
import { calculateVoteResults, closeVote, openVote } from 'Scripts/requests';
import { NavigationState, getPlaySlideType } from 'Scripts/slideHelper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { FeatureState } from 'Types/featureTypes';
import { PlaySlideType, PlayState } from 'Types/playTypes';
import { PresentationState } from 'Types/presentationTypes';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { classes } from './style.css';


const questionStartDelayMs = 5000;
/* How frequent the count down indicator should update */
const countdownIndicatorIntervalMs = 250;

const MultiplechoiceSlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {

    const slideProperties = getSlidePropertiesByType(slide.type || getPlaySlideType(slide));

    const runTimers = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).runTimers);

    const slideNavigationState = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slideNavigationState);

    const { presentationStyle } = usePresentationStyles();

    const planInfo = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).planInfo);


    const {
        navigateToNextSlideState,
        isActiveState,
    } = useSlideNavigation();

    const { translate } = useTranslations();

    const dispatch = useDispatch();

    const [questionTimer, setQuestionTimer] = useState(Number(slide?.dynamicTimerLimit) * 1000 || -1)

    const { allVotesAreIn } = useSession();

    const [questionStartTimer, setQuestionStartTimer] = useState(0)
    /* Percentage value of time between the vote shows up and when the vote opens  at 100%*/
    const questionStartPercentage = (100 / ((questionStartDelayMs - 500) / questionStartTimer)) >> 0
    /* Percentage value of time left to respond on a vote before it closes at 100% */
    const timePercentage = (100 / (Number(slide?.dynamicTimerLimit || 1) / (questionTimer / 1000)));

    const maxAudienceSize = planInfo?.audienceSize || 0;

    /* Countdown for the remaining time to vote when the vote has opened */
    useInterval(() => {

        setQuestionTimer(currentTime => currentTime - countdownIndicatorIntervalMs)

    }, (isActiveState(NavigationState.SlideOpen) && runTimers) ? countdownIndicatorIntervalMs : null)

    /* Countdown before the question opens */
    useInterval(() => {

        setQuestionStartTimer(currentTime => currentTime + countdownIndicatorIntervalMs)

    }, (runTimers && (isActiveState(NavigationState.BeforeOpen)) && (questionStartPercentage <= 100)) ? countdownIndicatorIntervalMs : null)

    /* If the question timer reaches 0, close the vote */
    useEffect(() => {

        if (questionTimer === 0 && isActiveState(NavigationState.SlideOpen)) {

            navigateToNextSlideState();
        }

    }, [questionTimer])

    const playCorrectMusic = () => {

        let musicType = musicTypes.TIMER_ANY;

        if (slide?.dynamicTimerLimit === 20) {

            musicType = musicTypes.TIMER_20_SECONDS;
        }

        dispatch(setVolume(1))

        dispatch(setMusic(musicType))
    }

    /* Open the vote after questionStartDelayMs ms */
    useInterval(() => {

        navigateToNextSlideState();

    }, (runTimers && isActiveState(NavigationState.BeforeOpen)) ? questionStartDelayMs : null)

    useEffect(() => {

        if (isActiveState(NavigationState.BeforeOpen)) {

            closeVote(slide.id);
        }

        if (isActiveState(NavigationState.SlideOpen)) {

            openVote(slide.id);
            playCorrectMusic()
        }

        if (isActiveState(NavigationState.SlideClosed)) {

            closeVote(slide.id);

            calculateVoteResults(slide.id, maxAudienceSize);
        }

    }, [slideNavigationState])

    /* Runs on unmounting */
    useEffect(() => {

        return () => {

            closeVote(slide.id);
        }

    }, []);

    return (
        <>
            {isActiveState(NavigationState.BeforeOpen) && <DynamicColoredProgressBar loadPercentage={Math.min(100, questionStartPercentage)} />}
            {isActiveState(NavigationState.SlideOpen) && <DynamicColoredProgressBar loadPercentage={Math.max(0, timePercentage)} />}
            <Grid
                container
                className={classes.questionContainer}
                sx={{
                    color: presentationStyle?.textColor,
                }}
                direction="row"
                justifyContent="center">
                <Grid
                    xs={12}
                    container
                    item
                    className={`${classes.detailsContainer} ${isActiveState(NavigationState.BeforeOpen) && classes.upSize}`}
                >
                    <LayoutWrapper
                        layoutType={slide?.slideLayout}
                        imageURL={slide?.file?.base64 || slide?.filename}
                        imageStyle={slide.filenameStyle}
                        backgroundOpacity={slide.backgroundOpacity}
                        backgroundColor={slide.backgroundColor}
                        videoURL={slide.videoURL}
                        title={
                            <div>
                                <PlaySlideTitlePreview
                                    showPreview={isActiveState(NavigationState.BeforeOpen)}
                                    title={
                                        <DelayedComponent
                                            animationDuration={250}
                                            animationType={AnimationTypes.Grow}
                                            showAfter={500}
                                            stop={!runTimers}>
                                            <Html
                                                content={slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                                fontSize={slideProperties.slideSettings.titleFontSize}
                                            />
                                        </DelayedComponent>
                                    }
                                />
                            </div>
                        }
                        content={(
                            <SessionVoteOptions slide={slide} />
                        )}
                    />
                </Grid>
            </Grid>
            {!allVotesAreIn && <SessionLoginInfo />}
            <Box className={classes.continueText} sx={{
                color: presentationStyle?.textColor,
            }}>
                <Grow in={allVotesAreIn && isActiveState(NavigationState.SlideOpen)}>
                    <AppText
                        fontWeight={700}
                        fontSize={24}
                        color={presentationStyle?.textColor}>
                        {translate('ALL_VOTES_ARE_IN')}
                    </AppText>
                </Grow>
            </Box>
        </>
    );
};

export default MultiplechoiceSlideSession
