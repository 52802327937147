import { Box } from '@mui/material';
import { TableCellData } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import AppDynamicTablePreview from 'Atomic/organisms/AppDynamicTable/preview/AppDynamicTablePreview';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { SlideConsumerComponent } from 'Types/appTypes';
import { SlideType } from 'Types/presentationTypes';
import React from 'react';

const PreviewTableSlideShape = ({
    slide
}: SlideConsumerComponent<SlideType>) => {

    const { presentationStyle } = usePresentationStyles();

    return (
        <Box
            mt={2}
            height="100%"
            overflow={{
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >
            <AppDynamicTablePreview
                data={slide.table as Array<Array<TableCellData>>}
                cellStyles={{
                    borderColor: presentationStyle?.textColor,
                    color: presentationStyle?.textColor,
                }}
            />
        </Box>
    );
};

export default PreviewTableSlideShape;