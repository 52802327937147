import React from 'react';

interface Props {
	children: React.ReactNode;
}

const FullSizeContainer = React.forwardRef<HTMLDivElement, Props>(({ children }: Props, ref) => {

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
			}}
			ref={ref}>
			{children}
		</div>
	)
});

export default FullSizeContainer