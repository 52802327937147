import { get } from "Scripts/api";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

export interface Language {
	country: string;
	isPremium: boolean;
	isoCode: string;
	localName: string;
	name: string;
}

export const useAiLanguages = () => {

	const [aiLanguages, setAiLanguages] = useState<Language[]|null>(null);

	const { enqueueSnackbar } = useSnackbar();

	const getAiLanguages = async () => {

		try {

			const response = await get('generate/available-languages');

			if (response.data) {

				setAiLanguages(response.data);
			}

			if (response.error) {

				enqueueSnackbar(response.error, { variant: 'error' });
			}

		} catch (error) {

			console.warn(error)
		}
	}

	const findLanguageByName = (languageName: string) => {

		return aiLanguages?.filter(lang => {
            return lang.name === languageName
        })[0];
	}

	useEffect(() => {

		getAiLanguages();

	}, []);


	return {
		aiLanguages,
		findLanguageByName
	}
}
