import { AnyAction } from "redux";

export const initialState = {
    flatInputObjValues: {}
}

export enum GeneratePresentationActionTypes {
    SetFlatInputObjValues = 'SET_FLAT_INPUT_OBJ_VALUES',
}

export const generatePresentationReducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        case GeneratePresentationActionTypes.SetFlatInputObjValues: {
            return {
                ...state,
                flatInputObjValues: {
                    ...state.flatInputObjValues,
                    flatInputObjValues: payload,
                }
            }
        }
        default:
            return state
    }
}
