import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Collapse, IconButton, InputBase, Radio } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { QuestionTypes } from 'Types/identificationQuestionTypes';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { classes } from './style.css';

interface QuestionAnswerProps {
    questionIndex: number;
    answerIndex: number;
    value: string;
    type: QuestionTypes;
    totalAnswersCount: number;
    update: (questionIndex: number, answerIndex: number, answerValue: string) => Promise<void>;
    remove: (questionIndex: number, answerIndex: number) => Promise<boolean>;
    testIdPrefix: number;
    dataPrefix: string;
    disabled: boolean;
}

const QuestionAnswer = ({
    questionIndex,
    answerIndex,
    value,
    type,
    totalAnswersCount,
    update,
    remove,
    testIdPrefix,
    dataPrefix,
    disabled,
}: QuestionAnswerProps) => {

    const [ placeholderAnswer, setPlaceholderAnswer ] = useState<string>('');

    const [ loading, setLoading ] = useState<boolean>(false);

    const [ deleting, setDeleting ] = useState<boolean>(false);

    const answerInputRef = useRef<RefObject<HTMLInputElement>>(null);

    const { translatePlaceholder } = useTranslations();

    const changeAnswer = async () => {

        if(Boolean(placeholderAnswer)) {
            /* Don't update if the title didn't change */
            if(placeholderAnswer === value) {

                return;
            }

            setLoading(true);

            await update(questionIndex, answerIndex, placeholderAnswer);

            setLoading(false);
        }
    }

    const selectAnswer = () => {

        answerInputRef.current && answerInputRef.current.select();
    }

    const removeAnswer = async () => {

        setLoading(true);

        const isRemoved = await remove(questionIndex, answerIndex);

        if (isRemoved) {

            setDeleting(true);

        }

        setLoading(false);
    }

    useEffect(() => {

        setPlaceholderAnswer(value || '')

    }, [value])

    useEffect(() => {

        if(answerIndex + 1 === totalAnswersCount) {

            selectAnswer();
        }

    }, [ totalAnswersCount ])

    return (
        <Collapse in={!deleting}>
            <div>
                <InputBase
                    disabled={disabled}
                    inputProps={{
                        ref: answerInputRef
                    }}
                    startAdornment={<>
                        {type === QuestionTypes.Radio && <Checkbox disabled />}
                        {type === QuestionTypes.MultipleChoice && <Radio disabled />}
                    </>}
                    endAdornment={<>
                        {loading && <CircularProgress className={classes.loader} />}
                        {!loading && Boolean(totalAnswersCount > 1) && <IconButton
                            data-testid={`${testIdPrefix}_${testIds.QUESTION_OPTION_DELETE}`}
                            data-eltype="button"
                            data-elaction={`${dataPrefix}_question_option_delete`}
                            data-elposition={answerIndex}
                            data-elscreen="settings"
                            onClick={() => removeAnswer()}
                            disabled={disabled}
                            className={classes.smIconButton}
                            size="large">
                            <CloseIcon
                                className={classes.removeAnswerIcon}
                            />
                        </IconButton>}
                    </>}
                    fullWidth
                    onClick={() => selectAnswer()}
                    onBlur={() => changeAnswer()}
                    onChange={e => setPlaceholderAnswer(e.target.value)}
                    value={placeholderAnswer}
                    placeholder={translatePlaceholder("ENTER_YOUR_ANSWER_OPTION")}
                    data-testid={`${testIdPrefix}_${testIds.QUESTION_OPTION_INPUT}`}
                />
            </div>
        </Collapse>
    );
};

export default QuestionAnswer;