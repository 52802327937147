import Grid from '@mui/material/Grid';
import { setNewMessagesAreIn } from 'Actions/playActions';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import PlaySlideTitlePreview from 'Atomic/molecules/PlaySlideTitlePreview/PlaySlideTitlePreview';
import Html from 'Components/common/util/html/Html';
import SessionLoginInfo from 'Components/play/templates/geometry_template/shared_components/session_login_info/SessionLoginInfo';
import OpenEndedSlideMessageList from 'Components/slides/shared/components/slide_messages/open_ended/list/OpenEndedSlideMessageList';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useSlideNavigation } from 'Hooks/useSlideNavigation';
import { useInterval } from 'Scripts/intervalHook';
import { closeMessageRound, openMessageRound } from 'Scripts/requests';
import { NavigationState } from 'Scripts/slideHelper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { PlaySlideType, PlayState } from 'Types/playTypes';
import { PresentationState } from 'Types/presentationTypes';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { classes } from './style.css';

const questionStartDelayMs = 3500;

let OpenEndedSlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {

    const { presentationStyle } = usePresentationStyles();

    const slideProperties = getPlaySlideProperties(slide);

    const [messageIdsOnScreen, setMessageIdsOnScreen] = useState<Array<number>>([]);


    const messages = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).messages);

    const newMessagesAreIn = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).newMessagesAreIn);

    const playSlides = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).playSlides);

    const slideNavigationState = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slideNavigationState);

    const previousSlideNavigationState = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).previousSlideNavigationState);

    const dispatch = useDispatch()

    const {
        navigateToNextSlideState,
        isActiveState,
    } = useSlideNavigation()

    useInterval(() => {

        dispatch(setNewMessagesAreIn(false))

    }, newMessagesAreIn ? 2500 : null)

    useInterval(() => {

        rotateMessages();

    }, 3500)

    const rotateMessages = () => {

        let messageIdsToBeShown: Array<number> = [];

        if (!isEmpty(messages)) {

            messageIdsToBeShown = Object.keys(messages?.[slide.id] || {}).filter((messageId) => {

                return messages[slide.id][messageId].status === 'showing'

            }).map(Number);
        }

        setMessageIdsOnScreen(messageIdsToBeShown as Array<number>)
    }

    /* Update screen whenever we detect changes state vs whats-on-screen */
    useEffect(() => {

        const messageKeys = Object.keys(messages[slide.id] || {})

        const newStateKeys: Array<number> = []

        messageIdsOnScreen.forEach(key => {

            if (messageKeys.includes(`${key}`)) {

                newStateKeys.push(key)
            }
        })

        setMessageIdsOnScreen(newStateKeys);

    }, [JSON.stringify(messages?.[slide.id])])

    useInterval(() => {

        navigateToNextSlideState();

    }, isActiveState(NavigationState.BeforeOpen) ? questionStartDelayMs : null)

    useEffect(() => {

        const isNotLimitedToSlide = (playSlides[slide.id].limitToSlide === 0)

        if (isActiveState(NavigationState.BeforeOpen)) {
            /* If navigating back or the question is limited to the current slide */
            if (!isNotLimitedToSlide || previousSlideNavigationState === NavigationState.SlideOpen) {
                /* Close it incase it might be still running*/
                closeMessageRound(slide.id)
            }
        }

        if (isActiveState(NavigationState.SlideOpen)) {

            openMessageRound(slide.id)
        }

    }, [slideNavigationState])

    /* Runs on unmounting */
    useEffect(() => {

        return () => {

            closeMessageRound(slide.id);
        }

    }, []);

    const sortMessagesByPosition = () => {

        return messageIdsOnScreen.sort((first, second) => {

            return messages[slide.id]?.[first]?.position - messages[slide.id]?.[second]?.position;
        })
    }

    return <>
        {slide && <Grid
            container
            className={classes.questionContainer}
            sx={{
                color: presentationStyle?.textColor,
            }}
            direction="row"
            justifyContent="center">
            <Grid
                container
                item
                xs={12}
                className={`${classes.detailsContainer} ${isActiveState(NavigationState.BeforeOpen) && classes.upSize}`}
            >
                <LayoutWrapper
                    layoutType={slide.slideLayout}
                    imageURL={slide?.file?.base64 || slide?.filename}
                    imageStyle={slide.filenameStyle}
                    backgroundOpacity={slide.backgroundOpacity}
                    backgroundColor={slide.backgroundColor}
                    videoURL={slide.videoURL}
                    title={(
                        <PlaySlideTitlePreview
                            showPreview={isActiveState(NavigationState.BeforeOpen)}
                            title={(
                                <DelayedComponent
                                    animationDuration={250}
                                    animationType={AnimationTypes.Grow}
                                    showAfter={500} >
                                    <Html
                                        content={slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                        fontSize={slideProperties.slideSettings.titleFontSize}
                                    />
                                </DelayedComponent>
                            )}
                        />
                    )}
                    content={(
                        <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            direction="column"
                            className={classes.openEndedGrid}>
                            {isActiveState(NavigationState.SlideOpen) && (
                                <OpenEndedSlideMessageList
                                    slide={slide}
                                    messages={sortMessagesByPosition().map(key => messages[slide.id][key]).filter(Boolean)}
                                />
                            )}
                        </Grid>
                    )}
                />
            </Grid>
        </Grid>}
        <SessionLoginInfo />
    </>;
};


export default OpenEndedSlideSession
