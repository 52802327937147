import { AnyAction } from 'redux';
import { PurchaseablePlanDetails, UserFlowState } from 'Types/userFlowTypes';


const initialState: UserFlowState = {
	userFlow: null,
	userLicenseFlow: null,
	joinableAccounts: [],
	pendingJoinToken: null,
	licenseHolder: null,
	showPurchaseCompletedThankYou: false,
	showSignupStepper: false,
	signupStepperStepNr: 0,
	signupStepperWelcomeShown: false,
	signupStepperData: {
		segment: '',
		role: '',
		signupReason: '',
	},
	supportDetails: null,
	availablePlans: {},
	accountJoinRequestCount: 0,
	ignoreLicensePopups: false,
	sendResultsEmailAfterSession: true,
	sendResultsEmailAfterSessionLoading: false,
	userFlowLoading: true,

	supportDetailsLoading: false,
	userSegmentUpdateLoading: false,
	requestAccessLoading: false,
	aiFunctionsDisabled: false,
	showAddInDownloadLink: true,
	showPricingLink: true,
	customerSupportTranslationKey: null,
	customSupportText: null,
}

export const userFlowReducer = (state: UserFlowState = initialState, action: AnyAction): UserFlowState => {
	switch (action.type) {
		case 'SET_SUPPORT_DETAILS_LOADING': {

			return {
				...state,
				supportDetailsLoading: action.isLoading,
			}
		}
		case 'SET_USER_SEGMENT_UPDATE_LOADING': {

			return {
				...state,
				userSegmentUpdateLoading: action.isLoading,
			}
		}
		case 'SET_REQUEST_ACCESS_LOADING': {

			return {
				...state,
				requestAccessLoading: action.isLoading,
			}
		}
		case 'SET_USER_FLOW_LOADING': {
			return {
				...state,
				userFlowLoading: action.isLoading,
			}
		}
		case 'SET_AVAILABLE_PLANS': {

			const plans = {}

			action.plans.forEach((plan: PurchaseablePlanDetails) => {

				plans[plan.name] = plan;
			})

			return {
				...state,
				availablePlans: plans,
			}
		}
		case 'SET_SUPPORT_DETAILS': {
			return {
				...state,
				supportDetails: action.details,
			}
		}
		case 'SET_SIGNUP_STEPPER_WELCOME_SHOWN': {
			return {
				...state,
				signupStepperWelcomeShown: action.shown,
			}
		}
		case 'INIT_USER_FLOW': {

			const data = action.data

			return {
				...state,
				userFlow: data.userFlow,
				userLicenseFlow: data.userLicenseFlow,
				joinableAccounts: data.joinableAccounts,
				pendingJoinToken: data.pendingJoinToken,
				licenseHolder: data.licenseHolder,
				accountJoinRequestCount: Number(data.accountJoinRequestCount || 0),
				sendResultsEmailAfterSession: Boolean(data.sendResultsEmailAfterSession),
				aiFunctionsDisabled: Boolean(data.aiFunctionsDisabled),
				showAddInDownloadLink: Boolean(data.showAddInDownloadLink),
				showPricingLink: Boolean(data.showPricingLink),
				customerSupportTranslationKey: data.customerSupportTranslationKey,
				customSupportText: data.customSupportText,

				signupStepperData: {
					...state.signupStepperData,
					...data.userSegmentInfo
				},
			}
		}
		case 'UPDATE_USER_FLOW_DATA': {
			return {
				...state,
				[action.key]: action.value
			}
		}
		case 'SET_PURCHASE_COMPLETED_TY': {
			return {
				...state,
				showPurchaseCompletedThankYou: action.show,
			}
		}
		case 'SET_SIGNUP_STEPPER_DATA': {
			return {
				...state,
				signupStepperData: {
					...action.data,
				}
			}
		}
		case 'SET_SIGNUP_STEPPER_STEP': {

			return {
				...state,
				signupStepperStepNr: action.stepNr,
			}
		}
		case 'SET_SHOW_SIGNUP_STEPPER': {

			return {
				...state,
				showSignupStepper: action.isOpen,
			}
		}
		case 'SET_IGNORE_LICENSE_POPUPS': {
            return {
                ...state,
                ignoreLicensePopups: action.ignore,
            }
		}
		case 'SET_TOGGLE_RESULTS_AFTER_SESSION': {
			return {
				...state,
				sendResultsEmailAfterSession: action.enabled,
			}
		}
		case 'SET_TOGGLE_RESULTS_AFTER_SESSION_LOADING': {
			return {
				...state,
				sendResultsEmailAfterSessionLoading: action.isLoading,
			}
		}
		default:
			return state
	}
}