import * as appActions from 'Actions/appActions';
import { useDispatch } from 'react-redux';

export const ONLINE_EVENT_TYPE = 'online';
export const OFFLINE_EVENT_TYPE = 'offline';

export const useConnection = () => {

	const dispatch = useDispatch();

	const setOnline = () => {

		dispatch(appActions.setIsOnline(true));
	}

	const setOffline = () => {

		dispatch(appActions.setIsOnline(false));
	}

	const bindConnectionListener = () => {

		window.addEventListener(ONLINE_EVENT_TYPE, setOnline, true);
		window.addEventListener(OFFLINE_EVENT_TYPE, setOffline, true);
	}

	const unBindConnectionListener = () => {

		window.removeEventListener(ONLINE_EVENT_TYPE, setOnline);
		window.removeEventListener(OFFLINE_EVENT_TYPE, setOffline);
	}

	return {
		bindConnectionListener,
		unBindConnectionListener,
	}
}