import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Html from 'Components/common/util/html/Html';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { getMUISafeAlphaColor } from 'Scripts/colorHelper';
import { config } from 'Scripts/config';
import { numberWithCommas } from 'Scripts/stringFormatter';
import { LayoutTypes } from 'Types/layoutTypes';
import { OptionPlaceholder, VoteOption } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';

export interface PlayQuestionOptionProps {
	option: VoteOption;
	show: boolean;
	showPercentages: boolean;
	showCorrectAnswers: boolean;
	index: number;
	voteCount: number;
	votePercentage: number;
	totalVoteCount: number;
	layout: LayoutTypes;
	fontSize: number;
}

const PlayQuestionOption = ({
	option,
	show,
	showCorrectAnswers,
	showPercentages,
	index,
	voteCount,
	votePercentage,
	totalVoteCount,
	layout,
	fontSize,
}: PlayQuestionOptionProps) => {

	const {
		presentationStyle
	} = usePresentationStyles();


	const getBackgroundColor = () => {

		if (showCorrectAnswers && !!option.correctAnswer) {

			return config.successColor;
		}

		if (layout === LayoutTypes.ImageOnBackground) {

			return getMUISafeAlphaColor(presentationStyle?.lightColor || '#fff', .75);
		}

		return presentationStyle?.lightColor;
	}

	return (
        <Grid
			style={{
				background: getBackgroundColor(),
				opacity: +show,
			}}
			className={classes.option}
			key={index}
			container
			item
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			xs={12}>
			<Box
				width={`${showCorrectAnswers ? (((voteCount / totalVoteCount) * 100) >> 0) : 0}%`}
				className={classes.progressBar}
			/>
			<Grid item>
				<Typography variant="h4" className={classes.indexText} sx={{
					color: presentationStyle?.textColor,
				}}>
					{config.optionKeys[index]}
				</Typography>
			</Grid>
			<Grid item xs>
				<Box p={3}>
					<Html
						content={option.answerHtml || option.answer || OptionPlaceholder.OptionText.replace(OptionPlaceholder.IndexText, config.optionKeys[index])}
						fontSize={fontSize}
					/>
				</Box>
			</Grid>
			{showCorrectAnswers && (
				<Grid
					item
					xs={2}
					display="flex"
					alignItems="center"
					justifyContent="center">
					<Typography className={classes.votePercentages} sx={{
						color: presentationStyle?.textColor,
					}}>
						{Boolean(showPercentages) && (
							<>
								{(votePercentage * 100).toFixed(2)} %
							</>
						)}
						{!Boolean(showPercentages) && (
							<>
								{numberWithCommas(voteCount)} / {numberWithCommas(totalVoteCount)}
							</>
						)}
					</Typography>
				</Grid>
			)}
		</Grid >
    );
};

export default PlayQuestionOption;