import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

declare global {

    interface Window {
        newrelic: BrowserAgent;
    }
}

/**
 * https://newrelic.com/blog/how-to-relic/custom-instrumentation-with-the-new-relic-browser-agent
 */
export const initNewRelic = () => {
    /** Don't init in local development */
    if (process.env.NODE_ENV !== 'production') {

        return;
    }

    return new BrowserAgent({
        init: {
            distributed_tracing: {
                enabled: true
            },
            privacy: {
                cookies_enabled: true
            },
            ajax: {
                deny_list: ["bam.nr-data.net"]
            }
        },
        info: {
            beacon: "bam.nr-data.net",
            errorBeacon: "bam.nr-data.net",
            licenseKey: "a795d5b853",
            applicationID: "601396092",
            sa: 1
        },
        loader_config: {
            accountID: "907373",
            trustKey: "907373",
            agentID: "601396092",
            licenseKey: "a795d5b853",
            applicationID: "601396092"
        },
    });
}

export const getNewrelic = (callback: (newRelicBrowserAgent: BrowserAgent) => void) => {

    if(window.newrelic !== undefined) {

        try {

            callback?.(window.newrelic);

        } catch (error) {

            console.warn(error);
        }
    }
}