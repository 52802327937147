import { AnyAction } from "redux";
import { LanguageState } from "Types/languageTypes";


export const initialState: LanguageState = {
	language: 'en',
	updateLanguageLoading: false,
	translations: {}
}

export function languageReducer(state = initialState, action: AnyAction) {
	switch (action.type) {
		case 'SET_LANGUAGE': {
			return {
				...state,
				language: action.language
			}
		}
		case 'SET_LANGUAGE_LOADING': {
			return {
				...state,
				updateLanguageLoading: action.isLoading,
			}
		}
		case 'SET_TRANSLATIONS': {
			return {
				...state,
				translations: action.translations
			}
		}
		default:
			return state
	}
}