import { isEmpty } from 'lodash';
import { AnyAction } from "redux";
import { IdentificationQuestionAttributes, IdentificationState, QuestionTypes } from "Types/identificationQuestionTypes";

export const initialState: IdentificationState = {
    questions: [],
    questionDeleting: false,
}

export function identificationReducer(state : IdentificationState = initialState, action : AnyAction)
{
    switch (action.type)
    {
        case 'ADD_QUESTION': {

            const newQuestions = [ ...state.questions ]

            const newQuestion: IdentificationQuestionAttributes = {
                data: {
                    deleted: 0,
                    title: '',
                    isRequired: 0,
                    type: QuestionTypes.Text,
                    isIdentifier: 0,
                    quizIdentifier: 0,
                },
                answers: []
            }

            newQuestions.push(newQuestion);

            return {
                ...state,
                questions: [ ...newQuestions ]
            }
        }
        case 'SET_QUESTIONS': {

            const questions : Array<IdentificationQuestionAttributes> = Object.keys(action.questions).map(questionId => ({
                ...action.questions[questionId],
            })).sort((a, b) => Number(b.data.quizIdentifier) - Number(a.data.quizIdentifier))

            return {
                ...state,
                questions: [
                    ...questions
                ],
            }
        }
        case 'SET_QUESTION_DELETING': {

            return {
                ...state,
                questionDeleting: action.isDeleting
            }
        }
        case 'REMOVE_QUESTION': {

            const updatedQuestions = [ ...state.questions ];

            updatedQuestions.splice(action.questionIndex, 1);

            return {
                ...state,
                questions: updatedQuestions
            }
        }
        case 'UPDATE_QUESTION': {

            const updatedQuestions = [ ...state.questions ]

            if(!isEmpty(updatedQuestions[action.questionIndex])) {

                updatedQuestions[action.questionIndex] = {
                    data: {
                        ...updatedQuestions[action.questionIndex].data,
                        ...(action.questionProps?.data || {})
                    },
                    answers: [
                        ...(action.questionProps?.answers || [])
                    ],
                }
            }

            return {
                ...state,
                questions: [ ...updatedQuestions ]
            }
        }
        case 'SET_QUESTION_ANSWER': {

            const updatedQuestions = [ ...state.questions ]

            updatedQuestions[action.questionIndex].answers[action.index].allowedValues = action.value;

            return {
                ...state,
                questions: updatedQuestions
            }
        }
        case 'REMOVE_QUESTION_ANSWER': {

            const updatedQuestions = [ ...state.questions ]

            if(!isEmpty(updatedQuestions[action.questionIndex])) {

                const updatedAnswers = [
                    ...updatedQuestions[action.questionIndex].answers.filter((answer, index) => {

                        return index !== action.answerIndex;
                    })
                ]

                updatedQuestions[action.questionIndex].answers = updatedAnswers;
            }

            return {
                ...state,
                questions: updatedQuestions
            }
        }
        case 'ADD_QUESTION_ANSWER': {

            const updatedQuestions = [ ...state.questions ]

            const updatedAnswers = [
                ...(updatedQuestions[action.questionIndex]?.answers || []),
                {
                    allowedValues: "",
                    participantinfofieldsId: action.questionId,
                }
            ]

            updatedQuestions[action.questionIndex].answers = updatedAnswers

            return {
                ...state,
                questions: updatedQuestions
            }
        }
        case 'UPDATE_QUESTION_ANSWER': {

            const updatedQuestions = [ ...state.questions ]

            const updatedAnswers = [
                ...(updatedQuestions[action.questionIndex].answers || [])
            ];

            updatedAnswers[action.answerIndex] = {
                id: action.answerId,
                allowedValues: action.answerValue,
                participantinfofieldsId: action.questionIndex,
            },

            updatedQuestions[action.questionIndex].answers = updatedAnswers

            return {
                ...state,
                questions: updatedQuestions
            }
        }
        default:
            return state;
    }
}