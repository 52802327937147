import Grid from '@mui/material/Grid';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import PlaySlideTitlePreview from 'Atomic/molecules/PlaySlideTitlePreview/PlaySlideTitlePreview';
import Html from 'Components/common/util/html/Html';
import SessionLoginInfo from 'Components/play/templates/geometry_template/shared_components/session_login_info/SessionLoginInfo';
import OpenEndedSlideMessageList from 'Components/slides/shared/components/slide_messages/open_ended/list/OpenEndedSlideMessageList';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useInterval } from 'Scripts/intervalHook';
import { NavigationState } from 'Scripts/slideHelper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ParticipantResponseList } from 'Types/playTypes';
import { MessageRoundType } from 'Types/presentationTypes';
import React, { useMemo, useState } from 'react';
import { classes } from './style.css';

const questionStartDelayMs = 3500;

const fakeMessages = [
    "Anger is the ultimate destroyer of your own peace of mind.",
    "You cannot be anything if you want to be everything.",
    "A good laugh and a long sleep are the best cures in the doctor's book.",
    "A smile is an inexpensive way to change your looks.",
    "If you want to see a rainbow you have to learn to see the rain.",
    "Emotional empathy is what motivates us to help others.",
    "Don't be afraid. Be focused. Be determined. Be hopeful. Be empowered.",
    "Strive for progress and perfection will follow.",
    "Education is learning what you didn’t even know you didn’t know.",
    "Never go to a doctor whose office plants have died.",
    "Forgive your enemies, but never forget their names.",
    "Without action, you aren’t going anywhere.",
    "You always pass failure on the way to success.",
    "It always seems impossible until it is done.",
    "Happiness is the only thing that multiplies when you share it.",
    "The best time to plant a tree was 20 years ago. The second best time is now.",
    "Hold the vision, trust the process.",
]

let OpenEndedSlideSessionPreview = ({ slide }: SlideConsumerComponent<MessageRoundType>) => {

    const { presentationStyle } = usePresentationStyles();

    const slideProperties = getSlideProperties(slide);


    const [slideNavigationState, setSlideNavigationState] = useState<NavigationState>(NavigationState.BeforeOpen)

    const [onScreenMessages, setOnScreenMessages] = useState<ParticipantResponseList>({});

    const isActiveState = (state: NavigationState) => {

        return slideNavigationState === state;
    }

    useInterval(() => {

        addRandomWord();

    }, isActiveState(NavigationState.SlideOpen) ? 2500 : null)

    useInterval(() => {

        setSlideNavigationState(NavigationState.SlideOpen);

    }, isActiveState(NavigationState.BeforeOpen) ? questionStartDelayMs : null)

    const rotatedMessages = useMemo(() => {

        Object.keys(onScreenMessages).forEach((messageId, index) => {

            onScreenMessages[messageId].position = ((onScreenMessages[messageId].position || index) + 1) % Object.keys(onScreenMessages).length;
        })

        return Object.keys(onScreenMessages).sort((a, b) => {

            return onScreenMessages[b].position - onScreenMessages[a].position

        }).map((key) => ({
            ...onScreenMessages[key],
        }));

    }, [onScreenMessages])

    const addRandomWord = () => {

        const randomMessage = fakeMessages[fakeMessages.length * Math.random() >> 0];

        let newMessages = {
            [randomMessage]: {
                id: randomMessage,
                text: randomMessage,
            },
            ...onScreenMessages
        }

        setOnScreenMessages({ ...newMessages })
    }

    const currentMessages = rotatedMessages.slice(0, slide?.nrOfMessageShapes || 3)

    return <>
        <Grid
            container
            className={classes.questionContainer}
            sx={{
                color: presentationStyle?.textColor,
            }}
            direction="row"
            justifyContent="center">
            <Grid
                container
                item
                xs={12}
                className={`${classes.detailsContainer} ${isActiveState(NavigationState.BeforeOpen) && classes.upSize}`}
            >
                <LayoutWrapper
                    layoutType={slide.slideLayout}
                    imageURL={slide?.file?.base64 || slide?.filename}
                    imageStyle={slide.filenameStyle}
                    backgroundOpacity={slide.backgroundOpacity}
                    backgroundColor={slide.backgroundColor}
                    videoURL={slide.videoURL}
                    interactive={false}
                    title={(
                        <PlaySlideTitlePreview
                            showPreview={isActiveState(NavigationState.BeforeOpen)}
                            title={(
                                <DelayedComponent
                                    animationDuration={250}
                                    animationType={AnimationTypes.Grow}
                                    showAfter={500} >
                                    <Html
                                        content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                        fontSize={slideProperties.slideSettings.titleFontSize}
                                    />
                                </DelayedComponent>
                            )}
                        />
                    )}
                    content={(
                        <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            direction="column"
                            className={classes.openEndedGrid}>
                            {isActiveState(NavigationState.SlideOpen) && (
                                <OpenEndedSlideMessageList
                                    slide={slide}
                                    messages={currentMessages}
                                />
                            )}
                        </Grid>
                    )}
                />
            </Grid>
        </Grid>
        <SessionLoginInfo />
    </>;
};


export default OpenEndedSlideSessionPreview
