import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import ContainerWithBorder from 'Atomic/atoms/ContainerWithBorder/ContainerWithBorder';
import AppSpinner from 'Atomic/atoms/Spinner/AppSpinner';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import TwoCtaDialog from "Atomic/atoms/TwoCtaDialog/TwoCtaDialog";
import GenerateAiPresentationWaitDialog from 'Components/common/dialogs/generate_ai_presentation/GenerateAiPresentationWaitDialog';
import useOutline from 'Hooks/useOutline';
import { useTranslations } from 'Hooks/useTranslations';
import { ISlideOutline, TDialogTypeObj } from 'Types/outlineTypes';
import React, { ChangeEvent, useEffect, useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { RewriteTypeEnum } from '../RewriteTypeEnum';
import SlideOutline from "../SlideOutline/SlideOutline";
import { classes } from "./style.css";

const defaultSlideOutline = {title: "", content: "", type: "title"};
  
const OutlineLogicContainer = ()=> {
    const inputObjValues = useSelector((state: RootStateOrAny) => state.generatePresentationReducer.flatInputObjValues);
    const { translatePlaceholder } = useTranslations();
    
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [dialogTypeObj, setDialogTypeObj] = useState<TDialogTypeObj>({dialogType: "", index: 0});
    const [slideOutlineSelected, setSlideOutlineSelected] = useState<number|null>(null);

    const {
        outline,
        rewriteSuggestions,
        isLoading,
        isLoadingRewrite,
        generatePresentationLoading,
        setOutline,
        generateOutline,
        generateRewriteOutline,
        generateOutlineProcess,
    } = useOutline();

    useEffect(()=> {
        generateOutline(inputObjValues);
    },[inputObjValues]);

    useEffect(()=> {
        if(rewriteSuggestions.length > 0) updateOutlineWithRewritedSuggestion();
    },[rewriteSuggestions]);

    useEffect(()=> {
        if(outline.length == 0) addSlideOutline(-1);
    },[outline]);

    const updateOutlineWithRewritedSuggestion = ()=> {
        let updatedOutline = [...outline];
        updatedOutline[dialogTypeObj.index] = {
            ...updatedOutline[dialogTypeObj.index],
            ["title"]: rewriteSuggestions[0].title,
            ["content"]: rewriteSuggestions[0].content
        }
        setOutline([...updatedOutline]);
    }

    const setInputValueByName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number)=> {
        outline[index][e.target.name as keyof ISlideOutline] = e.target.value;
        setOutline([...outline]);
    }

    const addSlideOutline = (index: number)=> {
        outline.splice(index + 1, 0, {...defaultSlideOutline});
        setOutline([...outline]);
    }

    const configureAndToggleDialog = (dialogType: "ai"|"remove", index: number)=> {
        setDialogTypeObj({dialogType: dialogType, index: index});
        if(dialogType == "ai" && outline[index]["content"].length == 0 && outline[index]["title"].length == 0){completeWithAi()} else {
            setDialogIsOpen(true);
        };
    }

    const deleteSlideOutline = ()=> {
        outline.splice(dialogTypeObj.index, 1);
        setOutline([...outline]);
        setDialogIsOpen(false);
    }

    const completeWithAi = ()=> {
        let rewriteOutline = [...outline];
        rewriteOutline[dialogTypeObj.index] = {
            ...rewriteOutline[dialogTypeObj.index],
            ['rewrite']: RewriteTypeEnum.Both
        };
        rewriteOutline = {
            ['outline']: [...rewriteOutline],
            ...inputObjValues.flatInputObjValues
        }
        generateRewriteOutline(rewriteOutline);
        setDialogIsOpen(false);
    }

    const generatePresentation = ()=> {
        let finalOutline = [...outline];
        finalOutline = {
            ['outline']: [...finalOutline],
            ...inputObjValues.flatInputObjValues
        }
        generateOutlineProcess(finalOutline);
    }

    const setNewType = (type: string, index: number)=> {
        let updateOutline = [...outline];
        updateOutline[index] = {
            ...updateOutline[index],
            ['type']: type
        };
        setOutline([...updateOutline]);
    }

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;
    
        if (!destination) {
          return;
        }
        let draggedOutline = [...outline];
        const [movedItem] = draggedOutline.splice(source.index, 1);
        draggedOutline.splice(destination.index, 0, movedItem);
        if(slideOutlineSelected != null) setSlideOutlineSelected(destination.index);
        setOutline([...draggedOutline]);
    };

    const onToggleSlideOutlineSelected = (selectedIndex: number)=> {
        if(selectedIndex == slideOutlineSelected){
            setSlideOutlineSelected(null);
        } else {
            setSlideOutlineSelected(selectedIndex);
        }
    }

    const dialogsContext = [
        {
            dialogType: "remove",
            title:  `${translatePlaceholder("ARE_YOU_SURE_YOU_WANT_TO_REMOVE_SLIDE_NUMBER")} "${dialogTypeObj.index + 1}" ?`,
            leftCtaIcon: <DeleteIcon />, 
            leftCtaLabel: translatePlaceholder("REMOVE"),
            leftCtaFc: deleteSlideOutline,
            variant: AppButtonVariant.Success
        },
        {
            dialogType: "ai",
            title: translatePlaceholder("ARE_YOU_SURE_YOU_WANT_TO_COMPLETE_WITH_AI"),
            leftCtaIcon: <AutoAwesomeOutlinedIcon  fontSize="small"/>, 
            leftCtaLabel: translatePlaceholder("COMPLETE_WITH_AI"),
            leftCtaFc: completeWithAi,
            variant: AppButtonVariant.Success
        }
    ];

    const dialogContext = dialogsContext.find((context)=> context.dialogType == dialogTypeObj.dialogType);
    
    return  isLoading ?
        <>
            <ContainerWithBorder> 
                <div className={classes.outlinePageTitle}>
                    <AppText as={AppTextVariant.BodySmallLight}>{translatePlaceholder("OUTLINE_EDITOR")}<InfoOutlinedIcon className={classes.infoIcon}/></AppText>
                </div>
                <div className={classes.outlineLoaderMsgContainer}>
                    <AppSpinner/>
                    <AppText className={classes.outlineLoaderMsg} variant="caption">
                        {translatePlaceholder("OUTLINE_EDITOR_GENERATE_WAIT_MSG")}
                    </AppText>
                </div>
            </ContainerWithBorder>
            <div className={classes.generatePresentationFooter}>
                <AppButton
                    className={classes.generatePresentationButton}
                    onClick={()=> generatePresentation()}
                    disabled={true}
                    as={AppButtonVariant.Success}>
                        {translatePlaceholder("GENERATE_PRESENTATION")}
                </AppButton>
            </div>
        </> 
    :
        <>
            <ContainerWithBorder> 
                <div className={classes.outlinePageTitle}>
                    <AppText as={AppTextVariant.BodySmallLight}>{translatePlaceholder("OUTLINE_EDITOR")}<InfoOutlinedIcon className={classes.infoIcon}/></AppText>
                </div>
                <DragDropContext
                    onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                        {
                            outline.map((slideOutline, index)=> {
                                return (
                                    <Draggable key={`draggable-${index+1}`} draggableId={`id-${index+1}`} index={index}>
                                        {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <SlideOutline
                                                key={`slide-outline-${index + 1}`}
                                                title={slideOutline?.title} 
                                                content={slideOutline?.content} 
                                                type={slideOutline?.type}
                                                index={index}
                                                isLoading={isLoadingRewrite}
                                                dialogTypeObj={dialogTypeObj}
                                                onChangeValue={setInputValueByName}
                                                addSlideOutline={addSlideOutline}
                                                deleteSlideOutline={configureAndToggleDialog}
                                                completeWithAi={configureAndToggleDialog}
                                                isSelected={slideOutlineSelected == index}
                                                onToggleSlideOutlineSelected={onToggleSlideOutlineSelected}
                                                setNewType={setNewType}
                                            />
                                        </div>
                                    )}
                                    </Draggable>
                                )
                            }) 
                        }
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>
            </ContainerWithBorder>
            <div className={classes.generatePresentationFooter}>
                <AppButton
                    className={classes.generatePresentationButton}
                    onClick={()=> generatePresentation()}
                    disabled={false}
                    as={AppButtonVariant.Success}>
                        {translatePlaceholder("GENERATE_PRESENTATION")}
                </AppButton>
            </div>
            <GenerateAiPresentationWaitDialog
                open={generatePresentationLoading}
            />
            <TwoCtaDialog 
                isOpen={dialogIsOpen}
                close={()=> setDialogIsOpen(false)}
                title={dialogContext?.title ?? ""} 
                leftCtaIcon={dialogContext?.leftCtaIcon || <></>} 
                leftCtaLabel={dialogContext?.leftCtaLabel ?? ""} 
                leftCtaFc={dialogContext?.leftCtaFc!}
                leftCtaVariant={dialogContext?.variant}
            />
        </>
}
export default OutlineLogicContainer;