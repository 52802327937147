import { mdiSendOutline } from '@mdi/js';
import Icon from '@mdi/react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ParticipantResponseList } from 'Types/playTypes';
import { MessageRoundType } from 'Types/presentationTypes';
import { isEmpty } from 'lodash';
import React from 'react';
import FlipMove from 'react-flip-move';
import QASlideMessage from '../item/QASlideMessage';
import { classes } from './style.css';

interface QASlideMessageListProps extends SlideConsumerComponent<MessageRoundType> {
	messages: ParticipantResponseList;
}

const QASlideMessageList = ({ slide, messages }: QASlideMessageListProps) => {


	const getNewestMessage = () => {

		if (!isEmpty(messages)) {

			const sortedMessageIdsByTimestamp = Object.keys(messages).sort((firstKey, secondKey) => {

				return new Date(messages[secondKey].timestamp).getTime() - new Date(messages[firstKey].timestamp).getTime()
			})

			const [newestMessageId] = sortedMessageIdsByTimestamp

			return messages[newestMessageId]
		}

		return {};
	}

	const sortMessageIdsByUpvotes = (): Array<number> => {

		return Object.keys(messages).sort((firstMessageId, secondMessageId) => {

			return messages[secondMessageId].upvoteCount - messages[firstMessageId].upvoteCount
		})
	}

	const getCurrentMessages = () => {

		return sortMessageIdsByUpvotes().slice(0, slide?.nrOfMessageShapes || 3)
	}

	const messagesWithoutNewestMessage = () => {

		const newestMessage = getNewestMessage();

		const currentMessages = getCurrentMessages();

		return currentMessages.filter(messageId => messageId !== newestMessage.id)
	}

	return (
        <Grid
			item
			container
			justifyContent="flex-start"
			alignItems="center"
			direction="row"
			className={classes.messageGrid}>
			<Grid item xs={12}>
				<FlipMove className={classes.flipMoveContainer}>
					{!isEmpty(messages) && Object.keys(messages).length > 1 && messagesWithoutNewestMessage().map((messageId: number) => (
						<div key={messageId}>
							<QASlideMessage message={messages[messageId]} />
						</div>
					))}
				</FlipMove>
			</Grid>
			<Grid item xs={12}>
				{!isEmpty(messages) && <>
					<FullWidthContainer>
						{Object.keys(messages).length > 1 && <>
							<Typography className={classes.newestMessageText} pb={1}>
								<Icon path={mdiSendOutline} className={classes.newestMessageIcon} /> Newest message
							</Typography>
						</>}
						<QASlideMessage isNew={true} key={getNewestMessage().id} message={getNewestMessage()} />
					</FullWidthContainer>
				</>}
			</Grid>
		</Grid>
    );
};

export default QASlideMessageList
