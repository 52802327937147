import { Box } from '@mui/material';
import { TableCellData } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import AppDynamicTablePreview from 'Atomic/organisms/AppDynamicTable/preview/AppDynamicTablePreview';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import Html from 'Components/common/util/html/Html';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import React from 'react';

const TableSlideSession = ({ slide }: SlideConsumerComponent<ContentSlideType>) => {

	const { presentationStyle } = usePresentationStyles();

	const slideProperties = getSlideProperties(slide);

	return (
		<LayoutWrapper
			layoutType={slide.slideLayout}
			videoURL={slide.videoURL}
			imageURL={slide?.file?.base64 || slide?.filename}
			imageStyle={slide.filenameStyle}
			backgroundOpacity={slide.backgroundOpacity}
			backgroundColor={slide.backgroundColor}
			title={(
				<FullWidthContainer>
					<Box p={6}>
						<Html
							content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
							fontSize={slideProperties.slideSettings.titleFontSize}
						/>
					</Box>
				</FullWidthContainer>
			)}
			content={(
				<FullWidthContainer>
					<Box p={6}>
						<AppDynamicTablePreview
							data={slide.table as Array<Array<TableCellData>>}
							cellStyles={{
								borderColor: presentationStyle?.textColor,
								color: presentationStyle?.textColor,
							}}
						/>
					</Box>
				</FullWidthContainer>
			)}
		/>
	);
};

export default TableSlideSession;