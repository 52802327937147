import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import 'Components/app/App.css';
import React, { ReactNode } from 'react';

interface CustomThemeProviderProps {
	children: ReactNode
}

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#21003c',
        },
        secondary: {
            main: '#8B6EC0'
        },
        success: {
            main: '#2DAD83'
        },
        warning: {
            main: '#F6B200',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 16,
                }
            }
        },
    },
});

// Design : H1
theme.typography.h1 = {
    fontFamily: 'Mulish',
    fontSize: '34px',
    color: '#1b1423',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: 2
}

// Design : H2
theme.typography.h2 = {
    fontFamily: 'Mulish',
    fontSize: '24px',
    fontWeight: 500,
    color: '#1b1423',
    letterSpacing: 0,
    lineHeight: 1.5,
}

// Design : H3
theme.typography.h3 = {
    fontFamily: 'Mulish',
    fontWeight: 500,
    fontSize: '18px',
    color: '#1B1423',
    letterSpacing: '.18px',
}

// Design : Body Text Dark 1B1423 18px
theme.typography.h4 = {
    fontFamily: 'Mulish',
    fontWeight: 500,
    fontSize: '18px',
    color: '#1B1423',
    letterSpacing: 0,
    lineHeight: 1.2
}

// Design : Subtext #5D526D 16px
theme.typography.h5 = {
    fontFamily: 'Mulish',
    fontSize: '16px',
    color: '#5D526D',
    letterSpacing: 0,
}

// Design : Prompttext #1B1423 14px
theme.typography.h6 = {
    fontFamily: 'Mulish',
    fontSize: '14px',
    color: '#1B1423',
    letterSpacing: 0,
    fontWeight: 500,
}

// Design : Body text light 857c93 18px
theme.typography.body1 = {
    fontFamily: 'Mulish',
    fontWeight: 500,
    fontSize: '16px',
    color: '#857C93',
    letterSpacing: 0,
}

// Design: Body text light 5d526d 18px
theme.typography.body2 = {
    fontFamily: 'Mulish',
    fontSize: '18px',
    color: '#5d526d',
    fontWeight: 500,
    letterSpacing: 0,
}

// Design : Subtitle 857C93
theme.typography.subtitle1 = {
    fontFamily: 'Mulish',
    fontSize: '16px',
    color: '#857C93',
    letterSpacing: 0,
}

// Design: Subtext 857C93
theme.typography.subtitle2 = {
    fontFamily: 'Mulish',
    fontSize: '16px',
    color: '#857C93',
    letterSpacing: 0,
    fontWeight: 500,
}

// Design: caption 5d526d 14px
theme.typography.caption = {
    fontFamily: 'Mulish',
    fontSize: '14px',
    color: '#5d526d',
    letterSpacing: 0,
    fontWeight: 500,
}

// General button
theme.typography.button = {
    fontFamily: 'Mulish',
    fontSize: '14px',
    textTransform: 'none',
    color: '#21003C',
    letterSpacing: 0,
}

const CustomThemeProvider = ({ children } : CustomThemeProviderProps) => {
	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default CustomThemeProvider;
