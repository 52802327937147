import { CircularProgress } from '@mui/material';
import AppText from 'Atomic/atoms/Text/AppText';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { SlideConsumerComponent } from 'Types/appTypes';
import { DefaultSlideValues, SlideType } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';

const PreviewQuizCountdownSlideShape = ({
    slide
}: SlideConsumerComponent<SlideType>) => {

    const { presentationStyle } = usePresentationStyles();

    return (
        <div className={classes.countdownContainer}>
            <CircularProgress
                variant="determinate"
                value={75}
                className={classes.progress}
                sx={{
                    color: presentationStyle?.textColor,
                }}
            />
            <AppText
                className={classes.countdownValue}
                style={{
                    color: presentationStyle?.textColor,
                }}>
                {slide.dynamicTimerLimit || DefaultSlideValues.DefaultTimerValue}
            </AppText>
        </div>
    )
}

export default PreviewQuizCountdownSlideShape;