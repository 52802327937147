/**
 * Pass a string to format it to JSON format
 */
export const parseJsonSafe = (stringOrJson: string | object) => {

    try {

        if (typeof stringOrJson === "object") {

            return stringOrJson;
        }

        return JSON.parse(stringOrJson || "{}");

    } catch (error) {

        console.warn(error);

        return {};
    }
}