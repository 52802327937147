import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';
import "./Styles.css";


interface CustomTooltipProps extends TooltipProps {
	children: any;
}

const CustomTooltip = (props: CustomTooltipProps) => {

	const { children } = props;

	return (
		<Tooltip
			classes={{
				tooltip: "app-tooltip",
				arrow: "app-tooltip-arrow",
				...props.classes,
			}}
			enterDelay={250}
			enterTouchDelay={0}
			leaveDelay={0}
			leaveTouchDelay={5000}
			arrow
			{...props}>
			{children}
		</Tooltip>
	);
};

export default CustomTooltip;