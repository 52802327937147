import { AppTranslationActionTypes } from "Reducers/appTranslationReducer";
import { AppTranslationItems } from "Types/appTranslationTypes";
import { AvailableLanguages, defaultAvailableLanguages, defaultLanguage } from "Types/languageTypes";

export const setAppLanguage = (language: string = defaultLanguage) => ({
	type: AppTranslationActionTypes.SetAppLanguage,
	language
})

export const setAppAvailableLanguages = (availableLanguages: AvailableLanguages[] = defaultAvailableLanguages) => ({
	type: AppTranslationActionTypes.SetAppAvailableLanguages,
	availableLanguages
})

export const setAppTranslations = (translations: AppTranslationItems) => ({
	type: AppTranslationActionTypes.SetApptranslations,
	translations
})

export const setAppUpdateLanguageLoading = (isLoading: boolean) => ({
	type: AppTranslationActionTypes.SetAppLanguageLoading,
	isLoading,
})