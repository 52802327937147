
/* https://stackoverflow.com/questions/36672561/how-to-exit-fullscreen-onclick-using-javascript */
export const isFullScreen = () : boolean => {

	const documentElement : Document = document;

	return (documentElement.fullscreenElement && documentElement.fullscreenElement !== null) ||
		(documentElement['webkitFullscreenElement'] && documentElement['webkitFullscreenElement'] !== null) ||
		(documentElement['mozFullScreenElement'] && documentElement['mozFullScreenElement'] !== null) ||
		(documentElement['msFullscreenElement'] && documentElement['msFullscreenElement'] !== null);
}

export const activateFullScreen = async () => {
	try {
		const targetElement: any = document.documentElement;
		if (targetElement.requestFullscreen) {
			await targetElement.requestFullscreen();
		} else if (targetElement.mozRequestFullScreen) {
			targetElement.mozRequestFullScreen();
		} else if (targetElement.webkitRequestFullScreen) {
			targetElement.webkitRequestFullScreen();
		} else if (targetElement.msRequestFullscreen) {
			targetElement.msRequestFullscreen();
		}
	} catch (error) {
		console.warn('Unable to close full screen', error)
	}
}

export const deactivateFullScreen = async () => {

	const documentElement: any = document;

	try {
		if (documentElement.exitFullscreen) {
			await documentElement.exitFullscreen();
		} else if (documentElement.webkitExitFullscreen) {
			documentElement.webkitExitFullscreen();
		} else if (documentElement.mozCancelFullScreen) {
			documentElement.mozCancelFullScreen();
		} else if (documentElement.msExitFullscreen) {
			documentElement.msExitFullscreen();
		}
	} catch (error) {
		console.warn('Unable to close full screen', error)
	}
}