import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Box } from '@mui/material';
import { useIdentification } from 'Hooks/useIdentification';
import React, { Fragment } from 'react';
import { testIds } from 'Scripts/cypressTestIds';
import { IdentificationQuestionAttributes, QuestionTypes } from 'Types/identificationQuestionTypes';
import Question from '../shared/question/Question';
import EmptyIdentificationAnswer from './answers/empty/EmptyIdentificationAnswer';
import IdentificationAnswer from './answers/filled/IdentificationAnswer';

interface IdentificationQuestionProps {
    question?: IdentificationQuestionAttributes;
    questionIndex: number;
}

const IdentificationQuestion = ({ question = {}, questionIndex }: IdentificationQuestionProps) => {

    const {
        data = {
            id: null,
            type: QuestionTypes.Text,
            title: '',
            isRequired: false,
        },
        answers = []
    } = question;

    const {
        questions,
        questionDeleting,
        updateIdentificationQuestion,
        removeIdentificationQuestion,
    } = useIdentification();

    const isQuizIdentifier = Boolean(question.data?.quizIdentifier);

    return (
        <Question
            totalQuestionCount={questions.length}
            questionId={data.id}
            index={questionIndex}
            value={data.title}
            type={data.type}
            isRequired={data.isRequired}
            answerComponents={<>
                {answers.map((answer, answerIndex) => {
                    return <Fragment key={`${questionIndex}_${answer?.id || answerIndex}`}>
                        <IdentificationAnswer
                            questionIndex={questionIndex}
                            answer={answer}
                            answerIndex={answerIndex}
                            questionType={data.type}
                        />
                    </Fragment>
                })}
                <EmptyIdentificationAnswer
                    questionIndex={questionIndex}
                    questionType={data.type}
                    questionId={data.id}
                />
            </>}
            update={updateIdentificationQuestion}
            remove={removeIdentificationQuestion}
            canDelete={questions.length > 1 && !isQuizIdentifier}
            disabled={questionDeleting || isQuizIdentifier}
            testIdPrefix={testIds.IDENTIFICATION_QUESTION_PREFIX}
            dataPrefix="identification"
            startAdornment={isQuizIdentifier && (
                <Box mr={2}>
                    <EmojiEventsIcon />
                </Box>
            )}
        />
    );
};

export default IdentificationQuestion;