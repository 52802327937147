import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid } from "@mui/material";
import ContainerWithBorder from 'Atomic/atoms/ContainerWithBorder/ContainerWithBorder';
import AppSpinner from 'Atomic/atoms/Spinner/AppSpinner';
import AppText, { AppTextVariant } from "Atomic/atoms/Text/AppText";
import useOutline from 'Hooks/useOutline';
import { useTranslations } from 'Hooks/useTranslations';
import { ISlideOutline } from 'Types/outlineTypes';
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { classes } from "./style.css";

const Overview = ()=> {
    const outline = useSelector((state: RootStateOrAny) => (state.outlineReducer).outline);

    const {
        isLoading,
    } = useOutline();

    const { translatePlaceholder } = useTranslations();

    return (
        <ContainerWithBorder>
            {isLoading ? <><AppText mb={"10px"} as={AppTextVariant.BodySmallLight}>{translatePlaceholder("OVERVIEW")}<InfoOutlinedIcon className={classes.infoIcon}/></AppText><AppSpinner/></> :
                <> 
                    <AppText mb={"10px"} as={AppTextVariant.BodySmallLight}>{translatePlaceholder("OVERVIEW")}<InfoOutlinedIcon className={classes.infoIcon}/></AppText>
                    <Grid container rowGap={0.5}>
                        {outline.map((outlineSlide: ISlideOutline, index: number) => (
                            <Fragment key={`overview-${index}`}>
                                <Grid className={classes.type} item xs={12} md={4}>
                                    <AppText as={AppTextVariant.FifthBgSmall}>{outlineSlide?.type ? translatePlaceholder(outlineSlide.type.toUpperCase()) : ""}</AppText>
                                </Grid>
                                <Grid className={classes.title} item xs={12} md={8}>
                                    <AppText as={AppTextVariant.TertiaryBgSmall}>{`${index + 1}. ${outlineSlide?.title ? outlineSlide.title : "--"}`}</AppText>
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>
                </>
               
            }
        </ContainerWithBorder>
    );
}
export default Overview;
