import festiveBackground from 'Assets/images/festive_background.webp';
import React, { ReactElement } from "react";
import { classes } from './style.css';

interface IErrorLayout {
    children: ReactElement;
}

const ErrorLayout = ({children}: IErrorLayout)=> {
    return(
        <div className={classes.background} style={{backgroundImage: `url(${festiveBackground})`}}>
            <div className={classes.dialog}>
                <div className={classes.filler} />
                    {children}
                <div className={classes.filler} />
            </div>
        </div>
    );
}
export default ErrorLayout;