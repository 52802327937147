
export const addToLocalStorage = (key: string, value: any) : string|boolean => {
    try {
        const localStorage = window.localStorage;
        localStorage.setItem(key, value);
        const storedItem = getFromLocalStorage(key);
        if (storedItem && typeof storedItem !== 'undefined') {
            return storedItem;
        }
    } catch (error) {
        console.warn(error)
    }
    return false;
}

export const getFromLocalStorage = (key: string) : string|boolean => {
    try {
        const storedObject = window.localStorage.getItem(key);
        if (storedObject && typeof storedObject !== 'undefined') {
            return storedObject;
        }
    } catch (error) {
        console.warn(error)
    }
    return false;
}

export const removeFromLocalStorage = (key: string) : boolean => {
    try {
        if (getFromLocalStorage(key)) {
            window.localStorage.removeItem(key);
            if (!getFromLocalStorage(key)) {
                return true;
            }
        }
    } catch (error) {
        console.warn(error)
    }
    return false;
}