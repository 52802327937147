import ParticipantEmojiToggle from 'Components/beta/settings/presentation_summary/options/general/emoji/ParticipantEmojiToggle';
import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import React from 'react';
import TimerCountdownSetting from '../../../items/countdown/TimerCountdownSetting';

const QuizCountdownSettings = () => {
    return (
        <>
            <TimerCountdownSetting />
            <LoginCodeEditor />
            <ParticipantEmojiToggle/>
        </>
    );
};

export default QuizCountdownSettings;