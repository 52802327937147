import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { setFadeMusicOut } from 'Actions/audioActions';
import Html from 'Components/common/util/html/Html';
import DarkTransitionAnimation from 'Components/play/templates/geometry_template/animations/components/dark/DarkTransitionAnimation';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useSlideNavigation } from 'Hooks/useSlideNavigation';
import { useInterval } from 'Scripts/intervalHook';
import { SlideConsumerComponent } from 'Types/appTypes';
import { PlaySlideType, PlayState } from 'Types/playTypes';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { classes } from './style.css';


const QuizCountdownSlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {

    const runTimers = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).runTimers);

    const slideProperties = getPlaySlideProperties(slide);

    const {
        navigateToNextSlideState
    } = useSlideNavigation();

    const dispatch = useDispatch();

    /* Countdown before the quiz actually starts */
    const [countdown, setCountdown] = useState((slide.dynamicTimerLimit) as number);

    /* Determines wether the page transition should be played */
    const [playTransition, setPlayTransition] = useState(false);

    const { presentationStyle } = usePresentationStyles();


    /*
        Every second, assuming the countdown is higher than 1,
        decrement the countdown untill it reaches 0
    */
    useInterval(() => {

        setCountdown(countdown - 1);

    }, (!!countdown && runTimers) ? 1000 : null)

    /*
        After the page transition has played,
        wait for 2.5s and navigate to the next slide
    */
    useInterval(() => {

        navigateToNextSlideState();

    }, playTransition ? 1500 : null)

    /*
        Whenever countdown changes, check if its still higher than 0.
        If not, navigate to next slide
    */
    useEffect(() => {

        dispatch(setFadeMusicOut(true))

        if (!countdown) {

            setPlayTransition(true)
        }

    }, [countdown])

    return (
        <div className={classes.wrapper}>
            <DarkTransitionAnimation show={playTransition} />
            <Fade in={true}>
                <Grid
                    style={{ height: '100%' }}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    <Grid item>
                        <Typography
                            variant="h1"
                            className={classes.countdownCounter}
                            sx={{
                                color: presentationStyle?.textColor,
                            }}>
                            {countdown}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.startingSoon}>
                        <Html
                            content={slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                            fontSize={slideProperties.slideSettings.titleFontSize}
                        />
                    </Grid>
                </Grid>
            </Fade>
        </div >
    );
};

export default QuizCountdownSlideSession