import { AnyAction } from "redux";
import { LAST_SELECTED_PRESENTATION_PAGE } from "Scripts/globals";
import { quizResultSortProperties, quizSortProperties, sortDirections } from "Scripts/listHelper";
import { addToLocalStorage, getFromLocalStorage } from "Scripts/localStorage";
import { OverviewState } from "Types/overviewTypes";

export const DEFAULT_ITEMS_PER_PAGE_COUNT = 4;

export const initialState: OverviewState = {
    searchValue: "",
    presentationLoading: false,
    resultLoading: false,
    sortProperty: quizSortProperties.DATE,
    resultSortProperty: quizResultSortProperties.START_TIME,
    sortDirection: sortDirections.DESC,
    selectedOverviewTab: 0,
    selectedResult: null,
    quizResults: {},
    presentationIdToRemove: null, // to overviewActions
    presentationOptionsAnchor: null,
    /* Todo table-ify this */
    presentationPageData: [],
    presentationPageLimit: DEFAULT_ITEMS_PER_PAGE_COUNT,
    presentationPageIndex: Number(getFromLocalStorage(LAST_SELECTED_PRESENTATION_PAGE)),
    presentationsTotalResultsCount: 0,
    presentationFilteredCount: 0,
}

export function overviewReducer(state: OverviewState = initialState, action: AnyAction) {

    switch (action.type) {
        case 'SET_PRESENTATION_PAGE_DATA': {
            return {
                ...state,
                presentationPageData: action.data.pageData,
                presentationsTotalResultsCount: action.data.totalResultsCount,
                presentationFilteredCount: action.data.filteredResultsCount,
            }
        }
        case 'SET_PRESENTATION_PAGE_LIMIT': {
            return {
                ...state,
                presentationPageLimit: action.limit,
                presentationPageIndex: 0,
            }
        }
        case 'SET_PRESENTATION_PAGE_INDEX': {

            addToLocalStorage(LAST_SELECTED_PRESENTATION_PAGE, action.index);

            return {
                ...state,
                presentationPageIndex: action.index
            }
        }
        case 'SET_PRESENTATIONS_OPTIONS_ANCHOR': {
            return {
                ...state,
                presentationOptionsAnchor: action.anchor
            }
        }
        case 'CLEAR_OVERVIEW_STATE': {
            return {
                ...initialState
            }
        }
        case 'SET_QUIZ_TO_REMOVE': {
            return {
                ...state,
                presentationIdToRemove: action.presentationId
            }
        }
        case 'SET_SELECTED_RESULT_ITEM': {
            return {
                ...state,
                selectedResult: action.item
            }
        }
        case 'SET_RESULT_LOADING': {
            return {
                ...state,
                resultLoading: action.isLoading,
            }
        }
        case 'SET_ALL_RESULTS': {
            return {
                ...state,
                quizResults: action.data
            }
        }
        case 'SET_OVERVIEW_TAB': {
            return {
                ...state,
                selectedOverviewTab: action.tab
            }
        }
        case 'SET_PRESENTATION_LOADING': {
            return {
                ...state,
                presentationLoading: action.isLoading
            }
        }
        case 'SET_RESULT_SORT_PROPERTY': {
            return {
                ...state,
                resultSortProperty: action.property
            }
        }
        case 'SET_SORT_PROPERTY': {
            return {
                ...state,
                sortProperty: action.property,
            }
        }
        case 'SET_SORT_DIRECTION': {
            return {
                ...state,
                sortDirection: action.direction
            }
        }
        case 'RESET_STATE': {
            return {
                state: {
                    ...initialState
                }
            }
        }

        case 'SET_SEARCH_VALUE': {
            return {
                ...state,
                searchValue: action.value,
                presentationPageIndex: 0,
            }
        }
        default:
            return state
    }
}