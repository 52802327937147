//themeVars.color.defaultBg

import { themeVars } from "Components/theme/vanillaTheme.css";
import React, { ReactElement } from "react";
import { classes } from "./style.css";

interface IContainerWithBorder {
    children: ReactElement[] | ReactElement;
    borderColor?: string;
}

const ContainerWithBorder = ({children, borderColor = themeVars.color.defaultBg} : IContainerWithBorder)=> {
    return(<div 
                style={{borderColor: borderColor}} 
                className={classes.container}>
                    {children}
            </div>);
}
export default ContainerWithBorder;