import { Box } from '@mui/material';
import WysiwygEditorContainer from 'Atomic/organisms/WysiwygEditor/variants/WysiwygEditorContainer';
import FullHeightContainer from 'Components/common/FullHeightContainer';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import { WysiwygToolBarType } from 'Components/common/util/wysiwygEditor/WysiwygEditor';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import useSlideActions from 'Hooks/useSlideActions';
import React from 'react';
import { testIds } from 'Scripts/cypressTestIds';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType, EditorSlideField, StringReturnFunction } from 'Types/presentationTypes';


const ContentSlideEditor = ({
    slide
}: SlideConsumerComponent<ContentSlideType>) => {

    const { setSlideSetting, } = useSlideActions();

    const slideProperties = getSlideProperties(slide);

    return (
        <FullHeightContainer data-testid={testIds.SLIDE_TYPE_TITLE_AND_TEXT}>
            <LayoutWrapper
                imageStyle={slide.filenameStyle}
                backgroundOpacity={slide.backgroundOpacity}
                backgroundColor={slide.backgroundColor}
                interactive={true}
                layoutType={slide.slideLayout}
                title={(
                    <FullWidthContainer>
                        <Box p={4}>
                            <WysiwygEditorContainer
                                value={slide.title}
                                getPlaceholderHtml={slideProperties.text.getHtmlTitlePlaceholder}
                                updateValue={newTitle => setSlideSetting(EditorSlideField.Title, newTitle, slide.id)}
                                placeholderText={slideProperties.text.titlePlaceholder}
                                fontSize={slideProperties.slideSettings.titleFontSize}
                                toolbarType={WysiwygToolBarType.Simple}
                            />
                        </Box>
                    </FullWidthContainer>
                )}
                content={(
                    <FullWidthContainer>
                        <Box p={4}>
                            <WysiwygEditorContainer
                                value={slide.content}
                                getPlaceholderHtml={slideProperties.text.getHtmlContentPlaceholder as StringReturnFunction}
                                updateValue={newContent => setSlideSetting(EditorSlideField.Content, newContent, slide.id)}
                                placeholderText={slideProperties.text.contentPlaceholder as string}
                                toolbarType={WysiwygToolBarType.Extended}
                                fontSize={slideProperties.slideSettings?.contentFontSize as number}
                            />
                        </Box>
                    </FullWidthContainer>
                )}
                imageURL={slide?.file?.base64 || slide?.filename}
                videoURL={slide?.videoURL}
            />
        </FullHeightContainer>
    );
};

export default ContentSlideEditor;