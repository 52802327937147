import { AnyAction } from 'redux';
import { config } from 'Scripts/config';
import { removeFromCookies } from 'Scripts/cookieHelper';
import { CookieKeys, REFRESH_TOKEN_NAME, TOKEN_EXPIRES_NAME, TOKEN_NAME } from "Scripts/globals";
import { addToLocalStorage, removeFromLocalStorage } from "Scripts/localStorage";
import { AppState } from 'Types/appTypes';

export const initialAppState: AppState = {
    colorType: config.blueColors,
    settingsOpen: false,
    menuOpen: false,
    loginOpen: false,  // Authreducer?
    loginLoading: false,
    saveOpen: false,
    currentUser: null, // Authreducer?
    currentUserRoles: [],
    userBrandingData: null,
    userSessionInfo: null,
    currentSessionId: null,
    headerHeight: 56,
    toolbarHeight: 56,
    ratingOpen: false,
    authLoading: false,  // Authreducer?
    liveWebsocket: null,
    loginCallback: null,  // Authreducer?
    selectSlideOpened: false,
    saveCompleted: false,
    generalSettingsOpen: false,
    unsavedChangesOpen: false,
    moderatorSharingToken: "",
    isOnline: true,
    userBrandingLoading: false,
    hash: "",
    presentationIdToShare: null,
    feedbackDialogOpen: false,
}

export const appReducer = (state : AppState = initialAppState, action: AnyAction) => {
    switch (action.type) {
        case 'SET_FEEDBACK_DIALOG_OPEN': {
            return {
                ...state,
                feedbackDialogOpen: action.open,
            }
        }
        case 'SET_PRESENTATION_ID_TO_SHARE': {
            return {
                ...state,
                presentationIdToShare: action.id,
            }

        }
        case 'SET_LOGIN_LOADING': {
            return {
                ...state,
                loginLoading: action.isLoading,
            }
        }
        case 'SET_IS_ONLINE': {
            return {
                ...state,
                isOnline: action.isOnline,
            }
        }
        case 'SET_MODERATOR_SHARING_TOKEN': {
            return {
                ...state,
                moderatorSharingToken: action.token
            }
        }
        case 'SET_USER_SESSION_INFO': {
            return {
                ...state,
                userSessionInfo: action.info
            }
        }
        case 'SET_BRANDING_LOADING': {
            return {
                ...state,
                userBrandingLoading: action.isLoading,
            }
        }
        case 'SET_USER_BRANDING_DATA': {
            return {
                ...state,
                userBrandingData: action.data
            }
        }
        case 'SET_UNSAVED_CHANGES_OPEN': {
            return {
                ...state,
                unsavedChangesOpen: action.isOpen,
            }
        }
        case 'SET_GENERAL_SETTINGS_OPEN': {
            return {
                ...state,
                generalSettingsOpen: action.open
            }
        }
        case 'TOGGLE_SELECT_SLIDE_OPENED': {
            return {
                ...state,
                selectSlideOpened: action.isOpened,
            }
        }
        case 'SET_AUTH_LOADING': {
            return {
                ...state,
                authLoading: action.isLoading,
            }
        }
        case 'SET_HEADER_HEIGHT': {
            return {
                ...state,
                headerHeight: action.height
            }
        }
        case 'SET_TOOLBAR_HEIGHT': {
            return {
                ...state,
                toolbarHeight: action.height
            }
        }
        case 'SET_SOCKET_CONNECTION': {
            return {
                ...state,
                liveWebsocket: action.socket
            }
        }
        case 'TOGGLE_RATING': {

            const { show } = action;

            return {
                ...state,
                ratingOpen: show,
            }
        }
        case 'UPDATE_USER': {

            const newCurrentUser = {
                ...state.currentUser,
                ...action.updatedUser,
            }

            return {
                ...state,
                currentUser: newCurrentUser,
            }
        }
        case 'UPDATE_USER_SETTING': {

            const updatedUserSettings = { ...state.currentUser }

            updatedUserSettings[action.key] = action.value;

            return {
                ...state,
                currentUser: updatedUserSettings
            }
        }
        case 'SAVE_USER_DATA': {

            if(action.data.expires > (Date.now() * .001 >> 0)) {
                /* If the token expires somewhere in the future, store the expiration time */
                addToLocalStorage(TOKEN_EXPIRES_NAME, action.data.expires);
            }

            return {
                ...state,
                currentUser: action.data.user,
                currentUserRoles: action.data.roles,
                currentSessionId: action.data.sessionId,
            }
        }
        case 'TOGGLE_SAVE': {
            return {
                ...state,
                saveOpen: !state.saveOpen
            }
        }
        case 'TOGGLE_LOGIN': {
            return {
                ...state,
                loginOpen: !state.loginOpen
            }
        }
        case 'TOGGLE_LOGIN_SCREEN': {
            return {
                ...state,
                loginOpen: action.isOpen
            }
        }
        case 'TOGGLE_MENU': {
            return {
                ...state,
                menuOpen: !state.menuOpen
            }
        }
        case 'SIGN_OUT': {
            removeFromLocalStorage(TOKEN_NAME);
            removeFromLocalStorage(TOKEN_EXPIRES_NAME);
            removeFromLocalStorage(REFRESH_TOKEN_NAME);
            removeFromCookies(CookieKeys.AuthToken);
            return {
                ...state,
                currentSessionId: null,
                currentUser: null,
            }
        }
        case 'OPEN_SETTINGS': {
            return {
                ...state,
                settingsOpen: true
            }
        }
        case 'CLOSE_SETTINGS': {
            return {
                ...state,
                settingsOpen: false
            }
        }
        case 'CLEAR_APP_STATE': {
            return {
                ...initialAppState
            }
        }
        case 'SET_LOGIN_CALLBACK': {
            return {
                ...state,
                loginCallback: action.callback
            }
        }
        case 'SET_HASH_FRAGMENT': {
            return {
                ...state,
                hash: action.hash
            }
        }
        default:
            return state
    }
}
