import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, ClickAwayListener, ListItemIcon, MenuItem, Paper, Popper } from '@mui/material';
import AppButton, { AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import React, { useContext, useEffect, useState } from 'react';
import { AppDynamicTableContext, TableCellAttributeNames, TableCellPosition, TableCellPositionAttributes } from '../AppDynamicTable';
import { classes } from './style.css';

const AppDynamicTableMenu = () => {

    const appTableContext = useContext(AppDynamicTableContext);

    const {
        tableRef,
        canAddRow,
        canAddColumn,
        onAddRow,
        onAddColumn,
        onDeleteColumn,
        onDeleteRow,
        onHighlightColumn,
        onHighlightRow,
        onClearTable,
        onToggleColumnHeadings,
        onToggleRowHeadings,
        rowHasHeader,
        columnHasHeader,
        canDeleteColumn,
        canDeleteRow,
    } = appTableContext;

    const { translatePlaceholder } = useTranslations();

    const [tableOptionsMenuAnchor, setTableOptionsMenuAnchor] = useState<HTMLElement | null>(null);

    const [cellOptionsMenuAnchor, setCellOptionsMenuAnchor] = useState<HTMLElement | null>(null);

    const [focusedCell, setFocusedCell] = useState<TableCellPosition | null>(null);

    const preventEventBubbling = (e: React.MouseEvent<HTMLElement>) => {

        e.preventDefault();
        e.stopPropagation();
    }

    const closeTableOptionsMenu = (e: React.MouseEvent<HTMLElement>) => {

        preventEventBubbling(e);

        setTableOptionsMenuAnchor(null);
    }

    const openTableOptionsMenu = (e: React.MouseEvent<HTMLElement>) => {

        preventEventBubbling(e);

        closeCellOptionsMenu(e);

        setTableOptionsMenuAnchor(tableOptionsMenuAnchor ? null : e.currentTarget);
    }

    const closeCellOptionsMenu = (e: React.MouseEvent<HTMLElement>) => {

        preventEventBubbling(e);

        setCellOptionsMenuAnchor(null);
    }

    const openCellOptionsMenu = (e: React.MouseEvent<HTMLElement>) => {

        checkSelectedCell();

        preventEventBubbling(e);

        closeTableOptionsMenu(e);

        setCellOptionsMenuAnchor(cellOptionsMenuAnchor ? null : e.currentTarget);
    }

    const handleToggleRowHeadings = (e: React.MouseEvent<HTMLElement>) => {

        preventEventBubbling(e);

        onToggleRowHeadings();
    }

    const handleToggleColumnHeadings = (e: React.MouseEvent<HTMLElement>) => {

        preventEventBubbling(e);

        onToggleColumnHeadings();
    }

    const onClickedClearTable = (e: React.MouseEvent<HTMLElement>) => {

        preventEventBubbling(e);

        closeCellOptionsMenu(e);

        closeTableOptionsMenu(e);

        onClearTable();
    }

    const handleDeleteColumn = (e: React.MouseEvent<HTMLElement>) => {

        preventEventBubbling(e);

        if (!focusedCell) {

            return;
        }

        onDeleteColumn(focusedCell.columnIndex);

        closeCellOptionsMenu(e);
    }

    const handleDeleteRow = (e: React.MouseEvent<HTMLElement>) => {

        preventEventBubbling(e);

        if (!focusedCell) {

            return;
        }

        onDeleteRow(focusedCell.rowIndex);

        closeCellOptionsMenu(e);
    }

    const handleAddRow = (e: React.MouseEvent<HTMLElement>, rowOffset: number = 0) => {

        preventEventBubbling(e);

        if (!focusedCell) {

            return;
        }

        onAddRow(focusedCell.rowIndex + rowOffset);

        closeCellOptionsMenu(e);
    }

    const handleAddColumn = (e: React.MouseEvent<HTMLElement>, columnOffset: number = 0) => {

        preventEventBubbling(e);

        if (!focusedCell) {

            return;
        }

        onAddColumn(focusedCell.columnIndex + columnOffset);

        closeCellOptionsMenu(e);
    }

    const handleHighlightRow = (shouldHighlight: boolean, highlightType: TableCellAttributeNames) => {

        if (!focusedCell) {

            return;
        }

        onHighlightRow(shouldHighlight, highlightType, focusedCell.rowIndex);
    }

    const handleHighlightColumn = (shouldHighlight: boolean, highlightType: TableCellAttributeNames) => {

        if (!focusedCell) {

            return;
        }

        onHighlightColumn(shouldHighlight, highlightType, focusedCell.columnIndex);
    }


    const currentRowIsHeading = (focusedCell?.rowIndex === 0) && rowHasHeader;

    const currentColumnIsHeading = (focusedCell?.columnIndex === 0) && columnHasHeader;

    const checkSelectedCell = () => {

        if (document.activeElement === document.body) {

            setFocusedCell(null);

            return;
        }

        const selectedCell = (tableRef.current as HTMLTableElement).querySelector('[data-highlight-select]') || null;

        if (!selectedCell) {

            setFocusedCell(null);

            return;
        }

        setFocusedCell({
            columnIndex: Number(selectedCell.getAttribute(TableCellPositionAttributes.DataCellColumn)),
            rowIndex: Number(selectedCell.getAttribute(TableCellPositionAttributes.DataCellRow)),
        });
    }

    useEffect(() => {

        document.addEventListener('focusin', checkSelectedCell);

        return () => {

            document.removeEventListener('focusin', checkSelectedCell);
        }

    }, [])

    return (
        <Box
            onMouseDown={(e) => {

                e.preventDefault();
                e.stopPropagation();

            }}
            alignItems="center"
            justifyContent="center"
            display="flex"
            p={2} >
            <Paper>
                <Box p={2} display="flex">
                    <Box pr={1}>
                        <AppButton
                            data-testid={testIds.TABLE_OPTIONS_BUTTON}
                            endIcon={<KeyboardArrowDownIcon />}
                            onMouseDown={openTableOptionsMenu}
                            as={AppButtonVariant.Square}>
                            <AppText as={AppTextVariant.BodyBold}>
                                {translatePlaceholder("TABLE_OPTIONS")}
                            </AppText>
                        </AppButton>
                    </Box>
                    <Box pr={1}>
                        <AppButton
                            data-testid={testIds.TABLE_CELL_OPTIONS_BUTTON}
                            disabled={!Boolean(focusedCell)}
                            endIcon={<KeyboardArrowDownIcon />}
                            onMouseDown={openCellOptionsMenu}
                            as={AppButtonVariant.Square}>
                            <AppText as={AppTextVariant.BodyBold}>
                                {translatePlaceholder("CELL_OPTIONS")}
                            </AppText>
                        </AppButton>
                    </Box>

                    <ClickAwayListener
                        onClickAway={closeCellOptionsMenu as any}
                        mouseEvent="onMouseDown"
                    >
                        <Popper
                            style={{ zIndex: 1301 }}
                            placement="top"
                            anchorEl={cellOptionsMenuAnchor}
                            open={Boolean(cellOptionsMenuAnchor)}
                        >
                            <Paper>
                                <Box p={1}>
                                    <MenuItem
                                        autoFocus={false}
                                        data-testid={testIds.TABLE_CELL_OPTIONS_ADD_COLUMN_LEFT}
                                        disabled={!(Boolean(focusedCell) && canAddColumn && !currentColumnIsHeading)}
                                        className={classes.cellMenuItem}
                                        onMouseOver={() => handleHighlightColumn(true, TableCellAttributeNames.ColumnBefore)}
                                        onMouseOut={() => handleHighlightColumn(false, TableCellAttributeNames.ColumnBefore)}
                                        onMouseDown={(e) => handleAddColumn(e)}>
                                        <ArrowBackSharpIcon className={classes.cellMenuIcon} />
                                        <AppText as={AppTextVariant.BodySmallBold}>
                                            {translatePlaceholder("ADD_COLUMN_LEFT")}
                                        </AppText>
                                    </MenuItem>
                                    <MenuItem
                                        autoFocus={false}
                                        data-testid={testIds.TABLE_CELL_OPTIONS_ADD_COLUMN_RIGHT}
                                        disabled={!(Boolean(focusedCell) && canAddColumn)}
                                        className={classes.cellMenuItem}
                                        onMouseOver={() => handleHighlightColumn(true, TableCellAttributeNames.ColumnAfter)}
                                        onMouseOut={() => handleHighlightColumn(false, TableCellAttributeNames.ColumnAfter)}
                                        onMouseDown={(e) => handleAddColumn(e, 1)}>
                                        <ArrowForwardSharpIcon className={classes.cellMenuIcon} />
                                        <AppText as={AppTextVariant.BodySmallBold}>
                                            {translatePlaceholder("ADD_COLUMN_RIGHT")}
                                        </AppText>
                                    </MenuItem>
                                    <MenuItem
                                        autoFocus={false}
                                        data-testid={testIds.TABLE_CELL_OPTIONS_ADD_ROW_ABOVE}
                                        disabled={!(Boolean(focusedCell) && canAddRow && !currentRowIsHeading)}
                                        className={classes.cellMenuItem}
                                        onMouseOver={() => handleHighlightRow(true, TableCellAttributeNames.RowBefore)}
                                        onMouseOut={() => handleHighlightRow(false, TableCellAttributeNames.RowBefore)}
                                        onMouseDown={(e) => handleAddRow(e)}>
                                        <ArrowUpwardSharpIcon className={classes.cellMenuIcon} />
                                        <AppText as={AppTextVariant.BodySmallBold}>
                                            {translatePlaceholder("ADD_ROW_ABOVE")}
                                        </AppText>
                                    </MenuItem>
                                    <MenuItem
                                        autoFocus={false}
                                        data-testid={testIds.TABLE_CELL_OPTIONS_ADD_ROW_BELOW}
                                        disabled={!(Boolean(focusedCell) && canAddRow)}
                                        className={classes.cellMenuItem}
                                        onMouseOver={() => handleHighlightRow(true, TableCellAttributeNames.RowAfter)}
                                        onMouseOut={() => handleHighlightRow(false, TableCellAttributeNames.RowAfter)}
                                        onMouseDown={(e) => handleAddRow(e, 1)}>
                                        <ArrowDownwardSharpIcon className={classes.cellMenuIcon} />
                                        <AppText as={AppTextVariant.BodySmallBold}>
                                            {translatePlaceholder("ADD_ROW_BELOW")}
                                        </AppText>
                                    </MenuItem>
                                    <MenuItem
                                        autoFocus={false}
                                        data-testid={testIds.TABLE_CELL_OPTIONS_DELETE_ROW}
                                        disabled={!(Boolean(focusedCell) && canDeleteRow)}
                                        className={classes.cellMenuItem}
                                        onMouseOver={() => handleHighlightRow(true, TableCellAttributeNames.Delete)}
                                        onMouseOut={() => handleHighlightRow(false, TableCellAttributeNames.Delete)}
                                        onMouseDown={(e) => handleDeleteRow(e)}>
                                        <DeleteOutlinedIcon className={classes.cellMenuIcon} />
                                        <AppText as={AppTextVariant.BodySmallBold}>
                                            {translatePlaceholder("DELETE_ROW")}
                                        </AppText>
                                    </MenuItem>
                                    <MenuItem
                                        autoFocus={false}
                                        data-testid={testIds.TABLE_CELL_OPTIONS_DELETE_COLUMN}
                                        disabled={!(Boolean(focusedCell) && canDeleteColumn)}
                                        className={classes.cellMenuItem}
                                        onMouseOver={() => handleHighlightColumn(true, TableCellAttributeNames.Delete)}
                                        onMouseOut={() => handleHighlightColumn(false, TableCellAttributeNames.Delete)}
                                        onMouseDown={(e) => handleDeleteColumn(e)}>
                                        <DeleteOutlinedIcon className={classes.cellMenuIcon} />
                                        <AppText as={AppTextVariant.BodySmallBold}>
                                            {translatePlaceholder("DELETE_COLUMN")}
                                        </AppText>
                                    </MenuItem>
                                </Box>
                            </Paper>
                        </Popper>
                    </ClickAwayListener>
                    <ClickAwayListener
                        onClickAway={closeTableOptionsMenu as any}
                        mouseEvent="onMouseDown"
                    >
                        <Popper
                            style={{ zIndex: 1301 }}
                            anchorEl={tableOptionsMenuAnchor}
                            open={Boolean(tableOptionsMenuAnchor)}
                        >
                            <Paper>
                                <Box p={1}>
                                    <MenuItem
                                        autoFocus={false}
                                        data-testid={testIds.TABLE_OPTIONS_HEADER_ROW}
                                        onMouseDown={handleToggleRowHeadings}>
                                        {rowHasHeader && (
                                            <ListItemIcon>
                                                <CheckIcon fontSize='small' />
                                            </ListItemIcon>
                                        )}
                                        <AppText as={AppTextVariant.BodySmallBold}>
                                            {translatePlaceholder("HEADER_ROW")}
                                        </AppText>
                                    </MenuItem>
                                    <MenuItem
                                        autoFocus={false}
                                        data-testid={testIds.TABLE_OPTIONS_HEADER_COLUMN}
                                        onMouseDown={handleToggleColumnHeadings}>
                                        {columnHasHeader && (
                                            <ListItemIcon>
                                                <CheckIcon fontSize='small' />
                                            </ListItemIcon>
                                        )}
                                        <AppText as={AppTextVariant.BodySmallBold}>
                                            {translatePlaceholder("HEADER_COLUMN")}
                                        </AppText>
                                    </MenuItem>
                                </Box>
                            </Paper>
                        </Popper>
                    </ClickAwayListener>
                    <CustomTooltip title={translatePlaceholder("CLEAR_TABLE_CONTENTS")}>
                        <AppButton
                            data-testid={testIds.TABLE_CLEAR_BUTTON}
                            onMouseDown={onClickedClearTable}
                            as={AppButtonVariant.Square}>
                            <DeleteForeverIcon />
                        </AppButton>
                    </CustomTooltip>
                </Box>
            </Paper>
        </Box >
    )
}

export default AppDynamicTableMenu;