import React from 'react';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import QuoteSlideSession from '../view/QuoteSlideSession';


const QuoteSlideSessionPreview = ({ slide }: SlideConsumerComponent<ContentSlideType>) => {

    return (
        <QuoteSlideSession slide={slide} />
    );
};

export default QuoteSlideSessionPreview;