import { VoteOption } from 'Types/presentationTypes';
import { config } from "./config";

export const isValidLength = (text: string, minLength: number, maxLength: number = 255) => {
	return !!text && (text.length >= minLength && text.length <= maxLength)
}

export const isValidEmail = (email: string) => {
	//from https://gist.github.com/badsyntax/719800
	return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(email);
}

export const containsDigitCharacter = (text: string) => {

	return text.match(/^(?=.*\d)/);
}

export const containsUpperCaseCharacter = (text: string) => {

	return text.match(/(?=.*[A-Z])/);
}

export const containsLowercaseCharacter = (text: string) => {

	return text.match(/(?=.*[a-z])/);
}

export const isValidPassword = (password: string) => {

	return (
		/* At least one digit */
		containsDigitCharacter(password) &&
		/* At least one lowercase character */
		containsLowercaseCharacter(password) &&
		/* At least one uppercase character */
		containsUpperCaseCharacter(password) &&
		/* At least 8 (to 100) characters */
		(password.length >= 8 && password.length <= 100)
	);
}

export const isValidSlideTime = (time: number) => {
	const { minQuestionTime, maxQuestionTime } = config;
	return (time >= minQuestionTime && time <= maxQuestionTime) || time === 0;
}

export const isValidSlidePoints = (points: number) => {
	const { minQuestionPoints, maxQuestionPoints } = config;
	return points >= minQuestionPoints && points <= maxQuestionPoints
}

export const isValidTitleLength = (title: string = '') => {
	const { minTitleLength, maxContentLength } = config
	return title.length >= minTitleLength && title.length <= maxContentLength;
}

export const isValidOptionLength = (option: string = '') => {
	const { minOptionLength, maxStringLength } = config
	return option.length >= minOptionLength && option.length <= maxStringLength;
}

export const validateOptionCount = (options: VoteOption[] = []) => {

	let validOptionCount = 0;

	if (options.length >= 2) {

		options.forEach((option) => {

			if (Boolean((option?.answer || '').length)) {

				validOptionCount++;
			}
		})

		return validOptionCount >= 2
	}

	return false
}

export const validateOptionsHasCorrect = (options: VoteOption[] = []) => {

	let correctCount = 0;

	options.forEach((option) => {

		if (Boolean(option?.correctAnswer)) {

			correctCount++;
		}
	});

	return correctCount > 0;
}

export const validateUniqueOptions = (options: VoteOption[] = []) => {

	const uniqueOptions : Array<string> = [];

	let hasUniqueOptions = true;

	options.forEach((option) => {

		if (option?.answer) {

			if (uniqueOptions.includes(option.answer)) {

				hasUniqueOptions = false;

			} else {

				uniqueOptions.push(option.answer)
			}
		}
	})

	return hasUniqueOptions;
}