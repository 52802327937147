import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import AppText from 'Atomic/atoms/Text/AppText';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import DynamicColoredProgressBar from 'Atomic/molecules/DynamicColoredProgressBar';
import PlaySlideTitlePreview from 'Atomic/molecules/PlaySlideTitlePreview/PlaySlideTitlePreview';
import Html from 'Components/common/util/html/Html';
import SessionLoginInfo from 'Components/play/templates/geometry_template/shared_components/session_login_info/SessionLoginInfo';
import SessionVoteOptions from 'Components/session/vote_options/SessionVoteOptions';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { useInterval } from 'Scripts/intervalHook';
import { NavigationState } from 'Scripts/slideHelper';
import { numberWithCommas } from 'Scripts/stringFormatter';
import { SlideConsumerComponent } from 'Types/appTypes';
import { SlideType, VoteType } from 'Types/presentationTypes';
import React, { useEffect, useState } from 'react';
import { classes } from './style.css';

const questionStartDelayMs = 7500;
/* How frequent the count down indicator should update */
const countdownIndicatorIntervalMs = 250;

const QuizSlideSessionPreview = ({ slide }: SlideConsumerComponent<VoteType>) => {

    const { translate } = useTranslations();

    const slideProperties = getSlideProperties(slide);

    const [slideNavigationState, setSlideNavigationState] = useState<NavigationState>(NavigationState.BeforeOpen);

    const isActiveState = (state: NavigationState) => {

        return slideNavigationState === state;
    }

    const { presentationStyle } = usePresentationStyles();


    const [questionStartTimer, setQuestionStartTimer] = useState(0)

    const [questionTimer, setQuestionTimer] = useState(Number(slide?.dynamicTimerLimit || 0) * 1000 || -1)

    const { allVotesAreIn } = useSession();
    /* Percentage value of time between the vote shows up and when the vote opens  at 100%*/
    const questionStartPercentage = (100 / ((questionStartDelayMs - 500) / questionStartTimer)) >> 0
    /* Percentage value of time left to respond on a vote before it closes at 100% */
    const timePercentage = (100 / (Number(slide?.dynamicTimerLimit || 1) / (questionTimer / 1000)))

    /* Countdown for the remaining time to vote when the vote has opened */
    useInterval(() => {

        setQuestionTimer(currentTime => currentTime - countdownIndicatorIntervalMs)

    }, (isActiveState(NavigationState.SlideOpen)) ? countdownIndicatorIntervalMs : null)

    /* Countdown before the question opens */
    useInterval(() => {

        setQuestionStartTimer(currentTime => currentTime + countdownIndicatorIntervalMs)

    }, (isActiveState(NavigationState.BeforeOpen) && (questionStartPercentage <= 100)) ? countdownIndicatorIntervalMs : null)

    /* Open the vote after questionStartDelayMs ms */
    useInterval(() => {

        setSlideNavigationState(NavigationState.SlideOpen);

    }, (isActiveState(NavigationState.BeforeOpen)) ? questionStartDelayMs : null)

    /* If the question timer reaches 0, close the vote */
    useEffect(() => {

        if (questionTimer === 0 && isActiveState(NavigationState.SlideOpen)) {

            setSlideNavigationState(NavigationState.SlideClosed);
        }

    }, [questionTimer])

    /* Triggered when a new navigation state is updated */
    useEffect(() => {

        if (isActiveState(NavigationState.BeforeOpen)) {

            setQuestionStartTimer(0);
        }

        if (isActiveState(NavigationState.SlideOpen)) {

            if (Boolean(slide.dynamicTimerLimit)) {

                setQuestionTimer(Number(slide.dynamicTimerLimit) * 1000);
            }
        }

    }, [slideNavigationState])

    return <>
        {!isActiveState(NavigationState.SlideResults) && <div className={classes.wrapper}>
            {isActiveState(NavigationState.BeforeOpen) && <DynamicColoredProgressBar loadPercentage={Math.min(100, questionStartPercentage)} />}
            {isActiveState(NavigationState.SlideOpen) && <DynamicColoredProgressBar loadPercentage={Math.max(0, timePercentage)} />}
            <Grid
                container
                className={classes.questionContainer}
                sx={{
                    color: `${presentationStyle?.textColor} !important`,
                }}
                direction="row"
                justifyContent="center">
                <Grid
                    container
                    item
                    xs={12}
                    className={`${classes.detailsContainer} ${isActiveState(NavigationState.BeforeOpen) && classes.upSize}`}
                >
                    <LayoutWrapper
                        layoutType={slide.slideLayout}
                        imageURL={slide?.file?.base64 || slide?.filename}
                        imageStyle={slide.filenameStyle}
                        backgroundOpacity={slide.backgroundOpacity}
                        backgroundColor={slide.backgroundColor}
                        videoURL={slide.videoURL}
                        interactive={false}
                        title={
                            <PlaySlideTitlePreview
                                title={<>
                                    <DelayedComponent
                                        animationDuration={250}
                                        animationType={AnimationTypes.Grow}
                                        showAfter={Boolean(slide.points) ? 2500 : 500}>
                                        <Html
                                            content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                            fontSize={slideProperties.slideSettings.titleFontSize}
                                        />
                                    </DelayedComponent>
                                    {Boolean(slide.points) && (
                                        <div className={classes.pointsContainer}>
                                            <DelayedComponent
                                                animationDuration={250}
                                                animationType={AnimationTypes.Grow}
                                                hideAfter={2000}>
                                                <Typography
                                                    variant="h2"
                                                    className={classes.correctAnswerText} sx={{
                                                        color: `${presentationStyle?.textColor} !important`,
                                                    }}>
                                                    {translate('ANSWER_CORRECT_AND_RECEIVE')}
                                                </Typography>
                                                <Typography
                                                    variant="h3"
                                                    className={classes.pointsIndicator} sx={{
                                                        color: presentationStyle?.textColor,
                                                    }}>
                                                    {translate('AMOUNT_OF_POINTS', [
                                                        numberWithCommas(slide.points as number)
                                                    ])}
                                                </Typography>
                                            </DelayedComponent>
                                        </div>
                                    )}
                                </>}
                                showPreview={isActiveState(NavigationState.BeforeOpen)}
                            />
                        }
                        content={(
                            <SessionVoteOptions slide={slide as SlideType} />
                        )}
                    />
                </Grid>
            </Grid>
            {!allVotesAreIn && <SessionLoginInfo />}
            <Box className={classes.continueText} sx={{
                color: `${presentationStyle?.textColor} !important`,
            }}>
                <Grow in={allVotesAreIn && isActiveState(NavigationState.SlideOpen)}>
                    <AppText
                        fontWeight={700}
                        fontSize={24}
                        color={presentationStyle?.textColor}>
                        {translate('ALL_VOTES_ARE_IN')}
                    </AppText>
                </Grow>
            </Box>
        </div>}
    </>;
};

export default QuizSlideSessionPreview
