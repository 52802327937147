import { Typography } from '@mui/material';
import Caption from 'Components/beta/common/caption/Caption';
import SummaryOption from 'Components/beta/common/summary/option/SummaryOption';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { LanguageDropdownType } from 'Types/languageTypes';
import { OverviewState } from 'Types/overviewTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import LanguageDropdown from './dropdown/LanguageDropdown';


const ResponseWebsiteLanguageSelect = () => {

	const {
		userSessionInfo,
	} = useSession();

	const {
		translatePlaceholder
	} = useTranslations();

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

	return (
		<SummaryOption
			loading={!userSessionInfo || presentationLoading}
			leftItem={<div>
					<Typography variant="body2">
						{translatePlaceholder('SETTINGS_RESPONSE_LANGUAGE_TITLE')}
					</Typography>
					<Caption title={translatePlaceholder('SETTINGS_RESPONSE_LANGUAGE_DESCR')} />
			</div>
			}
			rightItem={<>
				<LanguageDropdown
					languageDropdownType={LanguageDropdownType.ResponseWebsite}
				/>
			</>}
			trackingAttr={renderingGAAttr(DataElAction.ChangeLanguage, DataElPosition.MainOptSection, DataElScreen.Settings, DataElType.Dropdown)}
		/>
	);
};

export default ResponseWebsiteLanguageSelect;