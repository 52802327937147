import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";


const HOVER_STATE_CHECK_DELAY = 25;

/**
 * Checks if the mouse is hovering over a given element.
 *
 * Returns a ref to be attached to the element and a boolean if the mouse is hovering over the element.
 * Due to some ⚡ performance measurements, the mousemove event is debounced by 25ms. Meaning that the mouse
 * has to be within the bounding box for 50ms before the boolean is set to true.
 *
 * @TODO Fix this so whenever an element is over the container the hovers aren't checked.
 *
 * @returns {Object} { hoverRef, isHovering }
 */
export const useHover = () => {

    const [isHovering, setIsHovering] = useState<boolean>(false);

    const hoverRef = useRef<Element>(null);

    const checkIfMouseIsWithinBoundingBox = (e: MouseEvent) => {

        if (Boolean(hoverRef.current)) {

            const clientX = e.clientX;
            const clientY = e.clientY;

            const layoutBoxRect = (hoverRef.current as HTMLElement).getBoundingClientRect();

            if ((
                (layoutBoxRect.top <= clientY && layoutBoxRect.bottom >= clientY) &&
                (layoutBoxRect.left <= clientX && layoutBoxRect.right >= clientX)
            )) {

                setIsHovering(true);

            } else {

                setIsHovering(false);
            }
        }
    }

    const debouncedCheckIfMouseIsWithinBoundingBox = debounce(checkIfMouseIsWithinBoundingBox, HOVER_STATE_CHECK_DELAY);

    const bindMouseListener = () => {

        window.addEventListener('mousemove', debouncedCheckIfMouseIsWithinBoundingBox, { passive: true });
    }

    const unbindMouseListener = () => {

        window.removeEventListener('mousemove', debouncedCheckIfMouseIsWithinBoundingBox);
    }

    useEffect(() => {

        if(Boolean(hoverRef)) {

            bindMouseListener();

            return () => {

                unbindMouseListener();
            }
        }

    }, [isHovering, hoverRef])

    return { hoverRef, isHovering };
}