import { Collapse, Fade, Grow, Slide, Zoom } from '@mui/material';
import React, { useState } from 'react';
import { useInterval } from 'Scripts/intervalHook';


export enum AnimationTypes {
	Fade,
	Grow,
	Slide,
	Zoom,
	Collapse,
}

interface DelayedComponentProps {
	showAfter?: number;
	hideAfter?: number;
	animationDuration: number;
	animationType: AnimationTypes,
	children: any;
	stop?: boolean;
	[key: string]: any;
}

const DelayedComponent = ({
	showAfter = 0,
	hideAfter = 0,
	animationType,
	animationDuration = 0,
	children,
	stop = false,
	...props
}: DelayedComponentProps) => {

	const [show, setShow] = useState(false);

	const [shown, setShown] = useState(false)

	useInterval(() => {

		setShow(false)

	}, (show && hideAfter && !stop) ? hideAfter : null)

	useInterval(() => {

		setShow(true)

		setShown(true)

	}, (!show && !shown && !stop) ? showAfter : null)

	const animationProps = {
		in: show,
		timeout: animationDuration,
		...props,
	}

	/* Wrap content in div so animations work properly */
	const content = <div style={{ position: 'relative' }}>
		{children}
	</div>

	switch (animationType) {
		case AnimationTypes.Fade: {
			return <Fade {...animationProps}>
				{content}
			</Fade>
		}
		case AnimationTypes.Grow: {
			return <Grow {...animationProps}>
				{content}
			</Grow>
		}
		case AnimationTypes.Slide: {
			return <Slide {...animationProps}>
				{content}
			</Slide>
		}
		case AnimationTypes.Zoom: {
			return <Zoom {...animationProps}>
				{content}
			</Zoom>
		}
		case AnimationTypes.Collapse: {
			return <Collapse {...animationProps}>
				{content}
			</Collapse>
		}
		default: {
			return children
		}
	}
};

export default DelayedComponent;