import { BaseSlideParameters, FilenameCssStyle, MessageRoundType } from "Types/presentationTypes";

/* Default properties of a MessageRound */
const MessageRoundModel = ({
    id,
    presentationId,
    slideIndex,
} : BaseSlideParameters) : MessageRoundType => {

    return {
        id,
        backgroundOpacity: '.4',
		backgroundColor: null,
        presentationId,
        slideIndex,
        currentSessionId: null,
        title: '',
        active: 1,
        file: {}, /* For uploading purposes */
        filename: null,
        filenameStyle: FilenameCssStyle.Cover,
        opened: 0,
        inSlideShow: 0,
        nrOfMessageShapes: 3, /* Amount of messages on screen, default is 3 */
        limitToSlide: 1,
        skipModerator: 0,
        nrOfMessagesAllowed: 0,
        correctAnswers: null,
        isWordcloudQuestion: 0,
        isWebpageSlide: 0,
        hasUpvoting: 0,
        createdFrom: 'WebQuiz',
        slideDesignId: null,
        slideLayout: null,
        videoURL: null,
        notes: '',
    }
}

export { MessageRoundModel };

