import React from 'react';

const PreviewMessagePanelSlideShape = () => {
    return (
        <>
            {/* Nothing rendered here as we don't preview the messages (yet) */}
        </>
    );
};

export default PreviewMessagePanelSlideShape;