import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import AppSnackbarComponent from 'Atomic/molecules/AppSnackbar/AppSnackbarComponent';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React, { ReactNode, useRef } from 'react';
import { classes } from './style.css';


export enum SnackbarVariant {
	Default = 'default',
	Error = 'error',
	Warning = 'warning',
	WarningLoading = 'warningLoading',
	Success = 'success',
	SuccessLoading = 'successLoading',
	Info = 'info',
}

declare module 'notistack' {
	interface VariantOverrides {
		[key: string]: boolean;
	}
}

interface SnackbarWrapperProps {
	children: ReactNode | Array<ReactNode>;
}

/**
 * SnackbarWrapper component using notistack
 *
 * For some (stupid ?) reason enqueueSnackbar(text, props)
 * will flag props.variant as being invalid unless casted to unknown first. Can't
 * seem to find a workaround for this annoying issue, hence most enqueueSnackbars calls will show
 * as invalid syntax 😫🔫
 */
const SnackbarWrapper = ({ children }: SnackbarWrapperProps) => {

	const notistackRef = useRef<SnackbarProvider>(null);

	const onClickDismiss = (key: SnackbarKey) => () => {

		(notistackRef.current as SnackbarProvider).closeSnackbar(key);
	}

	const componentMap = Object.values(SnackbarVariant).reduce((map, variant) => {

		map[variant] = AppSnackbarComponent;

		return map;

	}, {} as Record<SnackbarVariant, ReactNode>);

	return (
        <SnackbarProvider
			preventDuplicate
			maxSnack={5}
			ref={notistackRef as any}
			anchorOrigin={{
				horizontal: 'right',
				vertical: 'bottom'
			}}
			Components={componentMap}
			action={key => (
				<IconButton onClick={onClickDismiss(key)} size="large">
					<Close className={classes.clearIcon} />
				</IconButton>
			)}>
			{children}
		</SnackbarProvider>
    );
}

export default SnackbarWrapper;
