import * as appActions from 'Actions/appActions';
import { setUserSessionInfo } from 'Actions/appActions';
import { useManageUsers } from 'Hooks/useManageUsers';
import usePlan from 'Hooks/usePlan';
import usePlaySettings from 'Hooks/usePlaySettings';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { usePresentationTemplates } from 'Hooks/usePresentationTemplates';
import useUserFlow from 'Hooks/useUserFlow';
import { get } from 'Scripts/api';
import { useDispatch } from 'react-redux';
import { useNewsFeed } from './useNewsFeed';
import usePresentation from './usePresentation';

export const useInitApplication = () => {

    const {
		fetchPresentationTemplates,
	} = usePresentationTemplates();

    const {
        fetchPresentationPageData
    } = usePresentation();

	const {
		fetchUserFlowData,
	} = useUserFlow();

	const {
		fetchManageUsersSettings,
	} = useManageUsers()

	const {
		fetchPresentationStyles,
	} = usePresentationStyles();

	const {
		fetchPlanDetails,
	} = usePlan();

    const {
        fetchNewsFeed,
    } = useNewsFeed();

	const {
		changeLanguage,
		fetchTranslations,
	} = usePlaySettings();

	const dispatch = useDispatch();

	const getUserBrandingData = async () => {

		try {

			dispatch(appActions.setUserBrandingLoading(true));

			const { branding } = await get('users/branding');

			dispatch(appActions.setUserBrandingData(branding));

		} catch (error) {

			console.warn(error)

		} finally {

			dispatch(appActions.setUserBrandingLoading(false));
		}
	}

	const getUserSessionInfo = async () => {

		try {

			const userSessionInfo = await get('users/session-info')

			dispatch(setUserSessionInfo(userSessionInfo))

			const { language } = userSessionInfo;

			changeLanguage(language);

		} catch (error) {

			console.warn(error)
		}
	}

    const fetchAppData = () => {

		fetchPresentationStyles();

		fetchUserFlowData();

		fetchManageUsersSettings();

		getUserBrandingData();

		getUserSessionInfo();

		fetchTranslations();

		fetchPlanDetails();

        fetchNewsFeed();

        fetchPresentationPageData({});

        fetchPresentationTemplates(new URLSearchParams({}));
	}

    return {
        fetchAppData
    }
}