import EditAnswersToggle from 'Components/beta/settings/presentation_summary/options/general/edit_answers/EditAnswersToggle';
import ParticipantEmojiToggle from 'Components/beta/settings/presentation_summary/options/general/emoji/ParticipantEmojiToggle';
import LoginCodeEditor from 'Components/beta/settings/presentation_summary/options/general/login_code/LoginCodeEditor';
import React from 'react';
import PointsPerAnswerSetting from '../../../items/points_per_answer/PointsPerAnswerSetting';
import SlideResultTypeToggle from '../../../items/result_type/SlideResultTypeToggle';
import TimerLimitSetting from '../../../items/time_limit/TimerLimitSetting';

const PointsSettings = () => {
	return (
		<>
			<SlideResultTypeToggle />
			<PointsPerAnswerSetting />
			<TimerLimitSetting />
			<LoginCodeEditor />
			<EditAnswersToggle/>
			<ParticipantEmojiToggle/>
		</>
	);
};

export default PointsSettings;