import { Collapse, Typography } from '@mui/material';
import Caption from 'Components/beta/common/caption/Caption';
import SummaryOption from 'Components/beta/common/summary/option/SummaryOption';
import usePlaySettings from 'Hooks/usePlaySettings';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { AppState } from 'Types/appTypes';
import { OverviewState } from 'Types/overviewTypes';
import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import CountryDropdown from './dropdown/CountryDropdown';

const CountrySelect = () => {

	const {
		fetchCountries,
	} = usePlaySettings();

	const {
		userSessionInfo
	} = useSession();

	const { translatePlaceholder } = useTranslations();

    const currentUser = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentUser);

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

	useEffect(() => {

		if(Boolean(currentUser)) {

			fetchCountries();
		}

	}, [currentUser])

	return (
		<Collapse in={Boolean(Number(userSessionInfo?.smsEnabled))}>
			<SummaryOption
				loading={!userSessionInfo || presentationLoading}
				leftItem={<div>
					<Typography variant="body2">
						{translatePlaceholder("COUNTRY")}
					</Typography>
					<Caption title={translatePlaceholder("SMS_TEXT_COUNTRY_EXPLANATION")} />
				</div>}
				rightItem={<CountryDropdown />}
				trackingAttr={renderingGAAttr(DataElAction.CountrySMSSelection, DataElPosition.HideAdvancedSection, DataElScreen.Settings, DataElType.Dropdown)}
			/>
		</Collapse>

	);
};

export default CountrySelect;