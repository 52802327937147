// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.app-tooltip {
    max-width: 512;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    top: -4px;
    padding: 16px;
    background-color: var(--primary-main);
    font-family: inherit;
}

.app-tooltip-arrow {
    color: var(--primary-main);
}`, "",{"version":3,"sources":["webpack://./src/components/common/tooltip/Styles.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,aAAa;IACb,qCAAqC;IACrC,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["\n.app-tooltip {\n    max-width: 512;\n    font-size: 16px;\n    font-weight: 400;\n    letter-spacing: 1px;\n    top: -4px;\n    padding: 16px;\n    background-color: var(--primary-main);\n    font-family: inherit;\n}\n\n.app-tooltip-arrow {\n    color: var(--primary-main);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
