
export enum SocketEvents {
    Connect = 'connect',
	SessionSubscribe = 'session:subscribe',
	AuthSubscribe = 'auth:subscribe',
	AuthConfirm = 'auth:confirm',
	AuthConfirmed = 'auth:confirmed',
	ConnectError = 'connect_error',
	ConnectTimeout = 'connect_timeout',
	Disconnect = 'disconnect',
	Reconnect = 'reconnect',
	ReconnectAttempt = 'reconnect_attempt',
	Reconnecting = 'reconnecting',
	ReconnectError = 'reconnect_error',
	ReconnectFailed = 'reconnect_failed',
	SessionSubscribed = 'session:subscribed',
	SessionStart = 'session:start',
	ParticipantJoined = 'participant:joined',
	ParticipantLoggedOut = 'participant:logged_out',
	ParticipantsInfoCreated = 'participantsInfo:created',
	VoteMessageCreated = 'voteMessage:created',
    MessageCreated = 'message:created',
    SessionEmoji = 'session:emoji',
    VoteMessageUpdated = 'voteMessage:updated',
    MessageUpdated = 'message:updated',
    MessageRemoved = 'message:removed',
    SessionMaxParticipantsReached = 'session:max_participants_reached',
	PlanUpgrade = 'plan:upgrade',
	VoteResults = 'vote:results',
}