import { setPresentationStyles, updateCurrentPresentationStyle } from "Actions/presentationStyleActions";
import * as presentationActions from 'Actions/quizActions';
import { PresentationStyleContext } from "Context/PresentationStyleContextWrapper";
import { get, post } from "Scripts/api";
import { PresentationState, PresentationStyleState, PresentationStyleType } from "Types/presentationTypes";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useAppRouting } from "./useAppRouting";

/** Get an unique font identifier for a specific font, or null */
export const getFontStyleName = (style: PresentationStyleType) => {

    return style?.font ? `font-${style?.id}` : null;
}

const usePresentationStyles = () => {

    const contextPresentationStyle = useContext(PresentationStyleContext);

    const [ presentationStylesLoading, setPresentationStylesLoading ] = useState<boolean>(false);

    const presentationStyleId = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).presentationStyleId);

    const presentationStyles = useSelector((state: RootStateOrAny) => (state.presentationStyleReducer as PresentationStyleState).presentationStyles);

    const { presentationId } = useAppRouting();

	const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch();

    /**
     * Once the fonts are fetched from the api, create custom style rules to load the fonts in the page.
     * Afterwards, we can use the font identifier to reference a certain font.
     */
    const handleFontStyleRules = (styles: Array<PresentationStyleType>) => {

        styles.forEach((style: PresentationStyleType) => {

            const sheet = window.document.styleSheets[0];

            if(Boolean(style.font)) {

                const fontIdentifierName = getFontStyleName(style);

                if((Boolean(fontIdentifierName))) {

                    Array.from(sheet.cssRules).forEach((_, index: number) => {

                        const cssRule = sheet.cssRules[index];
                        /** Delete the existing rule so we don't overload the sheet with rules */
                        if (cssRule?.cssText.includes(fontIdentifierName as string) && cssRule.type === CSSRule.FONT_FACE_RULE) {

                            sheet.deleteRule(index);
                        }
                    });
                }

                const cssFontRule = `@font-face { font-family: ${fontIdentifierName}; src:url('${style?.font}'); }`;

                sheet.insertRule(cssFontRule, sheet.cssRules.length);
            }
        });
    }

    const fetchPresentationStyles = async () => {

        try {

            setPresentationStylesLoading(true);

            const response = await get('presentation_style/styles');

            if(!response.error) {

                dispatch(setPresentationStyles(response.styles));
            }

        } catch (error) {

            console.warn('Unable to fetch presentation styles');

        } finally {

            setPresentationStylesLoading(false);
        }
    }

    useEffect(() => {

        handleFontStyleRules(presentationStyles);

    }, [presentationStyles.length])

    const defaultPresentationStyle = useMemo(() => {

        return presentationStyles.find((style: PresentationStyleType) => {

            return Boolean(style.isDefault);

        }) || null

    }, [presentationStyles.length])

    /* The user selected presentation style OR the first default match */
    const presentationStyle = useMemo(() => {

        return presentationStyles.find((style: PresentationStyleType) => {

            return style.id === presentationStyleId;

        }) || defaultPresentationStyle;

    }, [presentationStyleId, presentationStyles.length, contextPresentationStyle])

    const updateStyle = async (styleId: number) => {

        try {

            setPresentationStylesLoading(true);

            const update = await post(`presentation_style/set-style/${presentationId}`, {
                styleId,
            })

            if(update.error) {

                enqueueSnackbar(update.error, { variant: 'warning' });

            } else {

                dispatch(setPresentationStyleId(styleId));
            }

        } catch (error) {

            console.warn(error)

        } finally {

            setPresentationStylesLoading(false);
        }
    }

    const getPresentatyionStyleById = (styleId: number | null) => {

        return presentationStyles.find((style: PresentationStyleType) => {

            return style.id === styleId;

        }) || defaultPresentationStyle;
    }

    const updateStyleValue = (attribute: string, value: string) => {

        dispatch(updateCurrentPresentationStyle(presentationStyleId, attribute, value))
    }

    const setPresentationStyleId = (styleId: number | null) => {

        dispatch(presentationActions.setPresentationStyleId(styleId));
    }

    window.setStyle = updateStyle;
    window.setStyleValue = updateStyleValue;

    return {
        presentationStyle: contextPresentationStyle || presentationStyle,
        presentationStyles,
        presentationStylesLoading,
        fetchPresentationStyles,
        setPresentationStyleId,
        getPresentatyionStyleById,
        updateStyle,
        getFontStyleName,
    }
}

export default usePresentationStyles;