
import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { style } from './style.css';

export enum AppTextVariant {
    AppTextBaseStyle = 'appTextBaseStyle',
    CaptionLight = 'txtCaptionLight',
    CaptionRegular = 'txtCaptionRegular',
    CaptionBold = 'txtCaptionBold',
    BodyLight = 'txtBodyLight',
    BodyRegular = 'txtBodyRegular',
    BodyBold = 'txtBodyBold',
    BodySmallLight = 'txtBodySmallLight',
    BodySmallRegular = 'txtBodySmallRegular',
    BodySmallBold = 'txtBodySmallBold',
    BodyMicroLight = 'txtBodyMicroLight',
    BodyMicroRegular = 'txtBodyMicroRegular',
    BodyMicroBold = 'txtBodyMicroBold',
    BodyTinyLight = 'txtBodyTinyLight',
    BodyTinyRegular = 'txtBodyTinyRegular',
    BodyTinyBold = 'txtBodyTinyBold',
    H1Light = 'txtH1Light',
    H1Regular = 'txtH1Regular',
    H1Bold = 'txtH1Bold',
    H2Light = 'txtH2Light',
    H2Regular = 'txtH2Regular',
    H2Bold = 'txtH2Bold',
    H3Light = 'txtH3Light',
    H3Regular = 'txtH3Regular',
    H3Bold = 'txtH3Bold',
    H4Light = 'txtH4Light',
    H4Regular = 'txtH4Regular',
    H4Bold = 'txtH4Bold',
    H5Light = 'txtH5Light',
    H5Regular = 'txtH5Regular',
    H5Bold = 'txtH5Bold',
    H6Light = 'txtH6Light',
    H6Regular = 'txtH6Regular',
    H6Bold = 'txtH6Bold',
    Tip = 'tip',
    FifthBg = 'fifthBg',
    FifthBgSmall = 'fifthBgSmall',
    TertiaryBgSmall = 'tertiaryBgSmall',
    PrimaryBgSmall = 'primaryBgSmall',
}

interface AppTextProps extends TypographyProps {
    as?: AppTextVariant;
    children?: any;
    className?: string;
}

const AppText = React.forwardRef<HTMLParagraphElement, AppTextProps>((props: AppTextProps, ref) => {

    const {
        children,
        as,
        className,
        sx,
        ...otherProps
    } = props;

    return (
        <Typography
            noWrap
            dir="auto"
            ref={ref}
            {...otherProps}
            className={`${style[as as AppTextVariant] || ""} ${className || ""}`}
        >
            {children}
        </Typography>
    );
});

export default AppText;