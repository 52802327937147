import { useSlideLogo } from 'Hooks/useSlideLogo';
import React from 'react';

const PreviewLogoSlideShape = () => {

    const { slideLogo, showLogo } = useSlideLogo();

    return (
        <img
            src={slideLogo}
            style={{
                zIndex: 1,
                maxWidth: '100%',
                maxHeight: '100%',
                height: 'auto',
                width: 'auto',
                opacity: Number(showLogo),
            }}
        />
    )
}

export default PreviewLogoSlideShape;