import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Html from 'Components/common/util/html/Html';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { getMUISafeAlphaColor } from 'Scripts/colorHelper';
import { config } from 'Scripts/config';
import { LayoutTypes } from 'Types/layoutTypes';
import { OptionPlaceholder, VoteOption } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';

interface PlaceholderQuestionOptionProps {
    option: VoteOption;
    layout: LayoutTypes;
    index: number;
    fontSize: number;
}

const PlaceholderQuestionOption = ({
    option,
    layout,
    index,
    fontSize,
}: PlaceholderQuestionOptionProps) => {

    const {
        presentationStyle
    } = usePresentationStyles();


    const getBackgroundColor = () => {

        if (layout === LayoutTypes.ImageOnBackground) {

            return getMUISafeAlphaColor(presentationStyle?.lightColor || '#fff', .75);
        }

        return presentationStyle?.lightColor;
    }

    return (
        <Grid
            style={{
                background: getBackgroundColor(),
            }}
            className={classes.option}
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            xs={12}>
            <Grid item p={3}>
                <Typography variant="h4" className={classes.indexText} sx={{
                    color: presentationStyle?.textColor,
                }}>
                    {option.answerCode}
                </Typography>
            </Grid>
            <Grid item xs>
                <Box
                    p={3}
                    pr={10}>
                    <Html
                        content={option.answer || OptionPlaceholder.OptionText.replace(OptionPlaceholder.IndexText, config.optionKeys[index])}
                        fontSize={fontSize}
                    />
                </Box>
            </Grid>
        </Grid >
    );
};

export default PlaceholderQuestionOption;