import { LinearProgress, useTheme } from '@mui/material';
import React from 'react';
import { getTransitionColorValue } from 'Scripts/colorHelper';
import { config } from 'Scripts/config';

const DynamicColoredProgressBar = ({
	loadPercentage,
	fromColor = config.successColor,
	toColor = config.errorColor
}) => {

	const barBackgroundColor = getTransitionColorValue(fromColor, toColor, loadPercentage / 100)

	const theme = useTheme();

	return (
		<LinearProgress
			sx={{
				'& span': {
					backgroundColor: barBackgroundColor,
				},
				position: 'absolute',
				width: '100%',
				top: 0,
				height: 0,
				padding: `${theme.spacing(1)} 0`,
				background: 'transparent',
				zIndex: 3,
			}}
			color="primary"
			variant="determinate"
			value={loadPercentage}
		/>
	)
};

export default DynamicColoredProgressBar
