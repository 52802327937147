import { BaseSlideParameters, FilenameCssStyle, VoteType } from 'Types/presentationTypes';

const VoteModel = ({
	id,
	presentationId,
	slideIndex
} : BaseSlideParameters) : VoteType => {

	return {
		id,
		backgroundOpacity: '.4',
		backgroundColor: null,
		presentationId,
		slideIndex,
		title: '',
		active: 1,
		file: {}, /* For uploading purposes */
		filename: null,
        filenameStyle: FilenameCssStyle.Cover,
		opened: 0,
		nrOfVotes: 1,
		points: 0,
		isQuizVote: 0,
		showScores: 0,
		weightedVoting: 0,
		dynamicTimerLimit: 0,
		nrOfVotesArePerAnswer: 0,
		currentSessionId: null,
		multipleAllowed: 0,
		showPercentages: 1,
		dynamicResultsSlide: 1,
		votesCounterPresent: 1,
		displayStatusOnVoteSlide: 1,
		displayStatusOnResultsSlide: 1,
		selectedBackgroundMusic: -1,
		allowMultipleVotesPerAnswer: 0, /* Are these unique? */
		isRatingQuestion: 0, /* Are these unique? */
		ratingMinimumLabel: '',
		ratingMaximumLabel: '',
		createdFrom: 'WebQuiz',
		slideDesignId: null,
		graphType: 0,
		options: [],
		type: null,
		slideLayout: null,
		videoURL: null,
		notes: '',
	}
}

export { VoteModel };

