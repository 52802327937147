import Switch, { SwitchProps } from '@mui/material/Switch';
import { TestIdAttributes, TrackingAttributes } from 'Scripts/measurementsGAHelper';
import React from 'react';
import { classes } from './style.css';

interface OnOffSwitchProps extends SwitchProps, TrackingAttributes, TestIdAttributes { }

const OnOffSwitch = (props: OnOffSwitchProps) => {

	const { disabled, onClick, ...otherProps } = props;

	return (
		<Switch
			classes={{
				switchBase: `${classes.switchBase} ${disabled ? classes.disabled : ''}`,
				root: `${classes.root} ${disabled ? classes.lowOpacity : ''}`,
				checked: classes.checked,
				track: classes.track,
				thumb: classes.thumb,
				disabled: classes.disabled,
			}}
			disableRipple
			color='success'
			onClick={!disabled ? onClick : () => []}
			{...otherProps}
		/>
	);
};

export default OnOffSwitch;