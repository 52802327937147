import React from 'react';
import ModeratorPanelOption from '../../moderator_panel/ModeratorPanelOption';


const AdditionalOpenEndedSettings = () => {
	return (
		<>
			<ModeratorPanelOption />
		</>
	);
};

export default AdditionalOpenEndedSettings;