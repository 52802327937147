import * as playActions from 'Actions/playActions';
import { useAppRouting } from 'Hooks/useAppRouting';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { post } from 'Scripts/api';
import { PlayState } from 'Types/playTypes';
import { ApiVoteResult } from 'Types/presentationTypes';

export const useScores = () => {

	const quizRanksBySlideKey = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).quizRanksBySlideKey);

	const sessionRunId = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).sessionRunId);

	const playSlides = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).playSlides);

	const { currentSlideKey } = useAppRouting()

	const slideKeys = Object.keys(playSlides)

	const dispatch = useDispatch()

	const saveQuizRanks = results => {

		const newRanksByParticipantId = {}

		results.scores.forEach((score, index) => {

			newRanksByParticipantId[score.participantId] = {
				rank: index + 1,
				points: score.points
			}
		})

		const newQuizRanks = {
			...quizRanksBySlideKey,
			[currentSlideKey]: {
				...newRanksByParticipantId
			}
		}

		dispatch(playActions.setQuizRanksBySlideKey(newQuizRanks));
	}

	const broadcastScores = async () => {

		const currentSlideIndex = slideKeys.indexOf(`${currentSlideKey}`);

		const voteIdsForScores = slideKeys.slice(0, currentSlideIndex + 1)

		const broadcastedQuizResults = await post('presentations/broadcast-scores', {
			voteIds: voteIdsForScores,
			sessionRunId,
			currentVoteId: currentSlideKey,
		})

		if (broadcastedQuizResults.scores) {

			const { scores } = broadcastedQuizResults.scores

			const formattedScores = Object.keys(scores).map(participantId => {

				return scores[participantId]
			})

			const sortedByPoints = formattedScores.sort((a, b) => {

				return b.points - a.points
			})

			saveQuizRanks({ scores: sortedByPoints });

			dispatch(playActions.setQuizResults({ scores: sortedByPoints }));
		}
	}

	const setVoteResults = (voteId: number, responses: ApiVoteResult[]) => {

		dispatch(playActions.setVoteResults(voteId, responses));
	}

	return {
		setVoteResults,
		broadcastScores,
	}
}