import { Box } from '@mui/material';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import Html from 'Components/common/util/html/Html';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import React from 'react';
import { SlideConsumerComponent } from 'Types/appTypes';
import { PlaySlideType } from 'Types/playTypes';

const ContentSlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {

    const slideProperties = getPlaySlideProperties(slide);

    return (
        <LayoutWrapper
            layoutType={slide.slideLayout}
            imageURL={slide?.file?.base64 || slide?.filename}
            imageStyle={slide.filenameStyle}
            backgroundOpacity={slide.backgroundOpacity}
            backgroundColor={slide.backgroundColor}
            videoURL={slide.videoURL}
            title={
                <DelayedComponent
                    animationDuration={250}
                    animationType={AnimationTypes.Grow}
                    showAfter={500}
                    style={{
                        width: '100%'
                    }}>
                    <Box p={4}>
                        <Html
                            content={slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder) as string}
                            fontSize={slideProperties.slideSettings.titleFontSize}
                        />
                    </Box>
                </DelayedComponent>
            }
            content={(
                <Box
                    overflow="hidden"
                    flexDirection="column"
                    display="flex"
                    alignItems="center">
                    <DelayedComponent
                        animationDuration={250}
                        animationType={AnimationTypes.Grow}
                        showAfter={500}
                        style={{
                            width: '100%'
                        }}>
                        <Box p={4}>
                            <Html
                                content={slide.contentHtml || slide.content || slideProperties.text.getHtmlContentPlaceholder?.(slideProperties.text.contentPlaceholder) as string}
                                fontSize={slideProperties.slideSettings.contentFontSize as number}
                            />
                        </Box>
                    </DelayedComponent>
                </Box>
            )}
        />
    );
};

export default ContentSlideSession
