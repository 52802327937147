import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import ButtonLoader from 'Components/common/util/button_loader/ButtonLoader';
import { DataElType, TestIdAttributes, TrackingAttributes } from 'Scripts/measurementsGAHelper';
import React, { ReactNode } from 'react';
import { classes } from './style.css';


export interface DropdownAttributes extends TrackingAttributes, TestIdAttributes {
    value: string | number;
    displayValue?: string | ReactNode;
    [key: string]: any;
}

type ItemComponentCallback = (children: Element|ReactNode, item: DropdownAttributes | string | number) => ReactNode;

interface AppDropdownProps extends SelectProps {
    onChange: (value: any, index: number) => void;
    itemComponent?: ItemComponentCallback;
    values: Array<DropdownAttributes | string | number>;
    selectedValue?: any;
    loading?: boolean;
    borderColor?: string;
    itemPadding?: number;
    fullWidth?: boolean;
}

const AppDropdown = ({
    onChange,
    itemComponent,
    values = [],
    loading,
    selectedValue,
    borderColor,
    fullWidth,
    ...props
}: AppDropdownProps) => {


    return (
        <Select
            fullWidth={fullWidth}
            displayEmpty
            variant="filled"
            className={classes.dropdown}
            sx={{
                border: `2px solid ${borderColor || `#90809D`}`,
            }}
            value={selectedValue || ''}
            disableUnderline
            IconComponent={KeyboardArrowDownIcon}
            startAdornment={loading && (
                <Box pr={1}>
                    <ButtonLoader dark={true} inline={true} flex={true} />
                </Box>
            )}
            classes={{
                filled: classes.input,
            }}
            MenuProps={{
                classes: {
                    paper: classes.dropdownItems,

                },
                sx: {
                    border: `2px solid ${borderColor || `#90809D`}`,
                }
            }}
            disabled={loading || props?.disabled}
            label={props?.placeholder}
            {...props}>
            {props?.placeholder && (
                <MenuItem
                    disabled
                    value=''
                    classes={{
                        root: classes.menuItemPlaceholder,
                    }}>
                    <AppText as={AppTextVariant.BodyRegular} noWrap={false}>
                        {props?.placeholder}
                    </AppText>
                </MenuItem>
            )}
            {values.map((value, index) => {

                let otherProps = {} as DropdownAttributes;
                /** If an object was passed extract the 'value' from the object */
                if (Boolean(value) && typeof value === 'object') {

                    otherProps = { ...value };

                    value = otherProps.value;
                }

                const menuItem = (
                    <div className={classes.menuItemBox}>
                        <AppText as={AppTextVariant.BodyRegular} noWrap={false}>
                            {otherProps?.displayValue || value}
                        </AppText>
                    </div>
                )

                const component = !Boolean(itemComponent) ? menuItem : (itemComponent as ItemComponentCallback)(menuItem, {
                    ...otherProps,
                    value,
                })

                return (
                    <MenuItem
                        selected={(value === selectedValue && Boolean(selectedValue))}
                        onClick={() => onChange(value, index)}
                        classes={{
                            root: classes.menuItem,
                            selected: classes.selectedMenuItem
                        }}
                        key={value as string | number}
                        data-eltype={DataElType.Dropdown}
                        {...otherProps}
                        value={value as string | number}>
                        {component}
                    </MenuItem>
                )
            })}
        </Select>
    );
};

AppDropdown.defaultProps = {
	fullWidth: true,
};

export default AppDropdown;