import { Close } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import Error from '@mui/icons-material/Error';
import Info from '@mui/icons-material/Info';
import Warning from '@mui/icons-material/Warning';
import { Box, IconButton, useTheme } from '@mui/material';
import AppSpinner from 'Atomic/atoms/Spinner/AppSpinner';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import { SnackbarVariant } from 'Components/app/snackbar/SnackbarWrapper';
import { testIds } from 'Scripts/cypressTestIds';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import React, { ReactNode } from 'react';
import { classes } from './style.css';


interface AppSnackbarComponentProps extends Omit<CustomContentProps, 'variant'> {
    variant: SnackbarVariant;
}

const AppSnackbarComponent = React.forwardRef((props: AppSnackbarComponentProps, ref) => {

    const theme = useTheme();

    const { closeSnackbar } = useSnackbar();

    const {
        id,
        persist,
        variant,
        action,
        message,
        ...otherProps
    } = props;

    const snackbarBorderColors: Record<SnackbarVariant, string> = {
        [SnackbarVariant.Default]: theme.palette.primary.main,
        [SnackbarVariant.Error]: theme.palette.error.main,
        [SnackbarVariant.Warning]: theme.palette.warning.main,
        [SnackbarVariant.WarningLoading]: theme.palette.warning.main,
        [SnackbarVariant.Success]: theme.palette.success.main,
        [SnackbarVariant.SuccessLoading]: theme.palette.success.main,
        [SnackbarVariant.Info]: theme.palette.info.main,
    }


    const snackbarIcons: Record<SnackbarVariant, ReactNode> = {
        [SnackbarVariant.Default]: <Info className={classes.snaccIcon} />,
        [SnackbarVariant.Error]: <Error className={classes.snaccIcon} />,
        [SnackbarVariant.Warning]: <Warning className={classes.snaccIcon} />,
        [SnackbarVariant.WarningLoading]: <AppSpinner />,
        [SnackbarVariant.Success]: <Check className={classes.snaccIcon} />,
        [SnackbarVariant.SuccessLoading]: <AppSpinner />,
        [SnackbarVariant.Info]: <Info className={classes.snaccIcon} />,
    }

    return (
        <SnackbarContent ref={ref as any} {...otherProps}>
            <Box
                p={2}
                className={classes.snackbarBox}
                sx={{
                    borderLeft: `8px solid ${snackbarBorderColors[variant] || `transparent`}`,
                }}
            >
                <Box
                    pr={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    {snackbarIcons[variant]}
                </Box>
                <AppText
                    style={{
                        color: '#fff',
                    }}
                    data-testid={testIds.NOTIFICATION_POPUP_CONTENTS}
                    noWrap={false}
                    as={AppTextVariant.BodyBold}>
                    {message}
                </AppText>
                {!Boolean(props.persist) && (
                    <IconButton onClick={() => closeSnackbar(id)} size="large">
                        <Close className={classes.clearIcon} />
                    </IconButton>
                )};
            </Box>
        </SnackbarContent>
    )
})

export default AppSnackbarComponent;
