import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import PowerPoint3d from 'Assets/images/icons/powerpoint_3d.png';
import AppLottiePlayer from 'Atomic/atoms/Lottie/AppLottiePlayer';
import checkmarkAnimation from 'Atomic/atoms/Lottie/assets/checkmark_success_animation.json';
import scrollDownAnimation from 'Atomic/atoms/Lottie/assets/scroll_down_animation.json';
import { useTranslations } from 'Hooks/useTranslations';
import React, { useState } from 'react';


const AuthSuccessComponent = () => {

    enum AnimationStage {
        LoggedIn = 1,
        Arrows = 2,
        ToPowerpoint = 3,
    }

    const { translatePlaceholder } = useTranslations();

    const [currentStage, setCurrentStage] = useState(AnimationStage.LoggedIn);

    return (
        <Box
            m={3}
            p={3}
            textAlign="center">
            <Box mt={1} />
            {currentStage === AnimationStage.LoggedIn &&
                <>
                    <AppLottiePlayer
                        src={checkmarkAnimation}
                        loop={false}
                        autoplay
                        elementProps={{
                            style: {
                                height: 200,
                                width: 200,
                                margin: '0 auto',
                            }
                        }}
                        onAnimationComplete={() => setCurrentStage(AnimationStage.Arrows)}
                    />
                    <Box mt={1} />
                    <Typography variant="h3">
                        {translatePlaceholder("YOURE_NOW_LOGGED_IN")}
                    </Typography>
                </>
            }
            {currentStage === AnimationStage.Arrows &&
                <>
                    <AppLottiePlayer
                        src={scrollDownAnimation}
                        loop={false}
                        autoplay
                        elementProps={{
                            style: {
                                height: 200,
                                width: 200,
                                margin: '0 auto',
                            }
                        }}
                        onAnimationComplete={() => setCurrentStage(AnimationStage.ToPowerpoint)}
                    />
                    <Box mt={1} />
                    <Typography variant="h3">
                        {translatePlaceholder("PLEASE_RETURN_TO_POWERPOINT")}
                    </Typography>
                </>
            }
            {currentStage === AnimationStage.ToPowerpoint &&
                <>
                    <img src={PowerPoint3d} width="200" height="200" />
                    <Box mt={1} />
                    <Typography variant="h3">
                        {translatePlaceholder("PLEASE_RETURN_TO_POWERPOINT")}
                    </Typography>
                </>
            }
        </Box>
    )
}

export default AuthSuccessComponent;