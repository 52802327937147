import { createTheme } from '@mui/material/styles';
import { useMemo } from "react";
import { config } from "Scripts/config";

/**
 * We use a different theme when in the 'player'
 * These props are defined here
 */
export const usePlayTheme = () => {

	const playTheme = useMemo(() => {

		return createTheme({
			palette: {
				mode: 'light',
				primary: {
					main: config.blueColors.darker
				},
				secondary: {
					main: '#fcb731'
				}
			},
			components: {
				MuiButton: {
					styleOverrides: {
						root: {
							textTransform: 'none',
						}
					}
				},
				MuiTooltip: {
					styleOverrides: {
						tooltip: {
							fontSize: 16,
						}
					}
				},
			},
			zIndex: {
				snackbar: 2 ** 31
			},
			typography: {
				fontFamily: [
					'Trebuchet MS',
					'Helvetica',
					'-apple-system',
					'Roboto Slab',
					'Merriweather Sans',
					'sans-serif',
					'"Segoe UI"',
					'"Helvetica Neue"',
					'Arial',
					'"Apple Color Emoji"',
					'"Segoe UI Emoji"',
					'"Segoe UI Symbol"',
				].join(','),
			},
		});

	}, [])

	return {
		playTheme,
	}
}