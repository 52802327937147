
/** https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values */

export const previousNavigationKeys: string[] = [
	"ArrowDown",
	"ArrowLeft",
	"Backspace",
	"PageUp",
];

export const nextNavigationKeys: string[] = [
	"ArrowUp",
	"ArrowRight",
	"Spacebar",
	" ", /* Alias for spacebar key */
	"Enter",
	"PageDown",
];