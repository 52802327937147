import { DeleteOutline } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, CircularProgress, Collapse, Divider, FormControlLabel, IconButton, InputBase, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import ErrorText from 'Components/beta/common/text/error/ErrorText';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { QuestionTypes } from 'Types/identificationQuestionTypes';
import React, { ChangeEvent, ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import { classes } from './style.css';

interface QuestionProps {
    index: number;
    questionId: number | null;
    value: string;
    type: QuestionTypes;
    isRequired: boolean | number;
    totalQuestionCount: number;
    canDelete: boolean;
    answerComponents: ReactElement;
    update: (index: number, property: string, value: any) => Promise<void>;
    remove: (index: number, questionId: number | null) => Promise<boolean>;
    disabled: boolean;
    testIdPrefix: number;
    dataPrefix: string;
}

const Question = ({
    index,
    questionId,
    value,
    type,
    isRequired,
    totalQuestionCount,
    canDelete,
    answerComponents,
    update,
    remove,
    disabled,
    testIdPrefix,
    dataPrefix,
} : QuestionProps) => {

    /* Reference to the input field so we can programatically focus it */
    const questionInputRef = useRef<RefObject<HTMLInputElement>>(null);
    /* Question is loading, updating/saving/deleting */
    const [ questionLoading, setQuestionLoading ] = useState<boolean>(false);
    /* Question is selected (Options are expanded) */
    const [ selected, setSelected ] = useState<boolean>(false);
    /* Question is deleting (Collapse the question) */
    const [ deleting, setDeleting ] = useState<boolean>(false);
    /* Placeholder title so we don't have to update the store every keystroke */
    const [ placeholderTitle, setPlaceholderTitle ] = useState<string>('');

    const { translatePlaceholder } = useTranslations();

    const updateTitle = async () => {

        /* Don't update the title if theres no title or it hasn't changed */
        if(!placeholderTitle || placeholderTitle === value) {

            return;
        }

        setQuestionLoading(true);

        await update(index, 'title', placeholderTitle)

        setQuestionLoading(false);
    }

    const updateQuestionProperty = async (property: string, newValue: any) => {

        setQuestionLoading(true);

        await update(index, property, newValue)

        setQuestionLoading(false);
    }

    const deleteQuestion = async () => {

        if (canDelete) {

            setQuestionLoading(true);

            const isRemoved = await remove(index, questionId);

            if(isRemoved) {

                setDeleting(true);
            }

            setQuestionLoading(false);
        }
    }

    const selectQuestion = () => {

        questionInputRef.current && questionInputRef.current.select();

        setSelected(true);
    }

    const updateType = (e: ChangeEvent<HTMLInputElement>) => {

        e.stopPropagation();

        updateQuestionProperty('type', e.target.value)
    }

    useEffect(() => {

        setPlaceholderTitle(value || placeholderTitle)

    }, [ value ])

    useEffect(() => {

        if(index + 1 === totalQuestionCount) {
            /** If a question is added, focus the last question */
            selectQuestion();
        }

    }, [totalQuestionCount])

    return (
        <Collapse in={!deleting}>
            <Box
                className={classes.questionBox}
                sx={{
                    cursor: !selected ? 'pointer !important' : 'inherit',
                }}>
                <InputBase
                    inputProps={{
                        ref: questionInputRef,
                        'data-testid': `${testIdPrefix}_${testIds.QUESTION_INPUT}`,
                    }}
                    disabled={questionLoading || disabled}
                    fullWidth
                    endAdornment={<>
                        {!questionLoading && selected && <IconButton
                            data-testid={`${testIdPrefix}_${testIds.QUESTION_CLOSE}`}
                            data-eltype="button"
                            data-elaction={`${dataPrefix}_question_close`}
                            data-elposition={index}
                            data-elscreen="settings"
                            disabled={disabled}
                            className={classes.smIconButton}
                            onClick={() => setSelected(false)}
                            size="large">
                            <CloseIcon />
                        </IconButton>}
                        {questionLoading && <CircularProgress
                            className={classes.loader}
                        />}
                    </>}
                    onFocus={() => selectQuestion()}
                    onBlur={() => updateTitle()}
                    onChange={e => setPlaceholderTitle(e.target.value)}
                    value={placeholderTitle}
                    placeholder={translatePlaceholder("ENTER_YOUR_QUESTION")}
                />
                {!placeholderTitle && Boolean(value) && !questionLoading && !deleting && (
                    <ErrorText error={translatePlaceholder("ENTER_VALID_QUESTION_TITLE")} />
                )}
                <Collapse in={selected}>
                    <div>
                        {type !== QuestionTypes.Text && <>
                            {answerComponents}
                        </>}
                    </div>
                    <div className={classes.typeContainer}>
                        <div className={classes.typeBox}>
                            <Select
                                data-testid={`${testIdPrefix}_${testIds.QUESTION_TYPE_SELECT}`}
                                className={classes.typeSelect}
                                classes={{
                                    select: classes.select,
                                }}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}
                                disabled={questionLoading || !questionId || disabled}
                                value={type}
                                disableUnderline
                                onChange={e => updateType(e)} >
                                <MenuItem
                                    data-testid={`${testIdPrefix}_${testIds.QUESTION_TYPE_DROPDOWN_TEXT}`}
                                    data-eltype="button"
                                    data-elaction={`${dataPrefix}_question_change_to_text`}
                                    data-elposition={index}
                                    data-elscreen="settings"
                                    value={QuestionTypes.Text}>
                                    Text
                                </MenuItem>
                                <MenuItem
                                    data-testid={`${testIdPrefix}_${testIds.QUESTION_TYPE_DROPDOWN_MPC}`}
                                    data-eltype="button"
                                    data-elaction={`${dataPrefix}_question_change_to_multiplechoice`}
                                    data-elposition={index}
                                    data-elscreen="settings"
                                    value={QuestionTypes.MultipleChoice}>
                                    Multiplechoice
                                </MenuItem>
                                <MenuItem
                                    data-testid={`${testIdPrefix}_${testIds.QUESTION_TYPE_DROPDOWN_RADIO}`}
                                    data-eltype="button"
                                    data-elaction={`${dataPrefix}_question_change_to_checkbox`}
                                    data-elposition={index}
                                    data-elscreen="settings"
                                    value={QuestionTypes.Radio}>
                                    Checkbox
                                </MenuItem>
                            </Select>
                            <FormControlLabel
                                className={classes.requiredLabel}
                                label="Required"
                                control={
                                    <Checkbox
                                        data-testid={`${testIdPrefix}_${testIds.QUESTION_REQUIRED}`}
                                        data-eltype="button"
                                        data-elaction={`${dataPrefix}_question_required`}
                                        data-elposition={index}
                                        data-elscreen="settings"
                                        color="primary"
                                        disabled={questionLoading || !questionId || disabled}
                                        onChange={e => updateQuestionProperty('isRequired', Number(e.target.checked))}
                                        checked={Boolean(Number(isRequired))}
                                    />
                                }
                            />
                            {canDelete && <>
                                <Divider orientation="vertical" className={classes.divider} />
                                <IconButton
                                    data-testid={`${testIdPrefix}_${testIds.QUESTION_DELETE}`}
                                    data-eltype="button"
                                    data-elaction={`${dataPrefix}_question_delete`}
                                    data-elposition={index}
                                    data-elscreen="settings"
                                    onClick={() => deleteQuestion()}
                                    className={classes.smIconButton}
                                    disabled={questionLoading || disabled}
                                    size="large">
                                    <DeleteOutline className={classes.deleteIcon} />
                                </IconButton>
                            </>}
                        </div>
                    </div>
                </Collapse>
            </Box>
        </Collapse>
    );
};

export default Question;
