import FeatureWrapper from 'Atomic/atoms/FeatureWrapper/FeatureWrapper';
import EditableInput from 'Components/beta/common/input/editable/EditableInput';
import { Features } from 'Hooks/useFeatures';
import usePlaySettings from 'Hooks/usePlaySettings';
import { useSession } from 'Hooks/useSession';
import { testIds } from 'Scripts/cypressTestIds';
import { TrackingAttributes } from 'Scripts/measurementsGAHelper';
import React, { useMemo } from 'react';
import { classes } from './style.css';

const LoginCodeField = (props: TrackingAttributes) => {

	const {
		updateResponseCode,
		updateResponseCodeLoading,
	} = usePlaySettings();

	const {
		userSessionInfo
	} = useSession();

	const responseCode = useMemo(() => {

		return userSessionInfo?.responseCode;

	}, [userSessionInfo?.responseCode])

	return (
        <>
			{Boolean(responseCode) && (
				<FeatureWrapper
					{...props}
					feature={Features.EDIT_RESPONSE_CODE}>
					<EditableInput
						className={classes.input}
						value={responseCode as string}
						onSave={(newValue: string) => updateResponseCode(newValue)}
						isLoading={updateResponseCodeLoading}
						disabled={updateResponseCodeLoading}
						data-testid={testIds.EDIT_LOGIN_CODE}
						{...props}
					/>
				</FeatureWrapper>
			)}
		</>

	);
};

export default LoginCodeField;