import { Box, Grid } from "@mui/material";
import { LayoutTypes } from 'Types/layoutTypes';
import { FilenameCssStyle } from 'Types/presentationTypes';
import React, { ReactElement, ReactNode } from "react";
import LayoutDefault from "./layouts/LayoutDefault/LayoutDefault";
import LayoutImageOnBackground from "./layouts/LayoutImageOnBackground/LayoutImageOnBackground";
import LayoutImageOnBottom from "./layouts/LayoutImageOnBottom/LayoutImageOnBottom";
import LayoutImageOnLeft from "./layouts/LayoutImageOnLeft/LayoutImageOnLeft";
import LayoutImageOnLeftPadded from "./layouts/LayoutImageOnLeftPadded/LayoutImageOnLeftPadded";
import LayoutImageOnLeftSmall from "./layouts/LayoutImageOnLeftSmall/LayoutImageOnLeftSmall";
import LayoutImageOnRight from "./layouts/LayoutImageOnRight/LayoutImageOnRight";
import LayoutImageOnRightPadded from "./layouts/LayoutImageOnRightPadded/LayoutImageOnRightPadded";
import LayoutImageOnRightSmall from "./layouts/LayoutImageOnRightSmall/LayoutImageOnRightSmall";
import LayoutImageOnTop from "./layouts/LayoutImageOnTop/LayoutImageOnTop";

export const videoEligibleLayouts = [
    LayoutTypes.ImageOnRightPadded,
    LayoutTypes.ImageOnLeftPadded,
    LayoutTypes.ImageOnRight,
    LayoutTypes.ImageOnLeft,
    null,
];

export interface LayoutWrapperProps {
    title?: ReactElement;
    content?: ReactElement | ReactNode | ReactNode[];
    imageURL: string | null;
    imageStyle?: FilenameCssStyle;
    videoURL?: string | null;
    layoutType: string | null;
    interactive?: boolean;
    hideCounters?: boolean;
    backgroundOpacity?: string | null;
    backgroundColor?: string | null;
}


export const LayoutWrapper = (props: LayoutWrapperProps) => {

    const getLayout = () => {

        switch (props.layoutType) {
            case LayoutTypes.ImageOnRightPadded:
                return <LayoutImageOnRightPadded {...props} />;
            case LayoutTypes.ImageOnLeftPadded:
                return <LayoutImageOnLeftPadded {...props} />;
            case LayoutTypes.ImageOnRight:
                return <LayoutImageOnRight {...props} />;
            case LayoutTypes.ImageOnTop:
                return <LayoutImageOnTop {...props} />;
            case LayoutTypes.ImageOnLeft:
                return <LayoutImageOnLeft {...props} />;
            case LayoutTypes.ImageOnBottom:
                return <LayoutImageOnBottom {...props} />;
            case LayoutTypes.ImageOnRightSmall:
                return <LayoutImageOnRightSmall {...props} />;
            case LayoutTypes.ImageOnLeftSmall:
                return <LayoutImageOnLeftSmall {...props} />;
            case LayoutTypes.ImageOnBackground:
                return <LayoutImageOnBackground {...props} />;
            default:
                return <LayoutDefault {...props} />;
        }
    }

    const currentLayout = getLayout();

    return (
        <Box
            height="100%"
            width="100%">
            <Grid
                container
                height="90%"
                flexDirection="column">
                <Grid
                    container
                    item
                    xs
                    width="100%"
                    height="100%">
                    {currentLayout}
                </Grid>
            </Grid>
            <Grid
                item
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px="5%"
                height="10%">
            </Grid>
        </Box>
    )
}