import { TableCellData } from "Atomic/organisms/AppDynamicTable/AppDynamicTable";
import { Dispatch } from "react";
import { LayoutTypes } from "Types/layoutTypes";
import { PresentationErrors, Slide, SlideList, SlideType } from 'Types/presentationTypes';
import { SlideShape, SlideTypes } from 'Types/slideTypes';



export const duplicateSlide = (slideIdToDuplicate: number, newSlides: Array<Slide>) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'DUPLICATE_SLIDE',
		slideIdToDuplicate,
		newSlides,
	});
}

export const setPresentationLogo = (url: string|null) => ({
	type: 'SET_PRESENTATION_LOGO',
	url,
})

export const setPresentationUserLogo = (url: string | null) => ({
	type: 'SET_PRESENTATION_USER_LOGO',
	url,
})

export const setSlideTable = (table: Array<Array<TableCellData>>, slideId: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'SET_SLIDE_TABLE',
		table,
		slideId,
	})

	dispatch(addToUnsyncedSlideIds(slideId));
}

export const convertSlide = (slideId: number, layoutType: LayoutTypes, slideType: SlideTypes) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'CONVERT_SLIDE',
		slideId,
		layoutType,
		slideType,
	})

	dispatch(addToUnsyncedSlideIds(slideId));
}

export const addToUnsyncedSlideIds = (slideKey: number) => {
	return {
		type: 'ADD_TO_UNSYNCED_SLIDE_IDS',
		slideKey
	}
}

export const clearUnsyncedSlideIds = (slideIds?: Array<number>) => ({
	type: 'CLEAR_UNSYNCED_SLIDE_IDS',
	slideIds,
})

export const setPresentationErrors = (errors: PresentationErrors) => ({
	type: 'SET_QUIZ_ERRORS',
	errors
})

export const clearSelected = () => ({
	type: 'CLEAR_SELECTED',
})

export const addNewSlide = (newSlide: Slide) => ({
	type: 'ADD_NEW_SLIDE',
	newSlide
})

export const addNewSlides = (slides: Array<Slide>) => ({
	type: 'ADD_NEW_SLIDES',
	slides,
})

export const clearQuestionFile = (slideKey: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'CLEAR_QUESTION_FILE',
		slideKey
	})

	dispatch(addToUnsyncedSlideIds(slideKey))
}

export const setQuestionPoints = (points: number, slideKey: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'SET_QUESTION_POINTS',
		points,
		slideKey
	})

	dispatch(addToUnsyncedSlideIds(slideKey))
}

export const setQuestionTime = (time: number, slideKey: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'SET_QUESTION_TIME',
		time,
		slideKey
	})

	dispatch(addToUnsyncedSlideIds(slideKey))
}

export const setQuizTitle = (quizTitle: string) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'SET_QUIZ_TITLE',
		quizTitle,
	})
}

export const updateSlide = (slide: SlideType) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'UPDATE_SLIDE',
		slide,
	})

	dispatch(addToUnsyncedSlideIds(slide.id))
}

export const setSlides = (slides: SlideList, addToUnsyncedKeys: boolean = false) => ({
	type: 'SET_SLIDES',
	slides,
	addToUnsyncedKeys
});

export const setQuestionText = (title: string, slideKey: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'SET_QUESTION_TEXT',
		title,
		slideKey
	})

	dispatch(addToUnsyncedSlideIds(slideKey))
}

export const setQuestionShowAsPercentage = (showPercentages: boolean, slideKey: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'SET_QUESTION_SHOW_AS_PERCENTAGE',
		showPercentages,
		slideKey
	})

	dispatch(addToUnsyncedSlideIds(slideKey))
}

export const updateSlideProperty = (slideKey: number, propertyName: string, propertyValue: any) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'UPDATE_SLIDE_PROPERTY',
		slideKey,
		propertyName,
		propertyValue
	})

	dispatch(addToUnsyncedSlideIds(slideKey))
}

export const addOption = (optionIndex: number, answer: string, correctAnswer: number|boolean, slideKey: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'ADD_OPTION',
		answer,
		correctAnswer,
		optionIndex,
		slideKey
	})

	dispatch(addToUnsyncedSlideIds(slideKey))
}

export const deleteOption = (optionIndex: number, slideKey: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'DELETE_OPTION',
		optionIndex,
		slideKey
	})

	dispatch(addToUnsyncedSlideIds(slideKey))
}

export const removeSlideItem = (slideKey: number) => ({
	type: 'REMOVE_SLIDE',
	slideKey,
})


export const setDefaultAnswerTime = (time: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'SET_DEFAULT_ANSWER_TIME',
		time
	})
}

export const setSlideNavigationState = (newState: string) => ({
	type: 'SET_SLIDE_NAVIGATION_STATE',
	newState
})

export const resetSlideNavigationState = () => ({
	type: 'RESET_SLIDE_NAVIGATION_STATE'
})

export const setDefaultShowPercentage = (showPercentages: boolean) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'SET_DEFAULT_SHOW_PERCENTAGES',
		showPercentages
	})
}

export const setDefaultPointsAwarded = (points: number) => (dispatch: Dispatch<any>) => {
	dispatch({
		type: 'SET_DEFAULT_POINTS_AWARDED',
		points
	})
}

export const reorderSlides = (sourceIndex: number, destinationIndex: number) => (dispatch: Dispatch<any>) => {
	dispatch({
		type: 'REORDER_SLIDES',
		sourceIndex,
		destinationIndex
	})
}

export const updateSlideFiles = (fileUrls: string[]) => ({
	type: 'UPDATE_SLIDE_FILES',
	fileUrls
})

export const resetSlide = (slideKey: number) => ({
	type: 'RESET_SLIDE',
	slideKey
})

export const clearSlides = () => ({
	type: 'CLEAR_SLIDES',
})

export const setPresentationId = (id: number) => ({
	type: 'SET_QUIZ_ID',
	id
})

export const clearQuizState = () => ({
	type: 'CLEAR_QUIZ_STATE'
})

export const setPresentationDataFromAPICall = (data: any) => ({
	type: 'SET_PRESENTATION_DATA_FROM_API',
	data,
})

export const setPresentationStyleId = (styleId: number | null) => ({
	type: 'SET_PRESENTATION_STYLE_ID',
	styleId,
})

export const setPreviewSlideLayout = (layoutType: LayoutTypes|null|undefined) => ({
	type: 'SET_PREVIEW_SLIDE_LAYOUT',
	layoutType,
})

export const setUpdatePresentationTitleLoading = (isLoading: boolean) => ({
	type: 'SET_UPDATE_PRESENTATION_TITLE_LOADING',
	isLoading,
})

export const updateSlideShape = (shape: SlideShape, shapeIndex: number) => (dispatch: Dispatch<any>) => {

	dispatch({
		type: 'UPDATE_SLIDE_SHAPE',
		shape,
		shapeIndex,
	})

	dispatch(addToUnsyncedSlideIds(shape.slideId))
}

export const setSlideShapePresets = (presets: Record<LayoutTypes, Record<SlideTypes, SlideShape>>) => ({
	type: 'SET_SLIDE_SHAPE_PRESETS',
	presets,
})

export const setPreventPresentationAutoMovement = (preventAutoMovement: boolean | null) => ({
	type: 'SET_PREVENT_AUTO_MOVEMENT',
	preventAutoMovement,
})

export const setHidePresentationLogo = (hide: boolean | null) => ({
	type: 'SET_HIDE_PRESENTATION_LOGO',
	hide,
})

export const setPresentationLogoData = (presentationLogo: string | null, presentationUserLogo: string | null, presentationDefaultLogo: string, presentationLogoHidden: boolean | null) => ({
	type: 'SET_PRESENTATION_LOGO_DATA',
	presentationLogo,
	presentationUserLogo,
	presentationDefaultLogo,
	presentationLogoHidden,
})