import { setSocketConnection } from 'Actions/appActions';
import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { TOKEN_NAME } from 'Scripts/globals';
import { getFromLocalStorage } from 'Scripts/localStorage';
import { getSessionIdFromToken } from 'Scripts/tokenHelper';
import io from 'socket.io-client/dist/socket.io.slim.js';
import { AppState } from 'Types/appTypes';
import { SocketEvents } from 'Types/socketTypes';
/*
    This hook lets you implement socket events without having to define all of them in one single place.
    Example use:

    const handleSessionSubscribed = data => {
        (Handle your data here)
    }

    useWebSocket({
        SESSION_SUBSCRIBED: data => handleSessionSubscribed(data)
    })
*/

const unimplementedCall = (callName: string) => {
    // console.warn(`The socket event handler '${callName}' isn't implemented, did you forget to assign it?`)
}

const useWebSocket = ({
    MAX_PARTICIPANTS_REACHED = () => unimplementedCall('MAX_PARTICIPANTS_REACHED'),
    PARTICIPANTSINFO_CREATED = () => unimplementedCall(`PARTICIPANTSINFO_CREATED`),
    PARTICIPANT_LOGGED_OUT = () => unimplementedCall(`PARTICIPANT_LOGGED_OUT`),
    VOTEMESSAGE_CREATED = () => unimplementedCall(`VOTEMESSAGE_CREATED`),
    VOTEMESSAGE_UPDATED = () => unimplementedCall(`VOTEMESSAGE_UPDATED`),
    SESSION_SUBSCRIBED = () => unimplementedCall(`SESSION_SUBSCRIBED`),
    PARTICIPANT_JOINED = () => unimplementedCall(`PARTICIPANT_JOINED`),
    MESSAGE_CREATED = () => unimplementedCall(`MESSAGE_CREATED`),
    MESSAGE_UPDATED = () => unimplementedCall(`MESSAGE_UPDATED`),
    MESSAGE_REMOVED = () => unimplementedCall(`MESSAGE_REMOVED`),
    SESSION_EMOJI = () => unimplementedCall(`SESSION_EMOJI`),
    SESSION_START = () => unimplementedCall(`SESSION_START`),
    PLAN_UPGRADE = () => unimplementedCall(`PLAN_UPGRADE`),
    /* Default connection, we don't really have to do anything with these */
    RECONNECT_ATTEMPT = () => unimplementedCall(`RECONNECT_ATTEMPT`),
    RECONNECT_FAILED = () => unimplementedCall(`RECONNECT_FAILED`),
    RECONNECT_ERROR = () => unimplementedCall(`RECONNECT_ERROR`),
    CONNECT_TIMEOUT = () => unimplementedCall(`CONNECT_TIMEOUT`),
    CONNECT_ERROR = () => unimplementedCall(`CONNECT_ERROR`),
    RECONNECTING = () => unimplementedCall(`RECONNECTING`),
    VOTE_RESULTS = () => unimplementedCall(`VOTE_RESULTS`),
    DISCONNECT = () => unimplementedCall(`DISCONNECT`),
    RECONNECT = () => unimplementedCall(`RECONNECT`),
}) => {

    const liveWebsocket = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).liveWebsocket);

    const currentUser = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentUser);

    const [ isConnected, setIsConnected ] = useState(false);

    const authToken = getFromLocalStorage(TOKEN_NAME);

    const dispatch = useDispatch()

    const createSocketConnection = () => {

        let newSocketConnection = liveWebsocket

        if (!newSocketConnection) {

            if(window['io']) {
                /* 'io' is injected into the window during cypress tests to mock socket events */
                newSocketConnection = window['io'];

            } else {

                newSocketConnection = io(process.env.NODE_URL, {
                    'reconnection': true,
                    'reconnectionDelay': 2000,
                    'reconnectionAttempts': (24 * 60 * 60),
                    'reconnectionDelayMax': 5000,
                    'timeout': 10000,
                    'transports': ['websocket'],
                });
            }

            dispatch(setSocketConnection(newSocketConnection))
        }
    }

    useEffect(() => {

        if(!liveWebsocket) {

            createSocketConnection()
        }

    }, [])

    const handleConnect = socket => {

        if(!isConnected) {

            socket.emit(SocketEvents.SessionSubscribe, {
                sessionId: getSessionIdFromToken(authToken),
                token: authToken,
                socketId: socket.id
            });

            setIsConnected(true)
        }
    }

    useEffect(() => {

        if (liveWebsocket && authToken && Boolean(currentUser)) {

            bindEvents(liveWebsocket);

            return () => {

                unbindEvents(liveWebsocket);
            }
        }

    }, [authToken, currentUser])

    const unbindEvents = (socket) => {

        socket.off(SocketEvents.ConnectError)

        socket.off(SocketEvents.ConnectTimeout)

        socket.off(SocketEvents.ReconnectAttempt)

        socket.off(SocketEvents.Reconnecting)

        socket.off(SocketEvents.ReconnectError)

        socket.off(SocketEvents.ReconnectFailed)

        socket.off(SocketEvents.SessionMaxParticipantsReached)

        socket.off(SocketEvents.SessionSubscribed)

        socket.off(SocketEvents.SessionStart)

        socket.off(SocketEvents.SessionEmoji)

        socket.off(SocketEvents.ParticipantJoined)

        socket.off(SocketEvents.VoteResults)

        socket.off(SocketEvents.ParticipantLoggedOut)

        socket.off(SocketEvents.ParticipantsInfoCreated)

        socket.off(SocketEvents.VoteMessageCreated)

        socket.off(SocketEvents.VoteMessageUpdated)

        socket.off(SocketEvents.MessageCreated)

        socket.off(SocketEvents.MessageUpdated)

        socket.off(SocketEvents.MessageRemoved)

        socket.off(SocketEvents.PlanUpgrade);
    }

    const bindEvents = (socket) => {

        handleConnect(socket);

        socket.on(SocketEvents.ConnectError, CONNECT_ERROR)

        socket.on(SocketEvents.ConnectTimeout, CONNECT_TIMEOUT)

        socket.on(SocketEvents.Disconnect, () => {

            setIsConnected(false);
        })

        socket.on(SocketEvents.Reconnect, () => {

            handleConnect(socket);
        })

        socket.on(SocketEvents.ReconnectAttempt, RECONNECT_ATTEMPT)

        socket.on(SocketEvents.Reconnecting, RECONNECTING)

        socket.on(SocketEvents.ReconnectError, RECONNECT_ERROR)

        socket.on(SocketEvents.ReconnectFailed, RECONNECT_FAILED)

        socket.on(SocketEvents.SessionMaxParticipantsReached, MAX_PARTICIPANTS_REACHED)

        socket.on(SocketEvents.SessionSubscribed, SESSION_SUBSCRIBED)

        socket.on(SocketEvents.SessionStart, SESSION_START)

        socket.on(SocketEvents.SessionEmoji, SESSION_EMOJI)

        socket.on(SocketEvents.ParticipantJoined, PARTICIPANT_JOINED)

        socket.on(SocketEvents.VoteResults, VOTE_RESULTS)

        socket.on(SocketEvents.ParticipantLoggedOut, PARTICIPANT_LOGGED_OUT)

        socket.on(SocketEvents.ParticipantsInfoCreated, PARTICIPANTSINFO_CREATED)

        socket.on(SocketEvents.VoteMessageCreated, VOTEMESSAGE_CREATED)

        socket.on(SocketEvents.VoteMessageUpdated, VOTEMESSAGE_UPDATED)

        socket.on(SocketEvents.MessageCreated, MESSAGE_CREATED)

        socket.on(SocketEvents.MessageUpdated, MESSAGE_UPDATED)

        socket.on(SocketEvents.MessageRemoved, MESSAGE_REMOVED)

        socket.on(SocketEvents.PlanUpgrade, PLAN_UPGRADE);
    }
};

export default useWebSocket;