import { Box } from '@mui/material';
import FullSizeContainer from 'Atomic/atoms/FullSizeContainer';
import WysiwygEditorContainer from 'Atomic/organisms/WysiwygEditor/variants/WysiwygEditorContainer';
import FullHeightContainer from 'Components/common/FullHeightContainer';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import { WysiwygToolBarType } from 'Components/common/util/wysiwygEditor/WysiwygEditor';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import useSlideActions from 'Hooks/useSlideActions';
import { testIds } from 'Scripts/cypressTestIds';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType, EditorSlideField, StringReturnFunction } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';


const TitleAndSubtitleSlideEditor = ({ slide }: SlideConsumerComponent<ContentSlideType>) => {


    const { setSlideSetting } = useSlideActions();

    const slideProperties = getSlideProperties(slide);

    return (
        <FullHeightContainer data-testid={testIds.SLIDE_TYPE_TITLE_AND_SUBTITLE}>
            <LayoutWrapper
                interactive={true}
                layoutType={slide.slideLayout}
                videoURL={slide.videoURL}
                imageURL={slide?.file?.base64 || slide?.filename}
                imageStyle={slide.filenameStyle}
                backgroundOpacity={slide.backgroundOpacity}
                backgroundColor={slide.backgroundColor}
                content={
                    <FullSizeContainer>
                        <Box className={classes.inputContainer} px={10}>
                            <FullWidthContainer>
                                <WysiwygEditorContainer
                                    value={slide.title}
                                    getPlaceholderHtml={slideProperties.text.getHtmlTitlePlaceholder}
                                    updateValue={newTitle => setSlideSetting(EditorSlideField.Title, newTitle, slide.id)}
                                    placeholderText={slideProperties.text.titlePlaceholder}
                                    fontSize={slideProperties.slideSettings.titleFontSize}
                                    toolbarType={WysiwygToolBarType.Simple}
                                />
                            </FullWidthContainer>
                            <Box pb={4} />
                            <FullWidthContainer>
                                <WysiwygEditorContainer
                                    value={slide.content}
                                    getPlaceholderHtml={slideProperties.text.getHtmlContentPlaceholder as StringReturnFunction}
                                    updateValue={newContent => setSlideSetting(EditorSlideField.Content, newContent, slide.id)}
                                    placeholderText={slideProperties.text.contentPlaceholder as string}
                                    toolbarType={WysiwygToolBarType.Extended}
                                    fontSize={slideProperties.slideSettings?.contentFontSize as number}
                                />
                            </FullWidthContainer>
                        </Box>
                    </FullSizeContainer>
                }
            />
        </FullHeightContainer>
    );
};

export default TitleAndSubtitleSlideEditor;