import React from 'react';

interface FullheightContainerProps {
	children: React.ReactNode
	[key: string]: any;
}

const FullHeightContainer = React.forwardRef<HTMLElement, FullheightContainerProps>(({ children, ...props }, ref) => {

	return <div
		ref={ref}
		style={{
			height: "100%"
		}}
		{...props}>
		{children}
	</div>
});

export default FullHeightContainer