import Info from '@mui/icons-material/InfoOutlined';
import AppText from 'Atomic/atoms/Text/AppText';
import LoginCodeField from 'Atomic/molecules/LoginCodeField/LoginCodeField';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { Features, useFeatures } from 'Hooks/useFeatures';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElPosition, DataElScreen } from 'Scripts/measurementsGAHelper';
import React from 'react';
import { classes } from './style.css';

const LoginCodeEditor = () => {

    const { translatePlaceholder } = useTranslations();

	const { isFeatureAvailable } = useFeatures()

	const hasEditLoginCodeFeature = isFeatureAvailable(Features.EDIT_RESPONSE_CODE);

	return (
        <div>
            <CustomTooltip title={translatePlaceholder("LOGIN_CODE_EXPLANATION")}>
                <AppText py={1.5} fontSize={14} color="#000" fontWeight={600}>
                    {!hasEditLoginCodeFeature && "💎"} {translatePlaceholder("AUDIENCE_LOGIN_CODE")} <Info className={classes.info} />
                </AppText>
            </CustomTooltip>
            <LoginCodeField
                data-elaction={`${DataElAction.LoginCodeEdit}${!hasEditLoginCodeFeature ? '_limited' : ''}`}
                data-elscreen={DataElScreen.Editor}
                data-elposition={DataElPosition.SettingsContainer}
            />
        </div>
	);
};

export default LoginCodeEditor;