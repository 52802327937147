import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useInterval } from 'Scripts/intervalHook';
import { PlayState } from 'Types/playTypes';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const QASlideMessage = ({ message, isNew = false }) => {

    const [pulseEffectEnabled, setPulseEffectEnabled] = useState(false)

    useEffect(() => {

        if (message.upvoteCount > 0) {

            setPulseEffectEnabled(true)
        }

    }, [message.upvoteCount])

    useInterval(() => {

        setPulseEffectEnabled(false)

    }, pulseEffectEnabled ? 250 : null)

    const theme = useTheme();

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const { presentationStyle } = usePresentationStyles();


    const sessionData = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).sessionData);

    const joinedParticipants = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).joinedParticipants);

    const getParticipant = () => {

        if (!!Number(sessionData?.anonymousSources)) {

            return null;
        }

        const { participantId } = message;

        return joinedParticipants.find(participant => Number(participant.participantId) === Number(participantId))
    }

    return (
        <Grid container justifyContent="center" alignItems="flex-start" item >
            <Grow in={true}>
                <div className={classes.upvoteMessage}>
                    <Grid container direction="row" alignItems="center" sx={{
                        padding: theme.spacing(isLargeScreen ? 2 : 1),
                    }}>
                        <Grid item xs container direction="column" alignItems="flex-start" className={classes.messageSection} >
                            <Grid className={classes.upvoteMessageGrid} item container justifyContent="center" direction="column">
                                <Typography className={classes.upvoteMessageText} variant={isLargeScreen ? "h5" : "h6"}>
                                    {message.text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grow>
        </Grid>
    );
};

export default QASlideMessage
