import React from 'react';


let FullWidthContainer = ({ children }) => {

	return <div style={{ width: '100%'}}>
		{children}
	</div>
};

export default FullWidthContainer