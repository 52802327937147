import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { classes } from './style.css';


interface ErrorTextProps {
	error: string | ReactElement | null;
	showIcon?: boolean;
}

const ErrorText = ({ error, showIcon = true } : ErrorTextProps) => {

	return (
		<div className={classes.textContainer}>
			{showIcon && <div>
				<ErrorIcon className={classes.warningIcon} />
			</div>}
			<div className={classes.textBox}>
				<Typography className={classes.warningtext}>
					{error}
				</Typography>
			</div>
		</div>
	);
};

export default ErrorText;