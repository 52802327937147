import React from 'react';

const PreviewWordcloudSlideShape = () => {
    return (
        <>
            {/* Nothing rendered here as we don't preview the wordcloud (yet) */}
        </>
    );
};

export default PreviewWordcloudSlideShape;