import { BaseTypes } from "Components/types/helpers/Slide";
import { NavigationState } from "Scripts/slideHelper";
import { SlideShape, SlideTypes } from 'Types/slideTypes';
import { ReactElement } from "react";
import { TableCellData } from '../atomic/organisms/AppDynamicTable/AppDynamicTable';
import { LayoutTypes } from "./layoutTypes";

export interface PresentationStyleType {
	id: number;
	name: string;
	darkerColor: string;
	darkColor: string;
	normalColor: string;
	lightColor: string;
	lighterColor: string;
	textColor: string;
	isDefault: number | boolean;
	isPremium: number | boolean;
	isDeleted: number | boolean;
	backgroundImage: string | null;
	indexSlideBackgroundImage: string | null;
	brandLogo: string | null;
	font: string | null;
}

export interface PresentationLibraryItemData {
	id: number;
	isNew: boolean;
	hasResponses: boolean;
	responseSessionRunId: number | null;
	/** @deprecated, remove if api doesn't return 'quizTitle' but 'name' */
	quizTitle: string;
	name: string;
	questions: number;
	icon: string | null;
	createdFrom: string;
	createdAt: string;
	updatedAt: string | null;
	indexSlide: SlideType;
	presentationStyleId: number;
	aiGenerated: boolean;
	totalPlays: number;
}

export interface PresentationStyleState {
	presentationStyles: Array<PresentationStyleType>;
}

export type SlideShapePresets = Record<LayoutTypes, Record<SlideTypes, SlideShape[]>>;

export interface PresentationState {
	presentationId: number | null;
	quizTitle: string;
	slides: Record<number, SlideType>;
	errors: PresentationErrors | null;
	defaultPointsAwarded: number;
	defaultAnswerTime: number | boolean;
	defaultShowPercentages: number | boolean;
	unSyncedSlideIds: Array<number>;
	previousSlideNavigationState: null | NavigationState;
	slideNavigationState: NavigationState;
	presentationStyleId: null | number;
	videoURL: null | string;
	currentPresentationIsTemplate: boolean;
	currentPresentationisAIGenerated: boolean;
	/** Undefined because default layout = null */
	previewSlideLayout: LayoutTypes | null | undefined;
	updatePresentationTitleLoading: boolean;
	slideShapePresets: SlideShapePresets | null,
	/**
	 * Logo for a given presentation
	 */
	presentationLogo: string | null;
	/**
	 * Default logo for a given user
	 */
	presentationUserLogo: string | null;
	/**
	 * Default presentation logo (Sendsteps logo)
	 */
	presentationDefaultLogo: string | null;
	/**
	 * Whether slides transition to their next state automatically
	 */
	preventAutoSlideMovement: boolean | null;
	/**
	 * Whether the slide logo shape should be hidden
	 */
	presentationLogoHidden: boolean | null;

}
export interface FileUploadObject {
	base64: string;
	name: string;
	type: string;
}

export interface VoteOption {
	id?: number;
	key?: number;
	correctAnswer?: number | boolean;
	answer?: string;
	answerHtml?: string;
	answerCode?: string;
}

export interface SlideVoteMap {
	[slideId: number]: Array<VoteOption>;
}

export interface SlideShapeMap {
	[slideId: number]: Array<SlideShape>;
}

export enum FilenameCssStyle {
	Cover = 'cover',
	Contain = 'contain',
}

export interface SlideFile {
	base64: string;
	type: string;
}

export interface Slide {
	id: number;
	type: SlideTypes;
	slideIndex: number;
	slideLayout: LayoutTypes | null;
	title: string;
	content: string;
	filename: string | null;
	presentationId: number;
	filenameStyle: FilenameCssStyle
	backgroundOpacity: string | null;
	backgroundColor: string | null;
	videoURL: string | null;
	dynamicTimerLimit: number | null;
	options?: Array<VoteOption>;
	table?: Array<Array<TableCellData>>;
	notes: string | null;
	file?: SlideFile;
	shapes?: Array<SlideShape>;
}

export interface SlideType extends VoteType, MessageRoundType, ContentSlideType {}

export interface ContentSlideType extends Slide {
	id: number;
	presentationId: number;
	slideIndex: number;
	content: string;
	isDeleted: number;
	createdFrom: string;
	createdAt: string;
	updatedAt: string;
}

export interface MessageRoundType extends Slide {
	id: number;
	presentationId: number;
	currentSessionId: number | null;
	slideIndex: number;
	active: number | boolean;
	opened: number | boolean;
	inSlideShow: number | boolean;
	nrOfMessageShapes: number;
	limitToSlide: number | boolean;
	skipModerator: number | boolean;
	nrOfMessagesAllowed: number;
	correctAnswers: string | null;
	isWordcloudQuestion: number | boolean;
	isWebpageSlide: number | boolean;
	hasUpvoting: number | boolean;
	createdFrom: string;
	slideDesignId: number | null;
}
export interface VoteType extends Slide {
	id: number;
	presentationId: number;
	slideIndex: number;
	active: number | boolean;
	nrOfVotes: number;
	nrOfVotesArePerAnswer: number | boolean;
	points: number | null;
	isQuizVote: number | boolean;
	weightedVoting: number | boolean;
	opened: number | boolean;
	showScores: number | boolean;
	currentSessionId: number | null;
	multipleAllowed: number | boolean;
	showPercentages: number | boolean;
	dynamicResultsSlide: number | boolean;
	votesCounterPresent: number | boolean;
	displayStatusOnVoteSlide: number | boolean;
	displayStatusOnResultsSlide: number | boolean;
	selectedBackgroundMusic: number;
	allowMultipleVotesPerAnswer: number | boolean;
	isRatingQuestion: number | boolean;
	ratingMinimumLabel: string;
	ratingMaximumLabel: string;
	createdFrom: string;
	slideDesignId: number | null;
	graphType: number;
	strictAnswers: number | boolean;
}

export type SlideList = Record<number, SlideType>;

export interface PresentationErrors {
	needsMinTitleLength?: string;
	slideLimitExceeded?: boolean;
	[slideKey: number]: SlideErrors;
}

export interface SlideErrors {
	needsMinOptions?: string;
	needsValidTime?: string;
	needsUnique?: string;
	needsMinQuestionlength?: string;
	needsMinSubTitleLength?: string;
	needsMinContentLength?: string;
	needsCorrect?: string;
	options?: SlideOptionErrors;
}

export interface SlideOptionErrors {
	needsValidOptionLength?: string;
}

export interface BaseVoteProperties extends BaseSlideProperties {
	baseModelValues: VoteType
}

export interface BaseMessageRoundProperties extends BaseSlideProperties {
	baseModelValues: MessageRoundType;
}

export interface BaseSlideParameters {
	id: number,
	presentationId: number,
	slideIndex: number,
}

export interface SlideComponents {

	/**
	 * The component which is displayed during a session
	 */
	slidePlayComponent: (slide: SlideType) => ReactElement;
	/**
	 * The component which is displayed when users are using the editor
	 */
	slideEditorComponent: (slide: SlideType) => ReactElement;
	/**
	* The component which can be displayed as a (non interactable) preview
	*/
	slideEditorPreviewComponent: (slide: SlideType) => ReactElement;
	/**
	 * The component which can be displayed as preview for a certain slide.
	 * The status of the slide is relevant of the given navigationState
	 */
	slidePlayPreviewComponent: (slide: SlideType, navigationState?: string) => ReactElement;
	/**
	 * The component which holds the different settings for a given slide
	 */
	slideEditorSettings?: ReactElement;
	/**
	* The component which is displayed under a slide (in the editor) as additional settings
	*/
	slideEditorAdditionalOptions?: ReactElement;
}

export interface SlideAssets {
	icon: ReactElement;
	slideExample: string;
}

export type StringReturnFunction = (text?: string) => string;

export interface SlideText {
	name: string;
	nameTranslationKey: string;
	detailsTranslationKey: string;
	alias: string;
	details: string;
	titlePlaceholder: string;
	contentPlaceholder?: string;
	getHtmlTitlePlaceholder: StringReturnFunction;
	getHtmlContentPlaceholder?: StringReturnFunction;
}

export interface SlideSettings {
	titleFontSize: number;
	contentFontSize?: number;
	optionFontSize?: number;
}

export interface SlideProperties {
	validation: (slide: SlideType) => SlideErrors;
	emptySlide: (slideIndex: number, id: number, presentationId: number, state?: PresentationState) => SlideType;
	externalUrl?: (url: string) => string;
	baseType: BaseTypes;
	slideComponents: SlideComponents;
	slideSettings: SlideSettings;
	requiredFeature?: string;
	slideAssets: SlideAssets;
	text: SlideText;
	isNew?: boolean;
	isExternal?: boolean;
	navigationStates: Array<NavigationState>;
	testIds: {
		[key: string]: number;
	};
}

export interface BaseSlideProperties {
	text: SlideText;
	baseType: string;
	isExternal?: boolean;
	externalUrl?: (e: any) => void;
}

export interface PresentationValidator {
	validator: (value: any) => boolean,
	error: string,
}

export enum OptionPlaceholder {
	OptionText = 'Enter option {index}',
	IndexText = '{index}',
}

export interface PresentationTemplate {
	id: number;
	icon: string;
	name: string;
	presentationId: number;
	shareKey: string;
	slides: Record<number, SlideType>;
	presentationStyleId: number | null;
	categories: Array<string>;
}

export enum EditorSlideField {
	Title = 'title',
	Content = 'content',
}

export enum TitlePlaceholderText {
	AddQuestion = 'Click to add question...',
	AddQuote = 'Click to add quote...',
	AddTitle = 'Click to add title...',
	GetReadyForQuiz = 'Get ready...',
}

export enum ContentPlaceholderText {
	AddText = 'Click to add text...',
	AddQuoteAuthor = 'Click to add quote author...',
	AddSubTitle = 'Click to add subtitle...',
}

export interface PresentationStyleConsumer {
	presentationStyle: PresentationStyleType;
}

export interface SlideGenerationTemplate {
	type: SlideTypes;
	title: string;
	content: Array<string>;
	slideLayout?: LayoutTypes;
	key?: string;
}

export interface ApiVoteAnswerResult {
	answerCode: string;
	answer: string;
	receivedNrOfVotes: number;
	percentageOfVotes: number;
	correctAnswer: number;
}

export interface ApiVoteResult {
	id: number;
	title: string;
	type: "vote";
	responseCount: number;
	responseTotalWeight: number;
	participants: number;
	responses: Record<string, ApiVoteAnswerResult>;
}

export enum DefaultSlideValues {
	DefaultTimerValue = 5,
}