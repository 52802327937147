import CloseIcon from '@mui/icons-material/Close';
import { Paper, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DiamondImage from 'Atomic/atoms/DiamondImage';
import GiftImage from 'Atomic/atoms/GiftImage';
import UnderlineLink from 'Beta/common/buttons/underlinelink/UnderlineLink';
import UpgradeButton from 'Beta/common/buttons/upgrade/UpgradeButton';
import { FeatureProps, useFeatures } from 'Hooks/useFeatures';
import { useLayout } from 'Hooks/useLayout';
import { useTranslations } from 'Hooks/useTranslations';
import { getFromCookies } from 'Scripts/cookieHelper';
import { testIds } from 'Scripts/cypressTestIds';
import { WEBSITE_PROMO_GET_PARAM_KEY } from 'Scripts/globals';
import { LayoutState } from 'Types/layoutTypes';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';


const MissingFeatureDialog = () => {
    const [page, setPage] = useState('');

    const {
        getFeatureProps
    } = useFeatures();

    const { translatePlaceholder } = useTranslations();

    const shownMissingFeature = useSelector((state: RootStateOrAny) => (state.layoutReducer as LayoutState).shownMissingFeature);

    const {
        setShowMissingFeature
    } = useLayout()

    const close = () => {
        setShowMissingFeature(null);
    }

    const featureProps = getFeatureProps(shownMissingFeature as string) as FeatureProps;

    const christmasDiscountValue = getFromCookies(WEBSITE_PROMO_GET_PARAM_KEY);

    const showUpgradeButton = featureProps.hideUpgradeButton === undefined ? true : !featureProps.hideUpgradeButton;

    useEffect(() => {
		setPage(window.location.pathname);
	}, [window.location.pathname])

    return (
        <Dialog
            classes={{
                root: classes.rootDialog,
            }}
            data-testid={testIds.MISSING_FEATURE_DIALOG}
            open={Boolean(shownMissingFeature)}
            onClose={() => close()}>
            {!isEmpty(featureProps) && <Paper className={classes.dialogContainer}>
                <CloseIcon
                    data-testid={testIds.MISSING_FEATURE_DIALOG_CLOSE}
                    onClick={() => close()}
                    className={classes.closeIcon}
                />
                {Boolean(christmasDiscountValue) && <GiftImage />}
                {!Boolean(christmasDiscountValue) && <DiamondImage />}
                <CardContent className={classes.cardContent} >
                    <Typography variant="h2" fontWeight={600} data-testid={testIds.MISSING_FEATURE_TEXT}>
                       {featureProps.friendlyName}
                       {featureProps.addPremiumSuffix &&
                            <> {translatePlaceholder("IS_PREMIUM_FEATURE")}</>
                        }
                    </Typography>
                    <br/>
                    <Typography variant="h3">
                        {featureProps.explanationText}
                    </Typography>
                    {Boolean(christmasDiscountValue) && <>
                        <Typography pt={3} variant="h3">
                            Receive a 15% Christmas discount during the entire month of December.
                        </Typography>
                    </>}
                </CardContent>
                <div className={classes.cardBottomContent}>
                    {featureProps.continueButtonText &&
                        <div className={classes.continueButton}>
                            <UnderlineLink
                                title={featureProps.continueButtonText}
                                onClick={() => close()}
                                trackAction="continue_working"
                                trackScreen={shownMissingFeature?.toLowerCase() || 'unknown'}
                                trackPosition="feature_popup"
                                testId={testIds.MISSING_FEATURE_EXTRA_BUTTON}
                            />
                        </div>
                    }
                    {showUpgradeButton &&
                        <UpgradeButton
                            buttonText={Boolean(christmasDiscountValue) && "Get me my discount"}
                            trackClass="trackable-upgrade-button-missing-feature"
                            trackScreen={page}
                            trackAction={`upgrade_missing_feature_${shownMissingFeature?.toLowerCase() || 'unknown'}`}
                            trackPosition="popup"
                            feature={shownMissingFeature}
                        />
                    }
                </div>
            </Paper>}
        </Dialog>
    );
};

export default MissingFeatureDialog;