import faviconImage from 'Assets/images/favicon.ico';
import App from 'Components/app/App';
import CustomThemeProvider from 'Components/beta/theme/CustomThemeProvider';
import { usePlayTheme } from 'Hooks/usePlayTheme';
import { passiveSupport } from 'passive-events-support/src/utils';
import React, { useEffect } from 'react';
import GoogleTagManager from './GoogleTagManager';
import SnackbarWrapper from './snackbar/SnackbarWrapper';

/**
 * https://github.com/ignasdamunskis/passive-events-support
 */
passiveSupport({
	events: [
		'touchstart',
		'touchmove',
	]
})

const Wrapper = () => {

	const {
		playTheme
	} = usePlayTheme();

	const setFavicon = () => {
		const faviconLink = document.querySelector("link[rel*='icon']") || document.createElement('link');
		faviconLink.type = 'image/x-icon';
		faviconLink.rel = 'shortcut icon';
		faviconLink.href = faviconImage
		document.getElementsByTagName('head')[0].appendChild(faviconLink);
	}

	useEffect(() => {

		setFavicon()

		window.AppTheme = playTheme;

		return () => {

			window.AppTheme = undefined;
		}

	}, [])

	GoogleTagManager();

	return (
		<CustomThemeProvider>
			<SnackbarWrapper>
				<App />
			</SnackbarWrapper>
		</CustomThemeProvider>
	);
}



export default Wrapper;