import { Box } from '@mui/material';
import AppDynamicTable, { TableCellData } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import WysiwygEditorContainer from 'Atomic/organisms/WysiwygEditor/variants/WysiwygEditorContainer';
import FullHeightContainer from 'Components/common/FullHeightContainer';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import { WysiwygToolBarType } from 'Components/common/util/wysiwygEditor/WysiwygEditor';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import useSlideActions from 'Hooks/useSlideActions';
import { testIds } from 'Scripts/cypressTestIds';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType, EditorSlideField } from 'Types/presentationTypes';
import React from 'react';


const TableSlideEditor = ({
    slide
}: SlideConsumerComponent<ContentSlideType>) => {

    const { setSlideSetting } = useSlideActions();

    const { presentationStyle } = usePresentationStyles();

    const slideProperties = getSlideProperties(slide);

    const handleOnUpdateCell = (cellData: TableCellData, rowIndex: number, columnIndex: number) => {

        const newTableData = [...(slide.table || [])];

        if (!newTableData[rowIndex]) {

            newTableData[rowIndex] = [];
        }

        newTableData[rowIndex][columnIndex] = { ...cellData };

        setSlideSetting('table', newTableData, slide.id);
    }

    /**
     * @param newTableStructure
     */
    const handleOnUpdateTableStructure = (newTableStructure: Array<Array<TableCellData>>) => {

        setSlideSetting('table', newTableStructure, slide.id);
    }

    return (
        <FullHeightContainer
            data-testid={testIds.SLIDE_TYPE_QUOTE}
            style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <LayoutWrapper
                interactive={true}
                layoutType={slide.slideLayout}
                videoURL={slide.videoURL}
                imageURL={slide?.file?.base64 || slide?.filename}
                imageStyle={slide.filenameStyle}
                backgroundOpacity={slide.backgroundOpacity}
                backgroundColor={slide.backgroundColor}
                title={(
                    <FullWidthContainer>
                        <Box p={4}>
                            <WysiwygEditorContainer
                                value={slide.title}
                                getPlaceholderHtml={slideProperties.text.getHtmlTitlePlaceholder}
                                updateValue={newTitle => setSlideSetting(EditorSlideField.Title, newTitle, slide.id)}
                                placeholderText={slideProperties.text.titlePlaceholder}
                                fontSize={slideProperties.slideSettings.titleFontSize}
                                toolbarType={WysiwygToolBarType.Simple}
                            />
                        </Box>
                    </FullWidthContainer>
                )}
                content={(
                    <FullWidthContainer>
                        <Box p={6}>
                            <AppDynamicTable
                                data={slide.table as Array<Array<TableCellData>>}
                                onUpdateCell={handleOnUpdateCell}
                                onUpdateTableStructure={handleOnUpdateTableStructure}
                                cellStyles={{
                                    borderColor: presentationStyle?.textColor,
                                    color: presentationStyle?.textColor,
                                }}
                            />
                        </Box>
                    </FullWidthContainer>
                )}
            />
        </FullHeightContainer>
    )
}

export default TableSlideEditor;