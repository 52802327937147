import { Grid } from '@mui/material';
import PlayQuestionOption from 'Components/play/templates/geometry_template/slides/question_slide/options/play/PlayQuestionOption';
import { getSlidePropertiesByType } from 'Components/types/helpers/slideTypeHelper';
import { useSlideNavigation } from 'Hooks/useSlideNavigation';
import { config } from 'Scripts/config';
import { NavigationState, buildValidOptions, getPlaySlideType } from 'Scripts/slideHelper';
import { LayoutTypes } from 'Types/layoutTypes';
import { PlaySlideType, PlayState } from 'Types/playTypes';
import { SlideType } from 'Types/presentationTypes';
import React, { useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PlayQuestionOptionProps } from '../../play/templates/geometry_template/slides/question_slide/options/play/PlayQuestionOption';


interface SessionVoteOptionsProps extends Partial<PlayQuestionOptionProps> {
    slide: SlideType | PlaySlideType;
}

const SessionVoteOptions = ({
    slide,
    ...props
}: SessionVoteOptionsProps) => {

    const slideType = getPlaySlideType(slide as PlaySlideType) || slide.type;

    const slideProperties = getSlidePropertiesByType(slideType);

    const {
        isActiveState,
    } = useSlideNavigation();

    const voteResults = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).voteResults);

    const voteAnswerCounts = useMemo(() => {

        return voteResults[slide.id] || {};

    }, [
        slide.id,
        voteResults
    ]);

    const totalVoteCount = useMemo(() => {

        let totalCount = 0;

        for (const answerCode in voteAnswerCounts) {

            const voteCount = (voteAnswerCounts[answerCode]?.receivedNrOfVotes || 0);

            totalCount += voteCount;
        }

        return totalCount;

    }, [
        voteAnswerCounts
    ]);

    return (
        <Grid container height="100%">
            {buildValidOptions(slide.options).map((option, index) => {

                const answerCode = config.optionKeys[index];

                return (
                    <PlayQuestionOption
                        layout={slide.slideLayout as LayoutTypes}
                        voteCount={voteAnswerCounts[answerCode]?.receivedNrOfVotes || 0}
                        votePercentage={voteAnswerCounts[answerCode]?.percentageOfVotes || 0}
                        totalVoteCount={totalVoteCount}
                        index={index}
                        option={option}
                        show={!isActiveState(NavigationState.BeforeOpen)}
                        showPercentages={!!slide.showPercentages}
                        showCorrectAnswers={isActiveState(NavigationState.SlideResults)}
                        fontSize={slideProperties.slideSettings?.optionFontSize as number}
                        {...props}
                    />
                );
            })}
        </Grid>
    )
}

export default SessionVoteOptions;