

export const getSessionIdFromToken = (token: string) => {

    try {

        const [ encoding, tokenValues ] = token.split('.');

        const tokenObject = JSON.parse(atob(tokenValues));

        return tokenObject.sid;

    } catch (error) {

        console.warn('Unable to decode token', error)
    }

    return null;
}