import { Box } from '@mui/material';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import Html from 'Components/common/util/html/Html';
import PlaceholderQuestionOption from 'Components/play/templates/geometry_template/slides/question_slide/options/placeholder/PlaceholderQuestionOption';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import React from 'react';
import { SlideConsumerComponent } from 'Types/appTypes';
import { LayoutTypes } from 'Types/layoutTypes';
import { VoteOption, VoteType } from 'Types/presentationTypes';

const PointsSlideEditorPreview = ({
    slide
}: SlideConsumerComponent<VoteType>) => {

    const slideOptions: Array<VoteOption> = ((slide as VoteType)?.options || []);

    const slideProperties = getSlideProperties(slide);

    return (
        <LayoutWrapper
            interactive={false}
            imageStyle={slide.filenameStyle}
            backgroundOpacity={slide.backgroundOpacity}
            backgroundColor={slide.backgroundColor}
            layoutType={slide.slideLayout}
            title={(
                <FullWidthContainer>
                    <Box p={7}>
                        <Html
                            content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                            fontSize={slideProperties.slideSettings.titleFontSize}
                        />
                    </Box>
                </FullWidthContainer>
            )}
            content={slideOptions.map((option, index) => (
                <PlaceholderQuestionOption
                    index={index}
                    fontSize={slideProperties.slideSettings?.optionFontSize as number}
                    layout={slide.slideLayout as LayoutTypes}
                    option={option}
                />
            ))}
            imageURL={slide?.file?.base64 || slide?.filename}
            videoURL={slide?.videoURL}
        />
    );
};

export default PointsSlideEditorPreview;