import moment from 'moment';
import { PlaySlideList } from 'Types/playTypes';
import { SlideList } from 'Types/presentationTypes';
import { config } from "./config";
import { numberWithCommas } from "./stringFormatter";

/* Converts slideIndex into a number as opposed to "1" */
export const numberizeSlideIndices = (slides: SlideList|PlaySlideList) : SlideList|PlaySlideList => {
	Object.keys(slides).forEach(slideKey => {
		slides[slideKey].slideIndex = Number(slides[slideKey].slideIndex);
	})
	return slides;
}

export const calculateQuizDuration = (slides: SlideList) : string => {
	const { msIntervals, maxProgress, loadIncrements } = config.durations
	const extraTimePerSlide = (maxProgress / loadIncrements) * msIntervals * 4;
	const timeList = Object.keys(slides).map(slideKey => slides[slideKey]?.dynamicTimerLimit)
	const totalSeconds = timeList.filter(item => item > 0).reduce((total, amount) => total + amount + (extraTimePerSlide / 1000), 0)
	if (totalSeconds < 60) {
		return "Less than a minute"
	}
	return moment.duration({ "seconds": totalSeconds }).humanize()
}

export const calculateTotalPoints = (slides: SlideList) : string => {
	const pointsList = Object.keys(slides).map(slideKey => slides[slideKey]?.points)
	return numberWithCommas(pointsList.filter(item => item > 0).reduce((total, amount) => total + amount, 0));
}

export const getRandomPercentage = (min: number, max: number) => {

	return Math.min(Math.max(min, (Math.random() * 100)), max);
}