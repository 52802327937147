import { Divider } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import SlideSettingButton from 'Components/beta/common/buttons/settings/slide/SlideSettingButton';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import React, { useEffect, useState } from 'react';
import { classes } from './style.css';

const responseAmountOptions = [1, 2, 3, 4, 5];

const ParticipantResponsesSetting = () => {

	const {
		currentSlide,
		currentSlideKey,
	} = useSlides();

	const {
		setSlideSetting,
	} = useSlideActions();

	const { translatePlaceholder } = useTranslations();

	const [dummyMessagesAllowed, setDummyMessagesAllowed] = useState(0)


	useEffect(() => {

		setDummyMessagesAllowed(currentSlide.nrOfMessagesAllowed)

	}, [])

	const setAmountOfMessagesAllowed = (newValue: number) => {

		setSlideSetting('nrOfMessagesAllowed', newValue, currentSlideKey);
	}

	const setUnlimitedAmountOfMessagesAllowed = () => {

		setDummyMessagesAllowed(0);

		setSlideSetting('nrOfMessagesAllowed', 0, currentSlideKey);
	}

	useEffect(() => {

		setDummyMessagesAllowed(currentSlide.nrOfMessagesAllowed);

	}, [currentSlide.nrOfMessagesAllowed])

	const theme = useTheme();

	return (
		<div>
			<AppText
				as={AppTextVariant.AppTextBaseStyle}
				pt={2}
			>
				{translatePlaceholder("MAXIMUM_RESPONSES_PER_PARTICIPANT")}
			</AppText>
			<div className={classes.buttonBox}>
				{responseAmountOptions.map(responseValue => (
					<Box key={responseValue} className={classes.buttonContainer}
						sx={{
							minWidth: `calc(${100 / responseAmountOptions.length}% - ${theme.spacing(1 / 4)})`,
						}}>
						<SlideSettingButton
							data-testid={`${testIds.MAXIMUM_PARTICIPANT_RESPONSE_BUTTON}-${responseValue}`}
							isActive={dummyMessagesAllowed === responseValue}
							onClick={() => setAmountOfMessagesAllowed(responseValue)}
							value={responseValue}
							{...renderingGAAttr(`${DataElAction.MaxResponsePerParticipant}_${responseValue}`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Button)}
						/>
					</Box>
				))}
			</div>
			<div>
				<SlideSettingButton
					data-testid={`${testIds.MAXIMUM_PARTICIPANT_RESPONSE_BUTTON}-unlimited`}
					isActive={dummyMessagesAllowed === 0}
					onClick={() => setUnlimitedAmountOfMessagesAllowed()}
					value={"Unlimited"}
					{...renderingGAAttr(`${DataElAction.MaxResponsePerParticipant}_unlimited`, DataElPosition.SettingsContainer, DataElScreen.Editor, DataElType.Button)}
				/>
			</div>
			<Divider className={classes.bottomDivider} />
		</div>
	);
};

export default ParticipantResponsesSetting