import { SortObject, TablePaginationData } from "Components/common/table/AppTable";
import { PlanInfo } from "Types/featureTypes";
import { ManageUsersSettingsData } from "Types/teamTypes";

export const setLicenseSortProperty = (property: SortObject) => ({
	type: 'SET_LICENSE_SORT_PROPERTY',
	property,
})

export const setLicenseAutoAcceptDialogOpen = (isOpen: boolean) => ({
	type: 'SET_LICENSE_AUTO_ACCEPT_DIALOG_OPEN',
	isOpen
})

export const setLicenseManageUserSettings = (settings: ManageUsersSettingsData) => ({
	type: 'SET_LICENSE_MANAGE_USERS_SETTINGS',
	settings,
})

export const setUserSubcriptionData = (primaryPlan: PlanInfo, otherPlans: Array<PlanInfo>) => ({
	type: 'SET_USERS_SUBSCRIPTION_DATA',
	primaryPlan,
	otherPlans,
})

export const setLicenseUserTableDataLoading = (loading: boolean) => ({
	type: 'SET_MANAGE_USERS_TABLE_LOADING',
	loading,
})

export const setLicenseUsersPageData = (data: TablePaginationData) => ({
	type: 'SET_LICENSE_USERS_PAGE_DATA',
	data
})

export const setLicenseUsersSearchValue = (value: string) => ({
	type: 'SET_LICENSE_USERS_SEARCH_VALUE',
	value
});

export const setLicenseUsersPageIndex = (index: number) => ({
	type: 'SET_LICENSE_USERS_PAGE_INDEX',
	index
});

export const setLicenseUserIdToEdit = (userId: number | null) => ({
	type: 'SET_LICENSE_USER_ID_TO_EDIT',
	userId,
})

export const setAddLicenseUserDialogOpen = (isOpen: boolean) => ({
	type: 'SET_ADD_LICENSE_USER_DIALOG_OPEN',
	isOpen
});

export const setLicenseUserIdToDowngrade = (userId: number | null) => ({
	type: 'SET_LICENSE_USER_ID_TO_DOWNGRADE',
	userId,
})

export const setLicenseUserIdToDelete = (userId: number | null) => ({
	type: 'SET_LICENSE_USER_ID_TO_DELETE',
	userId,
})

export const setJoinRequestsSortProperty = (property: SortObject) => ({
	type: 'SET_JOIN_REQUESTS_SORT_PROPERTY',
	property,
})

export const setJoinRequestsPageIndex = (index: number) => ({
	type: 'SET_JOIN_REQUESTS_PAGE_INDEX',
	index
});

export const setJoinRequestsTableDataLoading = (loading: boolean) => ({
	type: 'SET_JOIN_REQUEST_TABLE_LOADING',
	loading,
})

export const setJoinRequestsPageData = (data: TablePaginationData) => ({
	type: 'SET_JOIN_REQUESTS_PAGE_DATA',
	data
})

export const setJoinRequestsSearchValue = (value: string) => ({
	type: 'SET_JOIN_REQUEST_SEARCH_VALUE',
	value
});

export const setLicensePageUserChangeLoading = (loading: boolean) => ({
	type: 'SET_LICENSE_PAGE_NAME_CHANGE_LOADING',
	loading,
});

export const setLicenseNameUpdateLoading = (loading: boolean) => ({
	type: 'SET_LICENSE_PAGE_USER_CHANGE_LOADING',
	loading,
});
