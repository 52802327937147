import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { RootStateOrAny, useSelector } from 'react-redux';
import { config } from 'Scripts/config';
import { AppState } from 'Types/appTypes';

const GoogleTagManager = () => {

	const currentUser = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentUser)

	const initializeGoogleTagmanager = () => {

		const tagManagerArgs = {
			gtmId: config.googleTagManagerId,
		}

		TagManager.initialize(tagManagerArgs);

		// MarketingId could be empty on user request, so only set userId when we
		// have recieved a marketingId
		if (!isEmpty(currentUser) && !isEmpty(currentUser.marketingId))
		{
			TagManager.dataLayer({
				dataLayer: { userId: currentUser.marketingId }
			});
		}
	}

	useEffect(() => {

		initializeGoogleTagmanager();

	}, [currentUser]);
}

export default GoogleTagManager;
