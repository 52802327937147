import { Modal } from '@mui/material';
import festiveBackground from 'Assets/images/festive_background.webp';
import { AppTranslationState } from 'Types/appTranslationTypes';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import SignupText from '../../login/signup_text/SignupText';
import { classes } from './style.css';

interface SignupBackDropProps {
    open: boolean;
    onClose?: () => void;
    children: ReactNode;
}

const isFourOFourRoute = ()=> {
    const path = window.location.pathname;
    return path.includes("404");
}

const SignupBackdrop = ({ open, children, onClose } : SignupBackDropProps) => {

    const updateLanguageLoading = useSelector((state: RootStateOrAny) => (state.appTranslationReducer as AppTranslationState).updateLanguageLoading);

    return (
        <>
            <Modal
                onClose={onClose}
                open={open}
                fullScreen
                BackdropProps={{
                    classes: {
                        root: classes.backdrop,
                    },
                    sx: {
                        background: `url(${festiveBackground})`,
                    }
                }}>
                <div className={classes.dialog}>
                    {!updateLanguageLoading &&
                        <>
                            <div className={classes.filler} />
                            {!isFourOFourRoute() && <SignupText />}
                            {children}
                            <div className={classes.filler} />
                        </>
                    }
                </div>
            </Modal>
        </>
    );
};

export default SignupBackdrop;