import { PlaySlideList, PlaySlideType } from "Types/playTypes";
import { SlideList, VoteOption } from "Types/presentationTypes";
import { SlideTypes } from 'Types/slideTypes';
import { config } from "./config";

export enum NavigationState {
    /* When showing the question, but slide isn't 'opened' yet */
    BeforeOpen = 'BeforeOpen',
    /* When the slide has been opened */
    SlideOpen = 'SlideOpen',
    /* When the slide has been closed (due timer running out, or manual close) */
    SlideClosed = 'SlideClosed',
    /* When showing the slide results, this is the last state for most slide types*/
    SlideResults = 'SlideResults',
}

export const containsQuizSlides = (slides: (SlideList|PlaySlideList)) : boolean  => {

    let hasQuizSlides = false;

    Object.keys(slides).forEach(slideId => {

        if ((slides[slideId]?.points > 0 && Boolean(slides[slideId]?.isQuizVote)) || slides[slideId].type === SlideTypes.Quiz) {

            hasQuizSlides = true;
        }
    })

    return hasQuizSlides;
}

export const optionKeysToRender = (options = {}) => {

    return config.optionKeys.slice(0, Math.max(2, Object.keys(options).length + 1))
}

export const buildValidOptions = (options = {}) => {

    const filteredIndexes = Object.keys(options).filter(optionIndex => {

        return !!options?.[optionIndex]?.answer
    })

    return filteredIndexes.map(index => ({
        ...options[index]
    }))
}

export const getSortedSlideKeysBySlideIndex = (slides: (SlideList|PlaySlideList) = {}) : number[] => {

    return Object.keys(slides).sort((firstSlideKey, secondSlideKey) => {

        return slides[Number(firstSlideKey)].slideIndex - slides[Number(secondSlideKey)].slideIndex;

    }).map(Number);
}

export const getDefaultSlideOptions = () : VoteOption[] => {

    return [
        {
            correctAnswer: false,
            answer: '',
            answerCode: 'A',
        },
    ];
}

export const navigationStatesPerSlideType = {
    [SlideTypes.MultipleChoice]: [
        NavigationState.BeforeOpen,
        NavigationState.SlideOpen,
        NavigationState.SlideClosed,
        NavigationState.SlideResults,
    ],
    [SlideTypes.Points]: [
        NavigationState.BeforeOpen,
        NavigationState.SlideOpen,
        NavigationState.SlideClosed,
        NavigationState.SlideResults,
    ],
    [SlideTypes.Quiz]: [
        NavigationState.BeforeOpen,
        NavigationState.SlideOpen,
        NavigationState.SlideClosed,
        NavigationState.SlideResults,
    ],
    [SlideTypes.Wordcloud]: [
        NavigationState.BeforeOpen,
        NavigationState.SlideOpen,
    ],
    [SlideTypes.OpenEnded]: [
        NavigationState.BeforeOpen,
        NavigationState.SlideOpen,
    ],
    [SlideTypes.QA]: [
        NavigationState.BeforeOpen,
        NavigationState.SlideOpen,
    ],

    /**
     * Content slides share the same navigation states.
     *
     * If we want to add specific navigation states for a certain slide,
     * we can add it here and reference the exact SlideTypes.
     *
     * It also needs to be adjusted in Slides.tsx
     */
    [SlideTypes.Content]: [
        NavigationState.BeforeOpen,
    ],
}

const uniqueSlidePropertiesByPlaySlideType: Partial<Record<SlideTypes, Partial<PlaySlideType>>> = {
    [SlideTypes.MultipleChoice]: {
        isQuizVote: 0,
        points: 0,
    },
    [SlideTypes.Quiz]: {
        isQuizVote: 1,
    },
    [SlideTypes.Wordcloud]: {
        isWordcloudQuestion: 1,
    },
    [SlideTypes.OpenEnded]: {
        isWordcloudQuestion: 0,
    },
    [SlideTypes.QA]: {
        hasUpvoting: 1,
        isWordcloudQuestion: 0,
    }
}

export const getPlaySlideType = (slide: PlaySlideType): SlideTypes => {

    let slideType = '';

    // if(slide.title.includes('invader')) {

    //     return slideTypes.SPACE_INVADERS;
    // }

    /* Iterate over the types of slides */

    Object.keys(uniqueSlidePropertiesByPlaySlideType).forEach((slideTypeKey: SlideTypes) => {
        /*
            Iterate over the unique properties of these slidetypes of slides
            and match those same properties with passed slide
        */
        let comparisonData: Partial<Record<keyof PlaySlideType, any>> = {};

        Object.keys((uniqueSlidePropertiesByPlaySlideType[slideTypeKey] || {})).forEach((propertyToCheck: keyof PlaySlideType) => {

            comparisonData[propertyToCheck] = slide?.[propertyToCheck];

        })
        /* If the strings generated by JSON.stringify is identical this means we have a match */
        if (JSON.stringify(comparisonData) === JSON.stringify(uniqueSlidePropertiesByPlaySlideType[slideTypeKey])) {

            slideType = slideTypeKey
        }
    })

    return ((slideType || slide?.variant) as SlideTypes)
}