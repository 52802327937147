import { Divider, Typography } from '@mui/material';
import RandomWidthSkeleton from 'Atomic/atoms/RandomWidthSkeleton';
import React, { ReactElement } from 'react';
import { classes } from './style.css';

export interface TrackingAttrTypes {
	"data-elaction": string;
	"data-elposition"?: any;
	"data-elscreen"?: string;
	"data-eltype"?: string;
}
interface SummaryOptionProps {
	leftItem?: ReactElement;
	rightItem?: ReactElement;
	loading?: boolean;
	trackingAttr?: TrackingAttrTypes
	divider?: boolean;
}

const SummaryOption = ({ leftItem, rightItem, loading, trackingAttr, divider }: SummaryOptionProps) => {


	return (
		<>
			<div className={classes.summaryContainer} >
				<div className={classes.containerLeftItem} >
					{loading && <Typography>
						<RandomWidthSkeleton />
					</Typography>}
					{!loading && leftItem}
				</div>
				<div
					className={classes.containerRightItem}
					{...trackingAttr}
				>
					{loading && <Typography>
						<RandomWidthSkeleton />
					</Typography>}
					{!loading && rightItem}
				</div>
			</div>
			{divider && <Divider />}
		</>
	)
}

SummaryOption.defaultProps = {
	divider: true,
};

export default SummaryOption;