import { Grid } from "@mui/material";
import React from "react";
import { classes } from './style.css';

interface IHeaderContentLayout {
    header: React.ReactElement,
    content: React.ReactElement,
}

const HeaderContentLayout = ({header, content} : IHeaderContentLayout)=>{
    return(
        <Grid container className={classes.container}>
            <Grid item>{header}</Grid>
            <Grid item className={classes.content}>{content}</Grid>
        </Grid>
    );
}
export default HeaderContentLayout;