import { green, red } from '@mui/material/colors';

/*
    This file defines some variables which are used app-wide.
*/

const colorThemes = {
    DARK: {
        lightest: '#1f16de',
        light: '#1710be',
        normal: '#140dad',
        dark: '#110a9d',
        darker: '#0d088d',
    },
    DEFAULT: {
        lightest: '#99c8e8',
        light: '#4aaae6',
        normal: '#3b99de',
        dark: '#1984cb',
        darker: '#0077c5',
    },
    ALT: {
        lightest: '#4aaae6',
        light: '#3b99de',
        normal: '#1984cb',
        dark: '#0077c5',
        darker: '#08598c',
    }
}

export const config = {
    googleTagManagerId: process.env.GTM_ID,
    blueColors: {
        ...colorThemes.ALT
    },
    baseQuizTitle: "New presentation",
    optionKeys: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
    minRequiredOptions: 2,
    maxAllowedOptions: 6,
    maxQuestionPoints: 1000,
    minQuestionPoints: 500,

    minQuestionTime: 10,
    defaultQuestionTime: 20,
    maxQuestionTime: 60,

    maxStringLength: 255,
    maxContentLength: 65536,

    minTitleLength: 1,

    minOptionLength: 1,

    errorColor: red[300],
    successColor: green[400],

    durations: {
        debounceDelay: 1000,
        /*
            duration = (maxProgress / loadIncrements) * msIntervals
            (100 / 4) * 250 = 6250ms (6.25 seconds)
        */

        msIntervals: 250,
        maxProgress: 100,
        loadIncrements: 4,
    },
    defaults: {
        defaultPointsAwarded: 500,
        defaultAnswerTime: 20,
        defaultShowPercentages: 1,
    },
    fakeData: [
        {
            name: "N/A",
            points: "∞",
            streak: 0,
        },
        {
            name: "N/A",
            points: "∞",
            streak: 0,
        },
        {
            name: "N/A",
            points: "∞",
            streak: 0,
        }
    ]
}