import React from 'react';

/**
 * Adds/removes items from a list using a setState handler based on a given attribute
 *
 * This assumes the list contains objects which all contain the attributeToCheck as a key
 *
 * e.g.: addOrRemoveFromList(newItem, 'itemId', setItems);
 *
 * @param item Some item to add/remove from the list
 * @param attributeToCheck The attribute from that list to check
 * @param setStateHandler The setState callback
 */
export const addOrRemoveFromListState = (item: any, attributeToCheck: string, setStateHandler: React.Dispatch<React.SetStateAction<any[]>>, insertAtIndex?: number = -1) => {

    setStateHandler((currentList: Array<any>) => {

        const itemIndex = currentList.findIndex(currentItem => (currentItem[attributeToCheck] === item[attributeToCheck]));

        if (itemIndex === -1) {

            const newList = [...currentList];

            if (insertAtIndex === -1 || insertAtIndex > currentList.length) {

                newList.push(item);

            } else {

                newList.splice(insertAtIndex, 0, item);
            }

            return newList;
        }

        const newList = [...currentList];

        newList.splice(itemIndex, 1);

        return newList;
    });
}