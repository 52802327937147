import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import AppLottiePlayer from 'Atomic/atoms/Lottie/AppLottiePlayer';
import addingInteractivityAnimation from 'Atomic/atoms/Lottie/assets/ai_adding_interactivity_animation.json';
import creatingSlidesAnimation from 'Atomic/atoms/Lottie/assets/ai_creating_slides_animation.json';
import finishingUpAnimation from 'Atomic/atoms/Lottie/assets/ai_finishing_up_animation.json';
import generatingContentAnimation from 'Atomic/atoms/Lottie/assets/ai_generating_content_animation.json';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import { useTranslations } from 'Hooks/useTranslations';
import { AIState } from 'Types/aiTypes';
import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';


interface GenerateAiPresentationWaitDialogProps {
	open: boolean;
}

enum GeneratePresentationStages {
	GeneratingContent,
	CreatingSlides,
	AddingInteractivity,
	FinishingUp,
}

const GenerateAiPresentationWaitDialog = ({
	open
}: GenerateAiPresentationWaitDialogProps) => {

	const { translatePlaceholder } = useTranslations();

	const animationStageText : Record<string, string> =  {
		[GeneratePresentationStages.GeneratingContent]: translatePlaceholder("GENERATING_CONTENT_PROGRESS"),
		[GeneratePresentationStages.CreatingSlides]: translatePlaceholder("CREATING_SLIDES_PROGRESS"),
		[GeneratePresentationStages.AddingInteractivity]: translatePlaceholder("ADDING_QUESTIONS_PROGRESS"),
		[GeneratePresentationStages.FinishingUp]: translatePlaceholder("FINISHING_UP_PROGRESS")
	}


    const type = useSelector((state: RootStateOrAny) => (state.aiReducer as AIState).aiStepper.type);

	const [currentStage, setCurrentStage] = useState<number>(GeneratePresentationStages.GeneratingContent);

	const incrementStage = () => {

		setCurrentStage((stage: number) => (
			(stage + 1) % Object.keys(animationStageText).length
		))
	}

	return (
		<Dialog
			open={open}
			maxWidth="sm"
			onClose={() => setCurrentStage(1)}
			PaperProps={{ className: classes.dialog }}
		>
			<DialogContent classes={{
				root: classes.dialogContentRoot
			}}>
				<Grid container>
					<Grid item xs={12} >
						<AppText
							as={AppTextVariant.H5Regular}
							noWrap={false}
							py={2}>
							{translatePlaceholder("WE_ARE_GENERATING_YOUR_TYPE", {
								type: `${(type as string).toLowerCase()}`
							})}
						</AppText>
					</Grid>
					<Grid container alignItems="center" justifyContent="center" >
						<Grid item pb={1}>
							{currentStage === GeneratePresentationStages.GeneratingContent && (
								<AppLottiePlayer
									src={generatingContentAnimation}
									autoplay
									loop={3}
									elementProps={{
										style: {
											height: 256,
											width: 256,
											margin: '0 auto',
										}
									}}
									onAnimationComplete={() => incrementStage()}
								/>
							)}
							{currentStage === GeneratePresentationStages.CreatingSlides && (
								<AppLottiePlayer
									src={creatingSlidesAnimation}
									autoplay
									loop={3}
									elementProps={{
										style: {
											height: 256,
											width: 256,
											margin: '0 auto',
										}
									}}
									onAnimationComplete={() => incrementStage()}
								/>
							)}
							{currentStage === GeneratePresentationStages.AddingInteractivity && (
								<AppLottiePlayer
									src={addingInteractivityAnimation}
									autoplay
									loop={3}
									elementProps={{
										style: {
											height: 256,
											width: 256,
											margin: '0 auto',
										}
									}}
									onAnimationComplete={() => incrementStage()}
								/>
							)}
							{currentStage === GeneratePresentationStages.FinishingUp && (
								<AppLottiePlayer
									src={finishingUpAnimation}
									autoplay
									loop
									elementProps={{
										style: {
											height: 256,
											width: 256,
											margin: '0 auto',
										}
									}}
								/>
							)}
							<Box mt={1} />
							<AppText as={AppTextVariant.BodyRegular}>
								{animationStageText[currentStage] as string}
							</AppText>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog >
	);
};

export default GenerateAiPresentationWaitDialog;