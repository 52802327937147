
export const setDeleteAccountDialogOpen = (isOpen: boolean) => ({
    type: 'SET_DELETE_ACCOUNT_DIALOG_OPEN',
    isOpen
})

export const setDeleteAccountLoading = (isLoading: boolean) => ({
    type: 'SET_DELETE_ACCOUNT_LOADING',
    isLoading,
})

export const setExternalAuthenticationKey = (key: string|null) => ({
    type: 'SET_EXTERNAL_AUTHENTICATION_KEY',
    key,
})

export const setExternalAuthenticationConfirmed = (isConfirmed: boolean) => ({
    type: 'SET_EXTERNAL_AUTHENTICATION_CONFIRMED',
    isConfirmed,
})