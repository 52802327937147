import { Box } from '@mui/material';
import FullSizeContainer from 'Atomic/atoms/FullSizeContainer';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import Html from 'Components/common/util/html/Html';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';


const TitleSlideEditorPreview = ({ slide }: SlideConsumerComponent<ContentSlideType>) => {


    const slideProperties = getSlideProperties(slide);

    return (
        <LayoutWrapper
            layoutType={slide.slideLayout}
            videoURL={slide.videoURL}
            imageURL={slide?.file?.base64 || slide?.filename}
            imageStyle={slide.filenameStyle}
            backgroundOpacity={slide.backgroundOpacity}
            backgroundColor={slide.backgroundColor}
            content={
                <FullSizeContainer>
                    <Box className={classes.inputContainer} px={10}>
                        <FullWidthContainer>
                            <Html
                                content={(slide.title || slideProperties.text?.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)) as string}
                                fontSize={slideProperties.slideSettings?.titleFontSize as number}
                            />
                        </FullWidthContainer>
                    </Box>
                </FullSizeContainer>
            }
        />
    );
};

export default TitleSlideEditorPreview;