import { Box } from '@mui/system';
import { Editor } from '@tinymce/tinymce-react';
import usePresentationStyles from "Hooks/usePresentationStyles";
import { getMUISafeAlphaColor } from 'Scripts/colorHelper';
import React, { forwardRef } from 'react';
import { WysiwygFieldProps } from "../AppWysiwygField";
import WysiwygContainer from "../container/WysiwygContainer";


/**
 * Wysiwyg component for the Slide editor fields
 */
const WysiwygEditorContainer = forwardRef(({
    ...props
}: WysiwygFieldProps, ref: React.ForwardedRef<Editor>) => {

    const { presentationStyle } = usePresentationStyles();

    return (
        <Box
            sx={{
                borderRadius: 1,
                transition: '250ms all',
                '&:hover': {
                    backgroundColor: getMUISafeAlphaColor(presentationStyle?.textColor || '#000', .05),
                },
                '&:focus-within': {
                    backgroundColor: getMUISafeAlphaColor(presentationStyle?.textColor || '#000', .05),
                },
            }}>
            <WysiwygContainer
                {...props}
                ref={ref}
            />
        </Box>
    );
});

export default WysiwygEditorContainer;