import FullSizeContainer from 'Atomic/atoms/FullSizeContainer';
import { PlaySlideType } from 'Types/playTypes';
import { SlideType } from 'Types/presentationTypes';
import { SlideShape } from 'Types/slideTypes';
import React, { Fragment } from 'react';
import PreviewSlideShape from './PreviewSlideShape';


interface Props {
    slide: SlideType | PlaySlideType;
    shapes: Array<SlideShape>;
}

const PreviewSlideShapeList = ({
    slide,
    shapes,
}: Props) => {

    return (
        <FullSizeContainer>
            {(shapes || []).map((shape, index) => (
                <Fragment key={shape.id || shape.type || index}>
                    <PreviewSlideShape
                        slide={slide}
                        shape={shape}
                    />
                </Fragment>
            ))}
        </FullSizeContainer>
    )
}

export default PreviewSlideShapeList;