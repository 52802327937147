import Grid from "@mui/material/Grid";
import { themeVars } from "Components/theme/vanillaTheme.css";
import React, { ReactElement } from "react";
import { classes } from "./style.css";

interface IThreeColumnsLayout {
    bgColor?: string;
    leftContent: ReactElement;
    rightContent: ReactElement;
    children?: ReactElement;
}

const ThreeColumnsLayout =({
    bgColor = themeVars.color.primaryBg,
    leftContent,
    rightContent,
    children,
}: IThreeColumnsLayout)=> {
    return(
        <Grid container style={{backgroundColor: bgColor}} className={classes.container}>
            <Grid xs={12} sm={3.5} lg={3} xl={3} item>{leftContent}</Grid>
            <Grid xs={12} sm={5} lg={6} xl={6} item>{children}</Grid>
            <Grid xs={12} sm={3.5} lg={3} xl={3} item>{rightContent}</Grid>
        </Grid>
    );
}
export default ThreeColumnsLayout;