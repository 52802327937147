import * as aiActions from "Actions/aiActions";
import TagManager from 'react-gtm-module';
import { useDispatch } from "react-redux";
import { AiContentCreatorStep, AISupportedLength, AISupportedToneOfVoice, AISupportedTypes, AISupportedUsage } from "Types/aiTypes";

export const useAI = () => {

    const dispatch = useDispatch();

    const setInitialPrompt = (initialPrompt: string) => {

        TagManager.dataLayer({
            dataLayer: { stepper_prompt: initialPrompt }
        });

        dispatch(aiActions.setInitialPrompt(initialPrompt));
    }

    const setChosenLanguage = (language: string | null) => {

        TagManager.dataLayer({
            dataLayer: { stepper_selected_language: language }
        });

        dispatch(aiActions.setChosenLanguage(language));
    }

    const setChosenToneOfVoice = (toneOfVoice: AISupportedToneOfVoice | null) => {

        TagManager.dataLayer({
            dataLayer: { stepper_selected_tone_of_voice: toneOfVoice }
        });

        dispatch(aiActions.setChosenToneOfVoice(toneOfVoice));
    }

    const setChosenLength = (length: AISupportedLength | null) => {

        TagManager.dataLayer({
            dataLayer: { stepper_selected_length: length }
        });

        dispatch(aiActions.setChosenLength(length));
    }

    const setChosenType = (type: AISupportedTypes | null) => {

        TagManager.dataLayer({
            dataLayer: { stepper_selected_type: type }
        });

        dispatch(aiActions.setChosenType(type));
    }

    const setChosenUsage = (usage: AISupportedUsage | null) => {

        dispatch(aiActions.setChosenUsage(usage));
    }

    const setChosenTitle = (title: string | null) => {

        TagManager.dataLayer({
            dataLayer: { stepper_selected_title: title }
        });

        dispatch(aiActions.setChosenTitle(title));
    }

    const setTitleSuggestions = (titles: Array<string>) => {

        dispatch(aiActions.setTitleSuggestions(titles));
    }

    const addTitleSuggestions = (titles: Array<string>) => {

        dispatch(aiActions.addTitleSuggestions(titles));
    }

    const setUseOwnTitle = (useOwnTitle: boolean) => {

        dispatch(aiActions.setUseOwnTitle(useOwnTitle));
    }

    const setAIGuidedTourRunning = (running: boolean) => {

        dispatch(aiActions.setAIGuidedTourRunning(running));
    }
    const setAIGuidedTourStep = (step: number) => {

        dispatch(aiActions.setAIGuidedTourStep(step));
    }

    const setAiContentCreatorKeywords = (keywords: string) => {

        dispatch(aiActions.setAiContentCreatorKeywords(keywords));
    }
    const setAiContentCreatorStep = (step: AiContentCreatorStep) => {

        dispatch(aiActions.setAiContentCreatorStep(step));
    }

    const setEnableInteractiveQuestions = (enable: boolean) => {

        dispatch(aiActions.setEnableInteractiveQuestions(enable));
    }

    return {
        setInitialPrompt,
        setChosenLanguage,
        setChosenUsage,
        setChosenToneOfVoice,
        setChosenLength,
        setChosenType,
        setChosenTitle,
        setTitleSuggestions,
        addTitleSuggestions,
        setUseOwnTitle,
        setAIGuidedTourRunning,
        setAIGuidedTourStep,
        setAiContentCreatorKeywords,
        setAiContentCreatorStep,
        setEnableInteractiveQuestions,
    }
}