import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import usePlaySettings from 'Hooks/usePlaySettings';
import { useTranslations } from 'Hooks/useTranslations';
import React from 'react';
import { classes } from './style.css';

const CountryDropdown = () => {

	const {
		fetchCountriesLoading,
		updateCountry,
		countries,
		selectedCountry,
	} = usePlaySettings();

	const { translatePlaceholder } = useTranslations();

	const handleChangeCountry = (e: React.MouseEvent<HTMLOptionElement>) => {

		const newIsoCode = e.target.value;

		updateCountry(newIsoCode);
	}



	return (
		<Select
			className={classes.countrySelect}
			classes={{
				root: classes.countryContainer,
			}}
			MenuProps={{
				classes: {
					paper: classes.countryList
				}
			}}
			disabled={fetchCountriesLoading}
			value={selectedCountry}
			disableUnderline
			placeholder={translatePlaceholder("SELECT_COUNTRY")}
			onChange={e => handleChangeCountry(e)} >
			{Object.keys(countries).map(isoCode => {
				return (
					<MenuItem
						key={isoCode}
						className={classes.country}
						value={isoCode}>
						{countries[isoCode]}
					</MenuItem>
				)
			})}
		</Select>

	);
};

export default CountryDropdown;