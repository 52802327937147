import { AnyAction } from "redux";

export const initialState = {
    outline: [{}],
    isLoading: false,
}

export enum OutlineActionTypes {
    SetOutline = 'SET_OUTLINE',
    SetIsLoading = 'SET_IS_LOADING',
}

export const outlineReducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        case OutlineActionTypes.SetOutline: {
            return {
                ...state,
                outline: payload
            }
        }
        case OutlineActionTypes.SetIsLoading: {
            return {
                ...state,
                isLoading: payload
            }
        }
        default:
            return state
    }
}
