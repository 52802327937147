import { AnyAction } from 'redux';
import { AuthState } from 'Types/authTypes';

export const initialAuthState: AuthState = {
    deleteAccountDialogOpen: false,
    deleteAccountLoading: false,
    externalAuthenticationKey: null,
    externalAuthenticationConfirmed: false,
}

export const authReducer = (state : AuthState = initialAuthState, action: AnyAction) => {
    switch (action.type) {
        case 'SET_EXTERNAL_AUTHENTICATION_KEY': {
            return {
                ...state,
                externalAuthenticationKey: action.key,
            }
        }
        case 'SET_EXTERNAL_AUTHENTICATION_CONFIRMED': {
            return {
                ...state,
                externalAuthenticationConfirmed: action.isConfirmed
            }
        }
        case 'SET_DELETE_ACCOUNT_DIALOG_OPEN': {
            return {
                ...state,
                deleteAccountDialogOpen: state.deleteAccountLoading ? state.deleteAccountDialogOpen : action.isOpen,
            }
        }
        case 'SET_DELETE_ACCOUNT_LOADING': {
            return {
                ...state,
                deleteAccountLoading: action.isLoading,
            }
        }
        default:
            return state
    }
}
