import Header from "Components/header/Header";
import HeaderContentLayout from "Components/layouts/headerContentLayout/HeaderContentLayout";
import ThreeColumnsLayout from "Components/layouts/threeColumnsLayout/ThreeColumnsLayout";
import Overview from "Components/outline/Overview/Overview";
import Summary from "Components/outline/Summary/Summary";
import React from "react";
import OutlineLogicContainer from "../../../../outline/OutlineLogicContainer/OutlineLogicContainer";
import { classes } from "./style.css";

const OutlinePage = ()=> {
    return (
            <HeaderContentLayout 
                header={<Header/>} 
                content={
                    <ThreeColumnsLayout 
                        leftContent={<Overview/>}
                        rightContent={<Summary/>}
                    >
                        <div className={classes.outlinePageContent}>
                            <OutlineLogicContainer/>
                        </div>
                    </ThreeColumnsLayout>
            }/>
        )
}
export default OutlinePage;