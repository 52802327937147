import * as appActions from 'Actions/appActions';
import { toggleSelectSlideOpened } from "Actions/appActions";
import * as layoutActions from 'Actions/layoutActions';
import { toggleEditPresentation, togglePlay } from "Actions/playActions";
import { SendstepsIntegrations } from "Types/appTypes";
import { MobileEditorTabs, SelectSlideTab, SlideOptionTabs, SlideTypeTabs } from "Types/layoutTypes";
import { SlideTypes } from 'Types/slideTypes';
import { useCallback } from 'react';
import { useDispatch } from "react-redux";
import { DerivedFeatures, Features } from "./useFeatures";

export const useLayout = () => {

	const dispatch = useDispatch();

	const setFeedbackDialogOpen = useCallback((open: boolean) => {
		dispatch(appActions.setFeedbackDialogOpen(open));
	}, []);

	const closeLeftDrawer = useCallback(() => {
		dispatch(layoutActions.toggleLeftDrawer(false));
	}, []);

	const toggleLeftDrawer = useCallback((open: boolean) => {
		dispatch(layoutActions.toggleLeftDrawer(open));
	}, []);

	const openLeftDrawer = useCallback(() => {
		dispatch(layoutActions.toggleLeftDrawer(true));
	}, []);

	const closeRightDrawer = useCallback(() => {
		dispatch(layoutActions.toggleRightDrawer(false));
	}, []);

	const openRightDrawer = useCallback(() => {
		dispatch(layoutActions.toggleRightDrawer(true));
	}, []);

	const openSelectSlide = useCallback(() => {
		dispatch(toggleSelectSlideOpened(true));
	}, []);

	const closeSelectSlide = useCallback(() => {
		dispatch(toggleSelectSlideOpened(false));
	}, []);

	const setSlideToRemove = useCallback((slideKey: number | null) => {
		dispatch(layoutActions.setSlideIdToRemove(slideKey));
	}, []);

	const togglePlayDialog = useCallback(() => {
		dispatch(togglePlay())
	}, []);

	const toggleAdvancedOptions = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.toggleAdvancedPresentationOptions(isOpen))
	}, []);

	const toggleAppSurveyPopup = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.toggleAppSurveyModal(isOpen));
	}, []);

	const toggleShowEditPresentation = useCallback((isOpen: boolean) => {
		dispatch(toggleEditPresentation(isOpen));
	}, []);

	const setShowMissingFeature = useCallback((featureName: Features | DerivedFeatures | null) => {
		dispatch(layoutActions.showMissingFeature(featureName))
	}, []);

	const toggleShowCreateFirstPresentation = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setShowWelcomeNewUser(isOpen));
	}, []);

	const toggleGuidedTour = useCallback((isRunning: boolean) => {
		dispatch(layoutActions.setGuidedTourRunning(isRunning));
	}, []);

	const setGuidedTourStep = useCallback((step: number) => {
		dispatch(layoutActions.setGuidedTourStep(step));
	}, []);

	const toggleLicenseFailed = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setLicenseRequestFailedOverlayOpen(isOpen))
	}, []);

	const toggleLicenseEligible = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setLicenseEligibleOverlayOpen(isOpen))
	}, []);

	const toggleMultipleLicenseEligible = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setMultiLicenseEligibleOverlayOpen(isOpen))
	}, []);

	const toggleWelcome = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setWelcomeOverlayOpen(isOpen))
	}, []);

	const toggleSupport = useCallback((show: boolean) => {
		dispatch(layoutActions.toggleSupportPanel(show));
	}, []);

	const togglePaymentSuccessDialogOpen = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.togglePaymentSuccessDialogOpen(isOpen));
	}, []);

	const togglePaymentPendingDialogOpen = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.togglePaymentPendingDialogOpen(isOpen));
	}, []);

	const togglePaymentFailedDialogOpen = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.togglePaymentFailedDialogOpen(isOpen));
	}, []);

	const toggleNewsFeedOpen = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.toggleNewsFeedOpen(isOpen));
	}, []);

	const setSelectedMobileEditorTab = useCallback((tab: MobileEditorTabs) => {
		dispatch(layoutActions.setSelectedMobileEditorTab(tab));
	}, []);

	const setShowCantDownloadAddinFromMobileDevice = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setShowCantDownloadAddinFromMobileDevice(isOpen));
	}, []);

	const setShowDeletePresentationWithoutSlides = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setShowDeletePresentationWithoutSlides(isOpen));
	}, []);

	const setSelectedSettingsIntegration = useCallback((integration: SendstepsIntegrations) => {
		dispatch(layoutActions.setSelectedSettingsIntegration(integration));
	}, []);

	const storeHashFragment = useCallback((hash: string) => {
		dispatch(appActions.storeHashFragment(hash));
	}, []);

	const setShowCantEditInspirationTemplateDialog = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setShowCantEditInspirationTemplateDialog(isOpen));
	}, []);

	const setShowMissingPresentationFeatures = useCallback((missingFeatures: Array<string> | null) => {
		dispatch(layoutActions.setShowMissingPresentationFeatures(missingFeatures));
	}, []);

	const setSelectedAddSlideDialogTab = useCallback((tab: SelectSlideTab) => {
		dispatch(layoutActions.setSelectedAddSlideDialogTab(tab));
	}, []);

	const setExampleSlideLayoutShown = useCallback((type: SlideTypes | null) => {
		dispatch(layoutActions.setExampleSlideLayoutShown(type));
	}, []);

	const setSlideOptionTab = useCallback((tab: SlideOptionTabs) => {
		dispatch(layoutActions.setSlideOptionTab(tab));
	}, []);

	const setSlideTypeTab = useCallback((tab: SlideTypeTabs) => {
		dispatch(layoutActions.setSlideTypeTab(tab));
	}, []);

	const setShowImageUploadDialog = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setShowImageUploadDialog(isOpen));
	}, []);

	const setShowAiPresentationReadyDialog = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setShowAiPresentationReadyDialog(isOpen));
	}, []);

	const setShowUploadDocumentDialog = useCallback((isOpen: boolean) => {
		dispatch(layoutActions.setShowUploadDocumentDialog(isOpen));
	}, []);

	const setIsRewriting = useCallback((isRewriting: boolean) => {
		dispatch(layoutActions.setIsRewriting(isRewriting));
	}, []);

	const setShowSlideLogoUploadDialog = useCallback((show: boolean) => {
		dispatch(layoutActions.setShowSlideLogoUploadDialog(show));
	}, []);

	const toggleSpeakerNotes = useCallback((show: boolean) => {
		dispatch(layoutActions.toggleSpeakerNotes(show));
	}, []);

	return {
		closeLeftDrawer,
		openLeftDrawer,
		toggleLeftDrawer,
		closeRightDrawer,
		openRightDrawer,
		openSelectSlide,
		closeSelectSlide,
		setSlideToRemove,
		togglePlayDialog,
		toggleAdvancedOptions,
		toggleAppSurveyPopup,
		toggleShowEditPresentation,
		setShowMissingFeature,
		toggleShowCreateFirstPresentation,
		toggleGuidedTour,
		setGuidedTourStep,
		toggleLicenseFailed,
		toggleWelcome,
		toggleMultipleLicenseEligible,
		toggleLicenseEligible,
		toggleSupport,
		togglePaymentFailedDialogOpen,
		togglePaymentPendingDialogOpen,
		togglePaymentSuccessDialogOpen,
		toggleNewsFeedOpen,
		toggleSpeakerNotes,
		setSelectedMobileEditorTab,
		setShowCantDownloadAddinFromMobileDevice,
		setShowDeletePresentationWithoutSlides,
		setSelectedSettingsIntegration,
		setShowCantEditInspirationTemplateDialog,
		storeHashFragment,
		setShowMissingPresentationFeatures,
		setSelectedAddSlideDialogTab,
		setExampleSlideLayoutShown,
		setSlideOptionTab,
		setSlideTypeTab,
		setShowImageUploadDialog,
		setShowAiPresentationReadyDialog,
		setShowUploadDocumentDialog,
		setFeedbackDialogOpen,
		setIsRewriting,
		setShowSlideLogoUploadDialog,
	}
}