import { AnyAction } from "redux";
import { SharedSlidesState } from "Types/sharedSlideTypes";


export const initialState: SharedSlidesState = {
    slideCategories: [],
    slideCategoriesLoading: false,
    selectedSlideCategory: '',
    selectedSlideType: null,
    sharedSlides: [],
    sharedSlidesLoading: false,
}

export function sharedSlideReducer(state = initialState, action: AnyAction) {
	switch (action.type) {
        case 'SET_SLIDE_CATEGORIES': {
            return {
                ...state,
                slideCategories: action.categories
            }
        }
        case 'SET_SLIDE_CATEGORIES_LOADING': {
            return {
                ...state,
                slideCategoriesLoading: action.isLoading
            }
        }
        case 'SET_SELECTED_SLIDE_CATEGORY': {
            return {
                ...state,
                selectedSlideCategory: action.category,
            }
        }
        case 'SET_SELECTED_SLIDE_TYPE': {

            const { slideType } = action;

            return {
                ...state,
                selectedSlideType: (slideType === state.selectedSlideType) ? null : slideType,
            }
        }
        case 'SET_SHARED_SLIDES': {
            return {
                ...state,
                sharedSlides: action.sharedSlides,
            }
        }
        case 'SET_SHARED_SLIDES_LOADING': {
            return {
                ...state,
                sharedSlidesLoading: action.isLoading,
            }
        }
		default:
			return state
	}
}