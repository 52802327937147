import { Collapse, InputBase } from '@mui/material';
import { useIdentification } from 'Hooks/useIdentification';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import React from 'react';
import { classes } from './style.css';

const EmptyIdentificationQuestion = () => {

    const {
        addIdentificationQuestion,
    } = useIdentification();

    const handleClick = () => {

        addIdentificationQuestion();
    }

    const { translatePlaceholder } = useTranslations();

    return (
        <Collapse in={true}>
            <div
                onClick={() => handleClick()}
                data-testid={`${testIds.IDENTIFICATION_QUESTION_PREFIX}_${testIds.QUESTION_INPUT_EMPTY}`}
                className={classes.questionBox}>
                <InputBase
                    data-eltype="button"
                    data-elaction="identification_question_add"
                    data-elscreen="settings"
                    fullWidth
                    placeholder={translatePlaceholder("ENTER_YOUR_QUESTION")}
                />
            </div>
        </Collapse>
    );
};

export default EmptyIdentificationQuestion;