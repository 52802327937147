import { aiReducer } from 'Reducers/aiReducer';
import { appReducer } from 'Reducers/appReducer';
import { appTranslationReducer } from 'Reducers/appTranslationReducer';
import { audioReducer } from 'Reducers/audioReducer';
import { authReducer } from 'Reducers/authReducer';
import { billingReducer } from 'Reducers/billingReducer';
import { featureReducer } from 'Reducers/featureReducer';
import { identificationReducer } from 'Reducers/identificationReducer';
import { languageReducer } from 'Reducers/languageReducer';
import { layoutReducer } from 'Reducers/layoutReducer';
import { newsFeedReducer } from 'Reducers/newsFeedReducer';
import { overviewReducer } from 'Reducers/overviewReducer';
import { playReducer } from 'Reducers/playReducer';
import { presentationStyleReducer } from 'Reducers/presentationStyleReducer';
import { presentationTemplatesReducer } from 'Reducers/presentationTemplatesReducer';
import { quizReducer } from 'Reducers/quizReducer';
import { resultReducer } from 'Reducers/resultReducer';
import { sharedSlideReducer } from 'Reducers/sharedSlideReducer';
import { teamReducer } from 'Reducers/teamReducer';
import { userFlowReducer } from 'Reducers/userFlowReducer';
import { combineReducers } from 'redux';
import { generatePresentationReducer } from './generatePresentationReducer';
import { outlineReducer } from './outlineReducer';

export const reducerStore = (extraProps?: any) => combineReducers({
	appReducer,
	quizReducer,
	playReducer,
	overviewReducer,
	audioReducer,
	featureReducer,
	languageReducer,
	appTranslationReducer,
	layoutReducer,
	userFlowReducer,
	presentationStyleReducer,
	teamReducer,
	resultReducer,
	identificationReducer,
	billingReducer,
	authReducer,
	newsFeedReducer,
	sharedSlideReducer,
	presentationTemplatesReducer,
	aiReducer,
	generatePresentationReducer,
	outlineReducer,
	...(extraProps || {}),
})