import { Box } from '@mui/material';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import Html from 'Components/common/util/html/Html';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import React from 'react';
import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';


const ContentSlideSessionPreview = ({ slide }: SlideConsumerComponent<ContentSlideType>) => {

    const slideProperties = getSlideProperties(slide);

    return <>
        <LayoutWrapper
            layoutType={slide.slideLayout}
            imageURL={slide?.file?.base64 || slide?.filename}
            imageStyle={slide.filenameStyle}
            backgroundOpacity={slide.backgroundOpacity}
            backgroundColor={slide.backgroundColor}
            videoURL={slide.videoURL}
            interactive={false}
            title={(
                <DelayedComponent
                    animationDuration={250}
                    animationType={AnimationTypes.Grow}
                    showAfter={500} >
                    <Box p={4}>
                        <Html
                            content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                            fontSize={slideProperties.slideSettings.titleFontSize}
                        />
                    </Box>
                </DelayedComponent>
            )}
            content={(
                <DelayedComponent
                    animationDuration={250}
                    animationType={AnimationTypes.Grow}
                    showAfter={500} >
                    <Box p={4}>
                        <Html
                            content={(slide.content || slideProperties.text?.getHtmlContentPlaceholder?.(slideProperties.text.contentPlaceholder)) as string}
                            fontSize={slideProperties.slideSettings?.contentFontSize as number}
                        />
                    </Box>
                </DelayedComponent>
            )}
        />
    </>;
};

export default ContentSlideSessionPreview
