import { MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import ButtonLoader from 'Components/common/util/button_loader/ButtonLoader';
import usePlaySettings from 'Hooks/usePlaySettings';
import { useTranslations } from 'Hooks/useTranslations';
import { addToCookies, getFromCookies } from 'Scripts/cookieHelper';
import { testIds } from 'Scripts/cypressTestIds';
import { CookieKeys } from 'Scripts/globals';
import { AppTranslationState } from 'Types/appTranslationTypes';
import { AvailableLanguages, LanguageDropdownType, LanguageState } from 'Types/languageTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const DEFAULT_LANGUAGE_KEY = "en";

interface LanguageDropdownProps {
    languageDropdownType: LanguageDropdownType;
}

const LanguageDropdown = ({ languageDropdownType } : LanguageDropdownProps) => {

	const {
		language,
		fetchTranslationsByIsoCode
	} = usePlaySettings();

	const {
		fetchAppTranslations
	} = useTranslations();

	const updateLanguageLoading = useSelector((state: RootStateOrAny) => (state.languageReducer as LanguageState).updateLanguageLoading);

	const updateAppLanguageLoading = useSelector((state: RootStateOrAny) => (state.appTranslationReducer as AppTranslationState).updateLanguageLoading);

    const allAvailableLanguages = useSelector((state: RootStateOrAny) => (state.appTranslationReducer as AppTranslationState).availableLanguages);

	// Filter languages if we need to show the app languages
	const availableLanguages = languageDropdownType == LanguageDropdownType.App
		? allAvailableLanguages.filter(l=>l.isAppLanguage)
		: allAvailableLanguages
	;

	const handleLanguageChange = (e: React.MouseEvent<HTMLOptionElement>) => {

		const newLanguage = e.target.value;

		if ( languageDropdownType == LanguageDropdownType.App )
		{
			// Change the cookie lang value to the selected one
			const expDate = new Date().getTime() + (1000 * 60 * 60 * 24 * 30); /** in 1 month */
			addToCookies(CookieKeys.Language, newLanguage, expDate);

			// Fetch the translations of the selected language
			fetchAppTranslations();
		}
		else if ( languageDropdownType == LanguageDropdownType.ResponseWebsite )
		{
			fetchTranslationsByIsoCode(newLanguage);
		}
	}

	const selectedLanguage = languageDropdownType == LanguageDropdownType.App
		? getFromCookies(CookieKeys.Language) || DEFAULT_LANGUAGE_KEY
		: language
	;

	const testIdDropdown = languageDropdownType == LanguageDropdownType.App
		? testIds.LANGUAGE_APP_DROPDOWN
		: testIds.LANGUAGE_DROPDOWN
	;

	const testIdDropdownItem = languageDropdownType == LanguageDropdownType.App
		? testIds.LANGUAGE_APP_DROPDOWN_ITEM
		: testIds.LANGUAGE_DROPDOWN_ITEM
	;

	const loading = languageDropdownType == LanguageDropdownType.App
		? updateAppLanguageLoading
		: updateLanguageLoading
	;

	return (
		<Select
			className={classes.languageSelect}
			classes={{
				root: classes.languageContainer,
			}}
			MenuProps={{
				classes: {
					paper: classes.languageList
				}
			}}
			disabled={loading}
			startAdornment={loading && (
				<ButtonLoader dark={true} inline={true} flex={true} />
			)}
			data-testid={testIdDropdown}
			value={selectedLanguage}
			disableUnderline
			onChange={e => handleLanguageChange(e)} >
			{availableLanguages.map( (language: AvailableLanguages) => {
				return (
					<MenuItem
						data-testid={`${testIdDropdownItem}-${language.isoCode}`}
						key={language.isoCode}
						className={classes.language}
						value={language.isoCode}>
						{language.localName}
					</MenuItem>
				)
			})}
		</Select>
	);
};

export default LanguageDropdown;