import { Box } from '@mui/system';
import Html from 'Components/common/util/html/Html';
import { useSlideShapes } from 'Hooks/useSlideShapes';
import { parseJsonSafe } from 'Scripts/parserHelper';
import { LayoutTypes } from 'Types/layoutTypes';
import { SlideType } from 'Types/presentationTypes';
import { SlideShape } from 'Types/slideTypes';
import React from 'react';

interface Props {
    shape: SlideShape;
    slide: SlideType;
}

const PreviewTextSlideShape = ({
    shape,
    slide,
}: Props) => {

    const { getShapePreset } = useSlideShapes();

    const shapePreset = getShapePreset(slide?.slideLayout as LayoutTypes, slide?.type, shape.type);

    const titlePlaceholder = shapePreset?.contents || '';

    const { fontSize, ...styles } = parseJsonSafe(shape.jss);

    return (
        <Box
            height="100%"
            width="100%"
            style={styles}
        >
            <Html
                content={shape.contents || titlePlaceholder}
                fontSize={fontSize || 32}
            />
        </Box>
    );
};

export default PreviewTextSlideShape;