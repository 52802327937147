import PlaceholderQuestionOption from 'Components/play/templates/geometry_template/slides/question_slide/options/placeholder/PlaceholderQuestionOption';
import { SlideConsumerComponent } from 'Types/appTypes';
import { LayoutTypes } from 'Types/layoutTypes';
import { SlideType } from 'Types/presentationTypes';
import { SlideInputFontSize } from 'Types/slideTypes';
import React, { Fragment } from 'react';
import { classes } from './style.css';

const PreviewMpcOptionsSlideShape = ({
    slide
}: SlideConsumerComponent<SlideType>) => {

    const slideOptions = (slide?.options || []);

    return <div className={classes.optionsList}>
        {slideOptions.map((option, index) => (
            <Fragment key={index}>
                <PlaceholderQuestionOption
                    index={index}
                    fontSize={SlideInputFontSize.Medium}
                    layout={slide.slideLayout as LayoutTypes}
                    option={option}
                />
            </Fragment>
        ))}
    </ div>
};

export default PreviewMpcOptionsSlideShape