import { useTheme } from '@mui/material';
import Grow from '@mui/material/Grow';
import { Box } from '@mui/system';
import { PlayState } from 'Types/playTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import SessionInternetInfo from './SessionInternetInfo';
import SessionSMSInfo from './SessionSMSInfo';

const SessionLoginInfo = () => {

    const sessionData = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).sessionData);

    const participantsWithLoginCodeOnly = sessionData?.participantsWithLoginCodeOnly ?? false;

    const theme = useTheme();

    return (
        <>
            {sessionData && !participantsWithLoginCodeOnly && <Grow in={true}>
                <Box sx={{
                    textShadow: '3px 3px 3px #00000050',
                    fontSize: theme.spacing(3),
                    textAlign: 'center',
                    fontWeight: 600,
                    position: 'absolute',
                    width: '100%',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    fontStyle: 'capitalize',
                    display: 'flex',
                    height: '10%',
                    alignItems: 'center',
                    zIndex: 1,
                }}>
                    <Box sx={{
                        maxWidth: `70%`,
                        margin: '0 auto',
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <SessionInternetInfo />
                        <SessionSMSInfo />
                    </Box>
                </Box>
            </Grow>}
        </>
    );
};

export default SessionLoginInfo;