import { AnyAction } from "redux";
import { TeamState } from "Types/teamTypes";

export const DEFAULT_USERS_PER_PAGE_COUNT = 10;

export const DEFAULT_JOIN_REQUESTS_PER_PAGE_COUNT = 4;

export const initialTeamState: TeamState = {
	primaryPlan: null,
	otherPlans: [],
	licenseUserPageData: {
		searchQuery: '',
		totalResultsCount: 0,
		filteredResultsCount: 0,
		pageResultsCount: 0,
		pageIndex: 0,
		pageData: [],
		pageSize: DEFAULT_USERS_PER_PAGE_COUNT,
		pageLoading: false,
		sortProperty: {
			id: 'date',
			desc: 1,
		}
	},
	licenseUserIdToEdit: null,
	licenseUserIdToDowngrade: null,
	licenseUserIdToDelete: null,
	licenseAddUserDialogOpen: false,
	licenseAutoAcceptDialogOpen: false,
	licenseManageUsersSettings: {
		licenseName: '',
		licenseAllowsAutomaticJoinRequests: null,
		licenseAllowsJoinRequests: null,
		licenseHasReachedMaximumUsers: null,
		licenseUserIsAccountOwner: null,
	},
	licensePageUserChangeLoading: false,
	licenseNameUpdateLoading: false,
	licenseJoinRequestsPageData: {
		searchQuery: '',
		totalResultsCount: 0,
		filteredResultsCount: 0,
		pageResultsCount: 0,
		pageIndex: 0,
		pageData: [],
		pageSize: DEFAULT_JOIN_REQUESTS_PER_PAGE_COUNT,
		pageLoading: false,
		sortProperty: {
			id: 'issuedAt',
			desc: 1,
		}
	},
}


export const teamReducer = (state: TeamState = initialTeamState, action: AnyAction) => {
	switch (action.type) {
		case 'SET_LICENSE_PAGE_NAME_CHANGE_LOADING': {

			return {
				...state,
				licenseNameUpdateLoading: action.loading,
			}
		}
		case 'SET_LICENSE_PAGE_USER_CHANGE_LOADING': {

			return {
				...state,
				licensePageUserChangeLoading: action.loading,
			}
		}
		case 'SET_JOIN_REQUESTS_SORT_PROPERTY': {
			return {
				...state,
				licenseJoinRequestsPageData: {
					...state.licenseJoinRequestsPageData,
					sortProperty: {
						...action.property,
					}
				}
			}
		}

		case 'SET_JOIN_REQUEST_SEARCH_VALUE': {
			return {
				...state,
				licenseJoinRequestsPageData: {
					...state.licenseJoinRequestsPageData,
					searchQuery: action.value,
					pageIndex: 0,
				}
			}
		}
		case 'SET_JOIN_REQUEST_TABLE_LOADING': {

			return {
				...state,
				licenseJoinRequestsPageData: {
					...state.licenseJoinRequestsPageData,
					pageLoading: action.loading,
				}
			}
		}
		case 'SET_JOIN_REQUESTS_PAGE_DATA': {

			return {
				...state,
				licenseJoinRequestsPageData: {
					...state.licenseJoinRequestsPageData,
					...action.data
				}
			}
		}
		case 'SET_JOIN_REQUESTS_PAGE_INDEX': {
			return {
				...state,
				licenseJoinRequestsPageData: {
					...state.licenseJoinRequestsPageData,
					pageIndex: action.index,
				}
			}
		}
		case 'SET_LICENSE_MANAGE_USERS_SETTINGS': {
			return {
				...state,
				licenseManageUsersSettings: {
					...action.settings
				}
			}
		}
		case 'SET_LICENSE_AUTO_ACCEPT_DIALOG_OPEN': {
			return {
				...state,
				licenseAutoAcceptDialogOpen: action.isOpen,
			}
		}
		case 'SET_LICENSE_SORT_PROPERTY': {
			return {
				...state,
				licenseUserPageData: {
					...state.licenseUserPageData,
					sortProperty: {
						...action.property,
					}
				}
			}
		}
		case 'SET_LICENSE_USERS_SEARCH_VALUE': {
			return {
				...state,
				licenseUserPageData: {
					...state.licenseUserPageData,
					searchQuery: action.value,
					pageIndex: 0,
				}
			}
		}
		case 'SET_MANAGE_USERS_TABLE_LOADING': {

			return {
				...state,
				licenseUserPageData: {
					...state.licenseUserPageData,
					pageLoading: action.loading,
				}
			}
		}
		case 'SET_LICENSE_USERS_PAGE_DATA': {

			return {
				...state,
				licenseUserPageData: {
					...state.licenseUserPageData,
					...action.data
				}
			}
		}
		case 'SET_LICENSE_USERS_PAGE_INDEX': {
			return {
				...state,
				licenseUserPageData: {
					...state.licenseUserPageData,
					pageIndex: action.index,
				}
			}
		}
		case 'SET_ADD_LICENSE_USER_DIALOG_OPEN': {
			return {
				...state,
				licenseAddUserDialogOpen: action.isOpen,
			}
		}
		case 'SET_USERS_SUBSCRIPTION_DATA': {
			return {
				...state,
				primaryPlan: action.primaryPlan,
				otherPlans: action.otherPlans,
			}
		}
		case 'SET_LICENSE_USER_ID_TO_EDIT': {
			return {
				...state,
				licenseUserIdToEdit: action.userId,
			}
		}
		case 'SET_LICENSE_USER_ID_TO_DOWNGRADE': {
			return {
				...state,
				licenseUserIdToDowngrade: action.userId,
			}
		}
		case 'SET_LICENSE_USER_ID_TO_DELETE': {
			return {
				...state,
				licenseUserIdToDelete: action.userId,
			}
		}
		default: {
			return state
		}
	}
}