import { AIActionTypes } from "Reducers/aiReducer";
import { AiContentCreatorStep, AISupportedLength, AISupportedToneOfVoice, AISupportedTypes, AISupportedUsage } from "Types/aiTypes";


export const setInitialPrompt = (initialPrompt: string) => ({
    type: AIActionTypes.SetAIInitialPrompt,
    payload: initialPrompt,
});

export const setChosenLanguage = (language: string | null) => ({
    type: AIActionTypes.SetAIPresentationLanguage,
    payload: language,
});

export const setChosenToneOfVoice = (toneOfVoice: AISupportedToneOfVoice | null) => ({
    type: AIActionTypes.SetAIPresentationToneOfVoice,
    payload: toneOfVoice,
})

export const setChosenLength = (length: AISupportedLength | null) => ({
    type: AIActionTypes.SetAIPresentationLength,
    payload: length,
});

export const setChosenType = (type: AISupportedTypes | null) => ({
    type: AIActionTypes.SetAIPresentationType,
    payload: type,
});

export const setChosenUsage = (usage: AISupportedUsage | null) => ({
    type: AIActionTypes.SetAIPresentationUsage,
    payload: usage,
});

export const setChosenTitle = (title: string | null) => ({
    type: AIActionTypes.SetAIPresentationTitle,
    payload: title,
});

export const setTitleSuggestions = (titles: Array<string>) => ({
    type: AIActionTypes.SetAIPresentationTitleSuggestions,
    payload: titles,
})

export const addTitleSuggestions = (titles: Array<string>) => ({
    type: AIActionTypes.AddAIPresentationTitleSuggestions,
    payload: titles,
})

export const setUseOwnTitle = (useOwnTitle: boolean) => ({
    type: AIActionTypes.SetAIUseOwnTitle,
    payload: useOwnTitle,
})


export const setAIGuidedTourRunning = (running: boolean) => ({
    type: AIActionTypes.SetAIGuidedTourRunning,
    payload: running,
})

export const setAIGuidedTourStep = (step: number) => ({
    type: AIActionTypes.SetAIGuidedTourStep,
    payload: step,
})

export const setAiContentCreatorKeywords = (keywords: string) => ({
    type: AIActionTypes.SetAIContentCreatorKeywords,
    payload: keywords,
})

export const setAiContentCreatorStep = (step: AiContentCreatorStep) => ({
    type: AIActionTypes.SetAiContentCreatorStep,
    payload: step,
})

export const setEnableInteractiveQuestions = (enable: boolean) => ({
    type: AIActionTypes.SetEnableInteractiveQuestions,
    payload: enable,
})