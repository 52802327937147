import { setLicenseEligibleOverlayOpen, setLicenseRequestFailedOverlayOpen, setMultiLicenseEligibleOverlayOpen, setShowWelcomeNewUser, showMissingFeature, toggleSupportPanel } from "Actions/layoutActions";
import * as userFlowActions from 'Actions/userFlowActions';
import { initUserFlow, setPurchaseCompletedThankYou, setShowSignupStepper, setSignupStepperData, setSignupStepperStep, updateUserFlowSetting } from "Actions/userFlowActions";
import useUrl from "Hooks/useUrl";
import { get, post } from "Scripts/api";
import { SignupStepperData } from "Types/layoutTypes";
import { SupportDetails } from "Types/userFlowTypes";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useTranslations } from "./useTranslations";

const useUserFlow = () => {

	const { enqueueSnackbar } = useSnackbar();

	const urls = useUrl();

    const { translatePlaceholder } = useTranslations();

	const dispatch = useDispatch();

	const makeLocaleUrl = (redirectUrl: string) : string => {

		return redirectUrl
			.replace('/en/pricing/', translatePlaceholder('PRICINGPAGE_URL'))
			.replace('/en/checkout/', translatePlaceholder('CHECKOUT_URL'))
		;
	}

	const resendLicenseVerification = async () => {

		try {

			const response = await get('license/resend-license-verification');

			if (response.message) {

				enqueueSnackbar(response.message, { variant: 'info' });

				fetchUserFlowData();
			}

			if (response.error) {

				enqueueSnackbar(response.error, { variant: 'error' });
			}

		} catch (error) {

			console.warn(error)
		}
	}

	const setSupportDetails = (details: SupportDetails) => {

		dispatch(userFlowActions.setSupportDetails(details))
	}

	const sendLicenseVerification = async () => {

		const response = await get('license/send-license-verification');

		if (response.message) {

			enqueueSnackbar(response.message, { variant: 'info' });
		}

		if (response.error) {

			enqueueSnackbar(response.error, { variant: 'error' });
		}

		fetchUserFlowData();
	}

	const cancelLicenseVerification = async () => {

		const response = await get('license/cancel-license-verification');

		if (response.message) {

			enqueueSnackbar(response.message, { variant: 'info' });
		}

		fetchUserFlowData();
	}

	const clickedUpgradeButton = async (userFlow = '', feature: string|null = null) => {

		try {
			/* Pass history to be able to redirect to local routes */
			/* @TODO: create useAPI */
			const upgradeResponse = await get('license/clicked-upgrade' + (feature !== null ? '?feature=' + feature : ''));
			/* Close Feature dialog */
			dispatch(showMissingFeature(null))

			if (upgradeResponse.redirect) {

				const redirectUrl = upgradeResponse.redirect;

				const localeRedirectUrl = makeLocaleUrl(redirectUrl);

				if (localeRedirectUrl.includes(window.location.origin)) {

					const route = localeRedirectUrl.replace(window.location.origin, '');

					handleLicenseRoute(route, userFlow);

				} else {

					// Construct a url with plan and feature if needed
					const redirectUrlWithAllParams = new URL(localeRedirectUrl);

					if (upgradeResponse.plan)
					{
						redirectUrlWithAllParams.searchParams.append('plan', upgradeResponse.plan )
					}
					if (upgradeResponse.feature)
					{
						redirectUrlWithAllParams.searchParams.append('feature', upgradeResponse.feature )
					}

					window.open(redirectUrlWithAllParams.href, '_self').focus();
				}
			}

			if (upgradeResponse?.error) {

				enqueueSnackbar(upgradeResponse?.error || "Please contact your administrator regarding upgrading your license.", { variant: 'info' })
			}

		} catch (error) {

			console.warn(error)
		}
	}

	const postSegmentInfo = async (stepperData: SignupStepperData) => {

		try {

			dispatch(userFlowActions.setUserSegmentUpdateLoading(true));

			dispatch(setSignupStepperData(stepperData));

			const response = await post('users/segment-info', {
				...stepperData
			})

			dispatch(userFlowActions.setUserSegmentUpdateLoading(false));

			if (response.error) {

				enqueueSnackbar(response.error, { variant: 'warning' });

			} else {

				return true;
			}

		} catch (error) {

			console.warn(error)
		}

		return false;
	}

	const setShowStepperOverlay = (show: boolean) => {

		dispatch(setShowSignupStepper(show));
	}

	const setSelectedStep = (stepNr: number) => {

		dispatch(setSignupStepperStep(stepNr))
	}

	const handleLicenseRoute = (route: string, userFlow: string = '') => {

		const hasPaidLicense = userFlow === "PAID_ACCOUNT";

		const searchParams = new URLSearchParams(window.location.search);

		const purchaseCompleteValue = searchParams.get('purchaseComplete');

		if (Boolean(purchaseCompleteValue)) {

			dispatch(setPurchaseCompletedThankYou(true));
		}

		switch (route) {
			case urls.requestedAccess: {
				enqueueSnackbar('A request has been sent to the license administrator. Once the request has been verified you will be automatically added to your company\'s license.', { variant: 'success', persist: true })
				return;
			}
			case urls.welcomePage: {
				dispatch(setShowWelcomeNewUser(true));
				return;
			}
			case urls.multipleLicenseEligiblePage: {
				dispatch(setShowWelcomeNewUser(true));
				dispatch(setMultiLicenseEligibleOverlayOpen(!hasPaidLicense));
				return;
			}
			case urls.licenseEligiblePage: {
				dispatch(setShowWelcomeNewUser(true));
				dispatch(setLicenseEligibleOverlayOpen(!hasPaidLicense));
				return;
			}
			case urls.licenseRequestFailedPage: {
				dispatch(setLicenseRequestFailedOverlayOpen(true));
				return;
			}
			default: {
				/* Nothing to be done, continue routing */
			}
		}
	}

	const fetchUserFlowData = async () => {

		try {

			dispatch(userFlowActions.setUserFlowLoading(true));

			const userFlowData = await get('users/flow');

			dispatch(initUserFlow({
				...userFlowData
			}))

		} catch (error) {

			console.warn(error)

		} finally {

			dispatch(userFlowActions.setUserFlowLoading(false));
		}
	}

	const requestAccountAccess = async (accountId: number) => {

		if (!Boolean(accountId)) {

			return false;
		}

		try {

			dispatch(userFlowActions.setRequestAccessLoading(true));

			const response = await post(`license/add-to-license/${accountId}`);

			if (response.message) {

				enqueueSnackbar(response.message, { variant: 'info' })

			} else if (response.error) {

				enqueueSnackbar(response.error, { variant: 'error' })
			}

			fetchUserFlowData();

		} catch (error) {

			console.warn(error);

			enqueueSnackbar('Something went wrong attempting to request access. Please try again later.', { variant: 'error' })

		} finally {

			dispatch(userFlowActions.setRequestAccessLoading(false));
		}

		dispatch(setMultiLicenseEligibleOverlayOpen(false));
	}

	const updateUserFlowData = (key: string, value: any) => {

		dispatch(updateUserFlowSetting(key, value));
	}

	const togglePurchaseThankYouPopup = (isOpen: boolean) => {

		dispatch(setPurchaseCompletedThankYou(isOpen));
	}

	const fetchSupportDetails = async (supportUrlOnly: boolean = false) => {

		try {

			dispatch(userFlowActions.setSupportDetailsLoading(true));

			const data = await get('users/support-details');

			if (supportUrlOnly || (!Boolean(data.brandSupportEmail) && Boolean(data.supportUrl))) {

				window.open(data.supportUrl, '_blank');

			} else {

				setSupportDetails(data);

				dispatch(toggleSupportPanel(true));
			}

		} catch (error) {

			console.warn(error)

		} finally {

			dispatch(userFlowActions.setSupportDetailsLoading(false));
		}
	}

	const toggleSetResultsEmailNotification = async (enabled: boolean) => {

		try {

			dispatch(userFlowActions.setToggleResultsAfterSessionLoading(true));

			const response = await post('users/toggle-send-results', {
				enabled,
			});

			if (!response?.error) {

				dispatch(userFlowActions.setToggleResultsAfterSession(enabled));
			}

		} catch (error) {

			console.warn(error)

		} finally {

			dispatch(userFlowActions.setToggleResultsAfterSessionLoading(false));
		}
	}

	const setIgnoreLicensePopups = (ignore: boolean) => {

		dispatch(userFlowActions.setIgnoreLicensePopups(ignore));
	}

	return {
		clickedUpgradeButton,
		requestAccountAccess,
		sendLicenseVerification,
		resendLicenseVerification,
		cancelLicenseVerification,
		fetchUserFlowData,
		updateUserFlowData,
		handleLicenseRoute,
		setSelectedStep,
		setShowStepperOverlay,
		togglePurchaseThankYouPopup,
		postSegmentInfo,
		fetchSupportDetails,
		toggleSetResultsEmailNotification,
		setIgnoreLicensePopups,
	}
}

export default useUserFlow;