import { SortObject, TablePaginationData } from "Components/common/table/AppTable"
import { PresentationTemplate } from "Types/presentationTypes"
import { PresentationCategoryData } from "Types/sharedSlideTypes"

export const setTemplatesLoading = (loading: boolean) => ({
    type: 'SET_TEMPLATES_LOADING',
    loading,
})

export const setTemplatesPageIndex = (pageIndex: number) => ({
    type: 'SET_TEMPLATE_PAGE_INDEX',
    pageIndex,
})

export const setTemplatesSortProperty = (sortProperty: SortObject) => ({
    type: 'SET_TEMPLATE_SORT_PROPERTY',
    sortProperty,
})

export const setTemplatesPageData = (tableData: TablePaginationData) => ({
    type: 'SET_TEMPLATE_PAGE_DATA',
    tableData,
})

export const setTemplateToPreview = (template: PresentationTemplate | null) => ({
    type: 'SET_TEMPLATE_TO_PREVIEW',
    template,
})

export const setTemplatesFilterCategory = (category: PresentationCategoryData | null) => ({
    type: 'SET_TEMPLATES_FILTER_CATEGORY',
    category,
})

export const setTemplateCategories = (categories: Array<PresentationCategoryData>) => ({
    type: 'SET_TEMPLATE_CATEGORIES',
    categories,
})