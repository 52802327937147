import { PlanInfo } from "Types/featureTypes"

export const setAvailableFeatures = (availableFeatures: string[] = []) => ({
    type: 'SET_AVAILABLE_FEATURES',
    availableFeatures,
})

export const setPlanInfo = (planInfo: PlanInfo) => ({
    type: 'SET_PLAN_INFO',
    planInfo
})

export const setPlanFeaturesLoading = (isLoading: boolean) => ({
    type: 'SET_PLAN_FEATURES_LOADING',
    isLoading,
})

export const setSubscriptionDataLoading = (isLoading: boolean) => ({
    type: 'SET_SUBSCRIPTION_DATA_LOADING',
    isLoading,
})