
/* Map() polyfill for IE */
import 'core-js/es/map';
/* Set() polyfill for IE */
import 'core-js/es/set';
/* navigator.sendBeacon() polyfill for IE to handle tab close events */
import 'navigator.sendbeacon';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Application from "./components/app/Application";

const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

root.render(<Application />);