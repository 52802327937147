
type EventHandler = (handler: any) => any;

export const bindKeyboardEvent = (handler : EventHandler) : void => {
    window.addEventListener("keydown", handler, true)
}

export const unbindKeyboardEvent = (handler: EventHandler) : void => {
    window.removeEventListener("keydown", handler, true)
}
