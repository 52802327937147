import { Box, BoxProps } from '@mui/system';
import { ScreenRatio, ScreenSizes } from 'Types/appTypes';
import React, { MutableRefObject, ReactNode, useCallback, useEffect, useRef } from 'react';

export interface AspectRatioWrapperProps extends BoxProps {
	containerRef?: MutableRefObject<any>;
	ratio?: ScreenRatio;
	transformOrigin?: string;
	height?: number;
	width?: number
	children: ReactNode | Array<ReactNode>;
	fixBottomMargin?: boolean;
	limitMaxScale?: boolean;
	forceMaxScale?: boolean;
	resizeCallback?: (width: number, height: number, scale: number) => void;
}

export interface AspectRatioUseStylesProps {
	ratio: ScreenRatio;
	transformOrigin: string;
	height: ScreenSizes;
	width: ScreenSizes;
	needsToRender: boolean;
	maxWidth: number;
}

const AspectRatioWrapper = ({
	children,
	containerRef,
	ratio = ScreenRatio.Ratio16By9,
	transformOrigin = 'center',
	width = ScreenSizes.Width1080P,
	height = ScreenSizes.Height1080P,
	fixBottomMargin = false,
	limitMaxScale = false,
	forceMaxScale = false,
	resizeCallback,
	...props
}: AspectRatioWrapperProps) => {

	const wrapperRef = useRef() as MutableRefObject<HTMLElement>;

	const onResize = useCallback(() => {

		if (Boolean(wrapperRef.current)) {

			const refElement = wrapperRef.current as HTMLElement;

			const containerRefElement = (containerRef?.current || document.body) as HTMLElement;

			const containerWidth = containerRefElement.clientWidth;
			const containerHeight = containerRefElement.clientHeight;

			let scale = Math.min(
				containerWidth / (refElement.clientWidth),
				containerHeight / refElement.clientHeight,
			)

			if (forceMaxScale && containerWidth > (refElement.clientWidth * scale)) {

				scale = Math.max(
					containerWidth / refElement.clientWidth,
					containerHeight / refElement.clientHeight,
				);
			}

			if (limitMaxScale) {

				scale = Math.min(1, scale);
			}

			requestAnimationFrame(() => {

				refElement.style.maxHeight = `${height}px`;
				refElement.style.height = `${height}px`;
				refElement.style.maxWidth = `${width}px`;
				refElement.style.width = `${width}px`;

				refElement.setAttribute('data-scale', String(scale));

				refElement.style.transform = `scale(${scale})`;

				if (fixBottomMargin) {
					/**
					 * Because the container has a fixed height,
					 * we want to add some minus bottom margin to the container
					 * so following elements will be positioned correctly under the
					 * scaling wrapper instead of under the container.
					 */
					const containerHeightDifference = refElement.clientHeight - (refElement.clientHeight * scale)

					refElement.style.marginBottom = `-${containerHeightDifference}px`;
				}

				resizeCallback?.(width * scale, height * scale, scale);
			})
		}
	}, [])

	useEffect(() => {

		const containerElement = containerRef?.current;

		if (containerElement) {

			onResize();

			const observer = new ResizeObserver(onResize);

			observer.observe(containerElement);

			return () => {

				observer.disconnect();
			};
		}

	}, [containerRef?.current, onResize])

	const maxWidth = containerRef?.current?.clientWidth;

	return (
		<Box
			sx={{
				minWidth: width,
				minHeight: height,
				aspectRatio: ratio,
				transformOrigin: transformOrigin,
				margin: '0 auto',
				width: '100%',
				height: '100%',
				...(maxWidth && { maxWidth }),
			}}
			ref={wrapperRef}
			{...props}>
			{children}
		</Box>
	)
}

export default AspectRatioWrapper
