import { TablePaginationData } from "Components/common/table/AppTable";
import { DynamicTablePaginationData } from '../components/common/table/AppTable';

export enum ChartTypes {
    PIE_CHART,
    BAR_CHART,
    COLUMN_CHART,
}

export enum ResultItemTypes {
    Vote = 'votes',
    Message = 'messages',
}

export interface VoteResult {
    answer: string;
    answerCode: string;
    correctAnswer: boolean | number;
    percentage: string;
    numVotes: number;
}

export interface MessageResult {
    text: string;
    timestamp: string;
    upvotes: number;
    status: string;
}

export interface PresentationResult {
    id: number;
    labels: Array<string>;
    participantCount: number;
    results: Array<VoteResult | MessageResult>;
    title: string;
    type: ResultItemTypes;
    weightedVoting: boolean | number;
}

export interface PresentationResultSummary {
    title: string;
    startDate: string;
    endDate: string;
    nrOfAttendees: number;
    nrOfResponses: number;
    sessionRunId: number;
    presentationId: number;
}

export enum TabItems {
    FILTER_BY_ANSWER,
    FILTER_BY_PERSON,
    FILTER_BY_GROUP
}

export enum ResultFileExtensions {
    CSV = 'csv',
    XLSX = 'xlsx',
    PDF = 'pdf',
}

export interface SessionSummary {
    id: number;
    endTime: string;
    activeAttendees: number;
    presentationId: number;
    startTime: string;
    userId: number;
}

export interface ResultState {
    result: PresentationResult | null;
    resultLoading: boolean;
    selectedResultTab: TabItems;
    resultSummary: PresentationResultSummary | null;
    resultSummaryLoading: boolean;
    presentationResultIdToRemove: number | null;
    presentationResultDeleting: boolean;
    selectedResultId: number | null;
    resultsAsPercentage: boolean;
    preferredChartType: ChartTypes;
    resultDownloading: boolean;
    resultPageData: TablePaginationData;
    individualResultsPageData: DynamicTablePaginationData;
    showUpgradeNeededForResults: boolean;
    sessionResults: Array<SessionSummary>;
    sessionResultsPageData: TablePaginationData;
    sessionRunIdToDelete: number | null;
    sessionResultPresentationTitle: string | null;
    showResultsAsWordcloud: boolean;
}