import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import React from 'react';
import ImageSlideSession from '../../session/view/ImageSlideSession';

const ImageSlideEditorPreview = ({
    slide,
}: SlideConsumerComponent<ContentSlideType>) => {

    return (
        <div style={{ pointerEvents: 'none' }}>
            <ImageSlideSession slide={{
                ...slide,
                filename: slide.filename
            }} />
        </div>
    )
}

export default ImageSlideEditorPreview;