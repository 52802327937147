import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslations } from 'Hooks/useTranslations';
import { get } from 'Scripts/api';
import { LocalStorageKeys } from 'Scripts/globals';
import { getFromLocalStorage, removeFromLocalStorage } from 'Scripts/localStorage';
import React, { useEffect, useState } from 'react';
import { classes } from './style.css';

interface SharedPresentationDetails {
    name: string;
    shareKey: string;
    icon?: string;
}

const ClaimPromotionalPresentationContainer = () => {

    const [ details, setDetails ] = useState<SharedPresentationDetails|null>(null);

    const [ loading, setIsLoading ] = useState<boolean>(false);

    const shareKey = getFromLocalStorage(LocalStorageKeys.ClaimPresentationKey);

    const { translatePlaceholder } = useTranslations();

    const getPromotionalPresentationDetails = async (key: string | null) => {

        try {

            setIsLoading(true);

            const sharedPresentationDetails = await get(`presentation-templates/details/${key}`)

            if(!sharedPresentationDetails.error) {

                setDetails(sharedPresentationDetails);

            } else {

                removeFromLocalStorage(LocalStorageKeys.ClaimPresentationKey);
            }

        } catch (error) {

            console.warn(error);

        } finally {

            setIsLoading(false);
        }
    }

    useEffect(() => {

        if(Boolean(shareKey)) {

            getPromotionalPresentationDetails(shareKey!);
        }

    }, [shareKey])


    return (
        <div
            className={classes.container}
            >
            {loading && <Box pt={4}>
                <CircularProgress className={classes.loader} />
            </Box>}
            {!loading && <>
                {Boolean(details) && <Box pt={4}>
                    <Box
                        display="flex"
                        alignItems="center">

                        <Typography
                            className={classes.text}
                            pr={.5}
                            variant="h2">
                            {translatePlaceholder("READY_TO_UNLOCK")}
                            {Boolean(details?.icon) && <span className={classes.noFontWeight}>
                                {details?.icon}
                            </span>} {details?.name}?
                        </Typography>
                    </Box>
                </Box>}
            </>}
        </div >
    )
};

export default ClaimPromotionalPresentationContainer;