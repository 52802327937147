import { SlideConsumerComponent } from 'Types/appTypes';
import { ContentSlideType } from 'Types/presentationTypes';
import React from 'react';
import VideoSlideSession from '../../session/view/VideoSlideSession';

const VideoSlideEditorPreview = ({
    slide,
}: SlideConsumerComponent<ContentSlideType>) => {

    return (
        <div style={{ pointerEvents: 'none' }}>
            <VideoSlideSession slide={slide} />
        </div>
    )
}

export default VideoSlideEditorPreview;