import { PresentationState } from 'Types/presentationTypes';
import { useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Features, useFeatures } from './useFeatures';


export const useSlideLogo = () => {

    const presentationDefaultLogo = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).presentationDefaultLogo);

    const presentationUserLogo = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).presentationUserLogo);

    const presentationLogo = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).presentationLogo);

    const presentationLogoHidden = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).presentationLogoHidden);

    const { isFeatureAvailable } = useFeatures();

    const hasCustomLogoFeature = isFeatureAvailable(Features.CUSTOM_PRESENTATION_LOGO);

    /**
     * If the user doesn't have the custom logo feature, use the default
     * Otherwise, use the current presentations logo, the users logo or fall back to the default
     */
    const slideLogo = useMemo(() => {

        if(!hasCustomLogoFeature) {

            return presentationDefaultLogo
        }

        return (
            presentationLogo ||
            presentationUserLogo ||
            presentationDefaultLogo
        )

    }, [
        hasCustomLogoFeature,
        presentationDefaultLogo,
        presentationUserLogo,
        presentationLogo,
    ]) as string;

    return {
        slideLogo,
        showLogo: !presentationLogoHidden,
    }
}