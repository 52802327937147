import { SlideConsumerComponent } from 'Types/appTypes';
import { ParticipantMessage } from 'Types/playTypes';
import { MessageRoundType } from 'Types/presentationTypes';
import React from 'react';
import FlipMove from 'react-flip-move';
import OpenEndedSlideMessage from '../item/OpenEndedSlideMessage';
import { classes } from './style.css';


interface OpenEndedSlideMessageListProps extends SlideConsumerComponent<MessageRoundType> {
    messages: Array<ParticipantMessage>;
}

const OpenEndedSlideMessageList = ({
    slide,
    messages,
}: OpenEndedSlideMessageListProps) => {

    return <>
        <FlipMove className={classes.flipMove}>
            {messages.slice(0, slide.nrOfMessageShapes || 3).map(message => (
                <div key={message.id}>
                    <OpenEndedSlideMessage message={message} />
                </div>
            ))}
        </ FlipMove>
    </>;
};


export default OpenEndedSlideMessageList
