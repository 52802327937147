import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { default as ImageOutlinedIcon } from '@mui/icons-material/ImageOutlined';
import { Box, Grow, IconButton, Paper } from '@mui/material';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { IntroGuidedTourStepID } from 'Hooks/useGuidedTour';
import { useLayout } from 'Hooks/useLayout';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType } from 'Scripts/measurementsGAHelper';
import { SlideTypes } from 'Types/slideTypes';
import { Plus } from 'iconoir-react';
import React, { ReactElement } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { classes } from './style.css';

interface FileUploadOptionProps {
	show?: boolean;
	center?: boolean;
	uploadPlaceholder?: ReactElement;
}

const FileUploadOption = ({
	show = false,
	center = false,
	uploadPlaceholder
}: FileUploadOptionProps) => {

	const {
		currentSlide,
		currentSlideKey,
	} = useSlides();

	const {
		setSlideSetting,
	} = useSlideActions();

	const {
		translatePlaceholder,
	} = useTranslations();

	const { setShowImageUploadDialog } = useLayout();


	const removeMedia = () => {

		setSlideSetting('file', {}, currentSlideKey);

		setSlideSetting('filename', null, currentSlideKey);

		setSlideSetting('videoURL', null, currentSlideKey);
	}

	/**
	* When a file is selected, we have access to the files base64 data before it's communicated to the API.
	* After being saved to the API, the slide.file object is emptied and replaced with slide.filename.
	*/
	const slideImage = currentSlide.filename;

	const slideVideo = currentSlide.videoURL;

	/**
	 * If an image is selected and the current slide type isn't a video only slide
	 */
	const hasImageSelected = (
		(Boolean(slideImage) &&
			(currentSlide.type !== SlideTypes.Video))
	);

	/**
	 * If a video is selected and the current slide type isn't an image only slide
	 */
	const hasVideoSelected = (
		(Boolean(slideVideo) &&
			(currentSlide.type !== SlideTypes.Image))
	)

	const hasFileSelected = (hasImageSelected || hasVideoSelected);

	const isOnEditor = (window.location.pathname.includes('editor'));

	return (
		<>
			{isOnEditor && (
				<div
					id={IntroGuidedTourStepID.SlideMedia}
					className={classes.uploadContainer}>
					{hasFileSelected && (
						<div >
							<Grow in={(show)}>
								<Paper
									className={classes.fileSettings} sx={{
										position: center ? 'relative' : 'absolute',
									}}>
									<div>
										<CustomTooltip title={translatePlaceholder("CLICK_TO_ADD_MEDIA")}>
											<IconButton
												data-testid={testIds.FILE_UPLOAD_BUTTON}
												data-eltype={DataElType.Button}
												data-elaction={DataElAction.AddMedia}
												data-elscreen={DataElScreen.Editor}
												data-elposition={DataElPosition.MediaButtonGroup}
												onClick={() => setShowImageUploadDialog(true)}
												className={classes.mediaIcon}>
												<ImageOutlinedIcon />
											</IconButton>
										</CustomTooltip>
										<CustomTooltip title={translatePlaceholder("CLICK_TO_REMOVE_MEDIA")}>
											<IconButton
												data-testid={testIds.FILE_UPLOAD_DELETE_BUTTON}
												data-eltype={DataElType.Button}
												data-elaction={DataElAction.RemoveMedia}
												data-elscreen={DataElScreen.Editor}
												data-elposition={DataElPosition.MediaButtonGroup}
												disabled={!hasFileSelected}
												onClick={() => removeMedia()}
												className={classes.mediaIcon}>
												<DeleteOutlinedIcon />
											</IconButton>
										</CustomTooltip>
									</div>
								</Paper>
							</Grow>
						</div>
					)}
					{!hasFileSelected && (
						<FileUploadBox
							onClick={() => setShowImageUploadDialog(true)}
							uploadPlaceholder={uploadPlaceholder}
						/>
					)}
				</div>
			)}
		</>
	);
};

interface FileUploadBoxProps {
	uploadPlaceholder?: ReactElement;
	[key: string]: any;
}

export const FileUploadBox = ({
	uploadPlaceholder,
	...props
}: FileUploadBoxProps) => {

	const { translatePlaceholder } = useTranslations();

	const {
		presentationStyle,
	} = usePresentationStyles();


	return (
		<Box
			data-testid={testIds.FILE_UPLOAD_BUTTON}
			data-eltype={DataElType.Button}
			data-elaction={DataElAction.AddMedia}
			data-elscreen={DataElScreen.Editor}
			data-elposition={DataElPosition.MediaArea}
			{...props}
			className={classes.fileUploadButton}>
			{uploadPlaceholder || (
				<div className={classes.iconImgBox} style={{
					color: presentationStyle?.textColor,
				}}>
					<div className={classes.addIconWrapper} style={{
						border: `2px solid ${presentationStyle?.textColor}`
					}}>
						<Plus className={classes.addIcon} style={{
							color: presentationStyle?.textColor,
							border: `3px solid ${presentationStyle?.textColor}`,
						}} />
					</div>
					<AppText as={AppTextVariant.H2Regular}
						style={{
							color: presentationStyle?.textColor
						}}>
						{translatePlaceholder("ADD_IMAGE")}
					</AppText>
				</div>
			)}
		</Box>
	)
}

export default FileUploadOption;