import { get, getErrorMessageFromApiResponse, post } from 'Scripts/api';
import { Dimension, Metric } from 'Types/dataConnectorTypes';

/**
 * 10 minus one, since we need an extra row for the headers
 */
const MAX_DATA_CONNECTOR_DATA_ROWS = 9;

export const useDataConnector = () => {

	const getDataConnections = async () => {

		const response = await get('data-connection');

		if (response.error) {

			const message = getErrorMessageFromApiResponse(response.error);

            throw new Error(message);
		}

		return response;
	}

	const getAccountsList = async (credentialsId: string) => {

		const response = await get(`data-connection/accounts-list/${credentialsId}`);

		if (response.error) {

			const message = getErrorMessageFromApiResponse(response.error);

            throw new Error(message);
		}

		return response;
	}

	const getDimensionsAndMetrics = async (referenceId: string, metrics: Array<string>, dimensions: Array<string>) => {

		const params = new URLSearchParams();

		params.set('metrics', metrics.toString());
		params.set('dimensions', dimensions.toString());

		const response = await get(`data-connection/metadata/${referenceId}?${params}`);

		if (response.error) {

			const message = getErrorMessageFromApiResponse(response.error);

			throw new Error(message);
		}

		return response;
	}

	const getReport = async (dimensions: Array<Dimension>, metrics: Array<Metric>, referenceId: string) => {

		const params = new URLSearchParams();

		params.set('dimensions', dimensions.map(d => d.apiName).join(','));
		params.set('metrics', metrics.map(m => m.apiName).join(','));
		params.set('orderBy', '');
		/** 10 - 1 for the headers  */
		params.set('limit', String(MAX_DATA_CONNECTOR_DATA_ROWS));

		const response = await get(`data-connection/report/${referenceId}?${params}`);

		if (response.error) {

            const message = getErrorMessageFromApiResponse(response.error);

            throw new Error(message);
		}

		return response;
	}

	const addReportMetadata = async (dimensions: Array<Dimension>, metrics: Array<Metric>, slideId: number, referenceId: string) => {

		const response = await post('data-connection/report-metadata', {
			dimensions: dimensions.map(d => d.apiName).join(','),
			metrics: metrics.map(m => m.apiName).join(','),
			slideId,
			referenceId,
		});

		if (response.error) {

			const message = getErrorMessageFromApiResponse(response.error);

			throw new Error(message);
		}
	}

	const getReportMetadata = async (slideId: number) => {

		const params = new URLSearchParams();

		params.set('slideId', String(slideId));

		const response = await get(`data-connection/report-metadata?${params}`);

		if (response?.error) {

			const message = getErrorMessageFromApiResponse(response.error);

			throw new Error(message);
		}

		return response;
	}

	return {
		getAccountsList,
		getReport,
		getReportMetadata,
		getDataConnections,
		getDimensionsAndMetrics,
		addReportMetadata,
	}
}