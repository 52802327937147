import { Box } from '@mui/material';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import Html from 'Components/common/util/html/Html';
import { getSlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import React from 'react';
import { SlideConsumerComponent } from 'Types/appTypes';
import { MessageRoundType } from 'Types/presentationTypes';

const QASlideEditorPreview = ({
    slide
}: SlideConsumerComponent<MessageRoundType>) => {

    const slideProperties = getSlideProperties(slide);

    return (
        <LayoutWrapper
            interactive={false}
            imageStyle={slide.filenameStyle}
            backgroundOpacity={slide.backgroundOpacity}
            backgroundColor={slide.backgroundColor}
            layoutType={slide.slideLayout}
            title={(
                <FullWidthContainer>
                    <Box p={7}>
                        <Html
                            content={slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                            fontSize={slideProperties.slideSettings.titleFontSize}
                        />
                    </Box>
                </FullWidthContainer>
            )}
            imageURL={slide?.file?.base64 || slide?.filename}
            videoURL={slide?.videoURL}
        />
    );
};

export default QASlideEditorPreview;