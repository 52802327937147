import { AnyAction } from "redux";
import { ChartTypes, ResultState, TabItems } from "Types/resultTypes";

const RESULT_PAGE_SIZE = 10;

export const initialResultState: ResultState = {
    result: null,
    resultLoading: false,
    resultSummary: null,
    selectedResultTab: TabItems.FILTER_BY_ANSWER,
    resultSummaryLoading: false,
    presentationResultIdToRemove: null,
    presentationResultDeleting: false,
    selectedResultId: null,
    resultsAsPercentage: false,
    preferredChartType: ChartTypes.PIE_CHART,
    showResultsAsWordcloud: false,
    resultDownloading: false,
    showUpgradeNeededForResults: false,
    resultPageData: {
        totalResultsCount: 0,
        filteredResultsCount: 0,
        pageResultsCount: 0,
        pageIndex: 0,
        pageData: [],
        pageSize: RESULT_PAGE_SIZE,
        pageLoading: false,
        sortProperty: {
            id: 'rank',
            desc: false,
        }
    },
    individualResultsPageData: {
        tableHeaders: [],
        totalResultsCount: 0,
        filteredResultsCount: 0,
        pageResultsCount: 0,
        pageIndex: 0,
        pageData: [],
        pageSize: RESULT_PAGE_SIZE,
        pageLoading: false,
        sortProperty: {
            id: 'id',
            desc: true,
        }
    },
    sessionResults: [],
    sessionResultsPageData: {
        totalResultsCount: 0,
        filteredResultsCount: 0,
        pageResultsCount: 0,
        pageIndex: 0,
        pageData: [],
        pageSize: RESULT_PAGE_SIZE,
        pageLoading: false,
        sortProperty: {
            id: 'startTime',
            desc: true,
        }
    },
    sessionRunIdToDelete: null,
    sessionResultPresentationTitle: null,
}

export const resultReducer = (state: ResultState = initialResultState, action: AnyAction) => {
	switch (action.type) {
        case 'SET_SHOW_RESULTS_AS_WORDCLOUD': {
            return {
                ...state,
                showResultsAsWordcloud: action.show,
            }
        }
        case 'SET_SHOW_UPGRADE_NEEDED_FOR_RESULTS': {
            return {
                ...state,
                showUpgradeNeededForResults: action.show,
            }
        }
        case 'SET_INDIVIDUAL_RESULTS_PAGE_LOADING': {
            return {
                ...state,
                individualResultsPageData: {
                    ...state.individualResultsPageData,
                    pageLoading: action.loading,
                }
            }
        }
        case 'SET_INDIVIDUAL_RESULTS_PAGE_SIZE': {
            return {
                ...state,
                individualResultsPageData: {
                    ...state.individualResultsPageData,
                    pageSize: action.pageSize,
                    pageIndex: 0,
                }
            }
        }
        case 'SET_INDIVIDUAL_RESULTS_SORT_PROPERTY': {

            return {
                ...state,
                individualResultsPageData: {
                    ...state.individualResultsPageData,
                    sortProperty: action.property,
                }
            }
        }
        case 'CLEAR_INDIVIDUAL_RESULTS_PAGE_DATA': {
            return {
                ...state,
                individualResultsPageData: {
                    ...initialResultState.individualResultsPageData
                },
            }
        }
        case 'SET_INDIVIDUAL_RESULTS_PAGE_DATA': {

            return {
                ...state,
                individualResultsPageData: {
                    ...state.individualResultsPageData,
                    ...action.data
                }
            }
        }
        case 'SET_INDIVIDUAL_RESULTS_PAGE_INDEX': {

            return {
                ...state,
                individualResultsPageData: {
                    ...state.individualResultsPageData,
                    pageIndex: action.index,
                }
            }
        }


        case 'SET_RESULT_PAGE_LOADING': {
            return {
                ...state,
                resultPageData: {
                    ...state.resultPageData,
                    pageLoading: action.loading,
                }
            }
        }
        case 'SET_RESULT_SORT_PROPERTY': {

            return {
                ...state,
                resultPageData: {
                    ...state.resultPageData,
                    sortProperty: action.property,
                }
            }
        }
        case 'SET_RESULT_PAGE_SIZE': {
            return {
                ...state,
                resultPageData: {
                    ...state.resultPageData,
                    pageSize: action.pageSize,
                    pageIndex: 0,
                }
            }
        }
        case 'CLEAR_RESULT_CHART_DATA': {

            return {
                ...state,
                result: null,
                selectedResultId: null,
            }
        }
        case 'CLEAR_RESULT_QUIZ_DATA': {

            return {
                ...state,
                resultPageData: {
                    ...initialResultState.resultPageData
                },
            }
        }
        case 'CLEAR_RESULTS_PAGE_DATA': {
            return {
                ...state,
                individualResultsPageData: {
                    ...initialResultState.individualResultsPageData
                },
            }
        }
        case 'SET_RESULT_PAGE_DATA': {

            return {
                ...state,
                resultPageData: {
                    ...state.resultPageData,
                    ...action.data
                }
            }
        }
        case 'SET_RESULT_PAGE_INDEX': {

            return {
                ...state,
                resultPageData: {
                    ...state.resultPageData,
                    pageIndex: action.index,
                }
            }
        }
        case 'SET_RESULTS_DOWNLOADING': {
            return {
                ...state,
                resultDownloading: action.downloading,
            }
        }
        case 'SET_SELECTED_RESULT_TAB': {

            return {
                ...state,
                selectedResultTab: action.tab,
            }
        }
        case 'SET_SELECTED_RESULT_ITEM': {
            return {
                ...state,
                selectedResultId: action.id
            }
        }
        case 'SET_PREFERRED_CHART_TYPE': {
            return {
                ...state,
                preferredChartType: action.chartType
            }
        }

        case 'SET_RESULTS_AS_PERCENTAGE': {

            return {
                ...state,
                resultsAsPercentage: action.isPercentage
            }
        }
        case 'SET_RESULT': {

            const [ firstResult ] = (action.result || [{}]);

            return {
                ...state,
                result: action.result,
                selectedResultId: firstResult?.id || null,
            }
        }
        case 'SET_RESULT_LOADING': {

            return {
                ...state,
                resultLoading: action.loading
            }
        }
        case 'SET_RESULT_SUMMARY_LOADING': {

            return {
                ...state,
                resultSummaryLoading: action.loading
            }
        }
        case 'SET_RESULT_SUMMARY': {

            return {
                ...state,
                resultSummary: { ...action.summary }
            }
        }
        case 'SET_PRESENTATION_RESULT_ID_TO_REMOVE': {

            return {
                ...state,
                presentationResultIdToRemove: action.id,
            }
        }
        case 'SET_PRESENTATION_RESULT_DELETING': {
            return {
                ...state,
                presentationResultDeleting: action.deleting,
            }
        }
        case 'SET_SESSIONRUN_RESULT_TABLE_PAGE_INDEX': {

            return {
                ...state,
                sessionResultsPageData: {
                    ...state.sessionResultsPageData,
                    pageIndex: action.index,
                }
            }

        }
        case 'SET_SESSIONRUN_RESULT_TABLE_SORT_PROPERTY': {

            return {
                ...state,
                sessionResultsPageData: {
                    ...state.sessionResultsPageData,
                    sortProperty: action.property,
                }
            }

        }
        case 'SET_SESSIONRUN_RESULT_TABLE_PAGE_SIZE': {

            return {
                ...state,
                sessionResultsPageData: {
                    ...state.sessionResultsPageData,
                    pageSize: action.size,
                    pageIndex: 0,
                }
            }
        }
        case 'SET_SESSIONRUNS_TABLE_LOADING': {
            return {
                ...state,
                sessionResultsPageData: {
                    ...state.sessionResultsPageData,
                    pageLoading: action.loading,
                }
            }
        }
        case 'SET_SESSIONRUNS_TABLE_PAGE_DATA': {
            return {
                ...state,
                sessionResultsPageData: {
                    ...state.sessionResultsPageData,
                    ...action.data,
                }
            }
        }
        case 'SET_SESSIONRUN_ID_TO_DELETE': {

            return {
                ...state,
                sessionRunIdToDelete: action.id,
            }
        }
        case 'SET_SESSIONRUN_PRESENTATION_TITLE': {
            return {
                ...state,
                sessionResultPresentationTitle: action.title,
            }
        }
		default:
			return state
	}
}
