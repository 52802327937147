import { Table, TableBody, TableCell, TableRow, alpha, useTheme } from '@mui/material';
import FullSizeContainer from 'Atomic/atoms/FullSizeContainer';
import AppText from 'Atomic/atoms/Text/AppText';
import { TableCellData } from 'Atomic/organisms/AppDynamicTable/AppDynamicTable';
import React, { CSSProperties, Fragment, useEffect, useRef } from 'react';
import { TableCellDataType } from '../AppDynamicTable';
import { classes } from './style.css';


interface AppDynamicTablePreviewProps {
	data: Array<Array<TableCellData>>;
	cellStyles: CSSProperties;
}

const AppDynamicTablePreview = ({
	data = [],
	cellStyles = {},
}: AppDynamicTablePreviewProps) => {

	const getCellTypeStyling = (cellType: TableCellDataType) => {

		if (cellType === TableCellDataType.Heading) {

			return {
				fontWeight: 700,
				backgroundColor: alpha('#000', .05),
			}
		}

		return {}
	}


	const theme = useTheme();

	return (
		<Table className={classes.table}>
			<TableBody className={classes.tableBody}>
                {(data || []).map((row, rowIndex) => (
					<TableRow key={rowIndex} >
						{row.map((cell, columnIndex) => (
							<Fragment key={cell.id}>
								<TableCell
									data-cell-column={columnIndex}
									data-cell-row={rowIndex}
									className={classes.tableCell}
									style={{
										...getCellTypeStyling(cell.cellType),
										border: `1px solid ${cellStyles.borderColor || `#C1C7D0`}`,
										color: `${cellStyles.color || theme.palette.common.black}`
									}}
								>
									<FullSizeContainer>
										<TableCellContents cell={cell} />
									</FullSizeContainer>
								</TableCell>
							</Fragment>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
	)
}

interface TableCellContents {
	cell: TableCellData;
}

const TableCellContents = ({
	cell
}: TableCellContents) => {

	const cellInputRef = useRef<HTMLTextAreaElement>(null);

	const cellPadding = 24;

	const handleCellSize = () => {

		if (!Boolean(cellInputRef.current)) {

			return;
		}

		const textArea = cellInputRef.current as HTMLTextAreaElement;
		/** Set height to auto to automatically expand element vertically based on its contents */
		textArea.style.height = 'auto';
		/** Extract the height based on the elements scrollheight and apply as height value */
		textArea.style.height = (textArea.scrollHeight - (cellPadding * 2)) + 'px';
	}

	useEffect(() => {

		handleCellSize();

	}, [
		cell.jss,
		cell.content,
	])


	return (
		<AppText
			ref={cellInputRef}
			className={classes.tableCellContents}
			sx={{
				padding: cellPadding,
			}}
			style={JSON.parse(cell.jss)}
		>
			{cell.content}
		</AppText>
	)
}

export default AppDynamicTablePreview;