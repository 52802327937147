import { AnyAction } from "redux";
import { AiContentCreatorStep, AIState } from "Types/aiTypes";
import { AISupportedTypes } from '../__types/aiTypes';

export const initialAIState: AIState = {
    aiStepper: {
        initialPrompt: '',
        language: null,
        usage: null,
        title: null,
        titleSuggestions: [],
        useOwnTitle: false,
        ownTitle: '',
        toneOfVoice: null,
        length: null,
        type: AISupportedTypes.Presentation,
        withInteractiveQuestions: false,
    },
    aiGuidedTourRunning: false,
    aiGuidedTourStep: 0,

    aiContentCreatorKeywords: '',
    aiContentCreatorStep: AiContentCreatorStep.AddKeywords,
}

export enum AIActionTypes {
    SetAIInitialPrompt                  = 'SET_AI_PRESENTATION_INITIAL_PROMPT',
    SetAIPresentationLanguage           = 'SET_AI_PRESENTATION_LANGUAGE',
    SetAIPresentationLength             = 'SET_AI_PRESENTATION_LENGTH',
    SetAIPresentationType               = 'SET_AI_PRESENTATION_TYPE',
    SetAIPresentationToneOfVoice        = 'SET_AI_PRESENTATION_TONE_OF_VOICE',
    SetAIPresentationUsage              = 'SET_AI_PRESENTATION_USAGE',
    SetEnableInteractiveQuestions       = 'SET_AI_PRESENTATION_ENABLE_INTERACTION',
    SetAIPresentationTitleSuggestions   = 'SET_AI_PRESENTATION_TITLE_SUGGESTIONS',
    AddAIPresentationTitleSuggestions   = 'ADD_AI_PRESENTATION_TITLE_SUGGESTIONS',
    SetAIPresentationTitle              = 'SET_AI_PRESENTATION_TITLE',
    SetAIUseOwnTitle                    = 'SET_AI_USE_OWN_TITLE',
    SetAIGuidedTourRunning              = 'SET_AI_GUIDED_TOUR_RUNNING',
    SetAIGuidedTourStep                 = 'SET_AI_GUIDED_TOUR_STEP',
    SetAIContentCreatorKeywords         = 'SET_AI_CONTENT_CREATOR_KEYWORDS',
    SetAiContentCreatorStep             = 'SET_AI_CONTENT_CREATOR_STEP',
}

export const aiReducer = (state: AIState = initialAIState, { type, payload }: AnyAction) => {
    switch (type) {
        case AIActionTypes.SetEnableInteractiveQuestions: {

            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    withInteractiveQuestions: payload,
                }
            }
        }
        case AIActionTypes.SetAIPresentationToneOfVoice: {

            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    toneOfVoice: payload,
                }
            }
        }
        case AIActionTypes.SetAIContentCreatorKeywords: {

            return {
                ...state,
                aiContentCreatorKeywords: payload,
            }
        }
        case AIActionTypes.SetAiContentCreatorStep: {

            return {
                ...state,
                aiContentCreatorStep: payload,
            }
        }
        case AIActionTypes.SetAIGuidedTourRunning: {

            return {
                ...state,
                aiGuidedTourRunning: payload,
            }
        }
        case AIActionTypes.SetAIGuidedTourStep: {

            return {
                ...state,
                aiGuidedTourStep: payload,
            }
        }
        case AIActionTypes.SetAIUseOwnTitle: {

            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    useOwnTitle: payload,
                }
            }
        }
        case AIActionTypes.AddAIPresentationTitleSuggestions: {

            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    titleSuggestions: [
                        ...state.aiStepper.titleSuggestions,
                        ...payload
                    ],
                }
            }
        }
        case AIActionTypes.SetAIPresentationTitle: {

            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    title: payload,
                }
            }
        }
        case AIActionTypes.SetAIPresentationTitleSuggestions: {

            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    titleSuggestions: payload,
                }
            }
        }
        case AIActionTypes.SetAIInitialPrompt: {
            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    initialPrompt: payload,
                }
            }
        }
        case AIActionTypes.SetAIPresentationLanguage: {
            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    language: payload,
                }
            }
        }
        case AIActionTypes.SetAIPresentationLength: {
            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    length: payload,
                }
            }
        }
        case AIActionTypes.SetAIPresentationType: {
            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    type: payload,
                }
            }
        }
        case AIActionTypes.SetAIPresentationUsage: {
            return {
                ...state,
                aiStepper: {
                    ...state.aiStepper,
                    usage: payload,
                }
            }
        }
        default:
            return state
    }
}
