import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import Loader from 'Components/common/util/loader/Loader';
import { useTranslations } from 'Hooks/useTranslations';
import { handleBorderRadius } from 'Scripts/googleChartsHelper';
import { useWindowSize } from 'Scripts/screenSizeHook';
import { GoogleCharts } from 'google-charts';
import React, { DetailedHTMLProps, useEffect, useRef } from 'react';
import { classes } from './style.css';


export enum AppChartType {
    Bar = 'Bar',
    Line = 'Line',
    Column = 'Column',
    Pie = 'Pie',
}
export interface AppChartData {
    columns: Array<string>;
    data: Array<Array<any>>;
}
export interface AppChartProps {
    chartType: AppChartType;
    chartData: AppChartData;
    chartOptions?: any;
    chartElementProps?: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    loading?: boolean;
}

const chartColors = ['#4A3AFF', '#FFC107', '#C893FD', '#2DAD83', '#7045AF', '#5A82FA'];

const AppChart = ({
    chartType,
    chartData,
    chartOptions,
    chartElementProps,
    loading = false,
}: AppChartProps) => {

    const chartsRef = useRef<HTMLDivElement>(null);

    const { translatePlaceholder } = useTranslations();

    const { width, height } = useWindowSize();

    const chartIsEmpty = !chartData?.data?.length || !chartData?.columns?.length;

    const formatChartData = (data: AppChartData) => {

        const newData = { ...data };

        if (!newData?.data?.length || !newData?.columns?.length) {
            /** Placeholder data to show empty chart */
            return {
                columns: ['', ''],
                data: [['', 0]],
            }
        }

        return newData;
    }

    const drawColumnChart = () => {

        if (chartIsEmpty) {

            console.warn('No data to draw column chart:', { chartData });
        }

        chartData = formatChartData(chartData);

        const data = GoogleCharts.api.visualization.arrayToDataTable([
            [...chartData.columns],
            ...chartData.data.map((item, index) => [...item]),
        ]);

        const options = {
            legend: {
                position: "top",
                maxLines: 0,
            },
            backgroundColor: 'transparent',

            colors: chartColors,
            vAxis: {
                viewWindow: {
                    min: 0,
                    max: Number(chartIsEmpty) || undefined,
                },
                viewWindowMode: "explicit",
                minValue: 0,
                format: 'short',
                ticks: chartData.data.length || [0, 1, 2],
                gridlineColor: '#E5E5EF',
                gridLines: {
                    count: 10,
                },
                textStyle: {
                    color: '#FF0000', // Example: Red color for hAxis labels
                    // Additional font properties can be set here
                },
            },
            focusTarget: 'datum',
            hAxis: {
                ticks: chartData.data.map(([label], index) => ({
                    v: index,
                    f: label,
                })),
                gridlineColor: '#E5E5EF',
                gridLines: {
                    count: 10,
                },
                textStyle: {
                    color: '#FF0000', // Example: Red color for hAxis labels
                    // Additional font properties can be set here
                },
            },
            tooltip: {
                isHtml: true,
            },
            chartArea: {
                width: '90%',
                height: '75%',
            },
        }

        const columnChart = new GoogleCharts.api.visualization.ColumnChart(chartsRef.current);

        handleBorderRadius(columnChart, chartsRef.current as HTMLElement, 8);

        columnChart.draw(data, { ...options, ...chartOptions });
    }

    const drawLineChart = () => {

        if (chartIsEmpty) {

            console.warn('No data to draw line chart:', { chartData });
        }

        chartData = formatChartData(chartData);

        const data = new GoogleCharts.api.visualization.arrayToDataTable([
            chartData.columns,
            ...chartData.data,
        ]);

        const options = {
            hAxis: {
                gridlineColor: '#E5E5EF',
                gridlines: {
                    count: chartData.data.length,
                },
                textStyle: {
                    bold: true,
                    fontSize: 16,
                },
                ticks: chartData.data.map(([label], index) => ({
                    v: index + 1,
                    f: label
                })),
            },
            vAxis: {
                viewWindow: {
                    min: 0,
                    max: Number(chartIsEmpty) || undefined,
                },
                minValue: 0,
                format: 'short',
                gridlineColor: '#E5E5EF',
                gridlines: {
                    count: 5
                }
            },
            pointSize: 6,
            lineWidth: 4,
            series: {
                0: {
                    color: '#0B84A5'
                },
                1: {
                    color: '#F6C85F'
                },
                2: {
                    color: '#6F4E7C'
                },
                3: {
                    color: '#9DD866'
                },
            },
            tooltip: {
                isHtml: true,
            },
            chartArea: {
                width: '90%',
                height: '80%',
            },
            legend: {
                position: "top",
                maxLines: 0,
                textStyle: {
                    bold: true,
                    fontSize: 16,
                }
            },
        }

        const lineChart = new GoogleCharts.api.visualization.LineChart(chartsRef.current);

        lineChart.draw(data, { ...options, ...chartOptions });
    }

    const drawBarChart = () => {

        if (chartIsEmpty) {

            console.warn('No data to draw bar chart:', { chartData });
        }

        chartData = formatChartData(chartData);

        const data = new GoogleCharts.api.visualization.arrayToDataTable([
            [...chartData.columns, { type: 'string', role: 'annotation' } ],
            ...chartData.data.map((data, index) => ([
                ...data,
                data[data.length - 1],
            ])),
        ]);

        const options = {
            legend: {
                position: 'none',
            },
            colors: chartColors,
            bar: {
                groupWidth: 20
            },
            hAxis: {
                gridlineColor: '#E5E5EF',
                gridLines: {
                    count: 10,
                },
            },
            annotations: {
                alwaysOutside: true,
            },
            tooltip: {
                isHtml: true,
            },
            chartArea: {
                width: '65%', /* was 75, but needs more space for "title and subtitle" */
                height: '120%', /* was 75, but needs more spacing */
            },
        }

        const lineChart = new GoogleCharts.api.visualization.BarChart(chartsRef.current);

        handleBorderRadius(lineChart, chartsRef.current as HTMLElement, 4);

        lineChart.draw(data, { ...options, ...chartOptions });
    }

    const drawPieChart = () => {

        if (chartIsEmpty) {

            console.warn('No data to draw pie chart:', { chartData });
        }

        chartData = formatChartData(chartData);

        const data = new GoogleCharts.api.visualization.arrayToDataTable([
            chartData.columns,
            ...chartData.data,
        ]);

        const options = {
            pointSize: 6,
            lineWidth: 4,
            pieSliceBorderColor: "transparent",
            colors: chartColors,
            tooltip: {
                isHtml: true,
            },
            chartArea: {
                width: '100%',
                height: '90%',
            },
            legend: {
                position: 'right',
                alignment: 'center',
                textStyle: {
                    bold: true,
                }
            },
        }

        const lineChart = new GoogleCharts.api.visualization.PieChart(chartsRef.current);

        lineChart.draw(data, { ...options, ...chartOptions });
    }

    const attemptDrawChart = () => {

        switch (chartType) {
            case AppChartType.Bar: {
                GoogleCharts.load(drawBarChart);
                break;
            }
            case AppChartType.Column: {
                GoogleCharts.load(drawColumnChart);
                break;
            }
            case AppChartType.Line: {
                GoogleCharts.load(drawLineChart);
                break;
            }
            case AppChartType.Pie: {
                GoogleCharts.load(drawPieChart);
                break;
            }
            default: {
                console.warn({
                    message: `Chart type "${chartType}" not supported`,
                })
            }
        }
    }

    useEffect(() => {

        const resizeObserver = new ResizeObserver(() => {

            attemptDrawChart();
        });

        if (Boolean(chartsRef.current)) {

            resizeObserver.observe(chartsRef.current as HTMLDivElement);

            attemptDrawChart();
        }

        return () => {

            if (Boolean(chartsRef.current)) {

                resizeObserver.unobserve(chartsRef.current as HTMLDivElement);
            }
        }

    }, [
        (chartData.columns || []).length,
        (chartData.data || []).length,
        chartData,
        chartType,
        chartsRef.current,
        width,
        height,
    ])

    return (
        <div className={classes.chartContainer}>
            <div
                {...chartElementProps}
                ref={chartsRef}
            />
            {(loading || chartIsEmpty) && (
                <div className={classes.overlay}>
                    {(chartIsEmpty && !loading) && (
                        <AppText
                            as={AppTextVariant.BodyBold}
                            mx={2}>
                            {translatePlaceholder("NO_DATA")}
                        </AppText>
                    )}
                    {(loading) && (
                        <Loader />
                    )}
                </div>
            )}
        </div>
    )
}

export default AppChart;