import * as appActions from 'Actions/appActions';
import * as presentationTemplateActions from 'Actions/presentationTemplateActions';
import { SortObject, TablePaginationData } from 'Components/common/table/AppTable';
import { DEFAULT_ITEMS_PER_PAGE_COUNT } from 'Reducers/overviewReducer';
import { get, post } from 'Scripts/api';
import { PresentationTemplate } from 'Types/presentationTypes';
import { PresentationCategoryData } from 'Types/sharedSlideTypes';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useUrl from './useUrl';

export const usePresentationTemplates = () => {

    const { enqueueSnackbar }  = useSnackbar();

    const dispatch = useDispatch();

    const urls = useUrl();

    const history = useHistory();

    const setPresentationIdToShare = (id: number | null) => {

        dispatch(appActions.setPresentationIdToShare(id));
    }

    const setTemplatesLoading = (loading: boolean) => {

        dispatch(presentationTemplateActions.setTemplatesLoading(loading));
    }

    const setTemplatesPageIndex = (pageIndex: number) => {

        dispatch(presentationTemplateActions.setTemplatesPageIndex(pageIndex));
    }

    const setTemplatesFilterCategory = (category: PresentationCategoryData | null) => {

        dispatch(presentationTemplateActions.setTemplatesFilterCategory(category));
    }

    const setTemplatesSortProperty = (sortProperty: SortObject) => {

        dispatch(presentationTemplateActions.setTemplatesSortProperty(sortProperty));
    }

    const setTemplatesPageData = (data: TablePaginationData) => {

        dispatch(presentationTemplateActions.setTemplatesPageData(data));
    }

    const setTemplateToPreview = (template: PresentationTemplate | null) => {

        dispatch(presentationTemplateActions.setTemplateToPreview(template));
    }

    const setTemplateCategories = (categories: Array<PresentationCategoryData>) => {

        dispatch(presentationTemplateActions.setTemplateCategories(categories));
    }

    const attemptClaimPresentation = async (shareKey: string) => {

		try {

			const claimedPresentation = await post('presentation-templates/claim', {
				shareKey,
			})

			if(!claimedPresentation.error) {

				enqueueSnackbar(`"${claimedPresentation.name}" has been added to your library!`, { variant: 'success'});

				history.push(urls.editPresentation(claimedPresentation.id));
			}

		} catch (error) {

			console.warn(error)
		}
	}


    const fetchPresentationTemplates = async (paginationParams: URLSearchParams) => {

        try {

            setTemplatesLoading(true);

            if(!paginationParams.get('sortDesc')) {

                paginationParams.set('sortDesc', `1`);
            }

            if (!paginationParams.get('sortBy')) {

                paginationParams.set('sortBy', `id`);
            }

            if (!paginationParams.get('pageSize')) {

                paginationParams.set('pageSize', `${DEFAULT_ITEMS_PER_PAGE_COUNT}`);
            }

            if (!paginationParams.get('pageIndex')) {

                paginationParams.set('pageIndex', `0`);
            }
            const data = await get(`presentation-templates/shared?${paginationParams}`);

            if (!data.error) {

                setTemplatesPageData(data);

            } else {

                console.warn(data.error);

                enqueueSnackbar('Something went wrong trying to retrieve the templates.', { variant: 'error' });
            }

            return data;

        } catch (error) {

            console.warn({
                error
            });

        } finally {

            setTemplatesLoading(false);
        }
    }

    return {
        fetchPresentationTemplates,
        attemptClaimPresentation,
        setPresentationIdToShare,
        setTemplatesLoading,
        setTemplatesPageIndex,
        setTemplatesSortProperty,
        setTemplatesPageData,
        setTemplateToPreview,
        setTemplatesFilterCategory,
        setTemplateCategories,
    }
}