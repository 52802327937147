import React from 'react';
import GeometryScorePanelItem from './score_item/GeometryScorePanelItem';


interface ScorePanelProps {
	scores: Array<{
		points: number;
		participantId?: number;
		lastReceivedPoints: number;
		name: string;
	}>;
	animate?: boolean;
}

const ScorePanel = ({ scores, animate = true }: ScorePanelProps) => {

	return (
		<>
			{scores.map((score, index) => (
				<GeometryScorePanelItem
					key={index}
					index={index}
					score={score}
					animate={animate}
				/>
			))}
		</>
	);
};

export default ScorePanel;