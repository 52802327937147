import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import WysiwygEditorContainer from 'Atomic/organisms/WysiwygEditor/variants/WysiwygEditorContainer';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import { WysiwygToolBarType } from 'Components/common/util/wysiwygEditor/WysiwygEditor';
import { getSlidePropertiesByType } from 'Components/types/helpers/slideTypeHelper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import useSlideActions from 'Hooks/useSlideActions';
import { useTranslations } from 'Hooks/useTranslations';
import { getMUISafeAlphaColor } from 'Scripts/colorHelper';
import { config } from 'Scripts/config';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { LayoutTypes } from 'Types/layoutTypes';
import { OptionPlaceholder, VoteOption, VoteType } from 'Types/presentationTypes';
import React, { KeyboardEvent } from 'react';
import { classes } from './style.css';

interface SlideOptionEditorProps {
    slide: VoteType;
    option: VoteOption;
    index: number;
}

const SlideOptionEditor = ({ slide, option = {}, index }: SlideOptionEditorProps) => {

    const { translatePlaceholder } = useTranslations();

    const isCorrect = Boolean(option?.correctAnswer);

    const slideProperties = getSlidePropertiesByType(slide.type);

    const {
        removeOption,
        updateSlideOption,
    } = useSlideActions();

    const {
        presentationStyle,
    } = usePresentationStyles();


    const deleteOption = () => {

        removeOption(index, slide.id);
    }

    const handleKeyDownEvent = (e: KeyboardEvent<any>) => {

        if (e.key === 'Tab' && !e.shiftKey) {

            if (slide.options.length - 1 === index) {

                if (slide.options.length < config.maxAllowedOptions) {

                    e.preventDefault();

                    updateSlideOption(index + 1, '', false, slide.id);
                }
            }
        }
    }

    const placeholderOptionText = OptionPlaceholder.OptionText.replace(OptionPlaceholder.IndexText, config.optionKeys[index]);

    return <>
        <Grid
            className={classes.option}
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                background: slide.slideLayout === LayoutTypes.ImageOnBackground ? getMUISafeAlphaColor(presentationStyle?.lightColor, .75) : presentationStyle?.lightColor,
                '&:hover .MuiCheckbox-root': {
                    opacity: 1
                }
            }}
            xs={12}>
            <Box className={classes.optionContainer} sx={{
                '&:hover .MuiIconButton-root': {
                    opacity: 1,
                }
            }}>
                <div>
                    <CustomTooltip title={`${isCorrect ? translatePlaceholder("CORRECT_ANSWER") : translatePlaceholder("INCORRECT_ANSWER")} (${translatePlaceholder("CLICK_TO_CHANGE")})`}>
                        <Checkbox
                            size="large"
                            classes={{
                                root: `${classes.isCorrectCheckbox} ${isCorrect && classes.checked}`,
                            }}
                            sx={{
                                color: presentationStyle?.textColor,
                            }}
                            tabIndex={index + config.maxAllowedOptions}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            checked={Boolean(option.correctAnswer)}
                            data-testid={`${testIds.EDITOR_SLIDE_OPTION_CORRECT}-${index}`}
                            onChange={() => updateSlideOption(index, (option?.answer || ''), !isCorrect, slide.id)}
                            {...renderingGAAttr(`${DataElAction.SelectOpt}_${Boolean(isCorrect)}`, `${index}`, DataElScreen.Editor, DataElType.Checkbox)}
                        />
                    </CustomTooltip>
                </div>
                <div>
                    <Typography className={classes.optionKey} style={{ color: presentationStyle?.textColor }}>
                        {config.optionKeys[index]}
                    </Typography>
                </div>
                <div
                    className={classes.optionInputContainer}
                    {...renderingGAAttr(DataElAction.UpdateOption, `${index}`, DataElScreen.Editor, DataElType.Input)}
                >
                    <WysiwygEditorContainer
                        id={`${testIds.EDITOR_SLIDE_OPTION_TEXT}-${index}`}
                        data-testid={`${testIds.EDITOR_SLIDE_OPTION_TEXT}-${index}`}
                        value={option.answer || placeholderOptionText}
                        updateValue={newValue => updateSlideOption(index, newValue.slice(0, config.maxStringLength + 1), Boolean(option.correctAnswer), slide.id)}
                        placeholderText={OptionPlaceholder.OptionText.replace(OptionPlaceholder.IndexText, config.optionKeys[index])}
                        handleKeyDown={handleKeyDownEvent}
                        toolbarType={WysiwygToolBarType.Extended}
                        getPlaceholderHtml={(placeholder: string) => `${placeholder}`}
                        fontSize={slideProperties.slideSettings?.optionFontSize as number}
                    />
                </div>
                <Box pr={4}>
                    <CustomTooltip title={translatePlaceholder("DELETE_OPTION")}>
                        <IconButton
                            data-testid={testIds.EDITOR_SLIDE_OPTION_DELETE}
                            className={classes.deleteIcon}
                            tabIndex={index + (config.maxAllowedOptions * 2)}
                            onMouseDown={() => deleteOption()}
                            size="large"
                            sx={{
                                color: presentationStyle?.textColor,
                            }}
                            {...renderingGAAttr(DataElAction.DeleteOpt, `${index}`, DataElScreen.Editor, DataElType.Button)}
                        >
                            <DeleteIcon fontSize="large" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
            </Box>
        </Grid >

    </>;
};

export default SlideOptionEditor