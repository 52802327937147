import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox, Grid, InputBase, Typography } from '@mui/material';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { config } from 'Scripts/config';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { OptionPlaceholder } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';

interface EmptySlideOptionEditorProps {
    index: number;
    optionsAmount?: number;
}

const EmptySlideOptionEditor = ({
    index,
}: EmptySlideOptionEditorProps) => {

    const {
        currentSlide,
        currentSlideKey,
    } = useSlides();

    const {
        setSlideOption,
    } = useSlideActions();

    const {
        presentationStyle
    } = usePresentationStyles();


    const slideOptions = (currentSlide?.options || []);

    const clickedNewOption = () => {

        if (slideOptions.length <= config.maxAllowedOptions) {

            setSlideOption(
                slideOptions.length,
                OptionPlaceholder.OptionText.replace(OptionPlaceholder.IndexText, config.optionKeys[index]),
                false,
                currentSlideKey,
            );
        }
    }

    return <>
        <Grid
            key={index}
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
            style={{
                background: presentationStyle?.lightColor,
            }}
            className={classes.optionContainer}
            onClick={() => clickedNewOption()}
        >
            <div>
                <Checkbox
                    classes={{
                        root: classes.isCorrectCheckbox
                    }}
                    inputProps={{
                        style: {
                            color: presentationStyle?.textColor,
                        }
                    }}
                    disabled
                    icon={<RadioButtonUncheckedIcon />}
                    checked={false}
                />
            </div>
            <div>
                <Typography className={classes.optionKey} style={{ color: presentationStyle?.textColor }}>
                    {config.optionKeys[slideOptions.length]}
                </Typography>
            </div>
            <div className={classes.optionInputContainer} >
                <InputBase
                    data-testid={`${testIds.EDITOR_SLIDE_OPTION_TEXT}-${index || slideOptions.length}`}
                    disabled
                    {...renderingGAAttr(DataElAction.AddOption, `${index}`, DataElScreen.Editor, DataElType.Input)}
                    placeholder={OptionPlaceholder.OptionText.replace(OptionPlaceholder.IndexText, config.optionKeys[slideOptions.length])}
                    className={classes.optionField}
                    fullWidth={true}
                    multiline
                    inputProps={{
                        className: classes.optionInput,
                        style: {
                            WebkitTextFillColor: presentationStyle?.textColor,
                            color: presentationStyle?.textColor,
                        }
                    }}
                />
            </div>
        </Grid>
    </>;
};


export default EmptySlideOptionEditor;