import { Divider } from '@mui/material';
import FeatureWrapper from 'Atomic/atoms/FeatureWrapper/FeatureWrapper';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import OnOffSwitch from 'Components/beta/common/buttons/switch/OnOffSwitch';
import Caption from 'Components/beta/common/caption/Caption';
import SummaryOption from 'Components/beta/common/summary/option/SummaryOption';
import { Features, useFeatures } from 'Hooks/useFeatures';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import React, { useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { testIds } from 'Scripts/cypressTestIds';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { OverviewState } from 'Types/overviewTypes';

const ParticipantEmojiToggle = () => {

	const {
		userSessionInfo,
		updateParticipantEmojis,
		updateParticipantEmojisLoading,
	} = useSession();

	const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

	const {
		isFeatureAvailable
	} = useFeatures();

	const { translatePlaceholder } = useTranslations();

	const hasEmojiFeature = isFeatureAvailable(Features.RESPONSE_WEBSITE_EMOJIS);

	const emojisEnabled = useMemo(() => {

		return Boolean(userSessionInfo?.allowParticipantEmojis);

	}, [userSessionInfo?.allowParticipantEmojis])

	const toggleSetting = () => {

		updateParticipantEmojis(!emojisEnabled)
	}

	return (
		<div>
			<Divider style={{ marginTop: "20px", marginBottom: "20px"}}/>
			{!hasEmojiFeature && "💎"}
			<SummaryOption
				loading={!userSessionInfo || presentationLoading}
				leftItem={<div>
					<AppText
						as={AppTextVariant.AppTextBaseStyle}
					>
						 {translatePlaceholder("PARTICIPANT_EMOJIS")}
					</AppText>
				</div>}
				rightItem={(
					<FeatureWrapper
						data-elscreen={DataElScreen.Settings}
						feature={Features.RESPONSE_WEBSITE_EMOJIS}>
						<OnOffSwitch
							disabled={updateParticipantEmojisLoading}
							checked={emojisEnabled}
							onChange={() => toggleSetting()}
							data-testid={testIds.PARTICIPANT_EMOJI_TOGGLE}
						/>
					</FeatureWrapper>
				)}
				trackingAttr={renderingGAAttr(`${DataElAction.ParticipantEmoji}_${emojisEnabled}${!hasEmojiFeature ? '_limited' : ''}`, DataElPosition.HideAdvancedSection, DataElScreen.Settings, DataElType.Toggle)}
				divider={false}
			/>
			<Caption title={translatePlaceholder("PARTICIPANT_EMOJIS_EXPLANATION")} />
		</div>
	);
};

export default ParticipantEmojiToggle;