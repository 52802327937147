import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTranslations } from 'Hooks/useTranslations';
import React from 'react';

const AuthPendingComponent = () => {

    const { translatePlaceholder } = useTranslations();

    return (
        <Box
            m={4}
            p={8}
            textAlign="center">
            <CircularProgress size={36} />
            <Box mt={1} />
            <Typography >
                {translatePlaceholder("AUTHENTICATING")}
            </Typography>
        </Box>
    )
}

export default AuthPendingComponent;