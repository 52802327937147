
export const setPresentationPageData = (data: Array<any>) => ({
    type: 'SET_PRESENTATION_PAGE_DATA',
    data,
})

export const setPresentationPageLimit = (limit: number) => ({
    type: 'SET_PRESENTATION_PAGE_LIMIT',
    limit
})

export const setPresentationPageIndex = (index: number) => ({
    type: 'SET_PRESENTATION_PAGE_INDEX',
    index
})

export const setSearchValue = (value: string) => ({
    type: 'SET_SEARCH_VALUE',
    value
})

export const setPresentationLoading = (isLoading: boolean) => ({
    type: 'SET_PRESENTATION_LOADING',
    isLoading
})

export const setSortProperty = (property: string) => ({
    type: 'SET_SORT_PROPERTY',
    property
})

export const setResultSortProperty = (property: string) => ({
    type: 'SET_RESULT_SORT_PROPERTY',
    property
})

export const setPresentationIdToRemove = (presentationId: number|null) => ({
    type: 'SET_QUIZ_TO_REMOVE',
    presentationId
})

export const setSortDirection = (direction: string) => ({
    type: 'SET_SORT_DIRECTION',
    direction
})

export const setOverviewTab = (tab: string) => ({
    type: 'SET_OVERVIEW_TAB',
    tab
})

export const setSelectedResultItem = (item: any) => ({
    type: 'SET_SELECTED_RESULT_ITEM',
    item
})

export const setResultLoading = (isLoading: boolean) => ({
    type: 'SET_RESULT_LOADING',
    isLoading
})

export const clearOverviewState = () => ({
    type: 'CLEAR_OVERVIEW_STATE'
})

export const setPresentationOptionsAnchor = (anchor: HTMLButtonElement) => ({
    type: 'SET_PRESENTATIONS_OPTIONS_ANCHOR',
    anchor
})