import Wrapper from 'Components/app/Wrapper';
import * as React from 'react';
/* Set() polyfill for IE */
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { reducerStore } from 'Reducers/index';
import { applyMiddleware, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { initNewRelic } from 'Scripts/newrelicHelper';

initNewRelic();

const store = createStore(reducerStore(), applyMiddleware(thunk));

if (process.env.NODE_ENV !== 'production') {

	console.warn('[DEVELOPMENT]');
}

window['AppState'] = store;

/**
 * Note: React strictMode calls useEffects twice.
 * Meaning this can disrupt the overall flow of the application
 * if initial calls are being done twice
 */
const Application = () => (
	// <React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<Wrapper />
			</Provider>
		</BrowserRouter>
	// </React.StrictMode>
)

export default Application;
