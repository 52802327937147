import { AnyAction } from "redux";
import { PresentationTemplatesState } from "Types/sharedSlideTypes";


export const initialState: PresentationTemplatesState = {
    templatesLoading: false,
    templatePageData: {
        totalResultsCount: 0,
        filteredResultsCount: 0,
        pageResultsCount: 0,
        pageIndex: 0,
        pageData: [],
        pageSize: 8,
        pageLoading: false,
        sortProperty: {
            id: 'id',
            desc: 1,
        }
    },
    templateToPreview: null,
    templateFilterCategory: null,
    templateCategories: [],
}

export function presentationTemplatesReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case 'SET_TEMPLATE_CATEGORIES': {
            return {
                ...state,
                templateCategories: action.categories,
            }
        }
        case 'SET_TEMPLATES_FILTER_CATEGORY': {
            return {
                ...state,
                templateFilterCategory: (action.category.id === state.templateFilterCategory?.id) ? null : action.category,
                templatePageData: {
                    ...state.templatePageData,
                    pageIndex: 0,
                }
            }
        }
        case 'SET_TEMPLATE_TO_PREVIEW': {
            return {
                ...state,
                templateToPreview: action.template,
            }
        }
        case 'SET_TEMPLATES_LOADING': {
            return {
                ...state,
                templatesLoading: action.loading
            }
        }
        case 'SET_TEMPLATE_PAGE_INDEX': {
            return {
                ...state,
                templatePageData: {
                    ...state.templatePageData,
                    pageIndex: action.pageIndex
                }
            }
        }
        case 'SET_TEMPLATE_SORT_PROPERTY': {
            return {
                ...state,
                templatePageData: {
                    ...state.templatePageData,
                    sortProperty: action.sortProperty,
                    pageIndex: 0,
                }
            }
        }
        case 'SET_TEMPLATE_PAGE_DATA': {

            return {
                ...state,
                templatePageData: {
                    ...state.templatePageData,
                    pageData: action.tableData.pageData,
                    pageResultsCount: action.tableData.pageResultsCount,
                    totalResultsCount: action.tableData.totalResultsCount,
                    filteredResultsCount: action.tableData.filteredResultsCount,
                }
            }
        }
        default:
            return state
    }
}