import { CircularProgress } from '@mui/material';
import { DataElType, TestIdAttributes, TrackingAttributes } from 'Scripts/measurementsGAHelper';
import React, { useTransition } from 'react';
import { style } from './style.css';

export enum AppButtonVariant {
    Primary = 'appBtnPrimary',
    Info = 'appBtnInfo',
    Light = 'appBtnLight',
    Secondary = 'appBtnSecondary',
    Success = 'appBtnSuccess',
    Warning = 'appBtnWarning',
    Plain = 'appBtnPlain',
    Error = 'appBtnError',
    Outlined = 'appBtnOutlined',
    InfoOutlined = 'appBtnInfoOutlined',
    SecondaryOutlined = 'appBtnSecondaryOutlined',
    Underline = 'appBtnUnderline',
    Text = 'appBtnText',
    Square = 'appBtnSquare',
    Inverted = 'appBtnInverted',
    AiMagic = 'aiMagic',
}

export interface AppButtonProps extends TrackingAttributes, TestIdAttributes {
    as: AppButtonVariant;
    loading?: boolean;
    children: any;
    responsive?: boolean;
    className?: string;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    href?: string;
    fullWidth?: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const AppButton = React.forwardRef<HTMLButtonElement, AppButtonProps>((props: AppButtonProps, ref) => {

    const [isPending, startTransition] = useTransition();

    const {
        as,
        children,
        loading,
        responsive,
        startIcon,
        endIcon,
        href,
        fullWidth,
        onClick,
        ...buttonProps
    } = props;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {

        if(href) {

            window.open(href, "_self");
        }

        startTransition(() => {

            onClick?.(e);
        })
    }

    const showLoader = (isPending || loading);

    return (
        <button
            ref={ref as any}
            data-eltype={DataElType.Button}
            {...buttonProps}
            onClick={handleClick}
            className={`${style[as]} ${fullWidth && style.fullWidth} ${buttonProps?.className} `}
        >
            <div className={style.flexButtonBox}>
                {(showLoader || startIcon) && (
                    <div className={style.startIconBox}>
                        {(startIcon && !showLoader) && startIcon}
                        {showLoader && (
                            <CircularProgress color="inherit" size={16} />
                        )}
                    </div>
                )}
                <div className={`${style.buttonContentBox} ${responsive && style.responsiveClass}`}>
                    {children}
                </div>
                {endIcon && (
                    <div className={style.endIconBox}>
                        {endIcon}
                    </div>
                )}
            </div>
        </button>
    )
});

export default AppButton;