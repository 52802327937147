import { Grid } from '@mui/material';
import FileUploadOption from 'Components/beta/editor/content/details/form/file/FileUploadOption';
import { useHover } from 'Hooks/useHover';
import { testIds } from 'Scripts/cypressTestIds';
import React from 'react';
import { LayoutWrapperProps } from '../../LayoutWrapper';
import { classes } from './style.css';

const LayoutImageOnRightSmall = ({
    title,
    content,
    imageURL,
    imageStyle,
    interactive,
}: LayoutWrapperProps) => {

    const { isHovering, hoverRef } = useHover();

    return (
        <Grid
            data-testid={testIds.LAYOUT_IMAGE_ON_RIGHT_SMALL}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="row"
            alignItems="flex-start">
            <Grid
                xs={9}
                item
                container
                height="100%"
                flexWrap="nowrap"
                flexDirection="column">
                <Grid
                    item
                    overflow="hidden"
                    width="100%" >
                    {title}
                </Grid>
                <Grid
                    flexGrow={1}
                    overflow="hidden"
                    display="flex"
                    flexDirection="column"
                    item
                    xs>
                    {content}
                </Grid>
            </Grid>
            <Grid
                xs={3}
                item
                height={`${(100 / 90) * 100}%`}>
                <Grid
                    height="100%"
                    width="100%"
                    ref={hoverRef}
                    className={classes.imageProperties}
                    sx={{
                        backgroundSize: imageStyle,
                        backgroundImage: `url(${imageURL})`,
                    }}>
                    {interactive && (
                        <FileUploadOption
                            show={isHovering && Boolean(imageURL)}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LayoutImageOnRightSmall;