import { Divider, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { useLayout } from 'Hooks/useLayout';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import useUrl from 'Hooks/useUrl';
import { AppState, SendstepsIntegrations } from 'Types/appTypes';
import { LayoutState } from 'Types/layoutTypes';
import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import ModeratorPanelToggle from './advanced/moderator_panel/ModeratorPanelToggle';
import ParticipantIdentificationToggle from './advanced/participant_identification/ParticipantIdentificationToggle';
import RespondViaSMSToggle from './advanced/sms_toggle/RespondViaSMSToggle';
import WeightedVotingInfo from './advanced/weighted_voting/WeightedVotingInfo';
import AdvancedSettingsToggle from './general/advanced_toggle/AdvancedSettingsToggle';
import AutoMovementSelect from './general/auto_movement/PresentationAutoMovementToggle';
import EditAnswersToggle from './general/edit_answers/EditAnswersToggle';
import ParticipantEmojiToggle from './general/emoji/ParticipantEmojiToggle';
import AppLanguageSelect from './general/language/AppLanguageSelect';
import ResponseWebsiteLanguageSelect from './general/language/ResponseWebsiteLanguageSelect';
import LoginCodeUserSettings from './general/login_code/LoginCodeUserSettings';
import PlayMusicToggle from './general/music/PlayMusicToggle';
import { classes } from './style.css';


enum Options {
	LoginCode,
	Language,
	PlayMusic,
	EditAnswers,
	ReviewResponses,
	IdentifyParticipants,
	Emojis,
	SMSTXT,
	PreventAutoMovement,
}

const optionsPerIntegration = {
	[SendstepsIntegrations.WebApp]: [
		Options.LoginCode,
		Options.Language,
		Options.PlayMusic,
		Options.EditAnswers,
		Options.ReviewResponses,
		Options.IdentifyParticipants,
		Options.Emojis,
		Options.SMSTXT,
		Options.PreventAutoMovement,
	],
	[SendstepsIntegrations.PowerPoint]: [
		Options.LoginCode,
		Options.EditAnswers,
		Options.ReviewResponses,
		Options.IdentifyParticipants,
		Options.SMSTXT,
	],
	[SendstepsIntegrations.MSTeams]: [
		Options.LoginCode,
	]
}

const SummaryOptionsContainer = () => {

	const hash = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).hash);

	const selectedSettingsIntegration = useSelector((state: RootStateOrAny) => (state.layoutReducer as LayoutState).selectedSettingsIntegration);

	const advancedPresentationOptionsShown = useSelector((state: RootStateOrAny) => (state.layoutReducer as LayoutState).advancedPresentationOptionsShown);

	const {
		presentationContainsMessageRounds,
		presentationContainsVotes,
	} =  useSlides();

	const urls = useUrl();

	const { translatePlaceholder } = useTranslations();

	const {
		toggleAdvancedOptions,
		storeHashFragment,
	} = useLayout();

	const isOnGlobalSettingsPage = () => {
		return window.location.pathname === urls.settings;
	}

	useEffect(() => {
		if(hash === "#identification") {

			toggleAdvancedOptions(true);

			storeHashFragment('');
		}

	}, [hash]);

	const showOption = (option: Options) => {

		return optionsPerIntegration[selectedSettingsIntegration].includes(option);
	}

	return (
		<div className={classes.optionsContainer}>
			<Typography variant="h2" mb={3}>
				{translatePlaceholder('MAIN_OPTIONS')}
			</Typography>
			<Divider />
			<div>
				{showOption(Options.LoginCode) && <LoginCodeUserSettings />}
				{showOption(Options.Language) && <ResponseWebsiteLanguageSelect />}
				{showOption(Options.Language) && <AppLanguageSelect />}
				{showOption(Options.PreventAutoMovement) && !isOnGlobalSettingsPage() && <AutoMovementSelect />}
				{showOption(Options.PlayMusic) && (
					(presentationContainsVotes || isOnGlobalSettingsPage()) && <PlayMusicToggle />
				)}
				{showOption(Options.EditAnswers) && <EditAnswersToggle />}
				{showOption(Options.ReviewResponses) && (
					(presentationContainsMessageRounds || isOnGlobalSettingsPage()) && <ModeratorPanelToggle />
				)}
				<AdvancedSettingsToggle isOpen={advancedPresentationOptionsShown} />
			</div>
			<Collapse in={advancedPresentationOptionsShown}>
				<div>
					{showOption(Options.IdentifyParticipants) && <ParticipantIdentificationToggle />}
					{showOption(Options.Emojis) && <ParticipantEmojiToggle />}
					{showOption(Options.SMSTXT) && <RespondViaSMSToggle />}
					<Typography variant="h2" my={3}>
						{translatePlaceholder("WEIGHTED_VOTING")}
					</Typography>
					<div>
						<WeightedVotingInfo />
					</div>
				</div>
			</Collapse>
		</div>
	);
};

export default SummaryOptionsContainer;