import React from 'react';

const AdditionalMultiplechoiceSettings = () => {
	return (
		<div>

		</div>
	);
};

export default AdditionalMultiplechoiceSettings;