import InfoIcon from '@mui/icons-material/Info';
import { Divider, FormControlLabel, Radio } from '@mui/material';
import { Box } from '@mui/system';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import OnOffSwitch from 'Components/beta/common/buttons/switch/OnOffSwitch';
import CustomTooltip from 'Components/common/tooltip/CustomTooltip';
import useSlideActions from 'Hooks/useSlideActions';
import useSlides from 'Hooks/useSlides';
import { useTranslations } from 'Hooks/useTranslations';
import { DataElAction, DataElType } from 'Scripts/measurementsGAHelper';
import { SlideTypes } from 'Types/slideTypes';
import React, { useCallback, useMemo } from 'react';


/**
 * Allowed cases
 *
 * strictAnswers = 1, nrOfVotes = 0 ✅
 * strictAnswers = 0, nrOfVotes = 0 ❌
 * strictAnswers = 1, nrOfVotes = 1 ❌
 * strictAnswers = 0, nrOfVotes = 1 ✅ -> default
 *
 * strictAnswers = 0 & nrOfVotes = 0 would mean that participants
 * are able to answer each option on the response website
 * which would automatically mean they have the question correct
 *
 * strictAnswers = 1 & nrOfVotes = 1 would mean that a presenter can
 * mark multiple answers as correct but participants would only be able
 * to answer once, meaning in this case they automatically have the question
 * wrong.
 *
 * So to prevent these cases we update the nrOfVotes relative to the strictAnswers value
 */
const AnswerModeToggle = () => {

	const { slides, currentSlide } = useSlides();

	const { setSlideSetting } = useSlideActions();

	const { translatePlaceholder } = useTranslations();

	const strictAnswerModeEnabled = Boolean(currentSlide.strictAnswers);

	const setStrictAnswers = (enabled: boolean | Number, slideId: number) => {

		setSlideSetting('strictAnswers', Number(enabled), slideId);

		const newNrOfVotes = enabled ? 0 : 1;

		setSlideSetting('nrOfVotes', newNrOfVotes, slideId);
	}

	/**
	 * Checks if the current setting is equal to all slides of the same (quiz) type
	 */
	const currentStrictModeOptionIsAppliedToAll = useMemo(() => {

		const quizSlides = Object.values((slides)).filter(slide => slide.type === SlideTypes.Quiz);

		const amountOfQuizSlides = quizSlides.length;

		const strictAnswerSlidesValue = quizSlides.reduce((amount, slide) => {

			return amount += Number(slide.strictAnswers);

		}, 0);

		/**
		 * If all of the quiz slides have the same value, or none of the quiz slides have the same value
		 * this returns true
		 */
		return (amountOfQuizSlides === strictAnswerSlidesValue) || (strictAnswerSlidesValue === 0);

	}, [slides, strictAnswerModeEnabled]);

	const applyCurrentStrictModeOptionToAllQuizSlides = useCallback(() => {

		for(const slideId in slides) {

			setStrictAnswers(Number(strictAnswerModeEnabled), Number(slideId));
		}

	}, [strictAnswerModeEnabled, currentStrictModeOptionIsAppliedToAll]);

	return (
		<>
			<Box p={1}>
			<AppText
				as={AppTextVariant.AppTextBaseStyle}
				pt={1}
			>
				{translatePlaceholder("CORRECT_ANSWER_MODE")}
			</AppText>
			<Box display="flex" flexDirection="column">
				<FormControlLabel
					label={
						<Box display="flex">
							<AppText pr={.5}>
								{translatePlaceholder("ANSWER_MODE_STRICT")}
							</AppText>
							<CustomTooltip title={translatePlaceholder("ANSWER_MODE_STRICT_EXPLANATION")}>
								<InfoIcon sx={{ fontSize: 12 }} />
							</CustomTooltip>
						</Box>
					}
					control={
						<Radio
							data-eltype={DataElType.Button}
							data-elaction={DataElAction.ToggleQuizStrictModeOn}
							data-elscreen="settings"
							color="primary"
							onChange={() => setStrictAnswers(true, currentSlide.id)}
							checked={strictAnswerModeEnabled}
						/>
					}
				/>
				<FormControlLabel
					label={
						<Box display="flex">
							<AppText pr={.5}>
								{translatePlaceholder("ANSWER_MODE_EASY")}
							</AppText>
							<CustomTooltip title={translatePlaceholder("ANSWER_MODE_EASY_EXPLANATION")}>
								<InfoIcon sx={{ fontSize: 12 }} />
							</CustomTooltip>
						</Box>
					}
					control={
						<Radio
							data-eltype={DataElType.Button}
							data-elaction={DataElAction.ToggleQuizStrictModeOff}
							data-elscreen="settings"
							color="primary"
							onChange={() => setStrictAnswers(false, currentSlide.id)}
							checked={!strictAnswerModeEnabled}
						/>
					}
				/>
			</Box>
			<Box pb={1}>
				<FormControlLabel
					label={
						<Box display="flex">
							<AppText pr={.5}>
								{translatePlaceholder("APPLY_TO_ALL")}
							</AppText>
						</Box>
					}
					control={
						<OnOffSwitch
							data-eltype={DataElType.Button}
							data-elaction={DataElAction.ToggleQuizStrictModeOff}
							data-elscreen="settings"
							onChange={() => applyCurrentStrictModeOptionToAllQuizSlides()}
							checked={currentStrictModeOptionIsAppliedToAll}
						/>
					}
				/>
			</Box>
		</Box>
		<Divider />
	</>
	)
}

export default AnswerModeToggle;