import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { setFadeMusicOut, setMusic, setVolume } from 'Actions/audioActions';
import AppText from 'Atomic/atoms/Text/AppText';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import DynamicColoredProgressBar from 'Atomic/molecules/DynamicColoredProgressBar';
import PlaySlideTitlePreview from 'Atomic/molecules/PlaySlideTitlePreview/PlaySlideTitlePreview';
import Html from 'Components/common/util/html/Html';
import SessionLoginInfo from 'Components/play/templates/geometry_template/shared_components/session_login_info/SessionLoginInfo';
import SessionVoteOptions from 'Components/session/vote_options/SessionVoteOptions';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useScores } from 'Hooks/useScores';
import { useSession } from 'Hooks/useSession';
import { useSlideNavigation } from 'Hooks/useSlideNavigation';
import { useTranslations } from 'Hooks/useTranslations';
import { musicTypes } from 'Reducers/audioReducer';
import { useInterval } from 'Scripts/intervalHook';
import { calculateVoteResults, closeVote, openVote } from 'Scripts/requests';
import { NavigationState } from 'Scripts/slideHelper';
import { numberWithCommas } from 'Scripts/stringFormatter';
import { SlideConsumerComponent } from 'Types/appTypes';
import { FeatureState } from 'Types/featureTypes';
import { PlaySlideType, PlayState } from 'Types/playTypes';
import { PresentationState, SlideType } from 'Types/presentationTypes';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { classes } from './style.css';

const questionStartDelayMs = 7500;
/* How frequent the count down indicator should update */
const countdownIndicatorIntervalMs = 250;

const QuizSlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {

    const { translate } = useTranslations();

    const slideProperties = getPlaySlideProperties(slide);

    const runTimers = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).runTimers);

    const slideNavigationState = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).slideNavigationState);

    const previousSlideNavigationState = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).previousSlideNavigationState);

    const planInfo = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).planInfo);

    const maxAudienceSize = planInfo?.audienceSize || 0;

    const dispatch = useDispatch();

    const {
        navigateToNextSlideState,
        navigateToPreviousSlideState,
        isActiveState,
        isLastSlide,
    } = useSlideNavigation()

    const { presentationStyle } = usePresentationStyles();


    const [questionStartTimer, setQuestionStartTimer] = useState(0)

    const [questionTimer, setQuestionTimer] = useState(Number(slide?.dynamicTimerLimit || 0) * 1000 || -1)

    const { allVotesAreIn } = useSession();

    const {
        broadcastScores,
    } = useScores();

    /* Percentage value of time between the vote shows up and when the vote opens  at 100%*/
    const questionStartPercentage = (100 / ((questionStartDelayMs - 500) / questionStartTimer)) >> 0
    /* Percentage value of time left to respond on a vote before it closes at 100% */
    const timePercentage = (100 / (Number(slide?.dynamicTimerLimit || 1) / (questionTimer / 1000)))

    const playCorrectMusic = () => {

        let musicType = musicTypes.TIMER_ANY;

        if (slide?.dynamicTimerLimit === 20) {

            musicType = musicTypes.TIMER_20_SECONDS;
        }

        dispatch(setVolume(1))

        dispatch(setMusic(musicType))
    }

    /**
     * Starts the music, resets the timer and opens the vote through the API
     */
    const startVote = async () => {

        playCorrectMusic()

        setQuestionStartTimer(Number(slide?.dynamicTimerLimit || 0) * 1000);

        await openVote(slide.id);
    }

    /**
     * Fades out the music, closes the vote through the API and broadcasts users scores
     */
    const stopQuizVote = async () => {

        dispatch(setFadeMusicOut(true));

        await closeVote(slide.id);

        await broadcastScores();
    }

    /* Countdown for the remaining time to vote when the vote has opened */
    useInterval(() => {

        setQuestionTimer(currentTime => currentTime - countdownIndicatorIntervalMs)

    }, (runTimers && isActiveState(NavigationState.SlideOpen)) ? countdownIndicatorIntervalMs : null)

    /* Countdown before the question opens */
    useInterval(() => {

        setQuestionStartTimer(currentTime => currentTime + countdownIndicatorIntervalMs)

    }, (runTimers && isActiveState(NavigationState.BeforeOpen) && (questionStartPercentage <= 100)) ? countdownIndicatorIntervalMs : null)

    /* Open the vote after questionStartDelayMs ms */
    useInterval(() => {

        navigateToNextSlideState();

    }, (runTimers && isActiveState(NavigationState.BeforeOpen)) ? questionStartDelayMs : null)

    /* If the question timer reaches 0, close the vote */
    useEffect(() => {

        if (questionTimer === 0 && isActiveState(NavigationState.SlideOpen)) {

            navigateToNextSlideState();
        }

    }, [questionTimer])

    /* Triggered when a new navigation state is updated */
    useEffect(() => {

        if (isActiveState(NavigationState.BeforeOpen)) {

            closeVote(slide.id);
            setQuestionStartTimer(0);
        }

        if (isActiveState(NavigationState.SlideOpen)) {

            startVote();

            if (Boolean(slide.dynamicTimerLimit)) {

                setQuestionTimer(Number(slide.dynamicTimerLimit) * 1000);
            }
        }

        if (isActiveState(NavigationState.SlideClosed)) {

            stopQuizVote();

            calculateVoteResults(slide.id, maxAudienceSize);
        }

    }, [slideNavigationState])

    /* Runs on unmounting */
    useEffect(() => {

        return () => {

            closeVote(slide.id)
        }

    }, []);

    return <>
        <>
            {!isActiveState(NavigationState.SlideResults) && <div className={classes.wrapper}>
                {isActiveState(NavigationState.BeforeOpen) && <DynamicColoredProgressBar loadPercentage={Math.min(100, questionStartPercentage)} />}
                {isActiveState(NavigationState.SlideOpen) && <DynamicColoredProgressBar loadPercentage={Math.max(0, timePercentage)} />}
                <Grid
                    container
                    className={classes.questionContainer}
                    direction="row"
                    justifyContent="center"
                    sx={{
                        color: presentationStyle?.textColor,
                    }}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={`${classes.detailsContainer} ${isActiveState(NavigationState.BeforeOpen) && classes.upSize}`}
                    >
                        <LayoutWrapper
                            layoutType={slide.slideLayout}
                            imageURL={slide?.file?.base64 || slide?.filename}
                            imageStyle={slide.filenameStyle}
                            backgroundOpacity={slide.backgroundOpacity}
                            backgroundColor={slide.backgroundColor}
                            videoURL={slide.videoURL}
                            title={
                                <PlaySlideTitlePreview
                                    title={<>
                                        <DelayedComponent
                                            animationDuration={250}
                                            animationType={AnimationTypes.Grow}
                                            showAfter={Boolean(slide.points) ? 2500 : 500}
                                            stop={!runTimers}>
                                            <Html
                                                content={slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                                fontSize={slideProperties.slideSettings.titleFontSize}
                                            />
                                        </DelayedComponent>
                                        {Boolean(slide.points) && (
                                            <div className={classes.pointsContainer}>
                                                <DelayedComponent
                                                    animationDuration={250}
                                                    animationType={AnimationTypes.Grow}
                                                    hideAfter={2000}
                                                    stop={!runTimers}>
                                                    <Typography variant="h4" className={classes.correctAnswerText} sx={{
                                                        color: presentationStyle?.textColor,
                                                    }}>
                                                        {translate('ANSWER_CORRECT_AND_RECEIVE')}
                                                    </Typography>
                                                    <Typography variant="h3" className={classes.pointsIndicator} sx={{
                                                        color: presentationStyle?.textColor,
                                                    }}>
                                                        {translate('AMOUNT_OF_POINTS', [
                                                            numberWithCommas(slide.points as number)
                                                        ])}
                                                    </Typography>
                                                </DelayedComponent>
                                            </div>
                                        )}
                                    </>}
                                    showPreview={isActiveState(NavigationState.BeforeOpen)}
                                />
                            }
                            content={(
                                <SessionVoteOptions
                                    slide={slide as SlideType}
                                    showCorrectAnswers={(
                                        isActiveState(NavigationState.SlideClosed) ||
                                        isActiveState(NavigationState.SlideResults)
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {!allVotesAreIn && <SessionLoginInfo />}
                <Box className={classes.continueText} sx={{
                    color: presentationStyle?.textColor,
                }}>
                    <Grow in={allVotesAreIn && isActiveState(NavigationState.SlideOpen)}>
                        <AppText
                            fontWeight={700}
                            fontSize={24}
                            color={presentationStyle?.textColor}>
                            {translate('ALL_VOTES_ARE_IN')}
                        </AppText>
                    </Grow>
                </Box>
            </div>}
        </>
    </>;
};

export default QuizSlideSession
