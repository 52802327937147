
export interface TranslationItems {
	[translationKey: string]: string;
}
export interface LanguageState {
	language: string;
	updateLanguageLoading: boolean;
	translations: TranslationItems;
}
export interface AvailableLanguages {
	isoCode: string;
	localName: string;
	isAppLanguage: boolean;
}

export const defaultLanguage = 'en';

export const defaultAvailableLanguages: AvailableLanguages[] = [{ isoCode: defaultLanguage, localName: 'English', isAppLanguage:true}]

export enum LanguageDropdownType {
    App = 'App',
    ResponseWebsite = 'ResponseWebsite',
}