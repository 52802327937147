import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppRouting } from 'Hooks/useAppRouting';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { config } from 'Scripts/config';
import { useInterval } from 'Scripts/intervalHook';
import { numberWithCommas } from 'Scripts/stringFormatter';
import { PlayState } from 'Types/playTypes';
import React, { useMemo, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const GeometryScorePanelItem = ({ score, index, animate }) => {

	const quizRanksBySlideKey = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).quizRanksBySlideKey);

	const playSlides = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).playSlides);

	const { points, participantId, lastReceivedPoints, name } = score

	const { presentationStyle } = usePresentationStyles();

	const slideKeys = Object.keys(playSlides)

	const { currentSlideKey } = useAppRouting()

	const currentSlideIndex = slideKeys.indexOf(`${currentSlideKey}`);

	const [appear, setAppear] = useState(!animate || false)

	useInterval(() => {

		setAppear(true)

	}, !appear ? ((index + 1) * 150) : null)

	const arrowUpElement = () => <ArrowDropUpIcon className={classes.arrowUp} sx={{
		color: config.successColor
	}} />

	const arrowDownElement = () => <ArrowDropDownIcon className={classes.arrowDown} sx={{
		color: config.errorColor
	}}/>

	const colors = useMemo(() => {

		const { lighterColor, lightColor, normalColor, darkColor, darkerColor } = presentationStyle || {};

		const styleColors = [
			lighterColor,
			lightColor,
			normalColor,
			darkColor,
			darkerColor
		];

		return[
			...styleColors.reverse(),
			...styleColors.splice(1, styleColors.length),
			...styleColors.reverse().splice(1, styleColors.length),
		];

	}, [presentationStyle])

	/*
		Iterate over a preset list of colors and return a color based on the index
	*/
	const getColorValueBasedOnIndex = () => {

		return colors[index % colors.length]
	}

	/*
		Determine arrow element (up/down green/red arrow) by comparing previous
		stored ranks with current ranks
	*/
	const determineArrowElement = () => {

		const currentRanks = quizRanksBySlideKey[currentSlideKey]

		const previousSlideIndex = playSlides?.[currentSlideIndex]?.slideIndex - 1

		let previousRanks = null

		let arrowElement = null;

		if (!!currentRanks) {

			const previousSlideKey = Object.keys(playSlides).find(slideKey => {

				if (playSlides[slideKey].slideIndex < currentSlideIndex) {

					if(playSlides[slideKey].isQuizVote) {

						return true;
					}
				}
			})

			if (previousSlideKey) {

				previousRanks = quizRanksBySlideKey[previousSlideKey]
			}
		}

		if (!!previousSlideIndex && previousRanks?.[participantId]) {

			if (currentRanks[participantId].rank < previousRanks[participantId].rank) {

				arrowElement = arrowUpElement()
			}

			if (currentRanks[participantId].rank > previousRanks[participantId].rank) {

				arrowElement = arrowDownElement()
			}
		}

		if (!previousRanks && !!lastReceivedPoints) {

			arrowElement = arrowUpElement()
		}

		return arrowElement
	}

	const color = getColorValueBasedOnIndex();

	return (
        <Grid
			item
			xs={12}
			direction="row"
			justifyContent="flex-start"
			container
			key={participantId}
			style={{
				background: color,
				opacity: +appear,
			}}
			className={classes.scoreItem} >
			<Grid
				direction="row"
				justifyContent="flex-start"
				container>
				<Grid
					xs={2}
					item
					container
					alignItems="center">
					<Typography noWrap className={classes.rankIndicator} sx={{
						color: presentationStyle?.textColor,
					}}>
						{index === 0 && "🥇"}
						{index === 1 && "🥈"}
						{index === 2 && "🥉"}
						{index > 2 && (index + 1)}
					</Typography>
				</Grid>
				<Grid
					xs={6}
					item
					container
					alignItems="center">
					<Typography noWrap className={classes.scoreText} sx={{
						color: presentationStyle?.textColor,
					}}>
						{name}
					</Typography>
				</Grid>
				<Grid
					xs={4}
					item
					container
					alignItems="center"
					justifyContent="space-evenly">
					<Grid item xs={4}>
						{!!lastReceivedPoints && <Typography className={classes.gainedPointsText} sx={{
							color: config.successColor,
						}}>
							+{numberWithCommas(lastReceivedPoints)}
						</Typography>}
					</Grid>
					<Grid item xs={6}>
						<Typography className={classes.scoreText} sx={{
							color: presentationStyle?.textColor,
						}}>
							{numberWithCommas(points)}
						</Typography>
					</Grid>
					<Grid item xs={2}>
						{determineArrowElement()}
					</Grid>
				</Grid>
			</Grid>
		</Grid >
    );
};

export default GeometryScorePanelItem;