import { Box } from '@mui/material';
import SlideStyleWrapper from 'Atomic/atoms/SlideStyleWrapper/SlideStyleWrapper';
import AspectRatioWrapper from 'Atomic/molecules/AspectRatioWrapper/AspectRatioWrapper';
import PreviewSlideShapeList from 'Components/slide_shapes/_preview/PreviewSlideShapeList';
import { getSlidePropertiesByType } from 'Components/types/helpers/slideTypeHelper';
import { ScreenSizes } from 'Types/appTypes';
import { FilenameCssStyle, SlideGenerationTemplate, SlideType, VoteOption } from 'Types/presentationTypes';
import { SlideShape } from 'Types/slideTypes';
import { isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { classes } from './style.css';


interface Props {
    template: SlideGenerationTemplate;
    height?: number;
    width?: number;
}

const defaultHeight = ScreenSizes.Height1080P / 10;
const defaultWidth = ScreenSizes.Width1080P / 10;

const AppSlideTemplate = ({
    template,
    height,
    width
}: Props) => {

    const containerRef = useRef();

    const slideProperties = getSlidePropertiesByType(template.type);

    const slide: SlideType = {
        ...template,
        options: template.content as Array<VoteOption>,
        filenameStyle: FilenameCssStyle.Cover,
    }

    return (

        <Box
            height={height || defaultHeight}
            ref={containerRef}
            className={classes.wrapper}
            width={width || defaultWidth}>
            <AspectRatioWrapper
                containerRef={containerRef}
                height={ScreenSizes.Height1080P}
                width={ScreenSizes.Width1080P}
                transformOrigin="top left"
                limitMaxScale>
                <SlideStyleWrapper slide={slide}>
                    {!isEmpty(slide.shapes) && (
                        <PreviewSlideShapeList
                            shapes={slide.shapes as Array<SlideShape>}
                            slide={slide}
                        />
                    )}
                    {isEmpty(slide.shapes) && slideProperties.slideComponents?.slideEditorPreviewComponent(slide)}
                </SlideStyleWrapper>
            </AspectRatioWrapper>
        </Box>
    )

}

export default AppSlideTemplate;