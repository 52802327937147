import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useTranslations } from 'Hooks/useTranslations';
import { numberWithCommas } from 'Scripts/stringFormatter';
import React from 'react';
import { classes } from './style.css';

/* Podium heights based on leaderboard display order */
const podiumHeights = [
    '50%',
    '65%',
    '35%'
]

/* Emoji medals based on leaderboard display order */
const medals = [
    "🥈",
    "🥇",
    "🥉",
]

const PreviewPodiumSlideShape = () => {

    const { presentationStyle } = usePresentationStyles();

    const { translate } = useTranslations();

    const topScores = [
        {
            name: "Participant A",
            points: 500,
        },
        {
            name: "Participant B",
            points: 1500,
        },
        {
            name: "Participant C",
            points: 1000,
        },
    ]
    /* Based on leaderboard display order */
    const winnerIndex = (topScores && topScores.length === 1) ? 0 : 1

    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="flex-end"
                justifyContent="space-evenly"
                className={classes.podiumContainer}
                sx={{
                    backgroundColor: presentationStyle?.normalColor

                }}>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-evenly">
                    <div style={{ position: 'absolute' }}>
                        <Typography className={classes.winnerText} sx={{
                            color: presentationStyle?.textColor,
                        }}>
                            {translate('THE_WINNER_IS')} {topScores && topScores[winnerIndex]?.name}! 🏆
                        </Typography>
                    </div>
                </Grid>
                {topScores && topScores.map((result, index) => (
                    <Grid key={index} item xs={topScores.length === 1 ? 12 : (topScores.length === 2 ? 6 : 4)} height={podiumHeights[index]}>
                        <Typography noWrap className={classes.winnerName} sx={{
                            color: presentationStyle?.textColor,
                        }}>
                            {medals[topScores.length === 1 ? 1 : index]} {result.name}
                        </Typography>
                        <div
                            className={classes.pillarItem}
                            style={{
                                background: presentationStyle?.darkColor,
                            }}>
                            <div className={classes.pillarBanner} style={{
                                background: presentationStyle?.darkerColor,
                            }}>
                                <Typography className={classes.podiumScoreText} sx={{
                                    color: presentationStyle?.textColor,
                                }}>
                                    {numberWithCommas(result.points)}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};


export default PreviewPodiumSlideShape;