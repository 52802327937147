import { setUserSessionInfo } from "Actions/appActions";
import { setPlayMusic } from "Actions/audioActions";
import { setLanguage, setTranslations, setUpdateLanguageLoading } from "Actions/languageActions";
import { setCountries, setSelectedCountry } from "Actions/playActions";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { get, post } from "Scripts/api";
import { AppState } from "Types/appTypes";
import { AudioState } from "Types/audioTypes";
import { LanguageState } from "Types/languageTypes";
import { PlayState } from "Types/playTypes";

const usePlaySettings = () => {

	const playMusic = useSelector((state: RootStateOrAny) => (state.audioReducer as AudioState).playMusic);

	const language = useSelector((state: RootStateOrAny) => (state.languageReducer as LanguageState).language);

	const userSessionInfo = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).userSessionInfo);

	const countries = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).countries);

	const selectedCountry = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).selectedCountry);

	const [updateResponseCodeLoading, setUpdateResponseCodeLoading] = useState<boolean>(false);

	const [updateTabSettingsLoading, setUpdateTabSettingsLoading] = useState<boolean>(false);

	const [fetchCountriesLoading, setFetchCountriesLoading] = useState<boolean>(false);

	const { enqueueSnackbar } = useSnackbar();

	const dispatch = useDispatch();

	const enableMusic = () => {

		dispatch(setPlayMusic(true))
	}

	const disableMusic = () => {

		dispatch(setPlayMusic(false))
	}

	const toggleMusic = () => {

		dispatch(setPlayMusic(!playMusic));
	}

	const changeLanguage = (isoCode: string) => {

		dispatch(setLanguage(isoCode));
	}

	const fetchTranslations = async () => {

		try {

			const translations = await get(`translations/user-translations`);

			dispatch(setTranslations(translations))

		} catch (error) {

			console.warn(error)
		}
	}

	const fetchTranslationsByIsoCode = async (isoCode: string) => {

		try {

			dispatch(setUpdateLanguageLoading(true))

			const { translations } = await get(`translations/language?isoCode=${isoCode}`);

			changeLanguage(isoCode);

			dispatch(setTranslations(translations))

		} catch (error) {

			console.warn(error)

			enqueueSnackbar('Something went wrong trying to fetch translations.', { variant: 'warning' });

		} finally {

			dispatch(setUpdateLanguageLoading(false));
		}
	}

	const updateResponseCode = async (responseCode: string) => {

		if(`${responseCode}`.length < 3) {

			enqueueSnackbar('Please enter between 3 and 30 characters', { variant: 'warning' });

			return;
		}

		responseCode = responseCode.substring(0, 30);

		setUpdateResponseCodeLoading(true);

		try {

			const response = await post('sessions/response-code', {
				responseCode,
			});

			if (response.error) {

				enqueueSnackbar(response.error, { variant: 'warning' });

				return;
			}

			dispatch(setUserSessionInfo({
				...userSessionInfo,
				responseCode,
			}))

		} catch (error) {

			enqueueSnackbar(error, { variant: 'warning' });

		} finally {

			setUpdateResponseCodeLoading(false);

		}

	}

	const updateTabSettings = async (tabProperty: string, tabValue: string | boolean | number) => {

		setUpdateTabSettingsLoading(true);

		try {

			const response = await post('sessions/tab-settings', {
				[tabProperty]: tabValue,
			});

			if (response.error) {

				console.warn(response.error)

				enqueueSnackbar('Something went wrong trying to adjust this setting.', { variant: 'warning' });

				return;
			}

			dispatch(setUserSessionInfo({
				...userSessionInfo,
				tabSettings: {
					...response.tabSettings,
				}
			}));

		} catch (error) {

			enqueueSnackbar(error, { variant: 'warning' });
		}

		setUpdateTabSettingsLoading(false);
	}

	const fetchCountries = async () => {

		setFetchCountriesLoading(true);

		try {

			const response = await get('sessions/countries');

			if (response.error) {

				enqueueSnackbar('Something went wrong trying to retrieve the list of countries.', { variant: 'warning' });

				console.warn(response.error)

				return;
			}

			const { countries, selectedCountry } = response;

			dispatch(setCountries(countries));

			dispatch(setSelectedCountry(selectedCountry));

		} catch (error) {

			enqueueSnackbar(error, { variant: 'warning' });
		}

		setFetchCountriesLoading(false);
	}

	const updateCountry = async (countryIsoCode: string) => {

		setFetchCountriesLoading(true);

		try {

			const response = await post('sessions/update-country', {
				countryIsoCode,
			});

			if (response.error) {

				enqueueSnackbar('Something went wrong trying to change your country.', { variant: 'warning' });

				console.warn(response.error);

				return;
			}

			dispatch(setSelectedCountry(countryIsoCode));

		} catch (error) {

			enqueueSnackbar(error, { variant: 'warning' });
		}

		setFetchCountriesLoading(false);
	}

	return {
		playMusic,
		enableMusic,
		disableMusic,
		toggleMusic,

		language,
		changeLanguage,
		fetchTranslationsByIsoCode,

		updateResponseCode,
		updateResponseCodeLoading,

		updateTabSettings,
		updateTabSettingsLoading,

		countries,
		selectedCountry,
		fetchCountries,
		updateCountry,
		fetchCountriesLoading,
		fetchTranslations,
	}
}

export default usePlaySettings;