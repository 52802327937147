import { Alert, Collapse, Typography } from '@mui/material';
import IdentificationQuestion from 'Components/beta/common/question/identification/IdentificationQuestion';
import EmptyIdentificationQuestion from 'Components/beta/common/question/identification/empty/EmptyIdentificationQuestion';
import { useIdentification } from 'Hooks/useIdentification';
import { useSession } from 'Hooks/useSession';
import { useTranslations } from 'Hooks/useTranslations';
import { AppState } from 'Types/appTypes';
import { IdentificationQuestionAttributes } from 'Types/identificationQuestionTypes';
import { LanguageState } from 'Types/languageTypes';
import { isEmpty } from 'lodash';
import React, { Fragment, useEffect, useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

const ParticipantIdentificationQuestions = () => {

    const language = useSelector((state: RootStateOrAny) => (state.languageReducer as LanguageState).language);

    const currentUser = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentUser);

	const {
		userSessionInfo,
    } = useSession();

    const { translatePlaceholder } = useTranslations();

    const {
        fetchIdentificationQuestions,
        questions
    } = useIdentification();

    useEffect(() => {

        if(Boolean(currentUser)) {

            fetchIdentificationQuestions();
        }

    }, [currentUser, language])

    const isAnonymous = useMemo(() => {

		return Boolean(Number(userSessionInfo?.isAnonymous));

	}, [userSessionInfo?.isAnonymous])


    return (
        <Collapse in={!isAnonymous}>
            <div>
                <Typography variant="body2" className={classes.identificationText}>
                    {translatePlaceholder("IDENTIFICATION_QUESTIONS")}
                </Typography>
                <Alert severity="info">
                    <Typography>
                        {translatePlaceholder("QUIZ_IDENTIFICATION_EXPLANATION")}
                    </Typography>
                </Alert>
                <div className={classes.questionContainer}>
                    {!isEmpty(questions) && <>
                        {questions.map((question: IdentificationQuestionAttributes, questionIndex: number) => (
                            <Fragment key={question?.data?.id}>
                                <IdentificationQuestion
                                    question={question}
                                    questionIndex={questionIndex}
                                />
                            </Fragment>
                        ))}
                    </>}
                    <div className={classes.questionBox}>
                        <EmptyIdentificationQuestion />
                    </div>
                </div>
            </div>
        </Collapse>
    );
};

export default ParticipantIdentificationQuestions;