import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Box, Hidden, alpha, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import AppText, { AppTextVariant } from 'Atomic/atoms/Text/AppText';
import UserAvatar from 'Components/beta/common/buttons/avatar/UserAvatar';
import { useAuthentication } from 'Hooks/useAuthentication';
import usePresentation from 'Hooks/usePresentation';
import { testIds } from 'Scripts/cypressTestIds';
import { AppState } from 'Types/appTypes';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import ProfileSummary from './profile_summary/ProfileSummary';
import { classes } from './style.css';

interface IProfileIcon {
	color?: string;
	as?: AppTextVariant;
}

const ProfileIcon = ({color = "primary", as = AppTextVariant.BodySmallBold}: IProfileIcon) => {


	const currentUser = useSelector((state: RootStateOrAny) => (state.appReducer as AppState).currentUser);

	const [profileSummaryAnchor, setProfileSummaryAnchor] = useState<HTMLButtonElement | null>(null);

	const summaryOpen = Boolean(profileSummaryAnchor);

	const {
		openLogin,
		addLoginCallback,
	} = useAuthentication();

	const {
		fetchPresentationPageData,
	} = usePresentation();

	const close = () => {

		setProfileSummaryAnchor(null);
	}

	const openSummary = (event: React.MouseEvent<HTMLButtonElement>) => {

		if (isEmpty(currentUser)) {

			openLogin();

			addLoginCallback(fetchPresentationPageData);

			event.stopPropagation();

			setProfileSummaryAnchor(null);

		} else {

			setProfileSummaryAnchor(event.currentTarget);
		}
	}

	const theme = useTheme();

	return <>
		{!isEmpty(currentUser) && (
			<>
				<Box
					onClick={(e) => openSummary(e)}
					sx={{
						'&:hover': {
							background: alpha(theme.palette.common.black, .1),
						},
					}}
					className={classes.userMenuContainer}>
					<IconButton
						data-testid={testIds.USER_LOGIN_BUTTON}
						color="inherit"
						className={classes.iconButton}
						size="large">
						<UserAvatar />
						<Hidden mdDown>
							<Box pl={1}>
								<AppText as={as} sx={{ color: color }}>
									{currentUser?.firstName} {currentUser?.lastName}
								</AppText>
							</Box>
						</Hidden>
					</IconButton>
					<ChevronLeft className={classes.chevron} />
				</Box>
				<Popover
					elevation={0}
					open={summaryOpen}
					anchorEl={profileSummaryAnchor}
					onClose={close}
					classes={{
						root: classes.root,
						paper: classes.popover
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}>
					<ProfileSummary onClose={close} />
				</Popover>
				<div id="userEmail" style={{ display: 'none' }}>{currentUser?.email}</div>
			</>
		)}
	</>;
};

export default ProfileIcon;