import AppButton, { AppButtonProps, AppButtonVariant } from 'Atomic/atoms/Button/AppButton';
import AppText from 'Atomic/atoms/Text/AppText';
import { useTranslations } from 'Hooks/useTranslations';
import useUserFlow from 'Hooks/useUserFlow';
import { testIds } from 'Scripts/cypressTestIds';
import { UserFlowState } from 'Types/userFlowTypes';
import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { classes } from './style.css';

interface UpgradeButtonProps extends Omit<AppButtonProps, "as" | "children" | "onClick"> {
    as?: AppButtonVariant;
    buttonText?: string;
    trackAction?: string;
    trackScreen?: string;
    trackPosition?: string;
    feature?: string|null;
    isLink?: boolean;
    responsive?: boolean;
    small?: boolean;
}

const UpgradeButton = ({ as, buttonText, trackAction, trackScreen, trackPosition, feature = null, isLink = false, responsive = true, small, ...props }: UpgradeButtonProps) => {

    const {
        clickedUpgradeButton,
    } = useUserFlow();

    const [isHovering, setIsHovering] = useState<boolean>(false);

    const userFlow = useSelector((state: RootStateOrAny) => (state.userFlowReducer as UserFlowState).userFlow);

    const [upgradeButtonLoading, setUpgradeButtonLoading] = useState<boolean>(false);

    const onClickedUpgradeButton = async () => {

        setUpgradeButtonLoading(true);

        await clickedUpgradeButton(userFlow as string, feature);

        setUpgradeButtonLoading(false);
    }

    const { translatePlaceholder } = useTranslations();

    return (
        <AppButton
            as={AppButtonVariant.Warning}
            data-testid={testIds.UPGRADE_MY_ACCOUNT}
            data-elaction={trackAction}
            data-elposition={trackPosition}
            data-elscreen={trackScreen}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave= {() => setIsHovering(false)}
            disableElevation
            disableFocusRipple
            disableTouchRipple
            disableRipple
            loading={upgradeButtonLoading}
            startIcon={(
                <div className={`${classes.upgradeIcon} ${isHovering && classes.rotate}`}>
                    💎
                </div>
            )}
            disabled={upgradeButtonLoading}
            onClick={onClickedUpgradeButton}
            {...props}>
            <AppText className={classes.upgradeText} sx={{
                fontSize: small ? 10 : 14,
            }}>
                {buttonText || translatePlaceholder("UPGRADE")}
            </AppText>
        </AppButton>
    )
};

UpgradeButton.defaultProps = {
    trackAction: 'upgrade_button',
    trackScreen: 'unspecified',
    trackPosition: 'unspecified'
};

export default UpgradeButton;