import { Box } from '@mui/material';
import FullSizeContainer from 'Atomic/atoms/FullSizeContainer';
import DelayedComponent, { AnimationTypes } from 'Atomic/molecules/DelayedComponent';
import FullWidthContainer from 'Components/common/FullWidthContainer';
import Html from 'Components/common/util/html/Html';
import { getPlaySlideProperties } from 'Components/types/helpers/slideTypeHelper';
import { LayoutWrapper } from 'Components/types/slides/LayoutWrapper';
import { SlideConsumerComponent } from 'Types/appTypes';
import { PlaySlideType } from 'Types/playTypes';
import React from 'react';
import { classes } from './style.css';


const TitleSlideSession = ({ slide }: SlideConsumerComponent<PlaySlideType>) => {


    const slideProperties = getPlaySlideProperties(slide);

    return (
        <LayoutWrapper
            layoutType={slide.slideLayout}
            videoURL={slide.videoURL}
            imageURL={slide?.file?.base64 || slide?.filename}
            imageStyle={slide.filenameStyle}
            backgroundOpacity={slide.backgroundOpacity}
            backgroundColor={slide.backgroundColor}
            content={
                <FullSizeContainer>
                    <Box className={classes.inputContainer} px={10}>
                        <FullWidthContainer>
                            <DelayedComponent
                                style={{ height: "100%", width: '100%' }}
                                animationDuration={250}
                                animationType={AnimationTypes.Grow}
                                showAfter={500} >
                                <Html
                                    content={slide.titleHtml || slide.title || slideProperties.text.getHtmlTitlePlaceholder?.(slideProperties.text.titlePlaceholder)}
                                    fontSize={slideProperties.slideSettings.titleFontSize}
                                />
                            </DelayedComponent>
                        </FullWidthContainer>
                    </Box>
                </FullSizeContainer>
            }
        />
    );
};

export default TitleSlideSession;