import { BaseSlideParameters, ContentSlideType, FilenameCssStyle } from "Types/presentationTypes";
import { SlideTypes } from "Types/slideTypes";

/* Default properties of a Content Slide */
const ContentSlideModel = ({
	id,
	presentationId,
	slideIndex,
} : BaseSlideParameters ): ContentSlideType => {

	return {
		id,
		slideIndex,
		backgroundOpacity: '.4',
		backgroundColor: null,
		presentationId,
		title: '',
		content: '',
		isDeleted: 0,
		file: {},
		filename: null,
		filenameStyle: FilenameCssStyle.Cover,
		type: SlideTypes.Content,
		createdFrom: 'WebQuiz',
		createdAt: '',
		updatedAt: '',
		slideLayout: null,
		videoURL: null,
		dynamicTimerLimit: null,
		notes: '',
	}
}

export { ContentSlideModel };

