import FourOFour from 'Components/common/dialogs/login/fourOFour/FourOFour';
import ErrorLayout from 'Components/layouts/errorLayout/ErrorLayout';
import useUrl from 'Hooks/useUrl';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const ErrorRoutes = () => {
    const urls = useUrl();

	return (
			<Switch>
				<Route path={urls.fourOFour}>
                    <ErrorLayout>
                        <FourOFour/>
                    </ErrorLayout>
                </Route>
			</Switch>
    )
}    
export default ErrorRoutes;