import { ApiVoteAnswerResult, ContentSlideType, MessageRoundType, SlideType, VoteOption, VoteType } from './presentationTypes';

export interface LivePresentationData {
	hasResults: boolean;
	votes: {
		[voteId: number]: VoteType;
	};
	messagerounds: {
		[messageroundId: number]: MessageRoundType;
	}
	voteAnswers: {
		[voteId: number]: Array<VoteOption>
	};
}

export interface PlaySlideType extends MessageRoundType, VoteType, ContentSlideType {
	slideId: number;
	titleHtml: string;
	contentHtml: string;
	variant: SlideType;
}

export interface PlaySlideList {
	[slideKey: number]: PlaySlideType;
}
export enum SendshakeRequests {
	StopSession = 'StopSession',
	OpenVote = 'OpenVote',
	CloseVote = 'CloseVote',
	OpenMessageRound = 'OpenMessageRound',
	CloseMessageRound = 'CloseMessageRound',
	UserOpinion = 'UserOpinion',
	CalculateVoteResults = 'CalculateVoteResults',
}

export interface ParticipantVote {
	id: number;
	voteId: number;
	edited: number | boolean;
}

export interface ParticipantMessage {
	id: number;
	participantId: number;
	text: string;
	status: string;
	position: number;
	screenIndex: number;
	messageRoundId: number;
	upvoteCount: number;
}
export interface Participant {
	name?: string;
	participantId: number;
	joinTime?: Date;
	emoji?: string;
}

export interface ScoreObject {
	participantId: number;
	streak: Array<number>;
	points: number;
	rank: number;
}

export interface VoteScores {
	[voteId: number]: {
		[participantId: number]: ScoreObject
	};
}

export interface SessionData {
	sessionId: number,
	sessionRunId: number,
	endTime: number,
	availability: string
	hasMaxAudienceSize: boolean | number;
	audienceSize: number;
	textMessagingSelected: boolean | number;
	internetSelected: boolean | number;
	twitterSelected: boolean | number;
	countryName?: string;
	phoneNumber?: string;
	phoneNumberDisplayText?: string;
	textMessagingKeyword?: string;
	votingSupported: boolean | number;
	messagingSupported?: boolean | number;
	sessionType: string;
	anonymousSources: boolean | number;
	autoApprove: boolean | number;
	isSessionFree: boolean | number;
	isSendstepsEducation: boolean | number;
	identification: number;
	internetKeyword?: string;
	internetAddress?: string;
	twitterAddress?: string;
	participantsWithLoginCodeOnly?: boolean | number;
}

export interface ParticipantResponseList {
	[messageId: number]: ParticipantMessage;
}

export interface VoteResponseList {
	[voteId: number]: ParticipantResponseList;
}

export interface MessageResponseList {
	[messageRoundId: number]: ParticipantResponseList;
}

export interface CountryList {
	[isoCode: string]: string;
}

export interface ResponseCountMap {
	[id: number]: Set<number>;
}

export interface PlayState {
	joinedParticipants: Participant[];
	sessionRunId: null | number;
	runTimers: boolean;
	streakScores: boolean;
	newMessagesAreIn: boolean;
	votes: VoteResponseList;
    messageRoundId: null | number;
	messages: MessageResponseList;
	quizRanksBySlideKey: VoteScores;
	quizResults: null | ScoreObject[];
	leftParticipants: Participant[];
	sessionData: null | SessionData;
	playOpen: boolean;
	stopOpen: boolean;
	quizPaused: boolean;
	toggleEditPresentationOpen: boolean;
	playEndSlideTransition: boolean;
	playOpenWithResults: boolean;
	missingPresentationFeaturesOpen: boolean;
	countries: CountryList;
	selectedCountry: string | null,
	playSlides: PlaySlideList,
	messageCountsByMessageRoundId: ResponseCountMap;
	voteCountsByVoteId: ResponseCountMap;
	voteResults: Record<number, Record<string, ApiVoteAnswerResult>>;
}