import { TableCell, alpha } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { testIds } from 'Scripts/cypressTestIds';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { AppDynamicTableContext, TableCellAttributeNames, TableCellData, TableCellDataType, TableControlsPosition } from '../AppDynamicTable';
import AppDynamicTableCellControls from '../controls/AppDynamicTableCellControls';
import { classes } from './style.css';

interface AppDynamicTableCellProps {
    rowIndex: number;
    columnIndex: number;
    cell: TableCellData;
}

const AppDynamicTableCell = ({
    rowIndex,
    columnIndex,
    cell,
}: AppDynamicTableCellProps) => {

    const appTableContext = useContext(AppDynamicTableContext);

    const {
        showTableControls,
        columnCount,
        rowCount,
        canAddRow,
        canAddColumn,
        columnHasHeader,
        rowHasHeader,
        canDeleteColumn,
        canDeleteRow,
        onAddColumn,
        onAddRow,
        onHighlightCell,
        onEditCell,
        onHighlightTable,
        onDeleteColumn,
        onDeleteRow,
        onHighlightColumn,
        onHighlightRow,
        cellStyles = {},
    } = appTableContext;

    const cellRef = useRef<HTMLTableCellElement>(null);

    const cellInputRef = useRef<HTMLTextAreaElement>(null);

    const cellContentsPadding = 24;


    const handleCellSize = () => {

        if(!Boolean(cellInputRef.current)) {

            return;
        }

        const textArea = cellInputRef.current as HTMLTextAreaElement;

        /** Set height to auto to automatically expand element vertically based on its contents */
        textArea.style.height = 'auto';
		/** Extract the height based on the elements scrollheight and apply as height value */
        textArea.style.height = (textArea.scrollHeight - (cellContentsPadding * 2)) + 'px';
    }

    /**
     * Check if the user pressed tab on the last cell of the table.
     * If so, add a new row and focus the first cell of the new row
     */
    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {

        if ((
            e.key === 'Tab' &&
            (columnCount - 1 === columnIndex) &&
            (rowCount - 1 === rowIndex) &&
            canAddRow
        )) {

            e.preventDefault();

            e.stopPropagation();

            onAddRow(rowIndex + 1);

            requestAnimationFrame(() => {

                if (Boolean(cellInputRef.current)) {

                    var focusableElements = Array.from(document.getElementsByClassName('table-cell-textarea'));

                    var currentIndex = focusableElements.indexOf(cellInputRef.current as HTMLTextAreaElement);

                    var nextIndex = (currentIndex + 1) % focusableElements.length;

                    var nextElement = focusableElements[nextIndex];

                    if (nextElement) {

                        (nextElement as HTMLElement).focus();
                    }
                }
            })
        }
    }

    const handleFocus = () => {

        if (Boolean(cellInputRef.current)) {

            cellInputRef.current?.focus();
        }

        onHighlightCell(true, TableCellAttributeNames.Select, columnIndex, rowIndex);
    }

    const handleBlur = () => {

        onHighlightCell(false, TableCellAttributeNames.Select, columnIndex, rowIndex);

        const cellInputElement = cellInputRef.current as HTMLTextAreaElement;

        onEditCell(rowIndex, columnIndex, cellInputElement.value);
    }

    const getCellTypeStyling = (cellType: TableCellDataType) => {

        if (cellType === TableCellDataType.Heading) {

            return {
                fontWeight: 700,
                backgroundColor: alpha('#000', .05),
            }
        }

        return {}
    }

    const cellStyle = useMemo(() => {

        try {

            return JSON.parse(cell.jss);

        } catch (error) {

            console.warn({ error });

            return {};
        }

    }, [cell.jss])

    useEffect(() => {

        if (Boolean(cellInputRef.current) && Boolean(cell.content)) {

            (cellInputRef.current as HTMLTextAreaElement).value = cell.content as string;
        }

        handleCellSize();

    }, [cell.content, cellInputRef])

    const currentRowIsHeading = (rowIndex === 0) && rowHasHeader;

    const currentColumnIsHeading = (columnIndex === 0) && columnHasHeader;

    const theme = useTheme();

    return (
        <TableCell

            ref={cellRef}
            data-cell-column={columnIndex}
            data-cell-row={rowIndex}
            className={classes.tableCell}
            style={{
                ...getCellTypeStyling(cell.cellType),
            }}
            sx={{
                border: `1px solid ${cellStyles.borderColor || `#C1C7D0`}`,
                color: `${cellStyles.color || theme.palette.common.black}`,
            }}
        >
            <div
                onClick={handleFocus}
                className={`${classes.tableCellContents} table-cell-contents`}
                >
                <textarea
                    data-testid={`${testIds.TABLE_SLIDE_CELL}-${rowIndex}-${columnIndex}`}
                    ref={cellInputRef}
                    onChange={handleCellSize}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    rows={1}
                    className={`${classes.tableCellTextArea} table-cell-textarea`}
                    style={{
                        ...cellStyle,
                        width: `calc(100% - ${cellContentsPadding * 2}px)`,
                        padding: cellContentsPadding,
                    }}
                />
            </div>
            {rowIndex === 0 && columnIndex === 0 && (
                <Box
                    onClick={() => onHighlightTable(true, TableCellAttributeNames.Select)}
                    className={`${classes.cornerControlsPlaceholder} table-cell-corner`}
                    sx={{
                        border: `1px solid ${cellStyles?.borderColor || `#C1C7D0`}`,
                        opacity: Number(showTableControls),
                        background: `${alpha(cellStyles?.color || '#b5b5b5', .2)}`,
                    }}
                />
            )}
            {rowIndex === 0 && (
                <AppDynamicTableCellControls
                    rowIndex={rowIndex}
                    columnIndex={columnIndex}
                    tableCellRef={cellRef}
                    position={TableControlsPosition.Top}
                    onSelect={(shouldHighlight, highlightType) => onHighlightColumn(shouldHighlight, highlightType, columnIndex)}
                    onAddBefore={() => onAddColumn(columnIndex)}
                    onAddAfter={() => onAddColumn(columnIndex + 1)}
                    onDelete={() => onDeleteColumn(columnIndex)}
                    canDelete={canDeleteColumn}
                    canAdd={canAddColumn && !currentColumnIsHeading}
                />
            )}
            {columnIndex === 0 && (
                <AppDynamicTableCellControls
                    rowIndex={rowIndex}
                    columnIndex={columnIndex}
                    tableCellRef={cellRef}
                    position={TableControlsPosition.Side}
                    onSelect={(shouldHighlight, highlightType) => onHighlightRow(shouldHighlight, highlightType, rowIndex)}
                    onAddBefore={() => onAddRow(rowIndex)}
                    onAddAfter={() => onAddRow(rowIndex + 1)}
                    onDelete={() => onDeleteRow(rowIndex)}
                    canDelete={canDeleteRow}
                    canAdd={canAddRow && !currentRowIsHeading}
                />
            )}
        </TableCell>

    )
}

export default AppDynamicTableCell;