import { Typography } from '@mui/material';
import React from 'react';
import { classes } from './style.css';


const Caption = ( { title } ) => {


	return (
		<div className={classes.captionTitle}>
			<Typography variant="caption">
				{title}
			</Typography>
		</div>
	);
};

export default Caption;