import { Typography } from '@mui/material';
import { setPreventPresentationAutoMovement } from 'Actions/quizActions';
import OnOffSwitch from 'Components/beta/common/buttons/switch/OnOffSwitch';
import Caption from 'Components/beta/common/caption/Caption';
import SummaryOption from 'Components/beta/common/summary/option/SummaryOption';
import { useAppRouting } from 'Hooks/useAppRouting';
import { useTranslations } from 'Hooks/useTranslations';
import { post } from 'Scripts/api';
import { DataElAction, DataElPosition, DataElScreen, DataElType, renderingGAAttr } from 'Scripts/measurementsGAHelper';
import { OverviewState } from 'Types/overviewTypes';
import { PresentationState } from 'Types/presentationTypes';
import React, { useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';


const AutoMovementSelect = () => {

	const { presentationId } = useAppRouting();

	const [toggleLoading, setToggleLoading ] = useState<boolean>(false);

	const dispatch = useDispatch();

	const { translatePlaceholder } = useTranslations();

    const presentationLoading = useSelector((state: RootStateOrAny) => (state.overviewReducer as OverviewState).presentationLoading);

	const preventAutoSlideMovement = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).preventAutoSlideMovement);

	const toggleAutoMovement = async () => {

		try {

			setToggleLoading(true);

			const response = await post(`presentations/${presentationId}/auto-movement`);

			if(!response.error) {

				dispatch(setPreventPresentationAutoMovement(response));

				return;
			}

		} catch (error) {

			console.warn(error);

		} finally {

			setToggleLoading(false);
		}
	}

	return (
		<SummaryOption
			loading={presentationLoading}
			leftItem={<div>
				<Typography variant="body2" >
					{translatePlaceholder("AUTOMATIC_TRANSITIONS")}
				</Typography>
				<Caption title={translatePlaceholder("AUTOMATIC_TRANSITIONS_EXPLANATION")} />
			</div>
			}
			rightItem={(
                <OnOffSwitch
                    disabled={toggleLoading}
                    checked={!preventAutoSlideMovement}
                    onChange={() => toggleAutoMovement()}
                />
			)}
			trackingAttr={renderingGAAttr(DataElAction.ToggleAutoMovement, DataElPosition.MainOptSection, DataElScreen.Settings, DataElType.Toggle)}
		/>
	);
}

export default AutoMovementSelect;