import { AnyAction } from "redux";
import { BillingState } from "Types/billingTypes";

// @todo split up this state
export const initialBillingState: BillingState = {
    billingHasAutomaticRenewal: false,
    billingType: null,
    billingLicenseEndDate: null,
    billingLoading: false,
    billingRenewalLoading: false,
    billingSelectedInvoiceId: null,
    billingCancelRenewalErrors: {},
    billingAdyenDropinLoading: false,
    billingAccountDataLoading: false,
    billingAccountDataUpdating: false,
    billingAvailableTimezones: [],
    billingAvailableCountries: [],
    billingAccountDataErrors: {},
    billingAccountData: {
        postalCode: '',
		city: '',
		country: '',
		vatId: '',
		timezone: '',
		address: '',
		university: '',
    },
    billingInvoicePageData: {
        totalResultsCount: 0,
        filteredResultsCount: 0,
        pageResultsCount: 0,
        pageIndex: 0,
        pageData: [],
        pageSize: 10,
        pageLoading: false,
        sortProperty: {
            id: 'invoiceId',
            desc: true,
        }
    },
    billingSelectedPaymentMethod: null,
    billingSelectedInvoiceData: {
        accountData: null,
        order: null,
        orderDetails: [],
        currency: {},
    },
    billingInvoiceDataLoading: false,
    billingLastOrderData: null,
    billingLastOrderLoading: false,
}

export const billingReducer = (state: BillingState = initialBillingState, action: AnyAction) => {
	switch (action.type) {
        case 'SET_LAST_ORDER_DATA': {
            return {
                ...state,
                billingLastOrderData: action.orderData,
            }
        }
        case 'SET_LAST_ORDER_LOADING': {
            return {
                ...state,
                billingLastOrderLoading: action.loading,
            }
        }
        case 'SET_CURRENT_PAYMENT_METHOD': {
            return {
                ...state,
                billingSelectedPaymentMethod: action.paymentMethod,
            }
        }
        case 'SET_BILLING_INVOICE_DATA_LOADING': {
            return {
                ...state,
                billingInvoiceDataLoading: action.loading,
            }
        }
        case 'SET_INVOICE_DATA': {
            return {
                ...state,
                billingSelectedInvoiceData: action.data
            }
        }
        case 'SET_SELECTED_INVOICE_ID': {
            return {
                ...state,
                billingSelectedInvoiceId: action.id,
            }
        }
        case 'SET_BILLING_INVOICE_PAGE_LOADING': {

            return {
                ...state,
                billingInvoicePageData: {
                    ...state.billingInvoicePageData,
                    pageLoading: action.loading
                }
            }
        }
        case 'SET_INVOICE_PAGE_INDEX': {
            return {
                ...state,
                billingInvoicePageData: {
                    ...state.billingInvoicePageData,
                    pageIndex: action.pageIndex
                }
            }
        }
        case 'SET_INVOICE_PAGE_SORT_PROPERTY': {
            return {
                ...state,
                billingInvoicePageData: {
                    ...state.billingInvoicePageData,
                    sortProperty: action.sortProperty,
                    pageIndex: 0,
                }
            }
        }
        case 'SET_BILLING_PAGE_DATA': {

            return {
                ...state,
                billingInvoicePageData: {
                    ...state.billingInvoicePageData,
                    ...action.pageData,
                }
            }
        }
        case 'SET_BILLING_ACCOUNT_DATA_UPDATING': {
            return {
                ...state,
                billingAccountDataUpdating: action.updating,
            }
        }
        case 'SET_BILLING_ACCOUNT_DATA_ERRORS': {

            return {
                ...state,
                billingAccountDataErrors: action.errors,
            }
        }
        case 'SET_BILLING_ACCOUNT_DATA_LOADING': {

            return {
                ...state,
                billingAccountDataLoading: action.loading
            }
        }
        case 'SET_ACCOUNT_DATA': {

            return {
                ...state,
                billingAccountData: action.data,
                billingAccountDataErrors: {}
            }
        }
        case 'SET_AVAILABLE_TIMEZONES': {

            return {
                ...state,
                billingAvailableTimezones: action.timezones,
            }
        }
        case 'SET_AVAILABLE_COUNTRIES': {

            return {
                ...state,
                billingAvailableCountries: action.countries,
            }
        }
        case 'SET_ADYEN_DROPIN_LOADING': {
            return {
                ...state,
                billingAdyenDropinLoading: action.loading,
            }
        }
        case 'SET_CANCEL_RENEWAL_ERRORS': {
            return {
                ...state,
                billingCancelRenewalErrors: action.errors,
            }
        }
        case 'SET_RENEWAL_LOADING': {
            return {
                ...state,
                billingRenewalLoading: action.loading,
            }
        }
        case 'SET_BILLING_DATA': {

            return {
                ...state,
                billingHasAutomaticRenewal: action.data.hasAutomaticRenewal,
                billingLicenseEndDate: action.data.licenseEndDate,
                billingType: action.data.billingType,
            }
        }
        case 'SET_BILLING_LOADING': {

            return {
                ...state,
                billingLoading: action.loading
            }
        }
		default:
			return state
	}
}
