import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import usePresentationStyles from 'Hooks/usePresentationStyles';
import { useTranslations } from 'Hooks/useTranslations';
import { PlayState } from 'Types/playTypes';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';


const SessionInternetInfo = () => {

	const sessionData = useSelector((state: RootStateOrAny) => (state.playReducer as PlayState).sessionData);

	const { presentationStyle } = usePresentationStyles();

	const { translate } = useTranslations();

	const theme = useTheme();

	return (
		<Box display="flex">
			{!!Number(sessionData.textMessagingSelected) && (
				<Typography
					variant="h5" mr={4}
					style={{
						minWidth: 128
					}}
					sx={{
						color: presentationStyle?.textColor,
						fontWeight: 600,
						fontSize: theme.spacing(4),
					}}>
					{translate('INTERNET')}
				</Typography>
			)}
			<Typography
				variant="h5"
				sx={{
					color: presentationStyle?.textColor,
					fontWeight: 600,
					fontSize: theme.spacing(4),
				}}>
				{`${sessionData.internetAddress} `}
				{translate('LOG_IN_WITH', [sessionData.internetKeyword])}
			</Typography>
		</Box>
	);
};

export default SessionInternetInfo;