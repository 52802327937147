import { PresentationStyleType } from 'Types/presentationTypes';

export const setPresentationStyles = (styles: Array<PresentationStyleType>) => ({
    type: 'SET_PRESENTATION_STYLES',
    styles
});

export const updateCurrentPresentationStyle = (id: number, attribute: string, value: string) => ({
    type: 'UPDATE_CURRENT_PRESENTATION_STYLE',
    id,
    attribute,
    value,
})