import FullSizeContainer from 'Atomic/atoms/FullSizeContainer';
import AppChart, { AppChartType } from 'Atomic/organisms/Chart/AppChart';
import { slideTableToChartData } from 'Scripts/tableHelper';
import { SlideType } from 'Types/presentationTypes';
import React from 'react';
import { classes } from './style.css';


interface Props {
    graphType: AppChartType;
    slide: SlideType;
}

const PreviewGraphSlideShape = ({
    slide,
    graphType,
}: Props) => {

    return (
        <FullSizeContainer>
            <div
                className={classes.chartContainer}>
                <AppChart
                    chartType={graphType}
                    chartData={slideTableToChartData(slide.table)}
                    chartElementProps={{
                        className: classes.chart,
                    }}
                />
            </div>
        </FullSizeContainer>
    );
};

export default PreviewGraphSlideShape;