import { AnyAction } from "redux";
import { AppTranslationState } from "Types/appTranslationTypes";
import { defaultAvailableLanguages, defaultLanguage } from "Types/languageTypes";

export enum AppTranslationActionTypes {
    SetAppLanguage           = 'SET_APP_LANGUAGE',
    SetAppAvailableLanguages = 'SET_APP_AVAILABLE_LANGUAGES',
    SetAppLanguageLoading    = 'SET_APP_LANGUAGE_LOADING',
    SetApptranslations 		 = 'SET_APP_TRANSLATIONS',
}

export const initialState: AppTranslationState = {
	language: defaultLanguage,
	availableLanguages: defaultAvailableLanguages,
	updateLanguageLoading: false,
	translations: {}
}

export function appTranslationReducer(state = initialState, action: AnyAction) {
	switch (action.type) {
		case AppTranslationActionTypes.SetAppLanguage: {
			return {
				...state,
				language: action.language
			}
		}
		case AppTranslationActionTypes.SetAppAvailableLanguages: {
            return {
                ...state,
                availableLanguages: action.availableLanguages,
            }
		}
		case AppTranslationActionTypes.SetAppLanguageLoading: {
			return {
				...state,
				updateLanguageLoading: action.isLoading,
			}
		}
		case AppTranslationActionTypes.SetApptranslations: {
			return {
				...state,
				translations: action.translations
			}
		}
		default:
			return state
	}
}
