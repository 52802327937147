import { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import * as router from 'react-router-dom';
import { PresentationState } from "Types/presentationTypes";

export const useAppRouting = () => {

	const match = router?.useRouteMatch() || null;

	const location = router?.useLocation() || null;

	const reducerPresentationId = useSelector((state: RootStateOrAny) => (state.quizReducer as PresentationState).presentationId);

	const extractPresentationIdFromUrl = () => {

		const pathIds = window.location.pathname.split('/').filter(Number).map(Number);

		const [ presentationId, _ ] = pathIds

		return presentationId;
	}

	const extractSessionRunidFromUrl = () => {

		const pathIds = window.location.pathname.split('/').filter(Number).map(Number);

		const [ sessionRunId, _ ] = pathIds

		return sessionRunId;
	}

	const extractSlideKeyFromUrl = () => {

		const pathIds = window.location.pathname.split('/').filter(Number).map(Number);

		const [_, slideKey] = pathIds

		return slideKey;
	}

	const presentationId: number = useMemo(() => {

		const pathMatch = router?.matchPath(location.pathname, { path: `${match?.path}/:presentationId` });

		return Number(pathMatch?.params?.presentationId || match?.params?.presentationId) || reducerPresentationId || extractPresentationIdFromUrl();

	}, [location.key, window.location.href, reducerPresentationId]);

	const sessionRunId: number = useMemo(() => {

		const pathMatch = router?.matchPath(location.pathname, { path: `${match?.path}/:sessionRunId` });

		return Number(pathMatch?.params?.sessionRunId || match?.params?.sessionRunId) || extractSessionRunidFromUrl() || 0;

	}, [location.key, window.location.href]);

	const currentSlideKey: number = useMemo(() => {

		const pathMatch = router?.matchPath(location.pathname, { path: `${match?.path}/:presentationId/create/:slideKey` });

		return Number(pathMatch?.params?.slideKey || match?.params?.slideKey || extractSlideKeyFromUrl()) || 0;

	}, [location.key]);

	return {
		presentationId,
		currentSlideKey,
		sessionRunId,
	}
}