import { DerivedFeatures, Features } from "Hooks/useFeatures";
import { SendstepsIntegrations } from "Types/appTypes";
import { MobileEditorTabs, SelectSlideTab, SlideOptionTabs, SlideTypeTabs } from "Types/layoutTypes";
import { SlideTypes } from "Types/slideTypes";

export const toggleSpeakerNotes = (show: boolean) => ({
	type: 'TOGGLE_SPEAKER_NOTES',
	payload: show,
})

export const setShowSlideLogoUploadDialog = (show: boolean) => ({
	type: 'SET_SHOW_SLIDE_LOGO_UPLOAD_DIALOG',
	payload: show,
})

export const setPlanDetailsLoading = (isLoading: boolean) => ({
	type: 'TOGGLE_PLAN_DETAILS_LOADING',
	payload: isLoading,
})

export const setShownZohoFormUrl = (url: string | null) => ({
	type: 'SET_SHOWN_ZOHO_FORM_URL',
	payload: url
});

export const setGuidedTourRunning = (isOpen: boolean) => ({
	type: 'SET_GUIDED_TOUR_RUNNING',
	payload: isOpen
});

export const setGuidedTourStep = (step: number) => ({
	type: 'SET_GUIDED_TOUR_STEP',
	payload: step
});

export const setShowWelcomeNewUser = (isOpen: boolean) => ({
	type: 'SHOW_CREATE_FIRST_PRESENTATION',
	payload: isOpen,
})

export const toggleLeftDrawer = (isOpen: boolean) => ({
	type: 'TOGGLE_LEFT_DRAWER',
	payload: isOpen,
});

export const toggleRightDrawer = (isOpen: boolean) => ({
	type: 'TOGGLE_RIGHT_DRAWER',
	payload: isOpen,
})

export const showMissingFeature = (missingFeature: Features|DerivedFeatures|null) => ({
    type: 'SHOW_MISSING_FEATURE',
    payload: missingFeature,
})

export const setSlideIdToRemove = (slideKey: number | null) => ({
	type:'SET_SLIDE_ID_TO_REMOVE',
	payload: slideKey,
})

export const toggleAdvancedPresentationOptions = (isOpen: boolean) => ({
	type: 'TOGGLE_ADVANCED_PRESENTATION_OPTIONS',
	payload: isOpen,
})

export const setLicenseRequestFailedOverlayOpen = (isOpen: boolean) => ({
	type: 'SET_LICENSE_REQUEST_FAILED_OVERLAY_OPEN',
	payload: isOpen
})

export const setWelcomeOverlayOpen = (isOpen: boolean) => ({
	type: 'SET_WELCOME_OVERLAY_OPEN',
	payload: isOpen
})

export const setLicenseEligibleOverlayOpen = (isOpen: boolean) => ({
	type: 'SET_LICENSE_ELIGIBLE_OVERLAY_OPEN',
	payload: isOpen
})

export const setMultiLicenseEligibleOverlayOpen = (isOpen: boolean) => ({
	type: 'SET_MULTI_LICENSE_OVERLAY_OPEN',
	payload: isOpen
})

export const toggleAppSurveyModal = (isOpen: boolean) => ({
	type: 'TOGGLE_APP_SURVEY_MODAL',
	payload: isOpen,
})

export const toggleSupportPanel = (open: boolean) => ({
	type: 'TOGGLE_SUPPORT_PANEL',
	payload: open,
})

export const togglePaymentSuccessDialogOpen = (isOpen: boolean) => ({
	type: 'TOGGLE_PAYMENT_SUCCESS_DIALOG_OPEN',
	payload: isOpen,
})

export const togglePaymentPendingDialogOpen = (isOpen: boolean) => ({
	type: 'TOGGLE_PAYMENT_PENDING_DIALOG_OPEN',
	payload: isOpen,
})

export const togglePaymentFailedDialogOpen = (isOpen: boolean) => ({
	type: 'TOGGLE_PAYMENT_FAILED_DIALOG_OPEN',
	payload: isOpen,
})

export const toggleNewsFeedOpen = (isOpen: boolean) => ({
	type: 'TOGGLE_NEWS_FEED_OPEN',
	payload: isOpen,
})

export const setSelectedMobileEditorTab = (tab: MobileEditorTabs) => ({
	type: 'SET_SELECTED_MOBILE_EDITOR_TAB',
	payload: tab,
})

export const setShowCantDownloadAddinFromMobileDevice = (isOpen: boolean) => ({
	type: 'SET_SHOW_CANT_DOWNLOAD_ADDIN',
	payload: isOpen,
})

export const setShowDeletePresentationWithoutSlides = (isOpen: boolean) => ({
	type: 'SET_SHOW_DELETE_PRESENTATION_WITHOUT_SLIDES',
	payload: isOpen,
})

export const setSelectedSettingsIntegration = (integration: SendstepsIntegrations) => ({
    type: 'SET_SELECTED_SETTINGS_INTEGRATION',
    payload: integration
})

export const setShowCantEditInspirationTemplateDialog = (isOpen: boolean) => ({
	type: 'SET_SHOW_CANT_EDIT_INSPIRATION_TEMPLATE_DIALOG',
	payload: isOpen,
})

export const setShowMissingPresentationFeatures = (missingFeatures: Array<string> | null) => ({
	type: 'SET_SHOW_MISSING_PRESENTATION_FEATURES',
	payload: missingFeatures
})

export const setSelectedAddSlideDialogTab = (tab: SelectSlideTab) => ({
	type: 'SET_SELECT_SLIDE_DIALOG_ACTIVE_TAB',
	payload: tab,
})

export const setExampleSlideLayoutShown = (type: SlideTypes | null) => ({
	type: 'SET_EXAMPLE_SLIDE_LAYOUT_SHOWN',
	payload: type,
})

export const setSlideOptionTab = (tab: SlideOptionTabs) => ({
	type: 'SET_SLIDE_OPTION_TAB',
	payload: tab,
})

export const setSlideTypeTab = (tab: SlideTypeTabs) => ({
	type: 'SET_SLIDE_TYPE_TAB',
	payload: tab,
})

export const setShowImageUploadDialog = (isOpen: boolean) => ({
	type: 'SET_SHOW_IMAGE_UPLOAD_DIALOG',
	payload: isOpen,
})

export const setShowAiPresentationReadyDialog = (isOpen: boolean) => ({
	type: 'SET_SHOW_AI_PRESENTATION_READY_DIALOG',
	payload: isOpen,
})

export const setShowUploadDocumentDialog = (isOpen: boolean) => ({
	type: 'SET_SHOW_UPLOAD_DOCUMENT_DIALOG',
	payload: isOpen,
})

export const setIsRewriting = (isRewriting: boolean) => ({
	type: 'SET_IS_REWRITING',
	payload: isRewriting,
})